import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Box,
  Slider,
} from "@material-ui/core";

import NumberFormat from "react-number-format";
import * as Yup from "yup";
import { Formik } from "formik";
import { listGlobalSettings } from "../../../crud/global-settings.crud";
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

const CustomRadioGroup = ({ label, data, onChange }) => {
  const [value, setValue] = useState(data);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

const marks = [
  {
    value: 0,
    label: "0 Booking",
  },
  {
    value: 25,
    label: "25 Bookings",
  },
  {
    value: 50,
    label: "50 Bookings",
  },
  {
    value: 75,
    label: "75 Bookings",
  },
  {
    value: 100,
    label: "100 Bookings",
  },
];

const AppointmentRevenueCalculator = () => {
  const classes = useStyles();
  const ref = useRef(null);

  const [globalConfig, setGlobalConfig] = useState();
  const [discountDueToPromo, setDiscountDueToPromo] = useState("no");
  const [appointmentCompleted, setAppointmentCompleted] = useState("yes");
  const [amountPaidByCustomer, setAmountPaidByCustomer] = useState({
    total: 0,
    convenienceFee: 0,
    gst: 0,
    grossTotal: 0,
  });
  const [
    amountReceivedByProfessional,
    setAmountReceivedByProfessional,
  ] = useState({
    total: 0,
    convenienceFee: 0,
    gst: 0,
    grossTotal: 0,
  });

  const [
    amountReceivedByInstitution,
    setAmountReceivedByInstitution,
  ] = useState({
    total: 0,
  });
  const [
    amountCollectedAsGST,
    setAmountCollectedAsGST,
  ] = useState({
    total: 0,
  });
  const [
    transactionCharges,
    setTransactionCharges,
  ] = useState({
    total: 0,
  });
  const [
    amountRemainingInParentcraft,
    setAmountRemainingInParentcraft,
  ] = useState({
    total: 0,
  });

  const [noOfSeat, setNoOfSeat] = useState([50]);
  const [netAmountRemainingWithPC, setNetAmountRemainingWithPC] = useState(0);

  const checkRevenue = (refValues) => {
    const values = refValues ? refValues : ref.current.values;
    if (globalConfig && values && values.appointmentFee) {
      if (Number(values.appointmentFee)) {
        const appointmentFeeValue = Number(values.appointmentFee);
        const gstForCustomer = Number(
          (values.convenienceFee * globalConfig?.gstPercentage) / 100
        );
        const updatedAppointmentFee =
          discountDueToPromo === "yes"
            ? (appointmentFeeValue * (100 - values.discountPercentPromo)) /
              100
            : appointmentFeeValue;
        const grossTotalForCustomer = Number(updatedAppointmentFee) + Number(values.convenienceFee) + gstForCustomer;
        setAmountPaidByCustomer({
          total: updatedAppointmentFee,
          convenienceFee: Number(values.convenienceFee),
          gst: gstForCustomer,
          grossTotal: grossTotalForCustomer,
        });
        const updatedAppointmentFeeForProf =
          appointmentCompleted === "yes"
            ? updatedAppointmentFee
            : (updatedAppointmentFee * values.vendorPercentForNoShow) / 100;
        const convenienceFeeForProf =
          (updatedAppointmentFeeForProf *
            values.vendorPercentForConvenienceFee) /
          100;
        const gstForProf = Number(
          (convenienceFeeForProf * globalConfig?.gstPercentage) / 100
        );
        const grossTotalForProf =  Number(updatedAppointmentFeeForProf) - (Number(convenienceFeeForProf) + gstForProf);
        setAmountReceivedByProfessional({
          total: updatedAppointmentFeeForProf,
          convenienceFee: convenienceFeeForProf,
          gst: gstForProf,
          grossTotal: grossTotalForProf
        });
        const transactionChargesTotal = (Number(grossTotalForCustomer) * values.gatewayCharges)/100 + (Number(grossTotalForProf) * values.bankCharges)/100;
        setTransactionCharges({
          total: transactionChargesTotal
        });

        const updatedTotalForInstitution =
          discountDueToPromo === "yes"
            ? (updatedAppointmentFee * values.institutionPercent) / 100
            : 0;
        setAmountReceivedByInstitution({
          total: updatedTotalForInstitution,
        });
        const updatedAppointmentFeeForPC =
          appointmentCompleted === "yes"
            ? 0
            : (updatedAppointmentFee * (100 - values.vendorPercentForNoShow)) /
              100;
        const amountRemainingInPCTotal = 
        (Number(values.convenienceFee) +
        convenienceFeeForProf +
        updatedAppointmentFeeForPC) - (updatedTotalForInstitution+transactionChargesTotal);
        setAmountRemainingInParentcraft({
          total: amountRemainingInPCTotal,
        });

        setAmountCollectedAsGST({
          total: Number(gstForCustomer + gstForProf)
        });

        setNetAmountRemainingWithPC(amountRemainingInPCTotal * noOfSeat[0]);
      } else {
        setAmountPaidByCustomer({
          total: 0,
          convenienceFee: 0,
          gst: 0,
          grossTotal: 0,
        });
        setAmountReceivedByProfessional({
          total: 0,
          convenienceFee: 0,
          gst: 0,
          grossTotal: 0,
        });
        setAmountReceivedByInstitution({
          total: 0,
        });
        setAmountRemainingInParentcraft({
          total: 0,
        });
      }
    }
  };

  const handleChangeSliderOne = (_event, newValue) => {
    setNoOfSeat(newValue);
    setNetAmountRemainingWithPC(amountRemainingInParentcraft.total * newValue[0]);
  };

  useEffect(() => {
    listGlobalSettings({ skip: 0, limit: 0 }).then((data) => {
      setGlobalConfig(data.data.data);
    });
  }, []);

  useEffect(() => {
    checkRevenue();
    // eslint-disable-next-line
  }, [discountDueToPromo, appointmentCompleted]);

  return (
    <Formik
      innerRef={ref}
      enableReinitialize={true}
      initialValues={{
        appointmentFee: 0,
        vendorPercentForConvenienceFee: globalConfig
          ? globalConfig.defaultVendorCommissionCut
          : 0,
        convenienceFee: globalConfig ? globalConfig.convenienceFee : 0,
        vendorPercentForNoShow: 70,
        gatewayCharges: 1.99,
        discountPercentPromo: globalConfig
          ? globalConfig.promoCodeGeneral.discountPercentageOn.appointment
          : 0,
        institutionPercent: globalConfig
          ? globalConfig.promoCodeGeneral.institutionCommission.appointment
          : 0,
        bankCharges: 0
      }}
      validationSchema={Yup.object({
        appointmentFee: Yup.number().required(),
        vendorPercentForConvenienceFee: Yup.number().required(),
        convenienceFee: Yup.number().required(),
        gatewayCharges: Yup.number().required(),
        vendorPercentForNoShow: Yup.number().required(),
        discountPercentPromo: Yup.number().required(),
        institutionPercent: Yup.number().required(),
        bankCharges: Yup.number().required(),
      })}
      onSubmit={(values) => {
        checkRevenue(values);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div>
          <Card style={{ padding: 16, marginBottom: 16 }}>
            <h5>Variables</h5>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={3}>
                <TextField
                  label="Appointment Fee"
                  name="appointmentFee"
                  className={classes.textField}
                  placeholder="Enter appointment fee"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.appointmentFee}
                  error={Boolean(errors.appointmentFee)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Vendor % for Convenience Fee"
                  name="vendorPercentForConvenienceFee"
                  className={classes.textField}
                  placeholder="Enter vendor % for convenience fee"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vendorPercentForConvenienceFee}
                  error={Boolean(errors.vendorPercentForConvenienceFee)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Discount % Promo"
                  name="discountPercentPromo"
                  className={classes.textField}
                  placeholder="Enter appointment fee"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.discountPercentPromo}
                  error={Boolean(errors.discountPercentPromo)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Gateway Charges"
                  name="gatewayCharges"
                  className={classes.textField}
                  placeholder="Enter gateway charges"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gatewayCharges}
                  error={Boolean(errors.gatewayCharges)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Convenience Fee"
                  name="convenienceFee"
                  className={classes.textField}
                  placeholder="Enter convenience fee"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.convenienceFee}
                  error={Boolean(errors.convenienceFee)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Vendor % for No Show"
                  name="vendorPercentForNoShow"
                  className={classes.textField}
                  placeholder="Enter vendor % for No Show"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vendorPercentForNoShow}
                  error={Boolean(errors.vendorPercentForNoShow)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Institution %"
                  name="institutionPercent"
                  className={classes.textField}
                  placeholder="Enter institution %"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.institutionPercent}
                  error={Boolean(errors.institutionPercent)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Bank Charges"
                  name="bankCharges"
                  className={classes.textField}
                  placeholder="Enter bank charges"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.bankCharges}
                  error={Boolean(errors.bankCharges)}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 8,
              }}
            >
              <Button variant="contained" size="small" onClick={handleSubmit}>
                Check
              </Button>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <CardContent style={{ paddingBottom: 8 }}>
              <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={6}>
                  <CustomRadioGroup
                    label="Got discount due to promo code"
                    data={discountDueToPromo}
                    onChange={(value) => {
                      setDiscountDueToPromo(value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomRadioGroup
                    label="Appointment Completed"
                    data={appointmentCompleted}
                    onChange={(value) => {
                      setAppointmentCompleted(value);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Amount paid by customer
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountPaidByCustomer.total.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>Convenience Fee</p>
                      <p>{amountPaidByCustomer.convenienceFee.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>GST ({globalConfig?.gstPercentage}%)</p>
                      <p>{amountPaidByCustomer.gst.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountPaidByCustomer.grossTotal.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Amount received by professional
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountReceivedByProfessional.total.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>Convenience Fee</p>
                      <p>{amountReceivedByProfessional.convenienceFee.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>GST ({globalConfig?.gstPercentage}%)</p>
                      <p>{amountReceivedByProfessional.gst.toFixed(2)}</p>
                    </div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountReceivedByProfessional.grossTotal.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Amount received by institution
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountReceivedByInstitution.total.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Amount collected as GST
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountCollectedAsGST.total.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Transaction charges
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{transactionCharges.total.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent style={{ paddingBottom: 0 }}>
                  <h5 style={{ lineHeight: "32px" }}>
                    Amount remaining with parentcraft
                  </h5>
                  <div>
                    <div className="flex-between">
                      <p>Total</p>
                      <p>{amountRemainingInParentcraft.total.toFixed(2)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Card style={{ padding: 16, marginBottom: 16 }}>
            <h5 style={{ lineHeight: "32px", paddingBottom: 32 }}>
              Average bookings per month
            </h5>
            <Box width={"100%"} style={{ paddingLeft: 24, paddingRight: 24 }}>
              <Slider
                size="small"
                defaultValue={noOfSeat}
                aria-label="Small"
                valueLabelDisplay="auto"
                marks={marks}
                onChange={handleChangeSliderOne}
              />
            </Box>
            <div>
              <div className="flex-center">
                <p style={{ fontSize: 42 }}>
                  {netAmountRemainingWithPC.toFixed(2)}
                </p>
                <h6>Net Amount remaining with parentcraft</h6>
              </div>
            </div>
          </Card>
        </div>
      )}
    </Formik>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
});
export default AppointmentRevenueCalculator;