import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { removeInstitution } from '../../../crud/institution.crud';
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import { connect } from "react-redux";
import * as institution from "../../../store/ducks/institution.duck";
const RemoveInstitution = (props) => {
    console.log(props);
    const { classes, intl } = props;
    const [loading,
        setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        removeInstitution(props.institution._id).then((output) => {
            if (output.data.success) {
                props.institutionUpdated({ skip: 0, limit: 5 });
                setLoading(false);
                toast.success('Institution is successfully deleted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                props.handleClose();
            }
        }).catch((error) => {
            console.log('error', error);
            setLoading(false);
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                toast.error(intl.formatMessage({
                    id: ApiErrorMessage[error.response.data.errorCode]
                }, { title: props.category.title }), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        });

    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.institution.personalInfo.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this institution?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        No
                    </Button>

                    <Button
                        variant="primary"
                        style={{
                            position: "relative"
                        }}
                        onClick={() => handleDelete(props.institution._id)}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        institutionUpdated: ({ skip, limit }) => dispatch(institution.actions.institutionUpdated({ skip, limit }))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), { withTheme: true })(RemoveInstitution)));
