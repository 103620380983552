import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const ForumLeftTabCard = (props) => {
  const useStyles = makeStyles(() => ({
    card: {
      backgroundColor: props.color,
      height: 60,
      width: 260,
      borderRadius: 10,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 16,
      marginBottom: 16,
      '&:hover': {
        background: props.color,
        opacity: 0.7
     },
    },
    title: {
      color: "#F8F9FC",
    },
  }));
  const classes = useStyles();
  return (
    <Button className={classes.card} onClick={props.onClick}>
      <Typography className={classes.title}>{props.title}</Typography>
    </Button>
  );
};

export default ForumLeftTabCard;
