import axios from './axios.interceptor';
import api from './api.util';

export const QA_URL = `${api}/account/admin/qa`;
export const QA_LIST_URL = `${QA_URL}/list`;
export const FLAGGED_QA_LIST_URL = `${QA_URL}/flag-list`;
export const ASKED_BY_ADMIN_QA_LIST_URL = `${QA_URL}/asked-by-admin-list`;
export const PENDING_VERIFICATION_LIST_URL = `${QA_URL}/pending-verification-list`;

export function qaDetails(qaId) {
  return axios.get(`${QA_URL}/${qaId}`);
}

export function answerQa(qaId, payload) {
  return axios.post(`${QA_URL}/${qaId}/answer`, payload);
}

export function approveQaDetails(qaId) {
  return axios.put(`${QA_URL}/${qaId}/approve`);
}

export function rejectQaDetails(qaId) {
  return axios.put(`${QA_URL}/${qaId}/reject`);
}

export function deleteQaDetails(qaId) {
  return axios.put(`${QA_URL}/${qaId}/delete`);
}

export function approveQaAnswerDetails(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/approve`);
}

export function rejectQaAnswerDetails(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/reject`);
}

export function deleteQaAnswerDetails(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/delete`);
}

export function listQA({ skip, limit }) {
  return axios.get(`${QA_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function listPendingVerification({ skip, limit }) {
  return axios.get(`${PENDING_VERIFICATION_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function addQA({
  icon, 
  fname, 
  lname, 
  question
}) {
  const formData = new FormData();
  formData.append('profilePicture',icon);
  formData.append('firstName',fname);
  formData.append('lastName',lname);
  formData.append('question',question);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.post(`${QA_URL}/add-question-by-qa-user`, formData,config);
}

export function editQA(qaId, payload) {
  return axios.put(`${QA_URL}/${qaId}/update`, payload);
}

export function editForumAnswer(qaId, answerId, payload) {
  return axios.put(`${QA_URL}/${qaId}/answer/${answerId}/update`, payload);
}

export function listFlaggedQA({ skip, limit }) {
  return axios.get(`${FLAGGED_QA_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function listAskedByAdminQA({ skip, limit }) {
  return axios.get(`${ASKED_BY_ADMIN_QA_LIST_URL}?skip=${skip}&limit=${limit}`);
}

export function releaseFlag(qaId, answerId) {
  return axios.put(`${QA_URL}/${qaId}/remove-flag-from-answer/${answerId}`);
}