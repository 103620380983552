import React, {useState} from "react";
import { Form, Dropdown, Button } from "react-bootstrap";
import ChangeProfileTypeModal from "../modal/change-profile-type-modal";
import userConfig from './../../../../config/user';
const ChangeProfileType = ({ accountStatus, profileType, saveHandler, show, handleConfirm, handleClose }) => {
  const [profileTypeLocal, setProfileTypeLocal] = useState(profileType);
  const renderProfileType = (type) => {
    switch (type) {
      case 1:
        return "An Individual Practitioner";
      case 2:
        return "An Organization";
      default:
        return "";
    }
  };
  return (
    <div>
      <ChangeProfileTypeModal
        show={show}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        profileType={profileTypeLocal}
      />

      <Form.Group style={{ width: "100%", marginBottom: 0 }}>
        <Form.Label>Profile Type</Form.Label>
         {[userConfig.accountStatus.vendor.active,
           userConfig.accountStatus.vendor.approvalPending,
           userConfig.accountStatus.vendor.approvalRejected
          ].includes(accountStatus) || (userConfig.accountStatus.vendor.approved && profileType ===1) ? <>
          <Dropdown>
          <Dropdown.Toggle
            menualign="right"
            variant="primary"
            id="dropdown-menu-align-right"
          >
            {renderProfileType(profileTypeLocal)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setProfileTypeLocal(1);
              }}
            >
              An Individual Practitioner
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setProfileTypeLocal(2);
              }}
            >
              An Organization
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button
          style={{ marginTop: 16 }}
          variant="primary"
          onClick={()=>saveHandler(profileTypeLocal)}
        >
          Save
        </Button>
        </>: <p>{renderProfileType(profileType)}</p>}
        
      </Form.Group>
    </div>
  );
};

export default ChangeProfileType;
