import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { removeVendor } from "../../../crud/vendor.crud";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import { connect } from "react-redux";
import * as vendor from "../../../store/ducks/vendor.duck";
const RemoveVendor = (props) => {
  const { classes, intl } = props;
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    removeVendor(props.vendor._id)
      .then((output) => {
        if (output.data.success) {
          props.vendorUpdated({
            skip: props.listOption.skip,
            limit: props.listOption.limit,
            payload: props.listOption.payload
          });
          setLoading(false);
          toast.success("Professional is successfully deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.handleClose();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          toast.error(
            intl.formatMessage(
              {
                id: ApiErrorMessage[error.response.data.errorCode],
              },
              { title: props.vendor.firstName }
            ),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.vendor.personalInfo.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this professional?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            No
          </Button>

          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleDelete(props.vendor._id)}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vendorUpdated: ({ skip, limit, payload }) =>
      dispatch(vendor.actions.vendorUpdated({ skip, limit, payload })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(RemoveVendor)
  )
);
