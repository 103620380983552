import axios from "./axios.interceptor";
import api from "./api.util";
import workshopConfig from "../config/workshop";

export const WORKSHOP_URL = `${api}/account/admin/workshop`;
export const WORKSHOP_LIST_URL = `${WORKSHOP_URL}/list`;

export function getWorkshopDetails(workshopId) {
  return axios.get(`${WORKSHOP_URL}/${workshopId}`);
}

export function listWorkshop({ skip, limit, payload }) {
  payload = payload || {};
  console.log('payload', payload);
  return axios.post(
    `${WORKSHOP_LIST_URL}?skip=${skip}&limit=${limit}`,
    payload
  );
}

export function listRequestedWorkshop({ skip, limit, workshopPayload }) {
  workshopPayload = workshopPayload || {};
  workshopPayload.status = workshopConfig.serverStatus.approvalPending;
  return axios.post(
    `${WORKSHOP_LIST_URL}?skip=${skip}&limit=${limit}`,
    workshopPayload
  );
}

export function approveWorkshopDetails(workshopId) {
  return axios.put(`${WORKSHOP_URL}/${workshopId}/approve`);
}

export function rejectWorkshopDetails(workshopId) {
  return axios.put(`${WORKSHOP_URL}/${workshopId}/reject`);
}

export function updateCommission(workshopId, commissionPercentage) {
  return axios.put(`${WORKSHOP_URL}/${workshopId}/update-commission`, { commissionPercentage });
}

export function updateWorkshopDetails(workshopId, key, value) {
  return axios.put(`${WORKSHOP_URL}/${workshopId}/update`, { 
    [key]: value
   });
}

export function broadcastNotification(workshopId) {
  return axios.put(`${WORKSHOP_URL}/${workshopId}/broadcast-notification`);
}

export function resendCertificate(workshopId) {
  return axios.put(`${WORKSHOP_URL}/booking/${workshopId}/resend-certificate`);
}

export function deleteWorkshopDetails(workshopId) {
  return axios.delete(`${WORKSHOP_URL}/${workshopId}/delete`);
}

