import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../../../config/constants";
const VendorStatesDropdown = ({ state, saveHandler }) => {
  const [stateLocal, setStateLocal] = useState(state);
  console.log('stateLocal', stateLocal);
  return (
    <div>
      <label className="form-label fee-label all-label">
        Select State<span className="mandatory-doc-message">*</span>
      </label>
      <select
        className="form-select form-control"
        aria-label="Select State"
        value={stateLocal.stateCode}
        onChange={(e) => {
          let selectedState = states.filter((eState) => eState.code === e.target.value);
          setStateLocal({
            stateName: selectedState[0].state,
            stateCode: selectedState[0].code,
          });
        }}
      >
        {states.map((val) => {
          return (
            <option key={val.code} value={val.code}>
              {val.state}
            </option>
          );
        })}
      </select>
      <Button
          style={{ marginTop: 16 }}
          variant="primary"
          disabled={!stateLocal.stateCode || (stateLocal.stateCode && stateLocal.stateCode === '00')}
          onClick={() => {
            if(stateLocal.stateCode === '00') return;
            saveHandler(stateLocal);
          }}
        >
          Save
        </Button>
    </div>
  );
};

export default VendorStatesDropdown;
