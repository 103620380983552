import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../../../_metronic/utils/utils";
import {  Button, Table, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { paymentDetailsForInstitution, submitPayoutForInstitution } from "../../../../crud/payment.crud";
import { getProfile } from "../../../../crud/institution.crud";
import moment from "moment";
import bookingConfig from "../../../../config/booking";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { toast } from "react-toastify";
import PayoutModal from "../../../common/modal/payout-modal";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    marginTop: "2px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
}));

const PaymentDetailsForInstitution = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { id } = useParams();
  // const { query } = useLocation();
  const selectedDate = localStorage.getItem('paymentSelectedDate')? new Date(localStorage.getItem('paymentSelectedDate')): new Date();
  const [institutionDetailsInfo, setInstitutionDetailsInfo] = useState(null);
  const [paymentDetailsInfo, setPaymentDetailsInfo] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState();
  const [isPaymentModeCheque, setIsPaymentModeCheque] = useState(false);
  const [referenceNo, setReferenceNo] = useState("");
  const [otherPayAmount, setOtherPayAmount] = useState("");
  const [showSAModal, setShowSAModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const onAmountChangeHandler = (event) => {
    const { value } = event.target;
    if(!isFinite(value)){
      return;
    }
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    setSelectedAmount(event.target.value);
  };

  const onReferenceChangeHandler = (event) => {
    setReferenceNo(event.target.value);
  };

  const onOtherPayChangeHandler = (event) => {
    const { value } = event.target;
    if(!isFinite(value)){
        return;
    }
    if (value.match(/\./g)) {
        const [, decimal] = value.split('.');
        if (decimal?.length > 2) {
        return;
        }
    }  
    let totalAmount = Number(selectedAmount) + Number(otherPayAmount);
    
    if(event.target.value<=totalAmount){
      let changedAmount = Number((totalAmount - Number(event.target.value)).toFixed(2));
      setSelectedAmount(changedAmount);
      setOtherPayAmount(event.target.value);
    }else{
      toast.error('Amount should not exceed due amount');
    }
  };

  const submitPayoutFees = () => {
    if (paymentDetailsInfo.paymentDetails) {
      if (selectedAmount && selectedPaymentMode) {
        if (selectedPaymentMode.key && referenceNo){
        setErrors(null);
        setLoading(true);
        const payoutPayload = {
          vendorRef: paymentDetailsInfo.paymentDetails.vendor._id,
          institutionRef: id,
          month: new Date(selectedDate).getMonth() + 1,
          year: new Date(selectedDate).getFullYear(),
          date: new Date(),
          amount: Number(selectedAmount),
          paymentMode: selectedPaymentMode.key,
        };
        if (referenceNo) {
          payoutPayload.referenceNumber = referenceNo;
        }
        if (otherPayAmount) {
          payoutPayload.deduction =  Number(otherPayAmount);
        }
        submitPayoutForInstitution(payoutPayload).then(() => {
          setLoading(false);
          setShowSAModal(false);
          toast.success("Payout successful!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          paymentDetailsForInstitution({
            institutionRef: id,
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
          }).then((output) => {
            const paymentInfo = output;
            if (paymentInfo.data.data) {
              setPaymentDetailsInfo({
                ...paymentDetailsInfo,
                ...paymentInfo.data.data,
              });
            } else {
              setPaymentDetailsInfo(null);
            }
          });
        });
      }else if (
        selectedPaymentMode.key &&
        !referenceNo
      ) {
        setErrors({
          selectedAmount: null,
          selectedPaymentMode: null,
          referenceNo: "Reference is required!",
        });
      }
      } else {
        if(!selectedAmount && !selectedPaymentMode){
          setErrors({
            selectedPaymentMode: "Payment mode is required!",
            selectedAmount: "Amount is required!",
          });        
        }else if(!selectedAmount) {
          setErrors({
            selectedAmount: "Amount is required!",
          });
        }
        else if(!selectedPaymentMode) {
          setErrors({
            selectedPaymentMode: "Payment mode is required!",
          });
        }
      }
    } else {
      setErrors({
        selectedAmount: "No Services found!",
      });
    }

  };

  useEffect(() => {
    Promise.all([
      getProfile(id),
      paymentDetailsForInstitution({
        institutionRef: id,
        year: new Date(selectedDate).getFullYear(),
        month: new Date(selectedDate).getMonth() + 1,
      }),
    ]).then((output) => {
      const profileInfo = output[0];
      const paymentInfo = output[1];

      if (profileInfo.data.data) {
        setInstitutionDetailsInfo(profileInfo.data.data);
      }

      if (paymentInfo.data.data) {
        setPaymentDetailsInfo({
          ...paymentDetailsInfo,
          ...paymentInfo.data.data,
        });
        setSelectedAmount(Number(paymentInfo.data.data.paymentDetails.totalFees.toFixed(2)));
      } else {
        setPaymentDetailsInfo(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight+16);
    }
  });

  if (!institutionDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <PayoutModal
        showModal={showSAModal}
        setShowModal={setShowSAModal}
        selectedAmount={selectedAmount}
        onAmountChangeHandler={onAmountChangeHandler}
        selectedPaymentMode={selectedPaymentMode}
        setSelectedPaymentMode={setSelectedPaymentMode}
        isPaymentModeCheque={isPaymentModeCheque}
        setIsPaymentModeCheque={setIsPaymentModeCheque}
        referenceNo={referenceNo}
        onReferenceChangeHandler={onReferenceChangeHandler}
        otherPayAmount={otherPayAmount}
        onOtherPayChangeHandler={onOtherPayChangeHandler}
        errors={errors}
        loading={loading}
        submitPayoutFees={submitPayoutFees}
        bankingDetails={institutionDetailsInfo.bankingDetails}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-end" style={{ marginBottom: '16px' }}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Month and Year"
            value={selectedDate}
            // onChange={handleDateChange}
            // onMonthChange={handleMonthChange}
            autoOk={true}
            disabled={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }}>
              <div className={classes.flexBox}>
                <div className="mr-3">
                  <img
                    src={
                      institutionDetailsInfo.personalInfo.profilePicture
                        ? institutionDetailsInfo.personalInfo.profilePicture
                        : toAbsoluteUrl(
                          `/media/default-image/default-image.png`
                        )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="text-left mt-1">
                  <h5>{institutionDetailsInfo.personalInfo.name}</h5>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Email -&nbsp;</span>
                    <span>{institutionDetailsInfo.personalInfo.email}</span>
                  </span>

                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
            {!institutionDetailsInfo.bankingDetails.isCheque?( 
                              <div className={classes.flexBox}>
                                <div className="text-left mt-1" style={{color: '#000', fontSize: '15px'}}>
                                  <h5>Bank Details</h5>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>Bank Name -&nbsp;</span>
                                    <span>{institutionDetailsInfo.bankingDetails.bankName}</span>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>Account Holder Name -&nbsp;</span>
                                    <span>
                                      {institutionDetailsInfo.bankingDetails.accountHolderName}
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>Account Number -&nbsp;</span>
                                    <span>
                                      {institutionDetailsInfo.bankingDetails.accountNumber}
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>IFSC Code -&nbsp;</span>
                                    <span>{institutionDetailsInfo.bankingDetails.ifscCode}</span>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>Branch Name -&nbsp;</span>
                                    <span>{institutionDetailsInfo.bankingDetails.branchName}</span>
                                  </span>
                                  <span
                                    style={{
                                      display: "block",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <span>GST Number -&nbsp;</span>
                                    <span>{institutionDetailsInfo.bankingDetails.gstNumber}</span>
                                  </span>
                                </div>
                              </div> )
                            : ( <div className={classes.flexBox}>
                                  <div className="text-left mt-1">
                                    <h5>Applied Cheque Facility</h5>
                                    <span
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <span>Cheque Name -&nbsp;</span>
                                      <span>{institutionDetailsInfo.bankingDetails.chequeName}</span>
                                    </span>
                                    <span
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <span>Cheque Address -&nbsp;</span>
                                      <span>
                                        {institutionDetailsInfo.bankingDetails.chequeAddress}
                                      </span>
                                    </span>
                                  </div>
                                </div>)}
            </Paper>
          </Grid>
          {paymentDetailsInfo &&
            !paymentDetailsInfo.institutionPayout && paymentDetailsInfo.paymentDetails &&
            paymentDetailsInfo.paymentDetails.totalFees ? (
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "12px",
                  margin: "8px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "#000"
                  }}
                >
                  Due Amount: Rs. {paymentDetailsInfo.paymentDetails.totalFees.toFixed(2)}/-
                </div>
                <Button
                  onClick={() => {
                    setShowSAModal(true);
                  }}
                >
                  Payout
              </Button>
              </Card>
            ) : paymentDetailsInfo && paymentDetailsInfo.institutionPayout ? (
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: "12px",
                  paddingLeft: "12px",
                  margin: "8px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "#000"
                  }}
                >
                  <p>{`Paid Amount: Rs. ${paymentDetailsInfo.institutionPayout.amount.toFixed(2)}/- on ${moment(paymentDetailsInfo.institutionPayout.date).format(
                                        "DD-MMM-YYYY"
                                    )} ${paymentDetailsInfo.institutionPayout.paymentMode===5?'with cheque reference no':'with transaction reference no '}`}  <b style={{display: 'inline-block'}}>{`#${paymentDetailsInfo.institutionPayout.referenceNumber || ''}`} </b></p>
                  {paymentDetailsInfo.institutionPayout.paymentMode===5 && paymentDetailsInfo.institutionPayout.deduction ?<p>{`The amount of Rs. ${paymentDetailsInfo.institutionPayout.deduction.toFixed(2)}/- has been deducted.`}</p>: null}
                </div>
              </Card>
            ) : null}
          {paymentDetailsInfo && paymentDetailsInfo.paymentDetails ? (
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <h4 className="mb-3">Bookings</h4>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {/* <th>Booking No</th> */}
                      <th>Date</th>
                      <th>Service Name</th>
                      <th>Service Type</th>
                      <th>Booking For</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetailsInfo.paymentDetails.services.map(
                      (serviceRef, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{serviceRef.bookingNumber}</td> */}
                            <td>
                              {serviceRef.date
                                ? moment(serviceRef.date).format(
                                  "DD-MMM-YYYY"
                                )
                                : "N/A"}
                            </td>
                            <td>{serviceRef.name}</td>
                            <td>{bookingConfig.bookingType[serviceRef.bookingType]}</td>
                            <td>{serviceRef.bookingForName}</td>

                            <td>Rs. {serviceRef.amount.toFixed(2)}/-</td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td colSpan="4">Total</td>
                      <td>
                        Rs. {paymentDetailsInfo.paymentDetails.totalFees.toFixed(2)}/-
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Paper>
            </Grid>
          ) : (
              <p style={{ marginLeft: 16, marginTop: 16 }}>No Services found!</p>
            )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default PaymentDetailsForInstitution;
