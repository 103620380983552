import React, { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sendEmail, sendEmailCustom } from "../../../crud/send-email.crud";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomList from "./custom-list";
import { Chip, Grid, Tooltip } from "@material-ui/core";
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "|",
      "indent",
      "outdent",
      "|",
      "blockQuote",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "en",
};

const SendEmail = () => {
  const [checkedProfessional, setCheckedProfessional] = useState(true);
  const [checkedCustomer, setCheckedCustomer] = useState(false);
  const [checkedCustom, setCheckedCustom] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [mailSubject, setMailSubject] = useState("");
  const [mailSubjectError, setMailSubjectError] = useState(false);
  const [mailBody, setMailBody] = useState("");
  const [showEmailSendingWait, setShowEmailSendingWait] = useState(false);
  const [
    showCustomSendEmailUserList,
    setShowCustomSendEmailUserList,
  ] = useState(false);
  const handleChangeProfessional = (event) => {
    setCheckedProfessional(event.target.checked);
  };

  const handleChangeCustomer = (event) => {
    setCheckedCustomer(event.target.checked);
  };

  const handleChangeCustom = (event) => {
    setCheckedCustom(event.target.checked);
    if(event.target.checked){
        setCheckedProfessional(false);
        setCheckedCustomer(false);
        setShowCustomSendEmailUserList(true);
    }
  };

  const handleChangeSubject = (event) => {
    setMailSubject(event.target.value);
  };

  const handleSend = () => {
    if (mailSubject) {
      setMailSubjectError(false);
      setShowEmailSendingWait(true);
      const payload = {
        checkedCustomer: checkedCustomer,
        checkedProfessional: checkedProfessional,
        mailBody: `<div class='email-body-by-admin'>${mailBody}</div>`,
        mailSubject: mailSubject,
      };
      if (checkedCustom) {
        payload.emails = selectedEmails;
        sendEmailCustom(payload).then(() => {
          setMailSubject("");
          setMailBody("");
          setShowEmailSendingWait(false);
        });
      } else {
        sendEmail(payload).then(() => {
          setMailSubject("");
          setMailBody("");
          setShowEmailSendingWait(false);
        });
      }
      console.log("payload", payload);
    } else {
      setMailSubjectError(true);
    }
  };

  const handleClose = () => {
    setShowCustomSendEmailUserList(false);
  };

  const handleDeleteEmail = (each) => {
    const filteredList = selectedEmails.filter((a) => !(a._id === each._id));
    setSelectedEmails(filteredList);
  };

  useEffect(() => {
    console.log(selectedEmails);
  }, [selectedEmails]);

  return (
    <div>
      {showCustomSendEmailUserList ? (
        <CustomList
          selectedEmails={selectedEmails}
          setSelectedEmails={setSelectedEmails}
          show={showCustomSendEmailUserList}
          handleClose={handleClose}
        />
      ) : null}
      <Backdrop style={{ zIndex: 1 }} open={showEmailSendingWait}>
        <CircularProgress color="inherit" />
        <p style={{ marginLeft: 24, marginTop: 16, fontWeight: 600 }}>
          {" "}
          Please wait. This may take some time.
        </p>
      </Backdrop>
      <Card>
        <CardContent>
          <h4>Send To</h4>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeProfessional}
                    inputProps={{ "aria-label": "controlled" }}
                    size="medium"
                    disabled={checkedCustom}
                    checked={checkedProfessional}
                  />
                }
                label={
                  <Typography style={{ fontSize: 16 }}>
                    All Professionals
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCustomer}
                    inputProps={{ "aria-label": "controlled" }}
                    size="medium"
                    disabled={checkedCustom}
                    checked={checkedCustomer}
                  />
                }
                label={
                  <Typography style={{ fontSize: 16 }}>
                    All Customers
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCustom}
                    inputProps={{ "aria-label": "controlled" }}
                    size="medium"
                    value={checkedCustom}
                  />
                }
                label={<Typography style={{ fontSize: 16 }}>Custom</Typography>}
              />
            </FormGroup>
          </div>
          {checkedCustom ? (
            <div style={{ marginTop: 8, marginBottom: 24 }}>
              <h4>
                Selected Emails <span style={{ color: "red" }}>*</span>
                <p
                  className="btn btn-icon custom-table-btn"
                  onClick={() => setShowCustomSendEmailUserList(true)}
                  style={{
                    margin: "0",
                    marginLeft: "16px"
                  }}
                >
                  <Tooltip title="Edit">
                    <i className="la la-edit"></i>
                  </Tooltip>
                </p>
              </h4>
              <Grid>
                {selectedEmails.length ? selectedEmails.map((each, key) => {
                  return (
                    <Chip
                      label={each.personalInfo.email}
                      onDelete={() => handleDeleteEmail(each)}
                      style={{marginLeft: key===0? 0: 12}}
                    />
                  );
                }): 'No Email selected...'}
              </Grid>
            </div>
          ) : null}
          <div style={{ marginTop: 8, marginBottom: 24 }}>
            <h4>
              Mail Subject <span style={{ color: "red" }}>*</span>
            </h4>
            <FormGroup>
              <FormControl>
                <Input
                  value={mailSubject}
                  placeholder="Please enter subject"
                  onChange={handleChangeSubject}
                  error={mailSubjectError && !mailSubject}
                />
                {mailSubjectError && !mailSubject ? (
                  <FormHelperText error={mailSubjectError && !mailSubject}>
                    Required
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </FormGroup>
          </div>
          <div>
            <h4>Mail Body</h4>
            <CKEditor
              id="editor"
              editor={ClassicEditor}
              data={mailBody}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setMailBody(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            <Button variant="contained" size="large" onClick={handleSend}>
              Send
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default SendEmail;
