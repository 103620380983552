import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { listRevenue, listTransaction } from "../../../crud/payment.crud";
import FullWidthTabs from "../../common/FullWidthTabs";
import * as paymentDuck from "../../../store/ducks/payment.duck";
import TransactionList from './transaction/index';
import RefundList from './refund/index';
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const TransactionRefundList = (props) => {

  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [tableDataTransaction, setTableDataTransaction] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(props.data.payment.currTab);
  const [selectedRefundedStatus, setSelectedRefundedStatus] = useState('All');
  const [selectedRefundStatus, setSelectedRefundStatus] = useState(undefined);
  // const [selectedTransactionStatus, setSelectedTransactionStatus] = useState();
  const selectedTransactionStatus = undefined;
  useEffect(() => {
   
    switch (props.data.payment.currTab) {
      case 0:
        listRevenue({
          skip: Number((page-1) * tableData.limit),
          limit: tableData.limit,
          payload: {
            status: selectedRefundStatus
          },

        }).then((docs) => {
          if (docs.data) {
            setTableData({
              data: [...docs.data.data.data],
              skip: Number((page-1) * tableData.limit),
              limit: tableData.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableData.limit)
              ),
            });
          }
        });
        break;
      case 1:
        setPage(1);
        listTransaction({
          skip: 0,
          limit: tableDataTransaction.limit,
          payload: {
            status: selectedTransactionStatus

          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataTransaction({
              data: [...docs.data.data.data],
              skip: 0,
              limit: tableDataTransaction.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataTransaction.limit)
              ),
            });
          }
        });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.payment]);

  const handleChange = (_event, value) => {
    setPage(value);

    switch (currentTab) {
      case 0:
        listRevenue({
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          payload: {
            status: selectedRefundStatus
          },
        }).then((docs) => {
          if (docs.data) {
            setTableData({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableData.limit),
              limit: tableData.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableData.limit)
              ),
            });
          }
        });
        break;
      case 1:
        listTransaction({
          skip: Number((value - 1) * tableDataTransaction.limit),
          limit: tableDataTransaction.limit,
          payload: {
            status: selectedTransactionStatus
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataTransaction({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableDataTransaction.limit),
              limit: tableDataTransaction.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataTransaction.limit)
              ),
            });
          }
        });
        break;
      default:
        break;
    }
  };

  const renderPagination = () => {
    switch (currentTab) {
      case 0:
        if (tableData.data.length) {
          return (<CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          console.log('break');
          break;
        }
      case 1:
        if (tableDataTransaction.data.length) {
          return (<CustomPagination
            count={tableDataTransaction.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          break;
        }
      default:
        return <NoDataFound/>;
    }
    return <NoDataFound/>;
  };

  const renderList = () => {
    switch (currentTab) {
      case 0:
        return <RefundList tableData={tableData} selectedRefundedStatus={selectedRefundedStatus} statusHandler={statusHandlerRefund}/>;
      case 1:
        return <TransactionList tableData={tableDataTransaction} />;;
      default:
        return null;
    }
  };

  const statusHandlerRefund = (status)=>{
    switch(status){
      case 0: 
        setSelectedRefundedStatus('All');
        setSelectedRefundStatus(undefined);
        break;
      case 1:
        setSelectedRefundedStatus('Auto Refunded');
        setSelectedRefundStatus(1);
        break;
      case 2:
        setSelectedRefundedStatus('Failed');
        setSelectedRefundStatus(2);
        break;
      case 3:
        setSelectedRefundedStatus('Pending');
        setSelectedRefundStatus(3);
        break;
      case 5:
          setSelectedRefundedStatus('Manually Refunded');
          setSelectedRefundStatus(5);
          break;
      default:
        break;
    }
    listRevenue({
      skip: 0,
      limit: tableData.limit,
      payload: {
        status: status
      },

    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableData.limit)
          ),
        });
      }
    });
  };

  return (
    <React.Fragment>

      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changePaymentTab(tab);
          setCurrentTab(tab);
        }}
        title={"Transaction and Refund List"}
        tabsTitle={["Refund", "Transaction"]}
      />

      <React.Fragment>
        {
          renderList()
        }
      </React.Fragment>

      {
        renderPagination()
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePaymentTab: (tab) => dispatch(paymentDuck.actions.changePaymentTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRefundList);
