import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "./../../../../../_metronic";

const DashboardStat = ({ bgStyle, type, desc, value, iconImg, link }) => {
  return (
    <div
      style={{
        ...bgStyle,
        height: 100,
        borderRadius: 4,
        opacity: 1,
        display: 'flex', 
        justifyContent: type === 'ViewMore' ? 'Center': 'inherit',
        alignItems: type === 'ViewMore' ? 'Center': 'inherit',
        marginBottom: 16
      }}
    >
      {type === "ViewMore" ? (
        <div>
          <Link to={`/${link}`} style={{
            fontSize: 16, 
            color: '#fff'
          }}>{desc} <i className="fa fa-chevron-right" style={{marginLeft: 4}}/></Link>
        </div>
      ) : (
        <div style={{
            width: '100%',
            padding: 15
        }}>
          <p style={{
            fontSize: '1.1rem',
            color: '#fff'
          }}>{desc}</p>
          <div
            style={{ marginTop: 8, marginBottom: iconImg ? -10 : "inherit" }}
          >
            <span style={{
                fontSize: '1.8rem', 
                color: '#fff',
                fontWeight: 600
            }}>{value}</span>
            {iconImg && (
              <img
                alt={`${desc}-logo`}
                style={{ float: "right", marginBottom: -10 }}
                src={toAbsoluteUrl(`/media/side-menus/${iconImg}.png`)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardStat;
