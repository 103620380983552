import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button, Grid, Typography, TextField, InputAdornment, CircularProgress, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import {
  listGlobalSettings,
  editGlobalSettings,
} from "../../../crud/global-settings.crud";
import { Formik } from "formik";
import "yup-phone";
import * as Yup from "yup";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
});

const GlobalSettings = () => {
  const classes = useStyles();
  const [globalConfig, setGlobalConfig] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listGlobalSettings({ skip: 0, limit: 0 }).then((data) => {
      setGlobalConfig(data.data.data);
    });
  }, []);

  const saveSetting = (payload) => {
    editGlobalSettings(payload).then((output) => {
      setGlobalConfig(output.data.data);
      setLoading(false);
      toast.success("Global Settings has been saved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  if (!globalConfig) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        appleStoreLink: {
          customer: globalConfig ? globalConfig.appleStoreLink.customer : null,
          vendor: globalConfig ? globalConfig.appleStoreLink.vendor : null,
        },
        playStoreLink: {
          customer: globalConfig ? globalConfig.playStoreLink.customer : null,
          vendor: globalConfig ? globalConfig.playStoreLink.vendor : null,
        },
        aboutUs: globalConfig ? globalConfig.aboutUs : null,
        termsAndCondition: globalConfig ? globalConfig.termsAndCondition : null,
        privacyPolicy: globalConfig ? globalConfig.privacyPolicy : null,
        termsAndConditionVendor: globalConfig ? globalConfig.termsAndConditionVendor : null,
        privacyPolicyVendor: globalConfig ? globalConfig.privacyPolicyVendor : null,
        cancellationPolicies: globalConfig
          ? globalConfig.cancellationPolicies
          : null,
        supportContactNo: globalConfig ? globalConfig.supportContactNo : null,
        supportEmail: globalConfig ? globalConfig.supportEmail : null,
        testableAccounts: {
          customer: globalConfig ? globalConfig.testableAccounts.customer : null,
          vendor: globalConfig ? globalConfig.testableAccounts.vendor : null,
        },
        convenienceFee: globalConfig ? globalConfig.convenienceFee : null,
        defaultVendorCommissionCut: globalConfig ? globalConfig.defaultVendorCommissionCut : null,
        eventCharge: globalConfig ? globalConfig.eventCharge : null,
        gstPercentage: globalConfig ? globalConfig.gstPercentage : null,
        bufferSlot: globalConfig ? globalConfig.bufferSlot : null,
        androidVersion: {
          customer: {
            minimumSupportedVersion: globalConfig
              ? globalConfig.androidVersion.customer.minimumSupportedVersion
              : null,
            currentVersion: globalConfig
              ? globalConfig.androidVersion.customer.currentVersion
              : null,
          },
          professional: {
            minimumSupportedVersion: globalConfig
              ? globalConfig.androidVersion.professional.minimumSupportedVersion
              : null,
            currentVersion: globalConfig
              ? globalConfig.androidVersion.professional.currentVersion
              : null,
          },
        },
        iOSVersion: {
          customer: {
            minimumSupportedVersion: globalConfig
              ? globalConfig.iOSVersion.customer.minimumSupportedVersion
              : null,
            currentVersion: globalConfig
              ? globalConfig.iOSVersion.customer.currentVersion
              : null,
          },
          professional: {
            minimumSupportedVersion: globalConfig
              ? globalConfig.iOSVersion.professional.minimumSupportedVersion
              : null,
            currentVersion: globalConfig
              ? globalConfig.iOSVersion.professional.currentVersion
              : null,
          },
        },
        customerApp: {
          rescheduledWindow: globalConfig
            ? globalConfig.customerApp.rescheduledWindow
            : null,
          noActionWindow: globalConfig
            ? globalConfig.customerApp.noActionWindow
            : null,
        },
        promoCodeGeneral: {
          expireDate: globalConfig && globalConfig.promoCodeGeneral
            ? globalConfig.promoCodeGeneral.expireDate : '',
          discountPercentageOn: {
            appointment: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.discountPercentageOn.appointment : '',
            event: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.discountPercentageOn.event : '',
            workshop: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.discountPercentageOn.workshop : ''
          },
          institutionCommission: {
            appointment: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.institutionCommission.appointment : '',
            event: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.institutionCommission.event : '',
            workshop: globalConfig && globalConfig.promoCodeGeneral
              ? globalConfig.promoCodeGeneral.institutionCommission.workshop : ''
          }
        },
        showSelfAssessment: globalConfig
          ? globalConfig.showSelfAssessment
          : false, 
        iTabLinks: {
            customer: {
              mainITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.customer.mainITab : '',
              appointmentITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.customer.appointmentITab : '',
              workshopItab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.customer.workshopItab : '',
              eventITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.customer.eventITab : '',
            },
            vendor: {
              mainITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.vendor.mainITab : '',
              serviceITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.vendor.serviceITab : '',
              workshopItab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.vendor.workshopItab : '',
              eventITab: globalConfig && globalConfig.iTabLinks
              ? globalConfig.iTabLinks.vendor.eventITab : '',
            },
          },
      }}
      validationSchema={Yup.object({
        appleStoreLink: Yup.object({
          customer: Yup.string()
            .url("Invalid url address")
            .required(),
          vendor: Yup.string()
            .url("Invalid url address")
            .required(),
        }),
        playStoreLink: Yup.object({
          customer: Yup.string()
            .url("Invalid url address")
            .required(),
          vendor: Yup.string()
            .url("Invalid url address")
            .required(),
        }),
        aboutUs: Yup.string()
          .url("Invalid url address")
          .required(),
        termsAndCondition: Yup.string()
          .url("Invalid url address")
          .required(),
        privacyPolicy: Yup.string()
          .url("Invalid url address")
          .required(),
        termsAndConditionVendor: Yup.string()
          .url("Invalid url address")
          .required(),
        privacyPolicyVendor: Yup.string()
          .url("Invalid url address")
          .required(),
        supportEmail: Yup.string()
          .email("Invalid email address")
          .required(),
        supportContactNo: Yup.string()
          // eslint-disable-next-line
          .phone("IN", true, "${path} is invalid")
          .required(),
        testableAccounts:Yup.object({
            customer: Yup.string()
              // eslint-disable-next-line
              .phone("IN", true, "${path} is invalid")
              .required(),
            vendor: Yup.string()
              // eslint-disable-next-line
              .phone("IN", true, "${path} is invalid")
              .required(),
        }),
        defaultVendorCommissionCut: Yup.number().required(),
        convenienceFee: Yup.number().required(),
        eventCharge: Yup.number().required(),
        gstPercentage: Yup.number().required(),
        bufferSlot: Yup.string().required().matches(/^[0-9]+$/, "Must be only digits")
        .min(1, 'Must be exactly 1 digits')
        .max(1, 'Must be exactly 1 digits'),
        androidVersion: Yup.object({
          customer: Yup.object({
            minimumSupportedVersion: Yup.number().required(),
            currentVersion: Yup.number().required(),
          }),
          professional: Yup.object({
            minimumSupportedVersion: Yup.number().required(),
            currentVersion: Yup.number().required(),
          }),
        }),
        iOSVersion: Yup.object({
          customer: Yup.object({
            minimumSupportedVersion: Yup.number().required(),
            currentVersion: Yup.number().required(),
          }),
          professional: Yup.object({
            minimumSupportedVersion: Yup.number().required(),
            currentVersion: Yup.number().required(),
          }),
        }),
        customerApp: Yup.object({
          rescheduledWindow: Yup.number().required(),
          noActionWindow: Yup.number().required(),
        }),
      })}
      onSubmit={(values) => {
        setLoading(true);
        saveSetting(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => (
        <Card className={classes.root}>
          <form className={classes.container} noValidate autoComplete="off">
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Customer App Links
              </Typography>
              <Card style={{ padding: 16 }}>
                <h5>iOS</h5>
                <span className={classes.flexBox}>
                  <img
                    src={toAbsoluteUrl(`/media/logos/appStore.png`)}
                    alt={`AppStoreLogo`}
                    className="mr-2"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "2rem",
                    }}
                  />
                  <TextField
                    id="outlined-name"
                    label="App Store Link"
                    name="appleStoreLink.customer"
                    className={classes.textField}
                    placeholder="Link Here"
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.appleStoreLink.customer}
                    error={Boolean(
                      errors.appleStoreLink && errors.appleStoreLink.customer
                    )}
                  />
                </span>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={6}>
                    <h6>Minimum Supported Version</h6>
                    <TextField
                      id="outlined-name"
                      name="iOSVersion.customer.minimumSupportedVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={values.iOSVersion.customer.minimumSupportedVersion}
                      error={Boolean(
                        errors.iOSVersion &&
                        errors.iOSVersion.customer &&
                        errors.iOSVersion.customer.minimumSupportedVersion
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h6>Current Version</h6>
                    <TextField
                      id="outlined-name"
                      name="iOSVersion.customer.currentVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={values.iOSVersion.customer.currentVersion}
                      error={Boolean(
                        errors.iOSVersion &&
                        errors.iOSVersion.customer &&
                        errors.iOSVersion.customer.currentVersion
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16, marginTop: 16 }}>
                <h5>Android</h5>
                <span className={classes.flexBox}>
                  <img
                    src={toAbsoluteUrl(`/media/logos/play-store.png`)}
                    alt={`PlayStoreLogo`}
                    className="mr-2"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "2rem",
                    }}
                  />
                  <TextField
                    id="outlined-name"
                    label="Play Store Link"
                    name="playStoreLink.customer"
                    className={classes.textField}
                    placeholder="Link Here"
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.playStoreLink.customer}
                    error={Boolean(
                      errors.playStoreLink && errors.playStoreLink.customer
                    )}
                  />
                </span>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={6}>
                    <h6>Minimum Supported Version</h6>
                    <TextField
                      id="outlined-name"
                      name="androidVersion.customer.minimumSupportedVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={
                        values.androidVersion.customer.minimumSupportedVersion
                      }
                      error={Boolean(
                        errors.androidVersion &&
                        errors.androidVersion.customer &&
                        errors.androidVersion.customer.minimumSupportedVersion
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h6>Current Version</h6>
                    <TextField
                      id="outlined-name"
                      name="androidVersion.customer.currentVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={values.androidVersion.customer.currentVersion}
                      error={Boolean(
                        errors.androidVersion &&
                        errors.androidVersion.customer &&
                        errors.androidVersion.customer.currentVersion
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Typography
                className={`${classes.title} mt-5`}
                color="textSecondary"
                gutterBottom
              >
                Professional App Links
              </Typography>
              <Card style={{ padding: 16 }}>
                <h5>iOS</h5>
                <span className={classes.flexBox}>
                  <img
                    src={toAbsoluteUrl(`/media/logos/appStore.png`)}
                    alt={`AppStoreLogo`}
                    className="mr-2"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "2rem",
                    }}
                  />
                  <TextField
                    label="App Store Link"
                    name="appleStoreLink.vendor"
                    className={classes.textField}
                    placeholder="Link Here"
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.appleStoreLink.vendor}
                    error={Boolean(
                      errors.appleStoreLink && errors.appleStoreLink.vendor
                    )}
                  />
                </span>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={6}>
                    <h6>Minimum Supported Version</h6>
                    <TextField
                      id="outlined-name"
                      name="iOSVersion.professional.minimumSupportedVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={
                        values.iOSVersion.professional.minimumSupportedVersion
                      }
                      error={Boolean(
                        errors.iOSVersion &&
                        errors.iOSVersion.professional &&
                        errors.iOSVersion.professional.minimumSupportedVersion
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h6>Current Version</h6>
                    <TextField
                      id="outlined-name"
                      name="iOSVersion.professional.currentVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={values.iOSVersion.professional.currentVersion}
                      error={Boolean(
                        errors.iOSVersion &&
                        errors.iOSVersion.professional &&
                        errors.iOSVersion.professional.currentVersion
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16, marginTop: 16 }}>
                <h5>Android</h5>
                <span className={classes.flexBox}>
                  <img
                    src={toAbsoluteUrl(`/media/logos/play-store.png`)}
                    alt={`PlayStoreLogo`}
                    className="mr-2"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "2rem",
                    }}
                  />
                  <TextField
                    label="Play Store Link"
                    name="playStoreLink.vendor"
                    className={classes.textField}
                    placeholder="Link Here"
                    margin="normal"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.playStoreLink.vendor}
                    error={Boolean(
                      errors.playStoreLink && errors.playStoreLink.vendor
                    )}
                  />
                </span>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={6}>
                    <h6>Minimum Supported Version</h6>
                    <TextField
                      id="outlined-name"
                      name="androidVersion.professional.minimumSupportedVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={
                        values.androidVersion.professional
                          .minimumSupportedVersion
                      }
                      error={Boolean(
                        errors.androidVersion &&
                        errors.androidVersion.professional &&
                        errors.androidVersion.professional
                          .minimumSupportedVersion
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h6>Current Version</h6>
                    <TextField
                      id="outlined-name"
                      name="androidVersion.professional.currentVersion"
                      className={classes.textField}
                      placeholder="Enter Version"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      value={values.androidVersion.professional.currentVersion}
                      error={Boolean(
                        errors.androidVersion &&
                        errors.androidVersion.professional &&
                        errors.androidVersion.professional.currentVersion
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Card style={{ padding: 16, marginTop: 16 }}>
                <h5>Customer</h5>

                <Grid container spacing={2} style={{ marginTop: 8 }}>
                  <Grid item xs={6}>
                    <h6>Rescheduled Window</h6>
                    <TextField
                      id="outlined-name"
                      name="customerApp.rescheduledWindow"
                      className={classes.textField}
                      placeholder="Enter Value"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position="end">Min</InputAdornment>
                        ),
                      }}
                      value={values.customerApp.rescheduledWindow}
                      error={Boolean(
                        errors.customerApp &&
                        errors.customerApp.rescheduledWindow
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h6>No Action Window</h6>
                    <TextField
                      id="outlined-name"
                      name="customerApp.noActionWindow"
                      className={classes.textField}
                      placeholder="Enter Value"
                      variant="outlined"
                      style={{ margin: 0, marginLeft: 16 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position="end">Min</InputAdornment>
                        ),
                      }}
                      value={values.customerApp.noActionWindow}
                      error={Boolean(
                        errors.customerApp && errors.customerApp.noActionWindow
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>

              <Typography
                className={`${classes.title} mt-5`}
                color="textSecondary"
                gutterBottom
              >
                Other Links
              </Typography>
              <TextField
                label="About Us"
                name="aboutUs"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.aboutUs}
                error={Boolean(touched.aboutUs && errors.aboutUs)}
              />
              <TextField
                label="Terms & conditions"
                name="termsAndCondition"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.termsAndCondition}
                error={Boolean(
                  touched.termsAndCondition && errors.termsAndCondition
                )}
              />
              <TextField
                label="Privacy Settings"
                name="privacyPolicy"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.privacyPolicy}
                error={Boolean(touched.privacyPolicy && errors.privacyPolicy)}
              />
              <TextField
                label="Terms & conditions for Professional"
                name="termsAndConditionVendor"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.termsAndConditionVendor}
                error={Boolean(
                  touched.termsAndConditionVendor && errors.termsAndConditionVendor
                )}
              />
              <TextField
                label="Privacy Settings for Professional"
                name="privacyPolicyVendor"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.privacyPolicyVendor}
                error={Boolean(touched.privacyPolicyVendor && errors.privacyPolicyVendor)}
              />
              <TextField
                label="Cancellation Policies"
                name="cancellationPolicies"
                className={classes.textField}
                placeholder="Link Here"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cancellationPolicies}
                error={Boolean(
                  touched.cancellationPolicies && errors.cancellationPolicies
                )}
              />
              <Typography
                className={`${classes.title} mt-5`}
                color="textSecondary"
                gutterBottom
              >
                Support
              </Typography>

              <TextField
                label="Contact No"
                name="supportContactNo"
                className={classes.textField}
                placeholder="Enter the support contact number"
                margin="normal"
                variant="outlined"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(
                  touched.supportContactNo && errors.supportContactNo
                )}
                value={values.supportContactNo}
              />

              <TextField
                label="Email"
                type="email"
                name="supportEmail"
                className={classes.textField}
                placeholder="Enter the support email"
                margin="normal"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.supportEmail && errors.supportEmail)}
                value={values.supportEmail}
              />

              <Typography
                className={`${classes.title} mt-5`}
                color="textSecondary"
                gutterBottom
              >
                Others
              </Typography>
              <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                <Grid item xs={6}>
                  <TextField
                    label="GST Percentage"
                    name="gstPercentage"
                    className={classes.textField}
                    placeholder="Enter GST Percentage"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.gstPercentage && errors.gstPercentage)}
                    value={values.gstPercentage}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Convenience Fee in Rupees"
                    name="convenienceFee"
                    className={classes.textField}
                    placeholder="Enter the convenience fees here"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.convenienceFee && errors.convenienceFee)}
                    value={values.convenienceFee}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Event Charge(per person/30 Mins) in Rupees"
                    name="eventCharge"
                    className={classes.textField}
                    placeholder="Enter the event charge here"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.eventCharge && errors.eventCharge)}
                    value={values.eventCharge}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Default Professional Convenience Fee in Percentage"
                    name="defaultVendorCommissionCut"
                    className={classes.textField}
                    placeholder="Enter the default professional Convenience fees here"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.defaultVendorCommissionCut && errors.defaultVendorCommissionCut)}
                    value={values.defaultVendorCommissionCut}
                  />
                </Grid>
                 <Grid item xs={6}>
                  <TextField
                    label="Buffer Slot (1 slot = 30 min)"
                    name="bufferSlot"
                    className={classes.textField}
                    placeholder="Enter Buffer Slot"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.bufferSlot && errors.bufferSlot)}
                    value={values.bufferSlot}
                  />
                </Grid>
              </Grid>
              <Card style={{ padding: 16, marginBottom: 16 }}>
                <FormGroup style={{
                  alignContent: 'flex-start'
                }}>
                  <FormControlLabel
                    value="start"
                    control={<Checkbox checked={values.showSelfAssessment} onChange={(e) => {
                      setFieldValue('showSelfAssessment', e.target.checked);
                    }} />}
                    label="Show Self Assessment"
                    labelPlacement="start"
                    style={{ margin: 0 }}
                  />
                </FormGroup>
              </Card>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Promo Codes
              </Typography>
              <Card style={{ padding: 16, marginBottom: 16 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    openTo="date"
                    views={["year", "month", "date"]}
                    label="Expire Date"
                    format="dd/MM/yyyy"
                    minDate={new Date()}
                    inputVariant={'outlined'}
                    value={values.promoCodeGeneral.expireDate}
                    onChange={(e) => {
                      setFieldValue('promoCodeGeneral.expireDate', e);
                    }}
                    autoOk={true}
                  />

                </MuiPickersUtilsProvider>
              </Card>
              <Card style={{ padding: 16, marginBottom: 16 }}>
                <h5>Discount Percentage on</h5>
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Appointment"
                      name="promoCodeGeneral.discountPercentageOn.appointment"
                      className={classes.textField}
                      placeholder="Enter discount percentage for booking appointment"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.discountPercentageOn.appointment}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.discountPercentageOn &&
                        errors.promoCodeGeneral.discountPercentageOn.appointment
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Event"
                      name="promoCodeGeneral.discountPercentageOn.event"
                      className={classes.textField}
                      placeholder="Enter event discount percentage"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.discountPercentageOn.event}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.discountPercentageOn &&
                        errors.promoCodeGeneral.discountPercentageOn.event
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Workshop"
                      name="promoCodeGeneral.discountPercentageOn.workshop"
                      className={classes.textField}
                      placeholder="Enter discount percentage for booking workshop"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.discountPercentageOn.workshop}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.discountPercentageOn &&
                        errors.promoCodeGeneral.discountPercentageOn.workshop
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16, marginBottom: 16 }}>
                <h5>Institution commission on</h5>
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={4}>
                    <TextField
                      label="Appointment"
                      name="promoCodeGeneral.institutionCommission.appointment"
                      className={classes.textField}
                      placeholder="Enter institution commission for booking appointment"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.institutionCommission.appointment}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.institutionCommission &&
                        errors.promoCodeGeneral.institutionCommission.appointment
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Event"
                      name="promoCodeGeneral.institutionCommission.event"
                      className={classes.textField}
                      placeholder="Enter event institution commission"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.institutionCommission.event}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.institutionCommission &&
                        errors.promoCodeGeneral.institutionCommission.event
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Workshop"
                      name="promoCodeGeneral.institutionCommission.workshop"
                      className={classes.textField}
                      placeholder="Enter institution commission for booking workshop"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.promoCodeGeneral.institutionCommission.workshop}
                      error={Boolean(
                        errors.promoCodeGeneral &&
                        errors.promoCodeGeneral.institutionCommission &&
                        errors.promoCodeGeneral.institutionCommission.workshop
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16, marginBottom: 16 }}>
                <h5>iTabLinks for Customer</h5>
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Main ITab"
                      name="iTabLinks.customer.mainITab"
                      className={classes.textField}
                      placeholder="Link Here"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.iTabLinks.customer.mainITab}
                      error={Boolean(
                        errors.iTabLinks &&
                        errors.iTabLinks.customer &&
                        errors.iTabLinks.customer.mainITab
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Appointment ITab"
                      name="iTabLinks.customer.appointmentITab"
                      className={classes.textField}
                      placeholder="Link Here"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.iTabLinks.customer.appointmentITab}
                      error={Boolean(
                        errors.iTabLinks &&
                        errors.iTabLinks.customer &&
                        errors.iTabLinks.customer.appointmentITab
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                       label="Workshop ITab"
                       name="iTabLinks.customer.workshopItab"
                       className={classes.textField}
                       placeholder="Link Here"
                       margin="normal"
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       value={values.iTabLinks.customer.workshopItab}
                       error={Boolean(
                         errors.iTabLinks &&
                         errors.iTabLinks.customer &&
                         errors.iTabLinks.customer.workshopItab
                       )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                       label="Event ITab"
                       name="iTabLinks.customer.eventITab"
                       className={classes.textField}
                       placeholder="Link Here"
                       margin="normal"
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       value={values.iTabLinks.customer.eventITab}
                       error={Boolean(
                         errors.iTabLinks &&
                         errors.iTabLinks.customer &&
                         errors.iTabLinks.customer.eventITab
                       )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16 }}>
                <h5>iTabLinks for Professional</h5>
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Main ITab"
                      name="iTabLinks.vendor.mainITab"
                      className={classes.textField}
                      placeholder="Link Here"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.iTabLinks.vendor.mainITab}
                      error={Boolean(
                        errors.iTabLinks &&
                        errors.iTabLinks.vendor &&
                        errors.iTabLinks.vendor.mainITab
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Service ITab"
                      name="iTabLinks.vendor.serviceITab"
                      className={classes.textField}
                      placeholder="Link Here"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.iTabLinks.vendor.serviceITab}
                      error={Boolean(
                        errors.iTabLinks &&
                        errors.iTabLinks.vendor &&
                        errors.iTabLinks.vendor.serviceITab
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                       label="Workshop ITab"
                       name="iTabLinks.vendor.workshopItab"
                       className={classes.textField}
                       placeholder="Link Here"
                       margin="normal"
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       value={values.iTabLinks.vendor.workshopItab}
                       error={Boolean(
                         errors.iTabLinks &&
                         errors.iTabLinks.vendor &&
                         errors.iTabLinks.vendor.workshopItab
                       )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                       label="Event ITab"
                       name="iTabLinks.vendor.eventITab"
                       className={classes.textField}
                       placeholder="Link Here"
                       margin="normal"
                       variant="outlined"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       value={values.iTabLinks.vendor.eventITab}
                       error={Boolean(
                         errors.iTabLinks &&
                         errors.iTabLinks.vendor &&
                         errors.iTabLinks.vendor.eventITab
                       )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ padding: 16 }}>
                <h5>Testable Accounts</h5>
                <Grid container spacing={2} style={{ marginTop: 8, marginBottom: 8 }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Customer"
                      name="testableAccounts.customer"
                      className={classes.textField}
                      placeholder="Customer Phone no"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.testableAccounts.customer}
                      error={Boolean(
                        errors.testableAccounts &&
                        errors.testableAccounts.customer
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Professional"
                      name="testableAccounts.vendor"
                      className={classes.textField}
                      placeholder="Professional Phone No"
                      margin="normal"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.testableAccounts.vendor}
                      error={Boolean(
                        errors.testableAccounts &&
                        errors.testableAccounts.vendor
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Button
                variant="contained"
                color="primary"
                className={`${classes.button} mt-3`}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </CardContent>
          </form>
        </Card>
      )}
    </Formik>
  );
};

export default GlobalSettings;