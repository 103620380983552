import React from "react";
import { Link } from "react-router-dom";
import CustomBookingCard from "./component/CustomBookingCard";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";
import NoDataFound from "./component/NoDataFound";
import SectionHoc from "./component/SectionHoc";
import CustomViewScroll from "./component/CustomViewScroll";

const AppointmentSection = ({ dashboardData }) => {
  return (
    <DashboardCardHOC
      title={"Appointments"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL"
              value={dashboardData.totalIndividualBookedSessionTillDate}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #2699FB 0%, #1886E4 67%, #046CC5 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="COMPLETED"
              value={dashboardData.totalIndividualBookedSessionCompleted}
              iconImg="Event"
              bgStyle={{
                background: "#1CC88A 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="UPCOMING"
              value={dashboardData.totalIndividualBookedSessionUpcoming}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(112deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="CANCELLED"
              value={dashboardData.totalIndividualBookedSessionCancelled}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(180deg, #F26522 0%, #F26522 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              type="ViewMore"
              desc="VIEW DATA"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #6692FC 0%, #608FFF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="appointments"
            />
          </div>
        </div>
      }
      collapseContent={
        <div>
          <div className="row" style={{ marginTop: 24 }}>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    Today (
                    {dashboardData?.totalIndividualBookedSessionToday || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/appointments">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.individualBookedSessionListToday &&
                dashboardData.individualBookedSessionListToday.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={
                      dashboardData.individualBookedSessionListToday.length > 8
                    }
                  >
                    {dashboardData.individualBookedSessionListToday.map(
                      (each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard
                              data={{
                                id: each._id,
                                title: each.title,
                                subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                                image: each.thumbnailImage,
                              }}
                              bgColor="#FEF8E7 0% 0% no-repeat padding-box"
                              pageDetails="appointment-details"
                            />
                          </div>
                        );
                      }
                    )}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    This Week (
                    {dashboardData?.totalIndividualBookedSessionThisWeek || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/appointments">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.individualBookedSessionListThisWeek &&
                dashboardData.individualBookedSessionListThisWeek.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={
                      dashboardData.individualBookedSessionListThisWeek.length >
                      8
                    }
                  >
                    {dashboardData.individualBookedSessionListThisWeek.map(
                      (each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard
                              data={{
                                id: each._id,
                                title: each.title,
                                subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                                image: each.thumbnailImage,
                              }}
                              pageDetails="appointment-details"
                              bgColor="#F5FFE2 0% 0% no-repeat padding-box"
                            />
                          </div>
                        );
                      }
                    )}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    This Month (
                    {dashboardData?.totalIndividualBookedSessionThisMonth || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/appointments">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.individualBookedSessionListThisMonth &&
                dashboardData.individualBookedSessionListThisMonth.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={
                      dashboardData.individualBookedSessionListThisMonth
                        .length > 8
                    }
                  >
                    {dashboardData.individualBookedSessionListThisMonth.map(
                      (each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard
                              data={{
                                id: each._id,
                                title: each.title,
                                subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                                image: each.thumbnailImage,
                              }}
                              pageDetails="appointment-details"
                              bgColor="#e3f0fc 0% 0% no-repeat padding-box"
                            />
                          </div>
                        );
                      }
                    )}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AppointmentSection;
