export const notificationTypesRedirectionURLMapObj = {
    "newCustomer": "/users",

    "newVendor": "/professionals",
    "vendorProfileVerificationPending": "/professionals",

    "newWorkshopAprrovalPending": "/workshops",
    "workshopCompleted": "/workshop-details/",

    "newEventAprrovalPending": "/events",

    "forumQuestionApprovalForPending": "/forums",
    "forumAnswerFlagged":"/forum-details/",

    "appointmentCompleted": "/appointment-details/",
    "appointmentBooked": "/appointment-details/",
    "appointmentCanceled": "/appointment-details/",
    "appointmentTimedout":  "/appointment-details/",

    "newSupportCase": "/supports",

    "newRefund": "/transaction-refund-list",

    "testCompleted": "/test-details/",

    "monthlyAppointmentPaymentReminder": "/payments", // settlement-> list-> respective month year
    "workshopPayoutPending": "/payment-details/", // settlement->workshop->details
    "eventPayoutPending": "/payment-details/", // settlement->event->details

    "appointmentCanceledByCustomer": "/booking-details/",
    "bookingCanceledByAdmin": "/booking-details/",
    "appointmentReminderMinute": "/booking-details/",
    "vendorAddedNote": "/booking-details/",
    "ratingReminderMinute": "/booking-details/",
    "eventBooked": "/event-booking-details/",
    "eventReminderMinute": "/event-booking-details/",
    "sessionBooked": "/session-booking-details/",
    "sessionReminderMinute": "/session-booking-details/",
    "approvedForumQuestion": "/myQA",
    "rejectedForumQuestion": "/myQA",
    "answeredForumQuestion": "/myQA",
    "newSession": "/professional-details/",
    "newEvent": "/event-details/",
    "newWorkshop": "/workshop-details/",
    "workshopBooked": "/workshop-booking-details/",
    "workshopReminderMinute": "/workshop-booking-details/",
    "workshopReminderDaily": "/workshop-booking-details/",
    "workshopReminderHour": "/workshop-booking-details/",
};