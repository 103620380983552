export const actionTypes = {
    QA_UPDATED: "QA_UPDATED",
    QA_ANSWER_UPDATED: "QA_ANSWER_UPDATED",
    QA_UPDATED_DONE: "QA_UPDATED_DONE"
};

const initialAuthState = {
    updated: false,
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {

        case actionTypes.QA_UPDATED:
            {
                return { ...state, updated: true };
            }
        case actionTypes.QA_ANSWER_UPDATED:
            {
                return { ...state, updated: true };
            }
        case actionTypes.QA_UPDATED_DONE:
            {
                return { ...state, updated: false };
            }
        default:
            return state;
    }
};

export const actions = {

    qaUpdated: () => ({
        type: actionTypes.QA_UPDATED
    }),
    qaAnswerUpdated: () => ({
        type: actionTypes.QA_ANSWER_UPDATED
    }),
    qaUpdatedDone: () => ({
        type: actionTypes.QA_UPDATED_DONE
    }),
};

export function* saga() {
}
