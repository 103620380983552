import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import ViewProfile from "./account/view-profile";
import RoleList from "./role-management/role-list";
import SubAdminList from "./sub-admin-management/sub-admin-list";
import OrganizationList from "./organization-management/organization-list";
import VendorCategoryList from "./vendor-category-management/vendor-category-list";
import VendorList from "./vendor-management/vendor-list";
import ProblemCategoryList from "./problem-category-management/problem-category-list";
import ProblemList from "./problem/problem-list";
import LanguageList from "./language-management/language-list";
import UserList from "./user-management/user-list";
import ForumList from "./forum-management/forum-list";
import ForumDetails from "./forum-management/forum-details";
import BookingList from "./booking-management/booking-list";
import BookingDetails from "./booking-management/booking-details";
import SessionList from "./session-management/session-list";
import SessionDetails from "./session-management/session-details";
import EventList from "./event-management/event-list";
import WorkshopList from "./workshop-management/workshop-list";
import WorkshopDetails from "./workshop-management/workshop-details";
import EventDetails from "./event-management/event-details";
import PaymentList from "./payment-management/payment-list";
import TransactionRefundList from "./transaction-refund-management/transaction-refund-list";
import PaymentDetailsForVendor from "./payment-management/vendor/payment-details";
import PaymentDetailsForOrganiser from "./payment-management/organization/payment-details";
import PaymentDetailsForOnlineEvent from "./payment-management/online-event/payment-details";
import PaymentDetailsForInstitution from "./payment-management/institution/payment-details";
import PaymentDetailsForWorkshop from "./payment-management/workshop/payment-details";
import SupportList from "./support-management/support-list";
import SupportDetails from "./support-management/support-details";
import FaqList from "./faq/faq-list";
import AppsTutorialList from "./apps-tutorial/apps-tutorial-list";
import SendEmail from "./send-email/index";
import SetupTests from "./setup-tests/setup-test-list";
import EditTestPaper from "./setup-tests/edit-test-paper";
import Tests from "./tests/index";
import AssesmentDetails from "./tests/assesment-details";
import InstitutionList from "./institution-management/institution-list";
import GlobalSettings from "./global-settings/index";
import Reports from "./Reports";
import RevenueCalculator from "./revenue-calculator";
import { LayoutSplashScreen } from "../../../_metronic";
import * as account from "../../store/ducks/account.duck";
import NotificationPage from "./notifications";
const HomePage = (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    props.accountUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(props.data.account.user){
      setLoading(false);
    }

  }, [props.data.account.user]);

  return (
    <>{loading? null:
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/builder" component={Builder} />
        <Route path="/profile" component={ViewProfile} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/roles" component={RoleList} />
        <Route path="/sub-admins" component={SubAdminList} />
        <Route path="/organizations" component={OrganizationList} />
        <Route path="/institutions" component={InstitutionList} />
        <Route path="/professional-categories" component={VendorCategoryList} />
        <Route path="/professionals" component={VendorList} />
        <Route path="/languages" component={LanguageList} />
        <Route path="/problem-categories" component={ProblemCategoryList} />
        <Route path="/problems" component={ProblemList} />
        <Route path="/forums" component={ForumList} />
        <Route path="/forum-details/:qaId" component={ForumDetails} />
        <Route path="/faqs" component={FaqList} />
        <Route path="/tutorials" component={AppsTutorialList} />
        <Route path="/users" component={UserList} />
        <Route path="/appointments" component={BookingList} />
        <Route path="/appointment-details/:appointmentId" component={BookingDetails} />
        <Route path="/sessions" component={SessionList} />
        <Route path="/session-details/:sessionId" component={SessionDetails} />
        <Route path="/workshops" component={WorkshopList} />
        <Route path="/workshop-details/:workshopId" component={WorkshopDetails} />
        <Route path="/events" component={EventList} />
        <Route path="/event-details/:eventId" component={EventDetails} />
        <Route path="/payments" component={PaymentList} />
        <Route path="/payment-details/:id/professional" component={PaymentDetailsForVendor} />
        <Route path="/payment-details/:id/organization" component={PaymentDetailsForOrganiser} />
        <Route path="/payment-details/:id/online-event" component={PaymentDetailsForOnlineEvent} />
        <Route path="/payment-details/:id/institution" component={PaymentDetailsForInstitution} />
        <Route path="/payment-details/:id/workshop" component={PaymentDetailsForWorkshop} />
        <Route path="/transaction-refund-list" component={TransactionRefundList} />
        <Route path="/supports" component={SupportList} />
        <Route path="/support-details/:supportId" component={SupportDetails} />
        <Route path="/send-email" component={SendEmail} />
        <Route path="/setup-tests" component={SetupTests} />
        <Route path="/edit-test-details/:testPaperId" component={EditTestPaper} />
        <Route path="/tests" component={Tests} />
        <Route path="/test-details/:customerId" component={AssesmentDetails} />
        <Route path="/global-setting" component={GlobalSettings} />
        <Route path="/reports" component={Reports} />
        <Route path="/revenue-calculator" component={RevenueCalculator} />
        <Route path="/notifications" component={NotificationPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>}
    </>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountUpdated: () => dispatch(account.actions.accountUpdated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
