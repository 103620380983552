import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button, Image as BImage } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import styles from "./styles";
import { editUser } from "../../../crud/user.crud";
import * as user from "../../../store/ducks/user.duck";
import DateFnsUtils from "@date-io/date-fns";
import ApiErrorMessage from "../../../crud/api.errorMessage";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  VALID_PHONE_ERROR,
  VALID_NAME_ERROR,
  INVALID_EMAIL,
} from "./../../../config/error-message";
const useStyles = makeStyles({
  root: {
    width: "600px",
  },
});

const EditUser = (props) => {
  const customClasses = useStyles();
  const { classes, intl } = props;
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    phone: null,
    dob: null,
    gender: null,
  });
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const observed = useRef();
  const [oldEmail, setOldEmail] = useState("");
  useEffect(() => {
    props.user.personalInfo.profilePicture &&
      setPreview(props.user.personalInfo.profilePicture);
    setName(props.user.personalInfo.name);
    setEmail(props.user.personalInfo.email);
    setPhone(props.user.personalInfo.phone.number);
    setDob(props.user.personalInfo.dob);
    setGender(props.user.personalInfo.gender);
    setEmail(props.user.personalInfo.email);
    setOldEmail(props.user.personalInfo.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const onChangeHandler = (event) => {
    let file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      console.log(img.width);
      console.log(img.height);
      if (img.width <= 600 && img.height <= 600) {
        console.log("true", file);
        setSelectedFile(file);
        setErrors({
          ...errors,
          icon: null,
        });
        return true;
      }
      setErrors({
        ...errors,
        icon: "Icon should be 600x600px",
      });
      return false;
    };
  };

  const validateName = (name) => {
    return /^[A-Za-z\s]+$/.test(name);
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    } else if (validateName(event.target.value)) {
      setErrors({
        ...errors,
        name: null,
      });
    } else {
      setErrors({
        ...errors,
        name: VALID_NAME_ERROR,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else if (validateEmail(event.target.value)) {
      setErrors({
        ...errors,
        email: null,
      });
    } else {
      setErrors({
        ...errors,
        email: INVALID_EMAIL,
      });
    }
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    if (!isFinite(phone) || (isFinite(phone) && phone.trim().length < 10)) {
      return false;
    } else if (isFinite(phone) && phone.trim().length === 10) {
      return true;
    }
  };

  const onChangePhoneHandler = (event) => {
    setPhone(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        phone: "Phone is required",
      });
    } else if (validatePhone(event.target.value)) {
      setErrors({
        ...errors,
        phone: null,
      });
    } else {
      setErrors({
        ...errors,
        phone: VALID_PHONE_ERROR,
      });
    }
  };

  const onChangeDobHandler = (date) => {
    setDob(date);
    if (!date) {
      setErrors({
        ...errors,
        dob: "DOB is required",
      });
    } else {
      setErrors({
        ...errors,
        dob: null,
      });
    }
  };
  const onChangeGenderHandler = (genderValue) => {
    setGender(genderValue);
    if (!genderValue) {
      setErrors({
        ...errors,
        gender: "Gender is required",
      });
    } else {
      setErrors({
        ...errors,
        gender: null,
      });
    }
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setPhone("");
    setDob("");
    setGender("");
    setPreview("");
    setErrors({
      name: null,
      email: null,
      phone: null,
      dob: null,
      gender: null,
    });
  };

  const validForm = () => {
    let e = true;

    if (!name && !phone && !email && !dob && !gender) {
      setErrors({
        ...errors,
        name: "Name is required",
        email: "Email is required",
        phone: "Phone Number is required",
        dob: "DOB is required",
        gender: "Gender is required",
      });
      e = false;
    }

    if (!name) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
      e = false;
    }

    if (name && !validateName(name)) {
      setErrors({
        ...errors,
        name: VALID_NAME_ERROR,
      });
      e = false;
    }

    if (!email) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
      e = false;
    }

    if (email && !validateEmail(email)) {
      setErrors({
        ...errors,
        email: INVALID_EMAIL,
      });
      e = false;
    }

    if (!phone) {
      setErrors({
        ...errors,
        phone: "Phone is required",
      });
      e = false;
    }

    if (phone && !validatePhone(phone)) {
      setErrors({
        ...errors,
        phone: VALID_PHONE_ERROR,
      });
      e = false;
    }

    if (!dob) {
      setErrors({
        ...errors,
        dob: "DOB is required",
      });
      e = false;
    }

    if (!gender) {
      setErrors({
        ...errors,
        gender: "Gender is required",
      });
      e = false;
    }

    if (
      name &&
      validateName(name) &&
      email &&
      validateEmail(email) &&
      phone &&
      validatePhone(phone) &&
      dob &&
      gender
    ) {
      setErrors({
        ...errors,
        name: null,
        email: null,
        phone: null,
        gender: null,
        dob: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      const payload = {
        name: name,
        phone: {
          countryCode: "IN",
          dialCode: "+91",
          number: phone,
        },
        email: email,
        dob: dob,
        gender: gender,
      };
      if (oldEmail !== email) {
        payload.email = email;
      }

      if (selectedFile) {
        payload.profilePicture = selectedFile;
      }

      editUser(props.user._id, payload)
        .then((output) => {
          if (output.data.success) {
            props.userUpdated({
              skip: props.listOption.skip,
              limit: props.listOption.limit,
            });
            resetState();
            setLoading(false);
            toast.success("User is successfully updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("subAdmin");
          } else {
            setLoading(false);
            toast.error(
              intl.formatMessage(
                {
                  id: ApiErrorMessage[output.data.errorCode],
                },
                { title: name }
              ),
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          resetState();
          props.handleClose("user");
        }}
      > 
        <div className={customClasses.root}>
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "16px",
              backgroundColor: "#2e40d4",
              borderColor: "#293ccc",
              color: "#fff",
              padding: "4px 8px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              resetState();
              props.handleClose("user");
            }}
          >
            <i className="la la-close"></i>
          </div>

          <div
            className={`vendor-page row}`}
            style={{
              margin: 0,
            }}
          >
            <div className="col-12 categories-heading">
              <h4 className={`mb-0`}>Edit User Profile</h4>
            </div>
            <div className="col-12" style={{padding: '12px 16px'}}>
              <Form>
                <Form.Group controlId="organizationForm.profilePicture">
                  <Form.Label>Profile Picture</Form.Label>
                  <div className="row">
                    <div className="col-5">
                      <div className={classes.bigAvatar}>
                        {preview ? (
                          <BImage src={preview} thumbnail />
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "120px",
                              background: "#ccc",
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              justifyContent: "center",
                              color: "#f6f6f6",
                              fontSize: "14px",
                            }}
                          >
                            <span>600 x 600 px</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-5">
                      <div className={classes.profileImageUploaderContainer}>
                        <div
                          className={classes.uploadImageButtonFile}
                          onClick={(e) => {
                            observed.current.click();
                          }}
                        >
                          <input
                            ref={observed}
                            type="file"
                            style={{
                              display: "none",
                            }}
                            onChange={onChangeHandler}
                          />
                          <img
                            src={toAbsoluteUrl("/media/icons/image-icon.png")}
                            alt="no-img"
                          />
                          <span>Browse Profile Picture</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.profilePicture ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{errors.profilePicture}</div>
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="user.name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={onChangeNameHandler}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="user.email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={email}
                    onChange={onChangeEmailHandler}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="user.phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={phone}
                    onChange={onChangePhoneHandler}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    label="Date of Birth"
                    format="dd-MM-yyyy"
                    value={dob}
                    onChange={onChangeDobHandler}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Form.Group controlId="user.gender" style={{paddingTop: '16px'}}>
                  <Form.Label>Gender</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    value={gender}
                    onChange={() => onChangeGenderHandler(1)}
                    isInvalid={!!errors.gender}
                    checked={gender === 1}
                    style={{paddingLeft: '24px'}}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    value={gender}
                    onChange={() => onChangeGenderHandler(2)}
                    isInvalid={!!errors.gender}
                    checked={gender === 2}
                    style={{paddingLeft: '24px'}}
                  />
                   <Form.Check
                    type="radio"
                    label="Other"
                    name="gender"
                    value={gender}
                    onChange={() => onChangeGenderHandler(3)}
                    isInvalid={!!errors.gender}
                    checked={gender === 3}
                    style={{paddingLeft: '24px'}}
                  />
                  {/* <Form.Control
                type="text"
                value={gender}
                onChange={onChangeGenderHandler}
                isInvalid={!!errors.gender}
              /> */}
                  <Form.Control.Feedback type="invalid">
                    {errors.gender}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>

              <Button
                variant="primary"
                style={{
                  position: "relative",
                }}
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userUpdated: ({ skip, limit }) =>
      dispatch(user.actions.userUpdated({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditUser)
  )
);
