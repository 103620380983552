/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

const UserProfile = (props) => {
  const { showHi, showAvatar, showBadge } = props;

  const [user, setUser] = useState({ personalInfo: null });

  useEffect(() => {
    if (props.account.user) {
      setUser(props.account.user);
    }
  }, [props.account.user]);

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          {showHi && (
            <span className="kt-header__topbar-welcome kt-hidden-mobile">
              Hi,
            </span>
          )}

          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user.personalInfo ? user.personalInfo.firstName : null}{" "}
              {user.personalInfo ? user.personalInfo.lastName : null}
            </span>
          )}

          {showAvatar && user.personalInfo && (
            <img
              alt="Profile Avater"
              src={
                user.personalInfo && user.personalInfo.profilePicture
                  ? user.personalInfo.profilePicture
                  : toAbsoluteUrl("/media/default-image/default-image.png")
              }
            />
          )}

          {showBadge && (
            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
              {/* TODO: Should get from currentUser */}
              {user.personalInfo ? user.personalInfo.firstName : null}{" "}
              {user.personalInfo ? user.personalInfo.lastName : null}
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div
          className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
          }}
        >
          <div className="kt-user-card__avatar">
            <img
              alt="Profile Avatar Card"
              className="kt-hidden"
              src={
                user.personalInfo && user.personalInfo.profilePicture
                  ? user.personalInfo.profilePicture
                  : toAbsoluteUrl("/media/default-image/default-image.png")
              }
            />
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
              {user.personalInfo && user.personalInfo.firstName.substr(0, 1)}
            </span>
          </div>
          <div className="kt-user-card__name">
            {user.personalInfo ? user.personalInfo.firstName : null}{" "}
            {user.personalInfo ? user.personalInfo.lastName : null}
          </div>
          {/* <div className="kt-user-card__badge">
              <span className="btn btn-success btn-sm btn-bold btn-font-md">
                23 messages
              </span>
            </div> */}
        </div>
        <div className="kt-notification">
          <div className="kt-notification__item">
            <div className="kt-notification__item-icon">
              <i className="flaticon2-calendar-3 kt-font-success" />
            </div>
            <Link
              to={{
                pathname: `/profile`,
                state: { title: `Profile Management` },
              }}
            >
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>

                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>
          </div>
          {/* <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-mail kt-font-warning" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Messages
                </div>
                <div className="kt-notification__item-time">
                  Inbox and tasks
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-rocket-1 kt-font-danger" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Activities
                </div>
                <div className="kt-notification__item-time">
                  Logs and notifications
                </div>
              </div>
            </a>
            <a className="kt-notification__item">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-hourglass kt-font-brand" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Tasks
                </div>
                <div className="kt-notification__item-time">
                  latest tasks and projects
                </div>
              </div>
            </a> */}
          <div className="kt-notification__custom">
            <Link to="/logout" className="btn btn-label-brand btn-sm btn-bold">
              Sign Out
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ account }) => ({
  account: account,
});

export default connect(mapStateToProps)(UserProfile);
