import React, { useState,useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { Form, Button, Modal, Image as BImage } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";

import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import * as qa from "../../../store/ducks/qa.duck";
import { addQA } from '../../../crud/qa.crud';
const CreateForum = (props) => {
    const [loading,
        setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description,
        setDescription] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const observed = useRef(null);
      
    const [errors,
        setErrors] = useState({
            description: null, 
            name: null,
        });
    const { classes } = props;
    let sub;

    useEffect(() => {
        if (!selectedFile) {
          setPreview(undefined);
          return;
        }
    
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
    
        return () => URL.revokeObjectURL(objectUrl);
      }, [selectedFile]);
    
      useEffect(() => {
        return () => {
          if (sub) {
            clearTimeout(sub);
          }
        };
      }, [sub]);
    
      const onChangeHandler = (event) => {
        let file = event.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          // if (img.width <= 160 && img.height <= 200) {
            setSelectedFile(file);
            setErrors({
              ...errors,
              icon: null,
            });
            return true;
          // }
          // setErrors({
          //   ...errors,
          //   icon: "Icon should be in 160x200px",
          // });
          // sub = setTimeout(() => {
          //   setErrors({
          //     ...errors,
          //     icon: null,
          //   });
          // }, 3000);
          // return false;
        };
      };
      

    const onChangeNameHandler = (event) => {
        setName(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                name: "Name is required",
            });
        } else {
            setErrors({
                ...errors,
                name: null,
            });
        }
    };


    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Question is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    const resetState = () => {
        setName('');
        setDescription('');
        setErrors({
            name: null, 
            description: null
        });
    }


    const validForm = () => {
        let e = true;

        if (!name && !description) {
            setErrors({
              ...errors,
              name: " Name is required",
              description: "Question is required",
            });
            e = false;
          } else if (!name) {
            setErrors({
              ...errors,
              name: "Name is required",
            });
            e = false;
          } else if (!description) {
            setErrors({
              ...errors,
              description: "Question is required",
            });
            e = false;
          }else {
            setErrors({
              ...errors,
              name: null,
              description: null,
            });
          }

        return e;
    };


    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);

            const payload = {
                icon: selectedFile,
                fname: name.split(' ')[0], 
                lname: name.split(' ')[1],
                question: description
            };

            addQA(payload).then((output) => {
                if (output.data.success) {
                    props.qaUpdated();
                    resetState();
                    setLoading(false);
                    toast.success('Forum question has been added successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('forums');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);

            });
        }

    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={() => props.handleClose('forums')}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group controlId="professionalCategoryForm.icon">
              <Form.Label>Asked By User Picture</Form.Label>
              <div className="row">
                <div className="col-4">
                  <div className={classes.bigAvatar}>
                    {selectedFile ? (
                      <BImage src={preview} thumbnail />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                        src={toAbsoluteUrl("/media/default-image/default-image.png")}
                        alt="no-img"
                      />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-1"></div>
                <div className="col-4">
                  <div className={classes.profileImageUploaderContainer}>
                    <div
                      className={classes.uploadImageButtonFile}
                      onClick={(e) => {
                        observed.current.click();
                      }}
                    >
                      <input
                        ref={observed}
                        type="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                      <img
                        src={toAbsoluteUrl("/media/icons/image-icon.png")}
                        alt="no-img"
                      />
                      <span>Browse User Picture</span>
                    </div>
                  </div>
                </div>
              </div>
              {errors.icon ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{errors.icon}</div>
                </div>
              ) : null}
            </Form.Group>
                        <Form.Group controlId="askby.name">
                            <Form.Label>Asked By User Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={onChangeNameHandler}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                       
                       
                        <Form.Group controlId="forum.question">
                        <Form.Label>Question</Form.Label>
                            <Form.Control as="textarea"
                                rows="3" value={description} onChange={onDescriptionChangeHandler} isInvalid={!!errors.description} />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose('forums')}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                            position: "relative"
                        }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        qaUpdated: () => dispatch(qa.actions.qaUpdated())
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), { withTheme: true })(CreateForum)));
