import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import styles from "./styles";
import { editAppsTutorial } from "../../../crud/apps-tutorial.crud";
import * as appsTutorial from "../../../store/ducks/apps-tutorial.duck";
import userConfig from "./../../../config/user";
const useStyles = makeStyles({
  root: {
    width: "600px",
  },
});
const EditAppsTutorial = (props) => {
  const customClasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ title: null, icon: null });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userType, setUserType] = useState(userConfig.role.customer);

  const { classes, appsTutorial } = props;

  useEffect(() => {
    const dataProps = JSON.parse(JSON.stringify(appsTutorial));
    setTitle(dataProps.title);
    setDescription(dataProps.description);
    setUserType(dataProps.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTitleChangeHandler = (event) => {
    setTitle(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        title: "Title is required",
      });
    } else {
      setErrors({
        ...errors,
        title: null,
      });
    }
  };

  const onDescriptionChangeHandler = (event) => {
    setDescription(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        description: "Description is required",
      });
    } else {
      setErrors({
        ...errors,
        description: null,
      });
    }
  };

  const resetState = () => {
    setTitle("");
    setDescription("");
    setUserType(userConfig.role.customer);
    setErrors({
      title: null,
      description: null,
    });
  };

  const validForm = () => {
    let e = true;

    if (!title && !description) {
      setErrors({
        ...errors,
        title: "Title is required",
        description: "Description is required",
      });
      e = false;
    } else if (!title) {
      setErrors({
        ...errors,
        title: "Title is required",
        description: "Description is required",
      });
      e = false;
    } else if (!description) {
      setErrors({
        ...errors,
        description: "Description is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        title: null,
        description: null,
      });
    }
    return e;
  };

  const handleUserType = (userTypeVal) => {
    setUserType(userTypeVal);
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);

      const payload = {
        title: title,
        description: description,
        userType: userType,
      };

      editAppsTutorial(appsTutorial._id, payload)
        .then((output) => {
          if (output.data.success) {
            props.appsTutorialUpdated({
              skip: props.listOption.skip,
              limit: props.listOption.limit,
              userType: props.userType,
            });
            resetState();
            setLoading(false);
            toast.success("Tutorial is successfully updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("appsTutorial");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          resetState();
          props.handleClose("appsTutorial");
        }}
      >
        <div className={customClasses.root}>
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "16px",
              backgroundColor: "#2e40d4",
              borderColor: "#293ccc",
              color: "#fff",
              padding: "4px 8px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              resetState();
              props.handleClose("vendor");
            }}
          >
            <i className="la la-close"></i>
          </div>

          <div
            className={`vendor-page row}`}
            style={{
              margin: 0,
            }}
          >
            <div className="col-12 categories-heading">
              <h4 className={`mb-0`}>Edit Tutorial</h4>
            </div>
            <div className="col-12" style={{ padding: "12px 16px" }}>
              <Form>
                <Form.Group>
                  <Form.Label>User</Form.Label>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      disabled
                      label="Customer"
                      name="userType"
                      type={"radio"}
                      checked={userType === userConfig.role.customer}
                      onChange={() => handleUserType(userConfig.role.customer)}
                    />
                    <Form.Check
                      inline
                      disabled
                      label="Professional"
                      name="userType"
                      type={"radio"}
                      checked={userType === userConfig.role.vendor}
                      onChange={() => handleUserType(userConfig.role.vendor)}
                    />
                  </div>
                </Form.Group>
                <Form.Group controlId="appsTutorialForm.title">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={onTitleChangeHandler}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="appsTutorialForm.description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    value={description}
                    onChange={onDescriptionChangeHandler}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>

              <Button
                variant="primary"
                style={{
                  position: "relative",
                }}
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appsTutorialUpdated: ({ skip, limit, userType }) =>
      dispatch(
        appsTutorial.actions.appsTutorialUpdated({ skip, limit, userType })
      ),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditAppsTutorial)
  )
);
