import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { styled } from "@material-ui/core/styles";

const CustomCardHeader = styled((props) => (
    <CardHeader
      {...props}
    />
  ))(() => ({
    backgroundColor: '#e6ebf8',
    '& .MuiTypography-h5': {
        fontSize: 16
    }
  }));

const SectionHoc = ({title, children, leftContent, rightContent, isleftRightTxt})=>{
    return (<>
    <Card style={{height: 550}}>
      {!leftContent && !rightContent && <CustomCardHeader title={title}/>}
      {(leftContent && rightContent) || (title && rightContent)?<div 
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#e6ebf8',
        padding: isleftRightTxt? 16: 'inherit'
      }}>
        <div>{leftContent? leftContent: <CustomCardHeader title={title}/>}</div>
        <div>{rightContent}</div>
      </div>: null }
      <CardContent style={{padding: 0}}>
          {children}
      </CardContent>
    </Card>
    </>)
};

export default SectionHoc;