import React from "react";

import { withStyles } from "@material-ui/core/styles";

const useStyles = () => ({
  show: {
    display: "block!important",
    background: "rgba(0,0,0,0.5)!important",
  },
});

const ChangeProfileTypeModal = ({
  classes,
  show,
  handleClose,
  handleConfirm,
  profileType,
}) => {
  return (
    <div className="row" style={{ margin: 0 }}>
      <div
        className={`modal ${show ? classes.show : null}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Alert</h5>
            </div>
            <div className="modal-body">
              <div className="row" style={{ margin: 0 }}>
                <h5 className="col-12 p-0 mb-3" style={{ color: "#000" }}>
                  {profileType===1? `If you change the profile type as An Individual Practitioner saved addresses and services
                  will be removed. You will have to create new addresses and
                  services as an individual.`: ''}
                  {profileType===1? <br />: null}
                  Do you want to change the profile type?
                </h5>
              </div>

              <div className="col-12 p-0 text-right mt-3">
                <button
                  type="button"
                  className="btn btn-outline-primary mr-2"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(
  (theme) => ({
    ...useStyles(theme),
  }),
  { withTheme: true }
)(ChangeProfileTypeModal);
