import React from 'react';
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import bookingConfig from "./../../../../config/booking";

export default function VendorPaymentList({ tableData, selectedDate }) {
    return (
        <Table bordered hover>
            <thead className="custom-table-header">
                <tr>
                    <th className="custom-table-header-text">SL No</th>
                    <th className="custom-table-header-text">Name</th>
                    <th className="custom-table-header-text">Phone</th>
                    <th className="custom-table-header-text">Total Fees</th>
                    <th className="custom-table-header-text">Payout Status</th>
                    <th className="custom-table-header-text">Action</th>
                </tr>
            </thead>
            <tbody>
                {tableData.data.length ? tableData.data.map((eachData, key) => {
                    return (
                        <tr key={`${eachData._id}`} className={
                            key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                          }>
                            <td className="custom-table-body-text">{`${tableData.skip + key + 1}`}</td>
                            <td className="custom-table-body-text">
                                {eachData.vendorRef.personalInfo.name
                                    ? eachData.vendorRef.personalInfo.name
                                    : "N/A"}
                            </td>
                            <td className="custom-table-body-text">{`${eachData.vendorRef.personalInfo.phone.dialCode}-${eachData.vendorRef.personalInfo.phone.number}`}</td>
                            <td className="custom-table-body-text">{eachData.totalFees.toFixed(2)}</td>
                            <td className="custom-table-body-text">{bookingConfig.transferStatus[eachData.status]}</td>
                            <td className="text-center">
                                <Link to={{ pathname: `/payment-details/${eachData.vendorRef._id}/professional`, query: { selectedDate: selectedDate } }}>
                                    <p
                                        className="btn btn-icon custom-table-btn"
                                        style={{
                                            margin: "0",
                                        }}
                                    >
                                        <i className="la la-eye"></i>
                                    </p>
                                </Link>
                            </td>
                        </tr>
                    );
                }) : null}
            </tbody>
        </Table>
    )
}