import React from 'react';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    mb: {
        marginBottom: '1rem !important'
    },
    pall: {
        padding: '16px !important'
    },
    pallSm:{
        padding:'7px 16px !important'
    },
    flexBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    CsTypography: {
        fontSize:'1rem',
        fontWeight:400
    },
    CsCenter:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center'
    }
});

const PCAddLanguageInputCard = (props)=> {
    const classes = useStyles();
    
    return (
        <Card className={`${classes.root} ${classes.mb}`}>
            <CardContent className={classes.pallSm}>
                <div className={classes.flexBetween}>
                    <div className={`${classes.CsTypography} ${classes.CsCenter}`}>Add Language</div>
                    <div>
                        <Fab size="small" color="primary" aria-label="Add" onClick={props.problemAdd}>
                            <AddIcon/>
                        </Fab>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};



export default PCAddLanguageInputCard;
