import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomColumnBarChart from "./component/CustomColumnBarChart";
import CustomDateRange from "./component/CustomDateRange";
import CustomUserCard from "./component/CustomUserCard";
import CustomBookingCard from "./component/CustomBookingCard";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";
import SectionHoc from "./component/SectionHoc";
import StyleDropdown from "../../../components/StyleDropdown";
import CustomPieChart from "./component/CustomPieChart";
import BorderLinearProgress from "./component/BorderLinearProgress";
import CustomViewScroll from "./component/CustomViewScroll";
import {
  getThisWeekProfessionalData,
  getThisMonthProfessionalData,
  getThisCustomDateRangeProfessionalData,
} from "./../../../crud/dashboard.crud";
import { listVendorByAllState, listVendor } from "./../../../crud/vendor.crud";
import NoDataFound from "./component/NoDataFound";
import { IconButton } from "@material-ui/core";
import { ListSharp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customBg: {
    "&.MuiIconButton-root": {
      background: "#5a5c69",
      opacity: 0.9,
      padding: 2,
      marginRight: 16,
      borderRadius: 2,
      "& .MuiIconButton-label": {
        color: "#fff",
      },
      "&:hover": {
        opacity: 1,
      },
    },
  },
}));

const stateJSON = {
  karnataka: "#D92121",
  "uttar pradesh": "#FF355E",
  uttarakhand: "#FFAA1D",
  bihar: "#299617",
  telangana: "#A7F432",
  "tamil nadu": "#2243B6",
  "andaman and nicobar": "#9C51B6",
  "madhya pradesh": "#FF6037",
  chhattisgarh: "#50BFE6",
  lakshadweep: "#FF00CC",
  chandigarh: "#A83731",
  "jammu and kashmir": "#AF6E4D",
  haryana: "#FF5470",
  "nct of delhi": "#87FF2A",
  "dadara and nagar havelli": "#FF007C",
  odisha: "#4A646C",
  maharashtra: "#85754E",
  "west bengal": "#0081AB",
  assam: "#02A4D3",
  rajasthan: "#AD4379",
  kerala: "#C95A49",
  jharkhand: "#9B7653",
};

const ProfessionalSection = ({ dashboardData }) => {
  const classes = useStyles();

  const [stateWiseVendorsData, setStateWiseVendorsData] = useState([]);
  const [recentVendorsData, setRecentVendorsData] = useState([]);
  const [stateWiseProfList, setStateWiseProfList] = useState(true);
  const [searchProfessionalType, setSearchProfessionalType] = useState({
    type: 1,
    text: " This Week Registration",
  });
  const [thisWeekProfessionalData, setThisWeekProfessionalData] = useState();
  const [thisMonthProfessionalData, setThisMonthProfessionalData] = useState();
  const [
    thisCustomDateRangeProfessionalData,
    setThisCustomDateRangeProfessionalData,
  ] = useState();

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchProfessionalWeekly = async () => {
    const output = await getThisWeekProfessionalData();

    if (output.data && output.data.data) {
      if (output.data.data.chartData.length) {
        setThisWeekProfessionalData(output.data.data);
      } else {
        setThisWeekProfessionalData();
      }
    }
  };

  const fetchProfessionalMonthly = async () => {
    const output = await getThisMonthProfessionalData();
    if (output.data && output.data.data) {
      if (output.data.data.chartData.length) {
        setThisMonthProfessionalData(output.data.data);
      } else {
        setThisMonthProfessionalData();
      }
    }
  };

  const fetchProfessionalCustom = async (startDate, endDate) => {
    const output = await getThisCustomDateRangeProfessionalData({
      startDate,
      endDate,
    });
    if (output.data && output.data.data) {
      if (output.data.data.chartData.length) {
        setThisCustomDateRangeProfessionalData(output.data.data);
      } else {
        setThisCustomDateRangeProfessionalData();
      }
    }
  };

  const getTotalProfs = () => {
    switch (searchProfessionalType.type) {
      case 1:
        return thisWeekProfessionalData?.totalProfessionalThisWeek;
      case 2:
        return thisMonthProfessionalData?.totalProfessionalThisMonth;
      case 3:
        return thisCustomDateRangeProfessionalData?.totalProfessionalThis;
      default:
        return 0;
    }
  };

  const getProfsChartData = () => {
    switch (searchProfessionalType.type) {
      case 1:
        return thisWeekProfessionalData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      case 2:
        return thisMonthProfessionalData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      case 3:
        return thisCustomDateRangeProfessionalData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      default:
        return 0;
    }
  };

  useEffect(() => {
    switch (searchProfessionalType.type) {
      case 1:
        fetchProfessionalWeekly();
        break;
      case 2:
        fetchProfessionalMonthly();
        break;
      case 3:
        fetchProfessionalCustom(new Date(), new Date());
        break;
      default:
        break;
    }
  }, [searchProfessionalType]);

  useEffect(() => {
    (async () => {
      const stateWiseVendorsOutput = await listVendorByAllState({
        payload: {
          selectedState: undefined,
        },
      });
      stateWiseVendorsOutput.data &&
        stateWiseVendorsOutput.data.data &&
        setStateWiseVendorsData(
          stateWiseVendorsOutput.data.data
            .map((each) => {
              return {
                state: each[0],
                count: each[1],
              };
            })
            .sort((a, b) => b.count - a.count)
        );

      const recentProfsData = await listVendor({
        skip: 0,
        limit: 10,
        payload: {
          searchKey: undefined,
          searchType: undefined,
          status: 6,
          submittedAt: undefined,
        },
      });
      recentProfsData.data &&
        recentProfsData.data.data &&
        setRecentVendorsData(recentProfsData.data.data.data);
      await fetchProfessionalWeekly();
    })();
  }, []);

  return (
    <DashboardCardHOC
      title={"Professionals"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL PROFESSIONAL"
              value={dashboardData.totalProfessional}
              iconImg="Professional"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #2699FB 0%, #1886E4 43%, #046CC5 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="PENDING for APPORVAL"
              value={dashboardData.totalProfessionalPendingApproval}
              iconImg="Professional"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="ACTIVE"
              value={dashboardData.totalProfessionalActive}
              iconImg="Professional"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #08A045 0%, #0B6E4F 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="INACTIVE"
              value={dashboardData.totalProfessionalInActive}
              iconImg="Professional"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #FA5954 0%, #F32934 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              type="ViewMore"
              desc="VIEW DATA"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #6692FC 0%, #608FFF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="professionals"
            />
          </div>
        </div>
      }
      collapseContent={
        <div>
          <div className="row">
            <div className="col-lg-7">
              <SectionHoc
                leftContent={
                  <>
                    <StyleDropdown style={{ padding: "7px 16px" }}>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {searchProfessionalType.text}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchProfessionalType({
                              type: 1,
                              text: "This Week Registration",
                            });
                          }}
                        >
                          This Week Registration
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchProfessionalType({
                              type: 2,
                              text: "This Month Registration",
                            });
                          }}
                        >
                          This Month Registration
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchProfessionalType({
                              type: 3,
                              text: "Custom",
                            });
                          }}
                        >
                          Custom
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </StyleDropdown>
                  </>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    Total {getTotalProfs()}
                  </p>
                }
              >
                {searchProfessionalType.type === 3 ? (
                  <CustomDateRange viewData={fetchProfessionalCustom} />
                ) : null}
                {(searchProfessionalType.type === 1 &&
                  thisWeekProfessionalData &&
                  thisWeekProfessionalData.chartData.length) ||
                (searchProfessionalType.type === 2 &&
                  thisMonthProfessionalData &&
                  thisMonthProfessionalData.chartData.length) ||
                (searchProfessionalType.type === 3 &&
                  thisCustomDateRangeProfessionalData &&
                  thisCustomDateRangeProfessionalData.chartData.length) ? (
                  <CustomColumnBarChart data={getProfsChartData()} />
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-5">
              <SectionHoc title={`Professionals (By Category)`}>
                <div
                  style={{
                    paddingTop: 48,
                  }}
                >
                  {dashboardData.totalProfessionalByCategory &&
                  dashboardData.totalProfessionalByCategory.length ? (
                    <CustomPieChart
                      name={`Professionals (By Category)`}
                      data={dashboardData.totalProfessionalByCategory.map(
                        (each) => {
                          return [
                            `${each.category} [${each.count}]`,
                            each.count,
                            false,
                          ];
                        }
                      )}
                    />
                  ) : null}
                </div>
              </SectionHoc>
            </div>
          </div>
          <div className="row" style={{ marginTop: 24 }}>
            <div className="col-lg-7">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    Recent Professionals
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/professionals">VIEW ALL</Link>
                  </p>
                }
              >
                {recentVendorsData && recentVendorsData.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={recentVendorsData.length > 8}
                  >
                    <div className="row row-cols-2">
                      {recentVendorsData.map((each, key) => {
                        const designations = each.personalInfo.categories.length
                          ? each.personalInfo.categories
                              .map((each) => each.title)
                              .join(", ")
                          : "N/A";
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomUserCard
                              userType="professional"
                              data={{
                                id: each._id,
                                name: each.personalInfo?.name,
                                designation: designations,
                                profileImg: each.personalInfo?.profilePicture,
                                address: each.address ? each.address : "N/A",
                              }}
                              color="#ECF7F7"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-5">
              <SectionHoc
                title={`Professional by states`}
                rightContent={
                  <div>
                    <IconButton
                      className={classes.customBg}
                      onClick={() => {
                        setStateWiseProfList(!stateWiseProfList);
                      }}
                      aria-label="List"
                    >
                      <ListSharp />
                    </IconButton>
                  </div>
                }
              >
                {stateWiseVendorsData && stateWiseVendorsData.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={stateWiseVendorsData.length > 8}
                  >
                    {stateWiseVendorsData.map((each, key) => {
                      let bgColor = stateJSON[`${each.state}`];
                      let title = toTitleCase(each.state);
                      return stateWiseProfList ? (
                        <div style={{ marginBottom: 12 }} key={key}>
                          <p style={{ textTransform: "capitalize" }}>
                            {title} ({each.count})
                          </p>
                          <BorderLinearProgress
                            variant="determinate"
                            value={Number(
                              (each.count / stateWiseVendorsData[0].count) * 100
                            )}
                            bgColor={bgColor}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#ffffff",
                            paddingBottom: 12,
                          }}
                          key={key}
                        >
                          <CustomBookingCard
                            data={{
                              id: each._id,
                              title: title,
                              subTitle: `Total professionals: ${each.count}`,
                              bgColor: bgColor,
                            }}
                            pageDetails="event-details"
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"
                            type="stateWiseProfList"
                          />
                        </div>
                      );
                    })}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ProfessionalSection;
