const supportConfig = {
  status: {
    1: "Pending",
    2: "Resolved",
    3: "Rejected",
  },
  type: {
    1: "Complaint",
    2: "Suggestion",
  },
};
export default supportConfig;
