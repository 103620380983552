import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { getAllAssessmentByUsers } from "../../../crud/test-paper.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const BookedTests = (props) => {
    const [tableData, setTableData] = useState({
        data: [],
        skip: 0,
        limit: 10,
        total: 0,
        totalPage: 0,
    });

    const [page, setPage] = useState(1);
    // eslint-disable-next-line
    const [selectedStatus, setSelectedStatus] = useState(undefined);

    useEffect(() => {

        reloadList({
            status: selectedStatus,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.data.testPaper.listData) {
            let listObject = {
                data: [...props.data.testPaper.listData.data.data.data],
                skip: tableData.skip,
                limit: tableData.limit,
                total: props.data.testPaper.listData.data.data.total,
                totalPage: Math.ceil(
                    Number(props.data.testPaper.listData.data.data.total / tableData.limit)
                ),
            };
            //   console.log("listObject", listObject);
            setTableData({
                ...tableData,
                ...listObject,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.testPaper.listData]);

    const reloadList = (payload) => {
        getAllAssessmentByUsers({
            skip: tableData.skip,
            limit: tableData.limit,
            payload: {
                status: payload.status,
            },
        }).then((docs) => {
            if (docs.data) {
                setTableData({
                    data: [...docs.data.data.data],
                    skip: tableData.skip,
                    limit: tableData.limit,
                    total: 0,
                    totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
                });
            }
        });
    };

    const handleChange = (_event, value) => {
        setPage(value);

        getAllAssessmentByUsers({
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            payload: {
                status: selectedStatus,
            },
        }).then((docs) => {
            let listObject = {
                data: [...docs.data.data.data],
                skip: Number((value - 1) * tableData.limit),
                limit: tableData.limit,
                total: docs.data.data.total,
                totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
            };

            setTableData({
                ...tableData,
                ...listObject,
            });
        });
    };

    // const renderBookingForType = (type) => {
    //     switch (type) {
    //         case 1:
    //             return 'Self';
    //         case 2:
    //             return 'Parents';
    //         case 3:
    //             return 'Guardian';
    //         case 4:
    //             return 'Other';
    //         default:
    //             return 'N/A';
    //     }
    // };

    // const renderGender = (gender) => {
    //     switch (gender) {
    //         case 1:
    //             return 'Male';
    //         case 2:
    //             return 'Female';
    //         case 3:
    //             return 'Other';
    //         default:
    //             return 'N/A';
    //     }
    // };


    return (<React.Fragment>
        <Table bordered hover style={{ tableLayout: "fixed" }}>
            <thead className="custom-table-header">
                <tr>
                    <th className="custom-table-header-text">SL No</th>
                    <th className="custom-table-header-text">User Name</th>
                    <th className="custom-table-header-text">Phone No</th>
                    <th className="custom-table-header-text">Total Assesment Categories</th>
                    <th className="custom-table-header-text">Total Assesments</th>
                    <th className="custom-table-header-text" style={{width: 230}}>Last Assesment Taken On</th>
                    <th className="custom-table-header-text">Action</th>
                </tr>
            </thead>
            <tbody>
                {tableData.data.length
                    ? tableData.data.map((eachData, key) => {
                        return (
                            <tr key={eachData._id} className={
                                key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                              }>
                                <td className="custom-table-body-text">
                                    {tableData.skip+key+1}
                                </td>
                                <td className="custom-table-body-text">
                                    {eachData.customer.name
                                        ? eachData.customer.name
                                        : "N/A"}
                                </td>
                                <td className="custom-table-body-text">
                                    {`${eachData.customer.phone.dialCode}-${eachData.customer.phone.number}`}
                                </td>
                                <td className="custom-table-body-text">
                                    {eachData.assesmentCategories}
                                </td>
                                <td className="custom-table-body-text">
                                    {eachData.totalAssesmentsTaken}</td>
                                <td className="custom-table-body-text">
                                    {moment(eachData.lastAssesmentTaken).format(
                                         "DD-MMM-YYYY, hh:mm a"
                                    )}
                                </td>
                                <td className="text-center">
                                    <Link to={`/test-details/${eachData._id}`}>
                                        <p
                                            className="btn btn-icon custom-table-btn"
                                            style={{
                                                margin: "0",
                                            }}
                                        >
                                            <i className="la la-eye"></i>
                                        </p>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })
                    : null}
            </tbody>
        </Table>

        {tableData.data.length === 0 ? (
           <NoDataFound/>
        ) : null}
        {tableData.data.length ? <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
        /> : null}
    </React.Fragment>)
};
const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookedTests);
