import axios from "./axios.interceptor";
import api from "./api.util";

export const BOOKING_URL = `${api}/account/admin/booking`;
export const BOOKING_LIST_URL = `${BOOKING_URL}/list`;

export function getBookingDetails(bookingId) {
  return axios.get(`${BOOKING_URL}/${bookingId}`);
}

export function cancelbookingDetails(bookingId) {
  return axios.put(`${BOOKING_URL}/${bookingId}/cancel`);
}

export function saveMeetingDetails(bookingId, meetingPayload) {
  return axios.put(`${BOOKING_URL}/${bookingId}/update-meeting-info`, meetingPayload);
}

export function rescheduleBookingDetails(bookingId, payload) {
  return axios.put(`${BOOKING_URL}/${bookingId}/reschedule`, payload);
}

export function getAvailability(id) {
  return axios.get(`${BOOKING_URL}/${id}/available-slot`);
}

export function listBooking({ skip, limit, bookingPayload }) {
  return axios.post(
    `${BOOKING_LIST_URL}?skip=${skip}&limit=${limit}`,
    bookingPayload
  );
}
