import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getBookingDetails,
  cancelbookingDetails,
  getAvailability,
  rescheduleBookingDetails,
  saveMeetingDetails,
} from "./../../../crud/booking.crud";
import moment from "moment";
import bookingConfig from "././../../../config/booking";
import Rating from "@material-ui/lab/Rating";
import { toast } from "react-toastify";
// import CancelModal from "./modal/cancel-modal";
// import RescheduleModal from "./modal/reschedule-modal";
import Button from "@material-ui/core/Button";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { colorsCodes, timeArrConst } from "../../../config/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
  starWrapper: {
    "& img": {
      marginRight: 8,
    },
  },
  icon: {
    color: "#E12936",
  },
  detailsParagraph: {
    display: "block",
    marginBottom: "5px",
  },
  detailsFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  detailsFlexLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5px",
  },
  fullWidth: {
    width: "100%",
  },
  stat: {
    padding: "8px 0px",
    margin: 0,
    fontSize: "18px",
  },
  trans: {
    display: "flex",
    flex: 0.5,
    padding: "8px 0px",
    margin: 0,
    fontSize: "15px",
  },
  tag: {
    background: "#cfd6fb",
    fontSize: "18px",
    padding: "10px 12px",
    textAlign: "right",
  },
  button: {
    margin: "6px 4px!important",
  },
}));

const BookingDetails = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { appointmentId } = useParams();
  const [bookingDetailsInfo, setBookingDetailsInfo] = useState();
  const [rescheduleBookingObj, updateRescheduleBookingObj] = useState({});
  const [isReschedule, setIsReschedule] = useState(false);
  const [rescheduleLoading, setIsRescheduleLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [meetingLoading, saveMeetingLoading] = useState(false);
  const [serviceTimeHours, updateServiceTimeHours] = useState([]);
  const [selectedServiceTime, updateSelectedServiceTime] = useState("");
  const [selectedDate, handleSelectedDate] = useState(new Date());
  const [datePickerOpened, toggleDatepickerOpened] = useState(false);
  const [meetingPlatform, setMeetingPlatform] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const timeArr = [...timeArrConst];
  const renderGender = (selectedGender) => {
    let genderText = "N/A";
    switch (selectedGender) {
      case 1:
        genderText = "Male";
        break;
      case 2:
        genderText = "Female";
        break;
      case 3:
        genderText = "Other";
        break;
      default:
        break;
    }
    return genderText;
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };
  const renderStatusColor = (status) => {
    switch (status) {
      case 1:
        return colorsCodes.orange;
      case 2:
        return colorsCodes.blue;
      case 3:
        return colorsCodes.green;
      case 4:
        return colorsCodes.red;
      case 5:
        return colorsCodes.red;
      case 6:
        return colorsCodes.red;
      case 7:
        return colorsCodes.red;
      case 8:
        return colorsCodes.red;
      default:
        return colorsCodes.blue;
    }
  };
  const renderBookingStatus = (status) => {
    let statusText = "N/A";
    switch (status) {
      case 1:
        statusText = "Payment Pending";
        break;
      case 2:
        statusText = "Confirmed";
        break;
      case 3:
        statusText = "Completed";
        break;
      case 4:
        statusText = "Cancelled By Customer";
        break;
      case 5:
        statusText = "Cancelled By Vendor";
        break;
      case 6:
        statusText = "Failed";
        break;
      case 7:
        statusText = "Timedout";
        break;
      case 8:
        statusText = "Cancelled By Admin";
        break;
      default:
        break;
    }
    return statusText;
  };
  const renderTransStatus = (status) => {
    let statusText = "N/A";
    switch (status) {
      case 1:
        statusText = "Payment not received";
        break;
      case 2:
        statusText = "Payment received";
        break;
      case 3:
        statusText = "Payment not received";
        break;
      case 4:
        statusText = "Refund initiated";
        break;
      case 5:
        statusText = "Refund initiated**";
        break;
      case 6:
        statusText = "Payment not received";
        break;
      default:
        break;
    }
    return statusText;
  };

  const rescheduleCall = async () => {
    const availabilityResult = await getAvailability(
      bookingDetailsInfo.serviceRef._id
    );
    const localBookingData = {};

    localBookingData.slotDuration = "";
    localBookingData.slotDate = "";
    localBookingData.slots = [];
    let localDateTimeObj = {};

    if (availabilityResult.data.data && availabilityResult.data.data.length) {
      availabilityResult.data.data.forEach((each) => {
        let localSlots = getAllSlots(
          bookingDetailsInfo.serviceRef.sessionTime,
          each.availableSlots,
          each.date
        ); // sessionTime needed
        if (localSlots.length) {
          localDateTimeObj = {};
          localDateTimeObj.date = each.date;
          localDateTimeObj.availableSlots = localSlots;
          localBookingData.slots.push({ ...localDateTimeObj });
        }
      });
    }
    console.log("localBookingData.slots ", localBookingData.slots);
    if (localBookingData.slots.length) {
      const localAvailableSlots = [];
      console.log("localBookingData.slots[0] ", localBookingData.slots);
      if (checkCurrentDay(localBookingData.slots[0].date)) {
        localBookingData.slots[0].availableSlots.forEach((each) => {
          if (checkCurrentTime(each.value)) {
            localAvailableSlots.push({
              text: getDuration(each.value),
              value: JSON.stringify(each.value),
            });
          }
        });
        if (localAvailableSlots.length) {
          localBookingData.slotDate = getFullDateFormat(
            new Date(localBookingData.slots[0].date)
          );
          localBookingData.slotDuration = getDuration(
            localBookingData.slots[0].availableSlots[0].value
          );
          handleSelectedDate(new Date(localBookingData.slots[0].date));
        }
        console.log("localAvailableSlots ", localAvailableSlots);
        if (!localAvailableSlots.length && localBookingData.slots.length > 1) {
          localBookingData.slotDate = getFullDateFormat(
            new Date(localBookingData.slots[1].date)
          );
          localBookingData.slotDuration = getDuration(
            localBookingData.slots[1].availableSlots[0].value
          );
          handleSelectedDate(new Date(localBookingData.slots[1].date));
          localBookingData.slots[1].availableSlots.forEach((each) => {
            localAvailableSlots.push({
              text: getDuration(each.value),
              value: JSON.stringify(each.value),
            });
          });
        }
      } else {
        console.log("in here ", localBookingData);
        localBookingData.slotDate = getFullDateFormat(
          new Date(localBookingData.slots[0].date)
        );
        localBookingData.slotDuration = getDuration(
          localBookingData.slots[0].availableSlots[0].value
        );
        handleSelectedDate(new Date(localBookingData.slots[0].date));
        localBookingData.slots[0].availableSlots.forEach((each) => {
          localAvailableSlots.push({
            text: getDuration(each.value),
            value: JSON.stringify(each.value),
          });
        });
      }

      updateServiceTimeHours([...localAvailableSlots]);
      if (localAvailableSlots.length) {
        updateSelectedServiceTime(localAvailableSlots[0].value);
      }
    }

    console.log("localBookingData ", localBookingData);
    updateRescheduleBookingObj({ ...localBookingData });
    if (localBookingData.slotDate) {
      setIsReschedule(true);
      handleSelectedDate(new Date(localBookingData.slotDate));
    } else {
      toast.error("No Slots Available to reschedule");
    }
  };

  const changeDate = (date) => {
    handleSelectedDate(new Date(date));
    const localBookingData = { ...rescheduleBookingObj };
    const localAvailableSlots = [];
    localBookingData.slots.forEach((each) => {
      if (
        getFullDateFormat(new Date(each.date)) ===
        getFullDateFormat(new Date(date))
      ) {
        if (each.availableSlots.length) {
          each.availableSlots.forEach((each1) => {
            localAvailableSlots.push({
              text: getDuration(each1.value),
              value: JSON.stringify(each1.value),
            });
          });
        }
      }
    });

    updateServiceTimeHours([...localAvailableSlots]);
    if (localAvailableSlots.length) {
      updateSelectedServiceTime(localAvailableSlots[0].value);
    }
  };

  const selectServiceTime = (e) => {
    updateSelectedServiceTime(e.target.value);
  };

  const disableRandomDates = (date) => {
    if (datePickerOpened) {
      const localBookingData = { ...rescheduleBookingObj };
      const dateArray = [];
      localBookingData.slots.forEach((each) => {
        dateArray.push(getFullDateFormat(new Date(each.date)));
      });
      return !dateArray.includes(getFullDateFormat(new Date(date)));
    }
  };

  const getFullDateFormat = (date) => {
    const day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    const month =
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const getTime = (timeStr, date) => {
    const time = timeStr.slice(0, timeStr.length - 3);
    const amPm = timeStr.slice(timeStr.length - 2);
    // console.log("time ", amPm, time)
    let d = new Date();
    if (date) {
      d = new Date(date);
    }
    if (amPm === "AM" && time.split(":")[0] === "12") {
      return new Date(
        new Date(
          new Date(
            new Date(d.setHours(0)).setMinutes(time.split(":")[1])
          ).setSeconds(0)
        ).setMilliseconds(0)
      ).getTime();
    }
    if (amPm === "PM" && time.split(":")[0] !== "12") {
      return new Date(
        new Date(
          new Date(
            new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(
              time.split(":")[1]
            )
          ).setSeconds(0)
        ).setMilliseconds(0)
      ).getTime();
    }
    return new Date(
      new Date(
        new Date(
          new Date(d.setHours(time.split(":")[0])).setMinutes(
            time.split(":")[1]
          )
        ).setSeconds(0)
      ).setMilliseconds(0)
    ).getTime();
  };

  const getDuration = (times) => {
    times.sort((a, b) => {
      return a - b;
    });
    const startTime = timeArr[times[0] - 1];
    const endTime = timeArr[times[times.length - 1]]
      ? timeArr[times[times.length - 1]]
      : timeArr[0];
    return `${startTime} - ${endTime}`;
  };

  const checkCurrentDay = (date) => {
    const today = new Date(
      new Date(
        new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)
      ).setMilliseconds(0)
    ).getTime();
    const day = new Date(
      new Date(
        new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(
          0
        )
      ).setMilliseconds(0)
    ).getTime();
    // console.log("today ", today, day)
    return today === day;
  };

  const checkCurrentTime = (times) => {
    const today = new Date().getTime();
    // console.log(getTime(timeArr[times[0] - 1]), today, getTime(timeArr[times[0] - 1]) > today);
    return getTime(timeArr[times[0] - 1]) > today;
  };

  const handleCancel = () => {
    setCancelLoading(true);
    cancelbookingDetails(bookingDetailsInfo._id).then((output) => {
      setBookingDetailsInfo({
        ...bookingDetailsInfo,
        ...output.data.data,
      });
      setCancelLoading(false);
      toast.success("Booking has been cancelled successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const saveMeetingInfo = () => {
    if (meetingLink && meetingPlatform) {
      saveMeetingLoading(true);
      saveMeetingDetails(bookingDetailsInfo._id, {
        meetingDetails: {
          link: meetingLink,
          platform: meetingPlatform,
        },
      }).then(() => {
        saveMeetingLoading(false);
        toast.success("Booking has been updated successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }
  };

  useEffect(() => {
    getBookingDetails(appointmentId).then((output) => {
      if (output.data) {
        setBookingDetailsInfo({
          ...bookingDetailsInfo,
          ...output.data.data,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  const getAllSlots = (sessionTime, slots, date) => {
    // need to check if sessionTime (1 hr) is more than slots

    let arr = slots;
    let limit = Number(sessionTime);
    let newArr = [];
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      if (arr[limit + i - 1]) {
        let arr1 = arr.slice(i, limit + i);
        obj = {};
        if (arr1[arr1.length - 1] - arr1[0] === limit - 1) {
          obj.value = arr1;
          if (checkCurrentDay(date)) {
            if (checkCurrentTime(arr1)) {
              newArr.push(obj);
            }
          } else {
            newArr.push(obj);
          }
        }
      }
    }
    return newArr;
  };

  const submitReschedule = async () => {
    setIsRescheduleLoading(true);
    try {
      const rescheduleBookingResult = await rescheduleBookingDetails(
        bookingDetailsInfo._id,
        {
          date: new Date(
            new Date(
              new Date(
                new Date(
                  new Date(getFullDateFormat(new Date(selectedDate))).setHours(
                    5
                  )
                ).setMinutes(30)
              ).setSeconds(0)
            ).setMilliseconds(0)
          ).toISOString(),
          slots: JSON.parse(selectedServiceTime),
        }
      );

      console.log("rescheduleBookingResult", rescheduleBookingResult);

      setIsRescheduleLoading(false);
      // if (rescheduleBookingResult.data.errorCode) {
      //     toast.error(API_ERROR[rescheduleBookingResult.data.errorCode]);
      //     return;
      // }
      // if (rescheduleBookingResult.data.data && rescheduleBookingResult.data.data.errorCode) {
      //     toast.error(API_ERROR[rescheduleBookingResult.data.data.errorCode]);
      //     return;
      // }
      console.log("rescheduleBookingResult ", rescheduleBookingResult);
      toast.success("Booking has been rescheduled successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getBookingDetails(appointmentId).then((output) => {
        setBookingDetailsInfo({
          ...bookingDetailsInfo,
          ...output.data.data,
        });
      });
    } catch (e) {
      setIsRescheduleLoading(false);
    }
  };

  useEffect(() => {
    if (bookingDetailsInfo && bookingDetailsInfo.meetingDetails) {
      if (bookingDetailsInfo.meetingDetails.platform) {
        setMeetingPlatform(bookingDetailsInfo.meetingDetails.platform);
      }
      if (bookingDetailsInfo.meetingDetails.link) {
        setMeetingLink(bookingDetailsInfo.meetingDetails.link);
      }
    }
  }, [bookingDetailsInfo]);

  if (!bookingDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper} style={{ height: height }}>
                <h1 className={classes.tag}>Customer</h1>
                <div className={classes.flexBox}>
                  <div className="mr-3">
                    <img
                      src={
                        bookingDetailsInfo.customerRef.personalInfo
                          .profilePicture
                          ? bookingDetailsInfo.customerRef.personalInfo
                              .profilePicture
                          : toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )
                      }
                      alt={`CustomersImage`}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className={`text-left mt-1 ${classes.fullWidth}`}>
                    <h5>{bookingDetailsInfo.customerRef.personalInfo.name}</h5>
                    <div className={classes.detailsParagraph}>
                      <span>Contact no -&nbsp;</span>
                      <span>
                        {
                          bookingDetailsInfo.customerRef.personalInfo.phone
                            .dialCode
                        }{" "}
                        {
                          bookingDetailsInfo.customerRef.personalInfo.phone
                            .number
                        }
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Email -&nbsp;</span>
                      <span>
                        {bookingDetailsInfo.customerRef.personalInfo.email}
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Gender -&nbsp;</span>
                      <span>
                        {renderGender(
                          bookingDetailsInfo.customerRef.personalInfo.gender
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper} ref={professionalRef}>
                <h1 className={classes.tag}>Professional</h1>
                <div className={classes.flexBox}>
                  <div className="mr-3">
                    <img
                      src={
                        bookingDetailsInfo.vendorRef.personalInfo.profilePicture
                          ? bookingDetailsInfo.vendorRef.personalInfo
                              .profilePicture
                          : toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )
                      }
                      alt={`CustomersImage`}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className="text-left mt-1">
                    <h5>{bookingDetailsInfo.vendorRef.personalInfo.name}</h5>
                    <div className={classes.detailsParagraph}>
                      <span>Contact no -&nbsp;</span>
                      <span>
                        {
                          bookingDetailsInfo.vendorRef.personalInfo.phone
                            .dialCode
                        }
                        {bookingDetailsInfo.vendorRef.personalInfo.phone.number}
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Email -&nbsp;</span>
                      <span>
                        {bookingDetailsInfo.vendorRef.personalInfo.email}
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Experience -&nbsp;</span>
                      <span>
                        {
                          bookingDetailsInfo.vendorRef.personalInfo.experience
                            .year
                        }{" "}
                        years
                      </span>{" "}
                      &nbsp;
                      {bookingDetailsInfo.vendorRef.personalInfo.experience
                        .month ? (
                        <span>
                          {
                            bookingDetailsInfo.vendorRef.personalInfo.experience
                              .month
                          }{" "}
                          months
                        </span>
                      ) : null}
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Categories -&nbsp;</span>
                      <span>
                        {bookingDetailsInfo.vendorRef.personalInfo.categories.map(
                          (eachCat, key) => {
                            return (
                              <span key={key} className={classes.categoryTag}>
                                {eachCat.title}
                              </span>
                            );
                          }
                        )}
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Website -&nbsp;</span>
                      <span>
                        {bookingDetailsInfo.vendorRef.personalInfo.website
                          ? bookingDetailsInfo.vendorRef.personalInfo.website
                          : "N/A"}
                      </span>
                    </div>
                    <div className={classes.detailsParagraph}>
                      <span>Description -&nbsp;</span>
                      <span>
                        {bookingDetailsInfo.vendorRef.personalInfo.description
                          ? bookingDetailsInfo.vendorRef.personalInfo
                              .description
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <h1 className={classes.tag}>Meeting Details</h1>
                <div className={classes.detailsFlex}>
                  <div>Platform </div>
                  <div style={{ width: "80%" }}>
                    <Form.Control
                      type="text"
                      value={meetingPlatform}
                      onChange={(event) => {
                        setMeetingPlatform(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.detailsFlex}>
                  <div>Link </div>
                  <div style={{ width: "80%" }}>
                    <Form.Control
                      type="text"
                      value={meetingLink}
                      onChange={(event) => {
                        setMeetingLink(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.button} mt-3`}
                    onClick={() => saveMeetingInfo()}
                    disabled={meetingLoading}
                  >
                    {meetingLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                    Save
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <h1 className={classes.tag}>Appointment Details</h1>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Booking No?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {bookingDetailsInfo.bookingNumber}
                  </div>
                </div>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Vendor Link Posted?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {!!bookingDetailsInfo.meetingDetails.link ? "Yes" : "No"}
                  </div>
                </div>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Vendor Joined?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {!!bookingDetailsInfo.vendorJoinedMeeting ? "Yes" : "No"}
                  </div>
                </div>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Customer Joined?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {!!bookingDetailsInfo.customerJoinedMeeting ? "Yes" : "No"}
                  </div>
                </div>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Appointment Pin?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {bookingDetailsInfo.pin}
                  </div>
                </div>
                <div className={classes.detailsFlexLeft}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    Otp Exchanged?{" "}
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    {bookingDetailsInfo.status === 3 ? "Yes" : "No"}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <h4 className="mb-3">Service</h4>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Service Fees</th>
                      <th>Convenience Fees</th>
                      <th>GST</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{bookingDetailsInfo.serviceRef.title}</td>
                      <td>{bookingDetailsInfo.serviceRef.description}</td>
                      <td>
                        {bookingDetailsInfo.date
                          ? moment(bookingDetailsInfo.date).format(
                              "DD-MMM-YYYY"
                            )
                          : "N/A"}
                      </td>
                      <td>{renderSlots(bookingDetailsInfo.slots)}</td>
                      <td>
                        <i
                          className="fas fa-rupee-sign"
                          style={{
                            fontSize: 12,
                            color: "#555",
                            paddingRight: 1,
                          }}
                        ></i>
                        {bookingDetailsInfo.paymentDetails.serviceFee}
                      </td>
                      <td>
                        <i
                          className="fas fa-rupee-sign"
                          style={{
                            fontSize: 12,
                            color: "#555",
                            paddingRight: 1,
                          }}
                        ></i>
                        {bookingDetailsInfo.paymentDetails.convenienceFee}
                      </td>
                      <td>
                        <i
                          className="fas fa-rupee-sign"
                          style={{
                            fontSize: 12,
                            color: "#555",
                            paddingRight: 1,
                          }}
                        ></i>
                        {bookingDetailsInfo.paymentDetails.gst}
                      </td>
                      <td>
                        <i
                          className="fas fa-rupee-sign"
                          style={{
                            fontSize: 12,
                            color: "#555",
                            paddingRight: 1,
                          }}
                        ></i>
                        {bookingDetailsInfo.paymentDetails.total}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Paper>
            </Grid>
            {bookingDetailsInfo.ratingDetails ? (
              <Grid item xs={12}>
                <Paper className={`${classes.paper} text-left`}>
                  <h4 className="mb-3">Rate & Review</h4>
                  <p className={classes.starWrapper}>
                    <Rating
                      defaultValue={bookingDetailsInfo.ratingDetails.rating}
                      size="large"
                      readOnly
                      classes={{
                        iconFilled: classes.icon, // class name, e.g. `classes-nesting-root-x`
                      }}
                    />
                  </p>
                  <p>{bookingDetailsInfo.ratingDetails.feedback}</p>
                </Paper>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Paper
                className={`${classes.paper} text-left`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p className={classes.stat}>
                  <span style={{ fontWeight: 600 }}>Status: </span>
                  <span
                    style={{
                      color: renderStatusColor(bookingDetailsInfo.status),
                    }}
                  >
                    {renderBookingStatus(bookingDetailsInfo.status)}
                  </span>
                </p>
                {bookingDetailsInfo.status === 2 ? (
                  <div>
                    {!isReschedule ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={`${classes.button} mt-3`}
                        onClick={() => rescheduleCall()}
                      >
                        Reschedule
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      className={`${classes.button} mt-3 ml-3`}
                      onClick={handleCancel}
                      disabled={cancelLoading}
                    >
                      {cancelLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                      Cancel
                    </Button>
                  </div>
                ) : null}
              </Paper>
            </Grid>

            {isReschedule ? (
              <Grid item xs={12}>
                <Paper className={`${classes.paper} text-left`}>
                  <div className="row" style={{ margin: "0 0 16px 0" }}>
                    <h4 className="col-sm-8 col-xs-12 pl-0">
                      Reschedule appointment
                    </h4>
                    <div className="col-sm-4 col-xs-12 pr-0 text-right pb-3 back-button-wrapper">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => setIsReschedule(false)}
                      >
                        Back
                      </button>
                    </div>
                    <div
                      className="col-md-6 col-xs-12 slot-wrapper white-bg"
                      style={{ margin: "0 auto" }}
                    >
                      {bookingDetailsInfo.slots.length ? (
                        <div className="row m-0">
                          <h6 className="col-12 p-0">
                            Fill up the following form to complete booking
                          </h6>
                          <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                            <label
                              className="form-label mb-0"
                              style={{ display: "block" }}
                            >
                              Select date
                            </label>
                            <DatePicker
                              style={{ paddingTop: 16 }}
                              fullWidth
                              value={selectedDate}
                              onChange={changeDate}
                              format="yyyy-MM-dd"
                              minDate={new Date()}
                              shouldDisableDate={disableRandomDates}
                              onOpen={() => toggleDatepickerOpened(true)}
                              onClose={() => toggleDatepickerOpened(false)}
                            />
                          </div>

                          <div className="col-sm-6 col-xs-12 pl-0 mb-3 mt-3">
                            <label
                              className="form-label"
                              style={{ display: "block" }}
                            >
                              Select Time
                            </label>
                            {serviceTimeHours.length ? (
                              <select
                                className="form-select form-control"
                                aria-label="Service Time"
                                value={selectedServiceTime}
                                onChange={selectServiceTime}
                              >
                                {serviceTimeHours.map((loc, index) => {
                                  return (
                                    <option key={index} value={loc.value}>
                                      {loc.text}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div className="text-center well col-12 color-black pt-3">
                                No Slots Available!
                              </div>
                            )}
                          </div>

                          <div className="col-sm-6 col-xs-12 pl-0 mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label fee-label"
                            >
                              Booking for
                            </label>
                            <input
                              type="text"
                              className={`form-control`}
                              value={
                                bookingDetailsInfo.customerRef.personalInfo.name
                              }
                              disabled
                            />
                          </div>

                          <div className="col-12 pl-0">
                            <Button
                              variant="contained"
                              color="primary"
                              className={`${classes.button} mt-3`}
                              onClick={() => submitReschedule()}
                              disabled={rescheduleLoading}
                            >
                              {rescheduleLoading && (
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                              Reschedule
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Paper>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <p className={classes.trans}>
                    <span style={{ fontWeight: 600, marginRight: 5 }}>
                      Transaction ID:{" "}
                    </span>
                    <span>
                      {bookingDetailsInfo.transactionDetails.transactionId}
                    </span>
                  </p>
                  <p className={classes.trans}>
                    <span style={{ fontWeight: 600, marginRight: 5 }}>
                      Order ID:{" "}
                    </span>
                    <span>{bookingDetailsInfo.transactionDetails.orderId}</span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <p className={classes.trans}>
                    <span style={{ fontWeight: 600, marginRight: 5 }}>
                      Amount:{" "}
                    </span>
                    <span>Rs. {bookingDetailsInfo.paymentDetails.total}/-</span>
                  </p>
                  <p className={classes.trans}>
                    <span style={{ fontWeight: 600, marginRight: 5 }}>
                      Payment Status:{" "}
                    </span>
                    <span>
                      {renderTransStatus(
                        bookingDetailsInfo.transactionDetails.status
                      )}
                    </span>
                  </p>
                </div>
                {[4,5].includes(bookingDetailsInfo.transactionDetails.status)&&
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <p className={classes.trans}>
                    ** Refund status to be checked in Refund Module ( Support
                    -&gt; Refund )
                  </p>
                </div>}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

export default BookingDetails;
