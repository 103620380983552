import axios from './axios.interceptor';
import api from './api.util';

export const APPS_TUTORIAL_URL = `${api}/account/admin/apps-tutorial`;
export const ADD_APPS_TUTORIAL_URL = `${APPS_TUTORIAL_URL}/add`;
export const LIST_APPS_TUTORIAL_URL = `${APPS_TUTORIAL_URL}/list`;

export function addAppsTutorial({ title, description, userType }) {
  return axios.post(ADD_APPS_TUTORIAL_URL, {
    title,
    description,
    userType
  });
}

export function listAppsTutorial({ skip, limit, userType }) {
  return axios.post(`${LIST_APPS_TUTORIAL_URL}?skip=${skip}&limit=${limit}`, { userType });
}

export function changeOrderAppsTutorial(id, payload) {

  return axios.put(`${APPS_TUTORIAL_URL}/${id}/change-order`, payload);
}


export function editAppsTutorial(id, payload) {

  return axios.put(`${APPS_TUTORIAL_URL}/${id}/update`, payload);
}

export function removeAppsTutorial(id) {
  return axios.delete(`${APPS_TUTORIAL_URL}/${id}/remove`);
}