import Pagination from "@material-ui/lab/Pagination";
import { styled } from "@material-ui/core/styles";
const CustomPagination = styled(Pagination)(() => ({
  "& .MuiPagination-ul": {
    "& .MuiPaginationItem-page.Mui-selected": {
      color: "#fff",
      backgroundColor: "#38A0FA",
    },
    "& .MuiButtonBase-root .MuiPaginationItem-root .MuiPaginationItem-page .MuiPaginationItem-outlined .MuiPaginationItem-rounded": {
      borderColor: "#38A0FA",
    },
    // "& li:not(:first-child):not(:last-child)":{
    //     "& button:not(.Mui-selected)":{
    //         backgroundColor: "transparent",
    //         borderColor: "#38A0FA",
    //         color: "#38A0FA",
    //     }
    // },
    "& li":{
      "& button:not(.Mui-selected)":{
          backgroundColor: "transparent",
          borderColor: "#38A0FA",
          color: "#38A0FA",
      }
    }
  }
}));

export default CustomPagination;
