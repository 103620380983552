export const API_SUCCESS = {
    CREATE_PROFILE: "Profile created successfully",
    UPDATE_PROFILE: "Profile updated successfully",
    ADD_LOCATION: "Location added successfully",
    UPDATE_LOCATION: "Location updated successfully",
    REMOVE_LOCATION: "Location removed successfully",
    ADD_IMAGE: "Image added successfully",
    REMOVE_IMAGE: "Image removed successfully",
    ADD_PHYSICAL_CONTRACTS: "Physical contracts added successfully",
    REMOVE_PHYSICAL_CONTRACTS: "Physical contracts removed successfully",
    UPDATE_PROFILE_IMAGE: "Profile picture updated successfully",
    UPDATE_BANK: "Bank details updated successfully",
    ADD_SERVICE: "Service added successfully",
    UPDATE_SERVICE: "Service updated successfully",
    REMOVE_SERVICE: "Service removed successfully",
    LOGIN_SUCCESS: "Login successfully"
};