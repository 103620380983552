import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";

const CustomUserCard = (props) => {
  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: props.color,
      display: "flex",
    },
    avatar: {
      height: 55,
      width: 55,
      borderRadius: "50%",
      marginLeft: 10,
      opacity: 1,
      background: "0% 0% no-repeat padding-box",
    },
    details: {
      color: "#5A5C69",
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Arial",
    },
    detailsDes: {
      color: "#5A5C69",
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Arial",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: 185,
    },
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      padding: 10,
    },
    content2: {
      padding: 15,
    },
    avatar2: {
      height: 35,
      width: 35,
      borderRadius: "50%",
      marginLeft: 20,
      opacity: 1,
      background: props.data.profileImg
        ? "0% 0% no-repeat padding-box"
        : "#b7b4b0",
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.card} elevation={0}>
        <div className={classes.cardContent}>
          {props.userType === "professional" ? (
            <>
              <Avatar
                className={classes.avatar}
                src={props.data.profileImg}
                alt="Profile Picture"
              />
              <div className={classes.content}>
                <Typography className={classes.details}>
                  {props.data.name}
                </Typography>
                <Typography className={classes.detailsDes}>
                  {props.data.designation}
                </Typography>
                <Typography className={classes.details}>
                  {props.data.address}
                </Typography>
              </div>
            </>
          ) : (
            <>
              <Avatar
                className={classes.avatar2}
                src={props.data.profileImg}
                alt="Profile Picture"
              />
              <div className={classes.content2}>
                <Typography className={classes.details}>
                  {props.data.name}
                </Typography>
              </div>
            </>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default CustomUserCard;
