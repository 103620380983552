import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import EditRole from "./edit-role";
import RemoveRole from "./remove-role";
import { listRole } from "../../../crud/role.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const RoleList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showEditRole, setShowEditRole] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showDeleteRole, setShowDeleteRole] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.role.listData) {
      // console.log('props.data.role.listData', props.data.role.listData);
      let listObject = {
        data: [...props.data.role.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.role.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.role.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.role.listData]);

  const reloadList = () => {
    listRole({ skip: tableData.skip, limit: tableData.limit }).then((docs) => {
      // console.log(docs);
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (event, value) => {
    // console.log("value", value);
    setPage(value);

    listRole({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowEditRole(false);
  };

  const handleCloseDelete = () => {
    setShowDeleteRole(false);
  };

  const openEditModal = (role) => {
    setSelectedData(role);
    setShowEditRole(true);
  };

  const openDeleteModal = (role) => {
    setSelectedData(role);
    setShowDeleteRole(true);
  };

  return (
    <React.Fragment>
      {showEditRole ? (
        <EditRole
          role={selectedData}
          show={showEditRole}
          handleClose={handleCloseEdit}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showDeleteRole ? (
        <RemoveRole
          role={selectedData}
          show={showDeleteRole}
          handleClose={handleCloseDelete}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">Role Name</th>
                <th className="custom-table-header-text">Accessible Modules</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{eachData.name}</td>
                    <td className="custom-table-body-text">
                      {eachData.permissions.map((eachP, keyP) => {
                        return eachData.permissions[keyP + 1]
                          ? `${eachP.moduleName}, `
                          : `${eachP.moduleName}`;
                      })}
                    </td>
                    <td className="text-center">
                      <p
                        title="Edit"
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
