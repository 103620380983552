import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

const allOptions = {
  1: "Harassment or bullying",
  2: "Sexually explicit material",
  3: "Wrong Information",
  4: "Duplicate content",
  5: "Others"
};

const FlagModal = (props) => {
  const [options, updateOptions] = useState([]);

  useEffect(() => {
    const localOption = {};
    const filteredOptions = [];
    let loopingArray = [];

    if (props.type.includes("Answer")) {
      loopingArray = [...props.answerItem.flaggedBy];
    } else {
      loopingArray = [...props.item.flaggedBy];
    }
    loopingArray.forEach(each => {
      if (!Object.keys(localOption).length) {
        localOption[each.flagType] = 1;
      } else if (!Object.keys(localOption).includes(each.flagType.toString())) {
        localOption[each.flagType] = 1;
      } else {
        ++localOption[each.flagType];
      }
        
    });
    for (let item in localOption) {
      filteredOptions.push({
        value: item,
        text: allOptions[item],
        count: localOption[item]
      });
    }
    console.log("filteredOptions ", filteredOptions);
    updateOptions([...filteredOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Release/ Delete the {props.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Answered by:</strong> {props.answerItem.answeredBy.personalInfo.name}</p>
          <p className="wrap-to-4-line"><strong>Answer: </strong>
            {props.title.split('\n').map((item, key) => {
              return <span key={key}>{item}<br /></span>
            })}
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Reason</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {options.map(flagItem => {
                return <tr key={flagItem.text}>
                  <td>
                    {flagItem.text}
                  </td>
                  <td>
                    {flagItem.count}
                  </td>
                </tr>
              })}
              <tr>
                <td>Total</td>
                <td>{props.type.includes("Answer") ? props.answerItem.flaggedBy.length : props.item.flaggedBy.length} </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              props.releaseAnswerFlagHandler();
              props.handleClose();
            }}
          >
            Release
          </Button>
          <Button
            variant="danger"
            style={{
              position: "relative",
            }}
            onClick={() => {
              props.handleDelete(false);
              props.handleClose();
            }}
          >
            {" "}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default FlagModal;
