import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import {Form, Button} from "react-bootstrap";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {injectIntl} from "react-intl";
import Select from 'react-select';
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {listLanguage} from '../../../crud/language.crud';
import {listCategory as listProblemCategories} from '../../../crud/problem-category.crud';
import {uploadAudioFile, addProblem} from '../../../crud/problem.crud';
import * as problem from "../../../store/ducks/problem.duck";
import PCLanguageInputCard from "../../common/languageProblem/PCLanguageInputCard";
import PCAddLanguageInputCard from "../../common/languageProblem/PCAddLanguageInputCard";
const CreateProblem = (props) => {
    const {classes} = props;
    const [errors,
        setErrors] = useState({title: null, icon: null});
    const [loading,
        setLoading] = useState(false);
    const [languages,
        setLanguages] = useState([]);

    const [problemCategories,
        setProblemCategories] = useState([]);
    const [selectedProblemCategory,
        setSelectedProblemCategory] = useState();
    const [pcLang,
        setPCLang] = useState([
        {
            languageRef: '',
            title: '',
            audioFile: '',
            description: '',
            highlightAnswer:''
        }
    ]);

    const problemAdd = () => {
        setPCLang([
            ...pcLang, {
                languageRef: '',
                title: '',
                audioFile: '',
                description: '',
                highlightAnswer:''
            }
        ]);
    };

    const removePCLangInput = (index) => {
        let t = [...pcLang];
        t.splice(index, 1);
        setPCLang([...t]);
    };

    const updatePCLangInput = (index, payload) => {
        let t = [...pcLang];
        t.splice(index, 1, payload);
        setPCLang([...t]);
    };

    const resetState = () => {

        setErrors({title: null});
    }

    const validForm = () => {
        let e = true;

        if (!selectedProblemCategory) {

            setErrors({
                ...errors,
                selectedProblemCategory: 'Problem Category is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                selectedProblemCategory: null
            });
        }
        return e;
    }

    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);
            let hasAudioFile = pcLang.filter((e)=>e.audioFile? true: false).length ? true: false;
            Promise.all(hasAudioFile? pcLang.map(async(eachLang) => {
                return await uploadAudioFile(eachLang.audioFile).then((data) => {
                    eachLang.audioFile = data.data.data;
                    return eachLang;
                });
            }):[Promise.resolve()]).then((data) => {
                const requestPayload = {
                    problemCategoryRef: selectedProblemCategory.value,
                    languageSet: data[0] ? [...data]: [...pcLang]
                };

                addProblem(requestPayload).then((output) => {
                    if (output.data.success) {
                        props.problemAdded({skip: 0, limit: 5});
                        resetState();
                        setLoading(false);
                        toast.success('Problem is successfully added!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                        props.handleClose('problems');
                    }
                }).catch((error) => {
                    console.log('error', error);
                    setLoading(false);
                });
            });

        }
    };

    useEffect(() => {
        Promise.all([
            listLanguage({skip: 0, limit: 0}),
            listProblemCategories({skip: 0, limit: 0})
        ]).then((output) => {
            let langsOutput = output[0].data.data.data;
            let problemCategoriesOutput = output[1].data.data.data;
            setLanguages([...langsOutput]);
            setProblemCategories([...problemCategoriesOutput.map((pco) => {
                    return {label: pco.categoryTitle, value: pco._id};
                })]);
        });
    }, []);

    return (
        <React.Fragment>
            <Drawer
                anchor={'right'}
                open={props.show}
                onClose={() => {
                resetState();
                props.handleClose('problems')
            }}>
                <div className={classes.createWrapper}>

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                resetState();
                                props.handleClose('problems')
                            }}
                                aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Create Problem
                            </Typography>
                            <Button
                                variant="primary"
                                style={{
                                position: "absolute",
                                right: 24
                            }}
                                onClick={() => handleSubmit()}
                                disabled={loading}>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Form className={classes.createForm}>
                        <Form.Group controlId="problemForm.category">
                            <Form.Label>Problem Category</Form.Label>
                            <Select
                                value={selectedProblemCategory}
                                defaultValue={problemCategories[0]}
                                options={problemCategories}
                                onChange={(e) => {
                                setSelectedProblemCategory(e);
                            }}/>

                        </Form.Group>
                        <PCAddLanguageInputCard problemAdd={problemAdd}/> {pcLang.length && languages.length
                            ? pcLang.map((each, index) => {
                                return <PCLanguageInputCard
                                    key={index}
                                    pcLangInput={each}
                                    languages={languages}
                                    updatePCLangInput={(payload) => updatePCLangInput(index, payload)}
                                    removePCLangInput={() => removePCLangInput(index)}/>;
                            })
                            : null}
                    </Form>
                </div>
            </Drawer>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {data: state};
};

const mapDispatchToProps = dispatch => {
    return {
        problemAdded: ({skip, limit}) => dispatch(problem.actions.problemAdded({skip, limit}))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), {withTheme: true})(CreateProblem)));
