import axios from "./axios.interceptor";
import api from "./api.util";

export const VENDOR_URL = `${api}/account/admin/vendor`;
export const ADD_VENDOR_URL = `${VENDOR_URL}/add`;
export const LIST_VENDOR_URL = `${VENDOR_URL}/list`;
export const LIST_REQUESTED_VENDOR_URL = `${VENDOR_URL}/requested-list`;

export function addVendor(payload) {
  return axios.post(ADD_VENDOR_URL, payload);
}

export function listRequestedVendor({ skip, limit }) {
  return axios.post(`${LIST_REQUESTED_VENDOR_URL}?skip=${skip}&limit=${limit}`);
}

export function listVendor({ skip, limit, payload }) {
  return axios.post(`${LIST_VENDOR_URL}?skip=${skip}&limit=${limit}`, payload);
}

export function listEmailVendor({ skip, limit, payload }) {
  return axios.post(`${VENDOR_URL}/email-list?skip=${skip}&limit=${limit}`, payload);
}

export function listVendorByState({ skip, limit, payload }) {
  return axios.post(`${VENDOR_URL}/list-by-state?skip=${skip}&limit=${limit}`, payload);
}

export function listVendorByAllState({ payload }) {
  return axios.post(`${VENDOR_URL}/list-by-all-state`, payload);
}

export function editVendor(vendorId, payload) {
  return axios.put(`${VENDOR_URL}/${vendorId}/update`, payload);
}

export function removeVendor(vendorId) {
  return axios.delete(`${VENDOR_URL}/${vendorId}/remove`);
}

export function approveVendorDetails(vendorId) {
  return axios.put(`${VENDOR_URL}/${vendorId}/approve`);
}

export function rejectVendorDetails(vendorId, reasonOfReject) {
  return axios.put(`${VENDOR_URL}/${vendorId}/reject`, {reasonOfReject: reasonOfReject});
}
export function getVendorsAllStateList() {
  return axios.get(`${VENDOR_URL}/address/state-list`);
}

export function sendNotificaton() {
  return axios.post(`${VENDOR_URL}/broadcast-notification-pending-vendor`);
}