import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Tooltip, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  listQA,
  listPendingVerification,
  approveQaDetails,
  rejectQaDetails,
  listFlaggedQA,
  listAskedByAdminQA,
} from "../../../crud/qa.crud";
import qaConfig from "../../../config/qa";
import ApproveModal from "./../../common/modal/approve-modal";
import RejectModal from "./../../common/modal/reject-modal";
import FullWidthTabs from "./../../common/FullWidthTabs";

import * as qa from "../../../store/ducks/qa.duck";
import * as notification from "./../../../store/ducks/notification.duck";

import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const ForumList = (props) => {
  const {tab} = props.data.notification; 
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [tableDataAll, setTableDataAll] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedData, setSelectedData] = useState();
  const [showApproval, setShowApproval] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (props.data.qa.updated) {
      reloadList(true);
      props.qaUpdatedDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.qa.updated]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = (skipZero) => {
    if (currentTab === 0) {
      listQA({
        skip: skipZero ? 0 : tableDataAll.skip,
        limit: tableDataAll.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableDataAll({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableDataAll.skip,
            limit: tableDataAll.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableDataAll.limit)
            ),
          });
        }
      });
    } else if (currentTab === 1) {
      listPendingVerification({
        skip: skipZero ? 0 : tableData.skip,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else if (currentTab === 2) {
      listAskedByAdminQA({
        skip: skipZero ? 0 : tableData.skip,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else {
      listFlaggedQA({
        skip: skipZero ? 0 : tableData.skip,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);

    if (currentTab === 0) {
      listQA({
        skip: Number((value - 1) * tableDataAll.limit),
        limit: tableDataAll.limit,
      }).then((docs) => {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableDataAll.limit),
          limit: tableDataAll.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableDataAll.limit)
          ),
        };
        setTableDataAll({
          ...tableDataAll,
          ...listObject,
        });
      });
    } else if (currentTab === 1) {
      listPendingVerification({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
      }).then((docs) => {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      });
    } else if (currentTab === 2) {
      listAskedByAdminQA({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
      }).then((docs) => {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      });
    } else {
      listFlaggedQA({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
      }).then((docs) => {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      });
    }
  };

  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowRejection(true);
  };

  const approveItem = (qaId) => {
    approveQaDetails(qaId).then(() => {
      toast.success("Forum's question is successfully approved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const rejectItem = (qaId) => {
    rejectQaDetails(qaId).then((output) => {
      toast.error("Forum's question is successfully rejected!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const handleApprove = (approve) => {
    if (approve) {
      approveItem(selectedData._id);
    } else {
      rejectItem(selectedData._id);
    }
  };

  const getUserName = ({
    customer, 
    vendor, 
    qaUser
  })=>{
    let user = customer || vendor || qaUser;
    return user?.personalInfo?.name;
  };

  useEffect(() => {
    setPage(1);
    reloadList(true);
    return ()=>{
      props.updateTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <React.Fragment>
      {showApproval ? (
        <ApproveModal
          type={"Forum Question"}
          title={selectedData.question}
          item={selectedData}
          show={showApproval}
          handleClose={() => setShowApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showRejection ? (
        <RejectModal
          type={"Forum Question"}
          title={selectedData.question}
          item={selectedData}
          show={showRejection}
          handleClose={() => setShowRejection(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        title={"Forum"}
        tabsTitle={[
          "All",
          "Pending Verification Requests",
          "Asked by admin",
          "Flagged",
        ]}
      />
      {currentTab === 0 && tableDataAll.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                  <th className="custom-table-header-text">ID</th>
                ) : null}
                <th className="custom-table-header-text" style={{ width: 400 }}>
                  Question
                </th>
                <th className="custom-table-header-text">Asked By</th>
                <th className="custom-table-header-text">Date</th>
                {currentTab === 0 || currentTab === 1 ? (
                  <th className="custom-table-header-text">Status</th>
                ) : null}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableDataAll.data.map((eachData, key) => {
                return (
                  <tr
                    key={key}
                    className={
                      key % 2 === 1 ? "odd-custom-row" : "even-custom-row"
                    }
                  >
                    <td className="custom-table-body-text">
                      {tableDataAll.skip + key + 1}
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                      <td className="custom-table-body-text">{eachData._id}</td>
                    ) : null}
                    <td className="custom-table-body-text text-left elipses-text-td">
                      {eachData.question}
                    </td>
                    <td className="custom-table-body-text">
                      {getUserName({
                        customer: eachData.customerId,
                        vendor: eachData.vendorId,
                        qaUser: eachData.qaUserId
                      })}
                    </td>
                    <td className="custom-table-body-text">
                      {moment(eachData.createdAt).format(
                        "DD-MMM-YYYY, hh:mm A"
                      )}
                    </td>
                    <td className="custom-table-body-text">
                      <Typography
                        color={eachData.status === 2 ? "secondary" : "error"}
                        align="center"
                      >
                        {qaConfig.status[eachData.status]}
                      </Typography>
                    </td>
                    <td className="text-center">
                      <Link
                        className="btn btn-icon custom-table-btn"
                        to={`/forum-details/${eachData._id}`}
                      >
                        <Tooltip title="View">
                          <i className="la la-eye"></i>
                        </Tooltip>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableDataAll.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </React.Fragment>
      ) : currentTab !== 0 && tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                  <th className="custom-table-header-text">ID</th>
                ) : null}
                <th className="custom-table-header-text" style={{ width: 400 }}>
                  Question
                </th>
                <th className="custom-table-header-text">Asked By</th>
                <th className="custom-table-header-text">Date</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr
                    key={key}
                    className={
                      key % 2 === 1 ? "odd-custom-row" : "even-custom-row"
                    }
                  >
                    <td className="custom-table-body-text">
                      {tableData.skip + key + 1}
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                      <td className="custom-table-body-text">{eachData._id}</td>
                    ) : null}
                    <td className="custom-table-body-text text-left elipses-text-td">
                      {eachData.question}
                    </td>
                    <td className="custom-table-body-text">
                    {getUserName({
                        customer: eachData.customerId,
                        vendor: eachData.vendorId,
                        qaUser: eachData.qaUserId
                      })}
                    </td>
                    <td className="custom-table-body-text">
                      {moment(eachData.createdAt).format(
                        "DD-MMM-YYYY, hh:mm A"
                      )}
                    </td>
                    <td className="text-center">
                      {currentTab === 0 || currentTab === 1 ? (
                        (eachData.status === 1 && currentTab) === 1 ? (
                          <div>
                            <p
                              className="btn btn-icon custom-table-btn"
                              title="Approve"
                              onClick={() => approvalConsent(eachData)}
                              style={{
                                margin: "0",
                              }}
                            >
                              <i className="la la-check-circle"></i>
                            </p>

                            <p
                              className="btn btn-icon custom-table-btn"
                              title="Reject"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                rejectionConsent(eachData);
                              }}
                            >
                              <i className="la la-close kt-font-danger"></i>
                            </p>
                            <Link
                              className="btn btn-icon custom-table-btn"
                              to={`/forum-details/${eachData._id}`}
                            >
                              <i className="la la-eye"></i>
                            </Link>
                          </div>
                        ) : (
                          <>
                            <Typography
                              color={
                                eachData.status === 2 ? "secondary" : "error"
                              }
                              align="center"
                            >
                              {qaConfig.status[eachData.status]}
                            </Typography>
                            <Link
                              className="btn btn-icon custom-table-btn"
                              to={`/forum-details/${eachData._id}`}
                            >
                              <Tooltip title="View">
                                <i className="la la-eye"></i>
                              </Tooltip>
                            </Link>
                          </>
                        )
                      ) : (
                        <Link
                          className="btn btn-icon custom-table-btn"
                          to={`/forum-details/${eachData._id}`}
                        >
                          <Tooltip title="View">
                            <i className="la la-eye"></i>
                          </Tooltip>
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </React.Fragment>
      ) : (
        <NoDataFound />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    qaUpdatedDone: () => dispatch(qa.actions.qaUpdatedDone()),
    updateTab: (data) => dispatch(notification.actions.updateTab({selectedTab: data})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumList);
