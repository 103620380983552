import { listSupport } from "../../crud/support.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
  SUPPORT_ADDED: "SUPPORT_ADDED",
  SUPPORT_UPDATED: "SUPPORT_UPDATED",
  SUPPORT_UPDATED_SUCCESS: "SUPPORT_UPDATED_SUCCESS",
};

const initialAuthState = {
  listData: undefined,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.SUPPORT_UPDATED_SUCCESS: {
      const { listData } = action.payload;
      return { listData };
    }
    default:
      return state;
  }
};

export const actions = {
  supportUpdated: ({ skip, limit }) => ({
    type: actionTypes.SUPPORT_UPDATED,
    payload: {
      skip,
      limit,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.SUPPORT_UPDATED, function* supportUpdated(
    action
  ) {
    const { skip, limit } = action.payload;
    const listData = yield listSupport({ skip, limit });

    yield put({
      type: actionTypes.SUPPORT_UPDATED_SUCCESS,
      payload: {
        listData,
      },
    });
  });
}
