export const REQUIRED_ERROR = "This field is required";
export const VALID_PHONE_ERROR = "Please enter valid phone number";
export const VALID_NAME_ERROR = "Please enter valid name";
export const VALID_PROMO_ERROR = "Please enter valid promo code";
export const INVALID_FROM_TO_DATE = "Please choose valid from and to time";
export const CHOOSE_ERROR = "Please select atleast one day of the week";
export const INVALID_EMAIL = "Please enter a valid email";
export const INVALID_WEBSITE = "Please enter a valid URL";
export const INVALID_MONTH = "Please enter a valid month (0-11)";
export const CHOOSE_CATEGORY_ERROR = "Please select atleast one category";
export const INVALID_LOCATION = "Please choose a valid location";
export const API_ERROR = {
  "400": "API validation error",
  "401": "Upload while uploading the file",
  "403": "Access denied",
  "405": "You are not authorized to access this page",
  "406": "Image not found",
  "501": "Invalid file type",
  "502": "You are not authorized to access this page",
  "1000": "Session not found",
  "1001": "Invalid OTP. Please enter correct OTP",
  "1002": "OTP is expired. Please resend OTP to your phone",
  "1005": "Phone number not found. Please enter correct phone number",
  "6000": "Vendor details not found",
  "6001":
    "Vendor email id already exists. Please enter a different email address",
  "8001": "Organizer details not found",
  "8002": "Organizer details required",
  "8003": "Organizer blocked by Admin",
  "8004": "Organizer has been suspended",
  "8005": "Organizer details deleted",
  "8500":
    "Service already added on the same time slot. Please enter a different time slot",
  "9000": "Category details not found",
  "2505": "Address is associated with some services",
  "5003": "Promo Code already exists!",
  "5100": "Institution email already exists",
  "5101": "No Institution Found!", 
  "1602": "Active booking present for this service, you cannot remove it"
};
