export const actionTypes = {
    FAQ_ADDED: "FAQ_ADDED",
    FAQ_ADDED_DONE: "FAQ_ADDED_DONE",
    FAQ_UPDATED: "FAQ_UPDATED",
    FAQ_UPDATED_DONE: "FAQ_UPDATED_DONE",
    CHANGE_FAQ_TAB: "CHANGE_FAQ_TAB"
};

const initialAuthState = {
    listData: undefined,
    currTab: 0,
    faqAdded: false,
    faqUpdated: false
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.FAQ_ADDED:
            {
                return { ...state, faqAdded: true };
            }
        case actionTypes.FAQ_ADDED_DONE:
            {
                return { ...state, faqAdded: false };
            }
        case actionTypes.FAQ_UPDATED:
            {
                return { ...state, faqUpdated: true };
            }
        case actionTypes.FAQ_UPDATED_DONE:
            {
                return { ...state, faqUpdated: false };
            }
        case actionTypes.CHANGE_FAQ_TAB: {
            return { ...state, currTab: action.payload.currTab };
        }
        default:
            return state;
    }
};

export const actions = {
    faqAdded: () => ({
        type: actionTypes.FAQ_ADDED
    }),
    faqAddedDone: () => ({
        type: actionTypes.FAQ_ADDED_DONE
    }),
    faqUpdated: () => ({
        type: actionTypes.FAQ_UPDATED
    }),
    faqUpdatedDone: () => ({
        type: actionTypes.FAQ_UPDATED_DONE
    }),
    changeFAQTab: (currTab) => ({
        type: actionTypes.CHANGE_FAQ_TAB,
        payload: {
            currTab: currTab,
        },
    }),
};

export function* saga() {
}
