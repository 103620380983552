export const actionTypes = {
    UPDATE_HAS_NOTIFICATION: "UPDATE_HAS_NOTIFICATION",
    UPDATE_TAB: "UPDATE_TAB",
  };
  
  const initialState = {
    tab: 0,
    hasNotification: false
  };
  
  export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.UPDATE_TAB: {
        const { selectedTab } = action.payload;
        return { ...state, tab: selectedTab };
      }
      case actionTypes.UPDATE_HAS_NOTIFICATION: {
        const { hasNotification } = action.payload;
        return { ...state, hasNotification: hasNotification };
      }
      default:
        return state;
    }
  };
  
  export const actions = {
    setHasNotification: ({ hasNotification }) => ({
      type: actionTypes.UPDATE_HAS_NOTIFICATION,
      payload: {
        hasNotification,
      },
    }),
    updateTab: ({ selectedTab }) => ({
      type: actionTypes.UPDATE_TAB,
      payload: {
        selectedTab,
      },
    })
  };
  