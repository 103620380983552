import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";

const CustomBookingCard = (props) => {
  const useStyles = makeStyles(() => ({
    card: {
      background: props.bgColor,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 10,
      alignItems: "center",
    },
    title: {
      color: "#5A5C69",
      fontSize: 16,
      fontWeight: 500,
      fontFamily: "Arial",
      overflow: "hidden",
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: props.data.type === 'forum'? 620 : 175
    },
    subTitle: {
      color: "#5A5C69",
      fontSize: 10,
      fontFamily: "Arial",
    },
    imgContainer: {
      borderRadius: "50%",
      opacity: 1,
      background: "0% 0% no-repeat padding-box",
    },
    avatar: {
      height: 35,
      width: 35,
    },
    arrowIcon: {
      backgroundColor: "#5A5C69",
      borderRadius: "50%",
      color: "white",
      padding: 4,
      width: 22,
      height: 22,
    },
    content: {
      paddingLeft: 10,
      paddingRight: 45,
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Link to={`/${props.pageDetails}/${props.data.id}`}>
        <div className={classes.card}>
          <div style={{
            flex: 0.95,
            display: 'flex'
          }}>
            {props.data.bgColor? <div style={{
              background: props.data.bgColor,
              height: 35,
              width: 35,
              borderRadius: 50,
            }}>
            </div>:<Avatar
              className={classes.avatar}
              src={props.data.image}
              alt="Profile Picture"
            />}

            <div className={classes.content}>
              <Typography className={classes.title}>
                {props.data.title}
              </Typography>
              <Typography className={classes.subTitle}>
                {props.data.subTitle}
              </Typography>
            </div>
          </div>
          {props.stateWiseProfList && <ArrowForwardIosOutlinedIcon className={classes.arrowIcon} />}
        </div>
      </Link>
    </React.Fragment>
  );
};

export default CustomBookingCard;
