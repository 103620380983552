import React from "react";
import { Button, Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ImageModal = (props) => {
    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={() => {
                    props.handleClose();
                }}
            >
                <Modal.Body>
                    <Carousel>
                        {props.images.map((each, key) => {
                            return (<div key={key}>
                                <img src={each.url} alt={`event-galley-${key}`} />
                            </div>);
                        })}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.handleClose();
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};
export default ImageModal;
