import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditLanguage from "./edit-language";
import RemoveLanguage from "./remove-language";
import { listLanguage } from "../../../crud/language.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const LanguageList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showLanguageEdit, setShowLanguageEdit] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showLanguageDelete, setShowLanguageDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.language.listData) {
      let listObject = {
        data: [...props.data.language.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.language.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.language.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.language.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listLanguage({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(docs.data.data.total / tableData.limit),
          });
        }
      }
    );
  };

  const handleChange = (event, value) => {
    setPage(value);
    listLanguage({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(docs.data.data.total / tableData.limit),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowLanguageEdit(false);
  };

  const handleCloseDelete = () => {
    setShowLanguageDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowLanguageEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowLanguageDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <React.Fragment>
      {showLanguageEdit ? (
        <EditLanguage
          language={selectedData}
          show={showLanguageEdit}
          handleClose={handleCloseEdit}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showLanguageDelete ? (
        <RemoveLanguage
          language={selectedData}
          show={showLanguageDelete}
          handleClose={handleCloseDelete}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'language'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                <th className="custom-table-header-text">Name</th>
                <th className="custom-table-header-text">Code</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{eachData._id}</td>
                    <td className="custom-table-body-text">{eachData.name}</td>
                    <td className="custom-table-body-text">{eachData.code}</td>
                    <td className="text-center">
                      {eachData.code === "eng-us" ? null : (
                        <div>
                          <p
                            className="btn btn-icon custom-table-btn"
                            onClick={() => openEditModal(eachData)}
                            style={{
                              margin: "0",
                            }}
                          >
                            <i className="la la-edit"></i>
                          </p>

                          <p
                            className="btn btn-icon custom-table-btn"
                            title="Delete"
                            style={{
                              margin: "0",
                            }}
                            onClick={() => {
                              openDeleteModal(eachData);
                            }}
                          >
                            <i className="la la-trash kt-font-danger"></i>
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageList);
