import { listVendor } from "../../crud/vendor.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
    VENDOR_ADDED: "VENDOR_ADDED",
    VENDOR_ADDED_SUCCESS: "VENDOR_ADDED_SUCCESS",
    VENDOR_UPDATED: "VENDOR_UPDATED",
    VENDOR_UPDATED_SUCCESS: "VENDOR_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.VENDOR_ADDED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        case actionTypes.VENDOR_UPDATED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        default:
            return state;
    }
};

export const actions = {
    vendorAdded: ({ skip, limit }) => ({
        type: actionTypes.VENDOR_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    vendorUpdated: ({ skip, limit, payload }) => ({
        type: actionTypes.VENDOR_UPDATED,
        payload: {
            skip,
            limit,
            payload
        }
    })
};

export function* saga() {
    yield takeLatest(actionTypes.VENDOR_ADDED, function* vendorAdded(action) {
        const { skip, limit } = action.payload;
        const listData = yield listVendor({ skip, limit });

        yield put({
            type: actionTypes.VENDOR_ADDED_SUCCESS, payload: {
                listData
            }
        });
    });

    yield takeLatest(actionTypes.VENDOR_UPDATED, function* vendorUpdated(action) {
        const { skip, limit, payload } = action.payload;
        const listData = yield listVendor({ skip, limit, payload });

        yield put({
            type: actionTypes.VENDOR_UPDATED_SUCCESS, payload: {
                listData
            }
        });
    });
}
