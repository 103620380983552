const VendorConfig = {
  initialMenuOptions: [
    {
      option: "Gallery",
      selected: true,
    },
    {
      option: "Bank Details",
      selected: false,
    },
    {
      option: "Address Book",
      selected: false,
    },
    {
      option: "Services",
      selected: false,
    },
    {
      option: "Physical Contracts",
      selected: false,
    },
  ],
  initialProfileError: {
    name: "",
    email: "",
    category: "",
    experience: "",
    websiteUrl: "",
  },
  initialBankDetailsError: {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    gstNumber: "",
  },
  initialLocation: {
    title: "",
    address: "",
    days: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
  },
  initialLocationError: {
    title: "",
    address: "",
    selectedFromDate: "",
    selectedToDate: "",
    days: "",
  },
  initialServiceDetails: {
    title: "",
    fees: "",
    description: "",
  },
  initialServiceError: {
    title: "",
    fees: "",
  },
  dayName: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
  serviceTimeHoursConstant: [
    {
      text: "30 mins",
      value: 1,
    },
    {
      text: "1 hr",
      value: 2,
    },
    {
      text: "1 hr 30 mins",
      value: 3,
    },
    {
      text: "2 hrs",
      value: 4,
    },
    {
      text: "2 hrs 30 mins",
      value: 5,
    },
    {
      text: "3 hrs",
      value: 6,
    },
    {
      text: "3 hrs 30 mins",
      value: 7,
    },
    {
      text: "4 hrs",
      value: 8,
    },
    {
      text: "4 hrs 30 mins",
      value: 9,
    },
    {
      text: "5 hrs",
      value: 10,
    },
  ],
};

export default VendorConfig;
