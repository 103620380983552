import React, { useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ProfileVisibilityConfirmModal from '../modal/profile-visibility-confirm-modal';
export default function ProfileVisibility({ state, handleChange }) {
    const [open, setOpen] = useState(false);
    const [isOnline, setIsOnline] = useState(state.isVisible);

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        handleChange(isOnline);
    };

    const handleChangeVisibility = (event) => {
        setOpen(true);
        setIsOnline(event.target.checked);
    };

    return (
        <div>
            <ProfileVisibilityConfirmModal open={open} handleClose={handleClose} handleConfirm={handleConfirm} isOnline={state.isVisible} />
            <FormControl component="fieldset">
                <FormLabel component="legend">Profile Visible</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={state.isVisible} onChange={handleChangeVisibility} name="isOnline" />}
                        label={state.isVisible ? "Enabled" : "Disabled"}
                    />
                </FormGroup>
            </FormControl>
        </div>

    );
}
