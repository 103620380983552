import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Dropdown } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import {
  listSupport,
  approveSupportDetails,
  rejectSupportDetails,
} from "../../../crud/support.crud";
import supportConfig from "../../../config/support";
import ApproveModal from './../../common/modal/approve-modal';
import RejectModal from './../../common/modal/reject-modal';
import { toast } from "react-toastify";
import CustomPagination from "../../../components/CustomPagination";
import StyleDropdown from "../../../components/StyleDropdown";
import NoDataFound from "../../../components/NoDataFound";

const SupportList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [selectedData, setSelectedData] = useState();
  const [showApproval, setShowApproval] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedType, setSelectedType] = useState(undefined);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  useEffect(() => {
    if (props.data.support.listData) {
      let listObject = {
        data: [...props.data.support.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.support.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.support.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.support.listData]);

  const reloadList = () => {
    listSupport({ payload: {type: selectedType}, skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    setPage(value);

    listSupport({
      payload: {type: selectedType},
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const approveItem = (supportId) => {
    approveSupportDetails(supportId).then(() => {
      reloadList();
      toast.success("Support ticket is successfully approved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const rejectItem = (supportId) => {
    rejectSupportDetails(supportId).then(() => {
      reloadList();
      toast.error("Support ticket is successfully rejected!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };
  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowRejection(true);
  };

  const handleApprove = (approve) => {
    if (approve) {
      approveItem(selectedData._id);
    } else {
      rejectItem(selectedData._id);
    }
  };

  const typeHandler = (type) => {
    setSelectedType(type);
  };

  useEffect(()=>{
    reloadList();
    // eslint-disable-next-line
  }, [selectedType])


  return (
    <React.Fragment>
      {showApproval ? (
        <ApproveModal
          type={'Support Request'}
          title={selectedData.description}
          item={selectedData}
          show={showApproval}
          handleClose={() => setShowApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showRejection ? (
        <RejectModal
          type={'Support Request'}
          title={selectedData.description}
          item={selectedData}
          show={showRejection}
          handleClose={() => setShowRejection(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text" style={{width: '50px'}}>ID</th>: null}
                <th className="custom-table-header-text">Date</th>
                <th className="custom-table-header-text" style={{width: '200px'}}>Asked By</th>
                <th className="custom-table-header-text">Description</th>
                <th className="custom-table-header-text">Type</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                {userInfo && userInfo.roleInfo.isSuperAdmin? <th ></th>: null}
                <th></th>
                <th></th>
                <th></th>
                <th style={{textAlign:'center'}}>
                    <StyleDropdown>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {selectedType? supportConfig.type[selectedType]: 'All'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      <Dropdown.Item
                          onClick={() => {
                            typeHandler(undefined);
                          }}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            typeHandler(1);
                          }}
                        >
                          Complaint
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            typeHandler(2);
                          }}
                        >
                          Suggestion
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </StyleDropdown>
                  </th>
                  <th></th>
              </tr>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">
                    <Link to={`/support-details/${eachData._id}`}>
                      {tableData.skip+key+1}
                      </Link>
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin?
                    <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{eachData._id}</td> : null}
                    <td className="custom-table-body-text">
                      {moment(eachData.createdAt).format(
                        "DD-MMM-YYYY, hh:mm A"
                      )}
                    </td>
                    <td className="custom-table-body-text">
                      {eachData.customerRef
                        ? eachData.customerRef.personalInfo.name
                        : eachData.vendorRef.personalInfo.name}
                    </td>
                    <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{eachData.description}</td>
                    <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{supportConfig.type[eachData.type]}</td>
                    <td className="text-center">
                      {eachData.status === 1 ? (
                        <div>
                          <p
                            className="btn btn-icon custom-table-btn"
                            title="Approve"
                            onClick={() => approvalConsent(eachData)}
                            style={{
                              margin: "0",
                            }}
                          >
                            <i className="la la-check-circle"></i>
                          </p>

                          <p
                            className="btn btn-icon custom-table-btn"
                            title="Reject"
                            style={{
                              margin: "0",
                            }}
                            onClick={() => {
                              rejectionConsent(eachData);
                            }}
                          >
                            <i className="la la-close kt-font-danger"></i>
                          </p>
                        </div>
                      ) : (
                          <Typography
                            color={eachData.status === 2 ? "secondary" : "error"}
                            align="center"
                          >
                            {supportConfig.status[eachData.status]}
                          </Typography>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportList);
