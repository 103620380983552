const userConfig = {
    role: {
        customer: 1,
        vendor: 2,
        admin: 3,
        organiser: 4,
    }, 
    accountStatus: {
        vendor: {
          active: 1,
          blocked: 2,
          deleted: 3,
          approvalPending: 4,
          approvalRejected: 5,
          approved: 6,
          deletedFromApp: 7,
        },
        organiser: {
          active: 1,
          blocked: 2,
          deleted: 3,
        },
        admin: {
          active: 1,
          blocked: 2,
          deleted: 3,
        },
        customer: {
          active: 1,
          blocked: 2,
          deleted: 3,
          deletedFromApp: 7
        },
        institution: {
          active: 1,
          blocked: 2,
          deleted: 3,
        }
    },
    gender:{
      1: 'Male', 
      2: 'Female', 
      3: 'Other'
    }
}
export default userConfig;