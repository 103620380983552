import React from "react";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";

const CustomPieChart = ({name, data}) => {
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
      column: {
        colorByPoint: true,
      },
    },
    colors: [
      "#47ACB1",
      "#286C4F",
      "#C9222B",
      "#F74044",
      "#96247A",
      "#FFCD33",
      "#5A5C69",
    ],

    series: [
      {
        name: name,
        type: "pie",
        allowPointSelect: true,
        keys: ["name", "y", "selected", "sliced"],
        data: data, 
        // [
        //   ["APTITUDE SERVICES [67]", 67, false],
        //   ["CAREER COUNSELLOR [72]", 72, false],
        //   ["CHILD PSYCHOLOGIST [24]", 24, false],
        //   ["PSYCHOANALYST [14]", 14, false],
        //   ["SEXOLOGIST [9]", 9, false],
        //   ["CHILD COUNSELLOR [44]", 44, false],
        //   ["OTHER [22]", 22, false],
        // ],
        showInLegend: true,
      },
    ],
    legend: {
      padding: 0,
      itemMarginTop: 5,
      itemMarginBottom: 5,
      itemStyle: {
          lineHeight: '14px'
      },
      symbolRadius: 0
  },
  };
  return (
    <div>
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
};

export default CustomPieChart;
