import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { AspectRatio } from 'react-aspect-ratio'; 
import EditProfile from './edit-profile';
import ChangePassword from './change-password';
import { connect } from "react-redux";
const ViewProfile = (props)=>{
    const [isProfileTab, setIsProfileTab] = useState(true);
    const [user, setUser] =  useState({personalInfo: null});
	const {account} = props;
	useEffect(()=>{
		if(account.user){
		  setUser(account.user);
		}
	  },[account]);

    return (<React.Fragment>
        <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor body-content"
					id="kt_content">

					<div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

						<div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">

							<button className="kt-app__aside-close" id="kt_user_profile_aside_close">
								<i className="la la-close"></i>
							</button>


							<div className="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">

								<div className="kt-portlet ">
									<div className="kt-portlet__head  kt-portlet__head--noborder">
										<div className="kt-portlet__head-label">
											<h3 className="kt-portlet__head-title">
											</h3>
										</div>
									</div>
									<div className="kt-portlet__body kt-portlet__body--fit-y">

										<div className="kt-widget kt-widget--user-profile-1">
											<div className="kt-widget__head">
												<div className="kt-widget__media">

                                                <AspectRatio ratio="1/1" style={{ maxWidth: '100px', maxHeight: '100px' }}>
                                                     <img alt={`user logo`} src={user.personalInfo && user.personalInfo.profilePicture ? user.personalInfo.profilePicture: toAbsoluteUrl(`/media/default-image/default-image.png`)} />
                                                </AspectRatio>
												</div>
												<div className="kt-widget__content">
													<div className="kt-widget__section">
														<span className="kt-widget__username">
															{user.personalInfo ? user.personalInfo.firstName: null} {user.personalInfo ? user.personalInfo.lastName: null}
															<i className="flaticon2-correct kt-font-success"></i>
														</span>
														<span className="kt-widget__subtitle">
															{user.roleInfo && user.roleInfo.isSuperAdmin ? 'Super Admin': null}
														</span>
													</div>
												</div>
											</div>
											<div className="kt-widget__body">
												<div className="kt-widget__content">
													<div className="kt-widget__info">
														<span className="kt-widget__label">Email&nbsp;:&nbsp;
															<span className="kt-widget__data">{user.personalInfo ? user.personalInfo.email: null}</span>
														</span>
													</div>
												</div>
												<div className="kt-widget__items nav nav-tabs" role="tablist">
													<p onClick={()=>{
                                                        setIsProfileTab(true);
                                                    }} className={`kt-widget__item ${isProfileTab? 'active': null}`}
														data-toggle="tab" role="tab" aria-selected="true" style={{width: '100%'}}>
														<span className="kt-widget__section">
															<span className="kt-widget__icon">
																<svg xmlns="http://www.w3.org/2000/svg"
																	xmlnsXlink="http://www.w3.org/1999/xlink"
																	width="24px" height="24px" viewBox="0 0 24 24"
																	version="1.1" className="kt-svg-icon">
																	<g stroke="none" strokeWidth="1" fill="none"
																		fillRule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24" />
																		<path
																			d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
																			fill="#000000" fillRule="nonzero"
																			opacity="0.3" />
																		<path
																			d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
																			fill="#000000" fillRule="nonzero" />
																	</g>
																</svg> </span>
															<span className="kt-widget__desc">
																Personal Information
															</span>
														</span>
													</p>
													<p onClick={()=>{
                                                        setIsProfileTab(false);
                                                    }} className={`kt-widget__item ${!isProfileTab? 'active': null}`}
														data-toggle="tab" role="tab" aria-selected="false" style={{width: '100%'}}>
														<span className="kt-widget__section">
															<span className="kt-widget__icon">
																<svg xmlns="http://www.w3.org/2000/svg"
																	xmlnsXlink="http://www.w3.org/1999/xlink"
																	width="24px" height="24px" viewBox="0 0 24 24"
																	version="1.1" className="kt-svg-icon">
																	<g stroke="none" strokeWidth="1" fill="none"
																		fillRule="evenodd">
																		<rect x="0" y="0" width="24" height="24" />
																		<path
																			d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
																			fill="#000000" opacity="0.3" />
																		<path
																			d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
																			fill="#000000" opacity="0.3" />
																		<path
																			d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
																			fill="#000000" opacity="0.3" />
																	</g>
																</svg> </span>
															<span className="kt-widget__desc">
																Change Password
															</span>
														</span>

													</p>
												</div>
											</div>
										</div>

									</div>
								</div>

							</div>


							<div className="kt-grid__item kt-grid__item--fluid kt-app__content">
								<div className="row">
									<div className="col-xl-12">
										<div className="tab-content">
											{isProfileTab? (<EditProfile user={user}/>):(<ChangePassword/>)}
                                        </div>
									</div>
								</div>
							</div>

						</div>

					</div>

				</div>
    </React.Fragment>)
};

const mapStateToProps = ({ account }) => ({
	account: account
  });
  
  export default connect(mapStateToProps)(ViewProfile);