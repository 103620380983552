import axios from './axios.interceptor';
import api from './api.util';

export const PAYMENT_URL = `${api}/account/admin/payment`;
export const PAYMENT_LIST_URL = `${PAYMENT_URL}/list`;
export const PAYMENT_PAYOUT = `${PAYMENT_URL}/submit-payout`;

export function listPayment({ skip, limit, queryPayload }) {
  return axios.post(`${PAYMENT_LIST_URL}?skip=${skip}&limit=${limit}`, queryPayload);
}

export function paymentDetailsForVendor(payload) {
  return axios.post(`${PAYMENT_URL}/details/vendor`, payload);
}

export function submitPayoutForVendor(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/vendor`, payload);
}

export function paymentDetailsForOrganiser(payload) {
  return axios.post(`${PAYMENT_URL}/details/organiser`, payload);
}

export function submitPayoutForOrganiser(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/organiser`, payload);
}

export function paymentDetailsForInstitution(payload) {
  return axios.post(`${PAYMENT_URL}/details/institution`, payload);
}


export function submitPayoutForInstitution(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/institution`, payload);
}


export function paymentDetailsForOnlineEvent(payload) {
  return axios.post(`${PAYMENT_URL}/details/online-event`, payload);
}

export function submitPayoutForOnlineEvent(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/online-event`, payload);
}

export function paymentDetailsForWorkshop(payload) {
  return axios.post(`${PAYMENT_URL}/details/workshop`, payload);
}

export function submitPayoutForWorkshop(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/workshop`, payload);
}

export function listRevenue({ skip, limit, payload }) {
  return axios.post(`${PAYMENT_URL}/refund-list?skip=${skip}&limit=${limit}`, payload);
}

export function markPaidRevenue(revenueId) {
  return axios.put(`${PAYMENT_URL}/refund-list/${revenueId}`);
}

export function syncRevenueStatus(revenueId){
  return axios.put(`${PAYMENT_URL}/sync-refund-status/${revenueId}`);
}

export function listTransaction({ skip, limit, payload }) {
  return axios.post(`${PAYMENT_URL}/transaction-list?skip=${skip}&limit=${limit}`, payload);
}