import { listPayment } from "../../crud/payment.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
  PAYMENT_UPDATED: "PAYMENT_UPDATED",
  PAYMENT_UPDATED_SUCCESS: "PAYMENT_UPDATED_SUCCESS",
  CHANGE_PAYMENT_TAB: "CHANGE_PAYMENT_TAB",
};

const initialAuthState = {
  listData: undefined,
  currTab: 0,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_UPDATED_SUCCESS: {
      const { listData } = action.payload;
      return { listData };
    }
    case actionTypes.CHANGE_PAYMENT_TAB: {
      return { ...state, currTab: action.payload.currTab };
    }
    default:
      return state;
  }
};

export const actions = {
  paymentUpdated: ({ skip, limit }) => ({
    type: actionTypes.PAYMENT_UPDATED,
    payload: {
      skip,
      limit,
    },
  }),
  changePaymentTab: (currTab) => ({
    type: actionTypes.CHANGE_PAYMENT_TAB,
    payload: {
      currTab: currTab,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.PAYMENT_UPDATED, function* paymentUpdated(action) {
    const { skip, limit } = action.payload;
    const listData = yield listPayment({ skip, limit });

    yield put({
      type: actionTypes.PAYMENT_UPDATED_SUCCESS,
      payload: {
        listData,
      },
    });
  });
}
