import React, { useEffect, useState } from "react";
import { Table, Image } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditVendorCategory from "./edit-vendor-category";
import RemoveVendorCategory from "./remove-vendor-category";
import { listCategory } from "../../../crud/vendor-category.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const VendorCategoryList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showVendorEditCategory, setShowVendorEditCategory] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showVendorDeleteCategory, setShowVendorDeleteCategory] = useState(
    false
  );
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.vendorCategory.listData) {
      let listObject = {
        data: [...props.data.vendorCategory.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.vendorCategory.listData.data.data.total,
        totalPage: Math.ceil(
          Number(
            props.data.vendorCategory.listData.data.data.total / tableData.limit
          )
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.vendorCategory.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listCategory({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (event, value) => {
    setPage(value);

    listCategory({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowVendorEditCategory(false);
  };

  const handleCloseDelete = () => {
    setShowVendorDeleteCategory(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowVendorEditCategory(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowVendorDeleteCategory(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <React.Fragment>
      {showVendorEditCategory ? (
        <EditVendorCategory
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          category={selectedData}
          show={showVendorEditCategory}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showVendorDeleteCategory ? (
        <RemoveVendorCategory
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          category={selectedData}
          show={showVendorDeleteCategory}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'professional category'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                <th className="custom-table-header-text">Icon</th>
                <th className="custom-table-header-text">Title</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{eachData._id}</td>
                    <td>
                      <Image
                        src={eachData.icon}
                        roundedCircle
                        style={{
                          height: "120px",
                        }}
                      />
                    </td>
                    <td className="custom-table-body-text">{eachData.title}</td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorCategoryList);
