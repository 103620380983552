export const actionTypes = {
  UPDATE_BOOKING_STATUS: "UPDATE_BOOKING_STATUS",
  UPDATE_BOOKING_MONTH: "UPDATE_BOOKING_MONTH",
  UPDATE_PAGE: "UPDATE_PAGE",
};

const initialState = {
  page: 1,
  selectedBookingStatus: undefined,
  selectedBookingMonth: new Date(),
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BOOKING_STATUS: {
      const { selectedBookingStatus } = action.payload;
      return { ...state, selectedBookingStatus };
    }
    case actionTypes.UPDATE_BOOKING_MONTH: {
      const { selectedBookingMonth } = action.payload;
      return { ...state, selectedBookingMonth };
    }
    case actionTypes.UPDATE_PAGE: {
      const { page } = action.payload;
      return { ...state, page };
    }
    default:
      return state;
  }
};

export const actions = {
  updateBookingStatus: ({ selectedBookingStatus }) => ({
    type: actionTypes.UPDATE_BOOKING_STATUS,
    payload: {
      selectedBookingStatus,
    },
  }),
  updateBookingMonth: ({ selectedBookingMonth }) => ({
    type: actionTypes.UPDATE_BOOKING_MONTH,
    payload: {
      selectedBookingMonth,
    },
  }),
  updatePage: ({ page }) => ({
    type: actionTypes.UPDATE_PAGE,
    payload: {
      page,
    },
  }),
};
