import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { editQA } from '../../../crud/qa.crud';
import * as qa from "../../../store/ducks/qa.duck";
const EditForum = (props) => {
    const [loading,
        setLoading] = useState(false);
    const [description,
        setDescription] = useState(props.qa.question);
    const [errors,
        setErrors] = useState({ description: null });
    const { classes, qa } = props;

    useEffect(() => {
        const dataProps = JSON.parse(JSON.stringify(qa));
        setDescription(dataProps.question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    const resetState = () => {
        setDescription('');
        setErrors({

            description: null
        });
    }


    const validForm = () => {
        let e = true;

        if (!description) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
        return e;
    };


    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);

            const payload = {
                question: description
            };

            editQA(qa._id, payload).then((output) => {
                if (output.data.success) {
                    props.qaUpdated();
                    resetState();
                    setLoading(false);
                    toast.success('Forum question is successfully updated!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('faq');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);

            });
        }

    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={() => props.handleClose('qa')}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="professionalFaqForm.description">
                            <Form.Control as="textarea"
                                rows="3" value={description} onChange={onDescriptionChangeHandler} isInvalid={!!errors.description} />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.handleClose('qa')}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                            position: "relative"
                        }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        qaUpdated: () => dispatch(qa.actions.qaUpdated())
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), { withTheme: true })(EditForum)));
