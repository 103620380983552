import React from 'react';
import { Dropdown } from "react-bootstrap";
import { styled } from "@material-ui/core/styles";

const StyleDropdown = styled((props) => (
    <Dropdown
      {...props}
    />
  ))(() => ({
    '& .btn-primary':{
        background: '#15579f',
        border: '0.5px solid #15579f',
        '&:hover, &:not(:disabled):not(.disabled):active':{
            background: '#15579f',
            border: '0.5px solid #15579f',
        },
        '&:after':{
            borderRadius: 50, 
            background: '#ffffff', 
            color: '#5a5c69',
            height: 24,
            width: 24, 
            padding: 4,
            opacity: 0.9
        },
    },
    '&.show .btn-primary.dropdown-toggle':{
      background: '#15579f',
      border: '0.5px solid #15579f',
    }
  }));
  
export default StyleDropdown;