import React, { Fragment, useEffect, useState } from "react";
import { Checkbox, Drawer, makeStyles } from "@material-ui/core";
import { Form } from "react-bootstrap";
import FullWidthTabs from "../../common/FullWidthTabs";
import { listEmailUser } from "../../../crud/user.crud";
import { listEmailVendor } from "../../../crud/vendor.crud";
import CustomUserCard from "../dashboard/component/CustomUserCard";
import userConfig from "../../../config/user";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import NoDataFound from "../../../components/NoDataFound";

const useStyles = makeStyles({
  root: {
    width: "340px",
  },
});

const CustomList = (props) => {
  const customClasses = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [tableDataCustomer, setTableDataCustomer] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });
  const [tableDataProfessional, setTableDataProfessional] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const handleSearch = (event) => {
    if (event.target.value) {
      setSearchText(event.target.value);
    } else {
      setSearchText("");
    }
  };

  const reloadListCustomer = () => {
    listEmailUser({
      skip: 0, // tableDataCustomer.skip,
      limit: 0, //tableDataCustomer.limit,
      payload: {
        searchKey: searchText ? searchText: undefined,
      },
    }).then((docs) => {
      if (docs.data) {
        let tempIds = props.selectedEmails.length
          ? props.selectedEmails.map((each) => each._id)
          : [];
        setTableDataCustomer({
          data: [...docs.data.data.data].map((each) => {
            each.isSelceted = tempIds.includes(each._id);
            return { ...each };
          }),
          skip: tableDataCustomer.skip,
          limit: tableDataCustomer.limit,
          total: 0,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableDataCustomer.limit)
          ),
        });
      }
    });
  };
  const reloadListProfessional = () => {
    listEmailVendor({
      skip: 0, //tableDataProfessional.skip,
      limit: 0, //tableDataProfessional.limit,
      payload: {
        searchKey: searchText ? searchText: undefined,
      },
    }).then((docs) => {
      if (docs.data) {
        let tempIds = props.selectedEmails.length
          ? props.selectedEmails.map((each) => each._id)
          : [];
        setTableDataProfessional({
          data: [...docs.data.data.data].map((each) => {
            each.isSelceted = tempIds.includes(each._id);
            return { ...each };
          }),
          skip: tableDataProfessional.skip,
          limit: tableDataProfessional.limit,
          total: 0,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableDataProfessional.limit)
          ),
        });
      }
    });
  };
  const handleCheck = (event, type, item) => {
    if (event.target.checked) {
      if (type === userConfig.role.customer) {
        setTableDataCustomer({
          ...tableDataCustomer,
          data: tableDataCustomer.data.map((e) => {
            if (e._id === item._id) {
              e.isSelceted = true;
            }
            return { ...e };
          }),
        });
      } else {
        setTableDataProfessional({
          ...tableDataProfessional,
          data: tableDataProfessional.data.map((e) => {
            if (e._id === item._id) {
              e.isSelceted = true;
            }
            return { ...e };
          }),
        });
      }
      // Add Email User
      props.setSelectedEmails([
        ...props.selectedEmails,
        { ...item, accountType: type },
      ]);
    } else {
      if (type === userConfig.role.customer) {
        setTableDataCustomer({
          ...tableDataCustomer,
          data: tableDataCustomer.data.map((e) => {
            if (e._id === item._id) {
              e.isSelceted = false;
            }
            return { ...e };
          }),
        });
      } else {
        setTableDataProfessional({
          ...tableDataProfessional,
          data: tableDataProfessional.data.map((e) => {
            if (e._id === item._id) {
              e.isSelceted = false;
            }
            return { ...e };
          }),
        });
      }
      // Remove Email User
      const filteredList = props.selectedEmails.filter(
        (a) => !(a._id === item._id)
      );
      props.setSelectedEmails(filteredList);
    }
  };
  useEffect(() => {
    switch (currentTab) {
      case 0:
        reloadListCustomer();
        break;
      case 1:
        reloadListProfessional();
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [currentTab]);

  useEffect(() => {
      switch (currentTab) {
        case 0:
          reloadListCustomer();
          break;
        case 1:
          reloadListProfessional();
          break;
        default:
          break;
      }
    // eslint-disable-next-line
  }, [searchText]);
  return (
    <Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          props.handleClose("sendEmailCustom");
        }}
      >
        <div className={customClasses.root}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <h5>Select users</h5>
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 50,
                background: "rgba(0,0,0,0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                props.handleClose("sendEmailCustom");
              }}
            >
              <img
                src={toAbsoluteUrl("/media/default-image/cross.png")}
                alt="cross icon"
              />
            </div>
          </div>
          <FullWidthTabs
            currentTab={currentTab}
            setCurrentTab={(tab) => {
              setCurrentTab(tab);
            }}
            title={"Custom List"}
            tabsTitle={["Customer", "Professional"]}
          />
          <div style={{ padding: 16 }}>
            <Form.Control
              type="text"
              placeholder="Search by email"
              value={searchText}
              onChange={handleSearch}
            />
          </div>

          {currentTab === 0 && (
            tableDataCustomer.data.length ?
            tableDataCustomer.data.map((each, key) => {
              return (
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    paddingBottom: 12,
                    paddingLeft: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                  key={key}
                >
                  <Checkbox
                    onChange={(e) =>
                      handleCheck(e, userConfig.role.customer, each)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    size="medium"
                    checked={each.isSelceted}
                  />
                  <CustomUserCard
                    userType="professional"
                    data={{
                      id: each._id,
                      name: each.personalInfo?.name,
                      designation: each.personalInfo?.email,
                      profileImg: each.personalInfo?.profilePicture,
                      address: "+91-" + each.personalInfo?.phone?.number,
                    }}
                    color="#ECF7F7"
                  />
                </div>
              );
            }): <NoDataFound/>)}
          {currentTab === 1 && (
            tableDataProfessional.data.length ?
            tableDataProfessional.data.map((each, key) => {
              return (
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    paddingBottom: 12,
                    paddingLeft: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                  key={key}
                >
                  <Checkbox
                    onChange={(e) =>
                      handleCheck(e, userConfig.role.vendor, each)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    size="medium"
                    checked={each.isSelceted}
                  />
                  <CustomUserCard
                    userType="professional"
                    data={{
                      id: each._id,
                      name: each.personalInfo?.name,
                      designation: each.personalInfo?.email,
                      profileImg: each.personalInfo?.profilePicture,
                      address: "+91-" + each.personalInfo?.phone?.number,
                    }}
                    color="#ECF7F7"
                  />
                </div>
              );
            }): <NoDataFound/>)}
        </div>
      </Drawer>
    </Fragment>
  );
};

export default CustomList;
