import { listAppsTutorial } from "../../crud/apps-tutorial.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
    APPS_TUTORIAL_ADDED: "APPS_TUTORIAL_ADDED",
    APPS_TUTORIAL_ADDED_SUCCESS: "APPS_TUTORIAL_ADDED_SUCCESS",
    APPS_TUTORIAL_UPDATED: "APPS_TUTORIAL_UPDATED",
    APPS_TUTORIAL_UPDATED_SUCCESS: "APPS_TUTORIAL_UPDATED_SUCCESS",
    CHANGE_APPS_TUTORIAL_TAB: "CHANGE_APPS_TUTORIAL_TAB"
};

const initialAuthState = {
    listData: undefined,
    currTab: 0,
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.APPS_TUTORIAL_ADDED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        case actionTypes.APPS_TUTORIAL_UPDATED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        case actionTypes.CHANGE_APPS_TUTORIAL_TAB: {
            return { ...state, currTab: action.payload.currTab };
        }
        default:
            return state;
    }
};

export const actions = {
    appsTutorialAdded: ({ skip, limit, userType }) => ({
        type: actionTypes.APPS_TUTORIAL_ADDED,
        payload: {
            skip,
            limit,
            userType
        }
    }),
    appsTutorialUpdated: ({ skip, limit, userType }) => ({
        type: actionTypes.APPS_TUTORIAL_UPDATED,
        payload: {
            skip,
            limit,
            userType
        }
    }),
    changeAppsTutorialTab: (currTab) => ({
        type: actionTypes.CHANGE_APPS_TUTORIAL_TAB,
        payload: {
            currTab: currTab,
        },
    }),
};

export function* saga() {
    yield takeLatest(actionTypes.APPS_TUTORIAL_ADDED, function* appsTutorialAdded(action) {
        const { skip, limit, userType } = action.payload;
        const listData = yield listAppsTutorial({ skip, limit, userType });

        yield put({
            type: actionTypes.APPS_TUTORIAL_ADDED_SUCCESS, payload: {
                listData
            }
        });
    });

    yield takeLatest(actionTypes.APPS_TUTORIAL_UPDATED, function* appsTutorialUpdated(action) {
        const { skip, limit, userType } = action.payload;
        const listData = yield listAppsTutorial({ skip, limit, userType });

        yield put({
            type: actionTypes.APPS_TUTORIAL_UPDATED_SUCCESS, payload: {
                listData
            }
        });
    });
}
