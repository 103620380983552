import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";

import { styled } from "@material-ui/core/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomCardHeader = styled((props) => (
    <CardHeader
      {...props}
    />
  ))(() => ({
    backgroundColor: '#e6ebf8',
    '& .MuiCardHeader-action':{
        alignSelf: 'end'
    }
  }));

const DashboardCardHOC = ({ title, fixedContent, collapseContent }) => {
  const [expanded, setExpanded] = useState(title === 'Professionals' ? true : false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{marginBottom: 16}}>
      <CustomCardHeader
        action={
          title === 'Assesments'? null: <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={{
                background: '#5a5c69', 
                padding: 4,
                marginRight: 12
            }}
          >
            <ExpandMoreIcon style={{
                color: 'rgba(255,255,255, 0.9)'
            }}/>
          </ExpandMore>
        }
        title={title}
      />
      <CardContent>{fixedContent}</CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{collapseContent}</CardContent>
      </Collapse>
    </Card>
  );
};

export default DashboardCardHOC;
