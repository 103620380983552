import React, { useState } from 'react';
import { Table, Dropdown } from "react-bootstrap";
import paymentConfig from "../../../../config/payment";
import MarkPaidModal from './modal/mark-paid-modal';
import { markPaidRevenue, syncRevenueStatus } from "../../../../crud/payment.crud";
import * as paymentDuck from "../../../../store/ducks/payment.duck";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from 'react-toastify';
import StyleDropdown from "../../../../components/StyleDropdown";
const RefundList = ({ tableData, changePaymentTab, selectedRefundedStatus, statusHandler }) => {
    const [showMarkPaidModal, setShowMarkPaidModal] = useState(false);
    const [refundDoc, setRefundDoc] = useState(false);
    const handleMarkPaid = (approve) => {
        if (approve) {
            markPaidRevenue(refundDoc._id)
                .then(() => {
                    console.log('success');
                    changePaymentTab(0);
                })
        }
        setShowMarkPaidModal(false);
    };

    const syncRefundStatus = (refundDocData)=>{
        syncRevenueStatus(refundDocData._id)
        .then((output) => {
            console.log('success', output);
            toast.info(output.data.data.resultMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            changePaymentTab(0);
        })
    }

    return (
        <div>
            {showMarkPaidModal ? (
                <MarkPaidModal
                    refundDoc={refundDoc}
                    show={showMarkPaidModal}
                    handleClose={() => setShowMarkPaidModal(false)}
                    handleMarkPaid={(approve) => handleMarkPaid(approve)}
                />
            ) : null}
            <Table bordered hover>
                <thead className="custom-table-header"> 
                    <tr>
                        <th className="custom-table-header-text">SL No</th>
                        <th className="custom-table-header-text">Name</th>
                        <th className="custom-table-header-text">Phone</th>
                        <th className="custom-table-header-text">Date</th>
                        <th className="custom-table-header-text" style={{ width: '10px' }}>Transaction Number</th>
                        <th className="custom-table-header-text" style={{ width: '150px' }}>Order Number</th>
                        <th className="custom-table-header-text">Amount</th>
                        <th className="custom-table-header-text">Status</th>  
                        <th className="custom-table-header-text">Action</th>  
                    </tr>
                    
                </thead>
                <tbody>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style={{ width: '10px' }}></th>
                        <th style={{ width: '150px' }}></th>
                        <th></th>
                        <th>
                            <StyleDropdown>
                                <Dropdown.Toggle
                                    menualign="right"
                                    variant="primary"
                                    id="dropdown-menu-align-right"
                                >
                                    {selectedRefundedStatus}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            statusHandler(0);
                                        }}
                                        >
                                        All
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            statusHandler(1);
                                        }}
                                        >
                                        Auto Refunded
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            statusHandler(5);
                                        }}
                                        >
                                        Manually Refunded
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            statusHandler(3);
                                        }}
                                        >
                                        Pending
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            statusHandler(2);
                                        }}
                                        >
                                        Failed
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </StyleDropdown>
                        </th>  
                        <th></th>
                    </tr>
                    {tableData.data.length ? tableData.data.map((eachData, key) => {
                        return (
                            <tr key={`${eachData._id}`} className={
                                key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                              }>
                                <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{`***${eachData._id.substr(eachData._id.length-5,5)}`}</td>
                                <td className="custom-table-body-text">
                                    {eachData.customerRef.personalInfo.name
                                        ? eachData.customerRef.personalInfo.name
                                        : "N/A"}
                                </td>
                                <td className="custom-table-body-text">{`${eachData.customerRef.personalInfo.phone.dialCode}-${eachData.customerRef.personalInfo.phone.number}`}</td>
                                <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{moment(eachData.createdAt).format("DD-MMM-YYYY hh:mm A")}</td>
                                <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{eachData.txnId}</td>
                                <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{eachData.orderId}</td>
                                <td className="custom-table-body-text">Rs. {eachData.amount}/-</td>
                                <td className="custom-table-body-text">{paymentConfig.pendingRefundStatusWeb[eachData.status]}</td>
                                <td className="text-center">
                                    {[paymentConfig.pendingRefundStatusServer.failed, paymentConfig.pendingRefundStatusServer.notInitiated].includes(eachData.status) ? <p
                                        className="btn btn-sm btn-primary"
                                        title={"Edit"}
                                        onClick={() => {
                                            setRefundDoc(eachData);
                                            setShowMarkPaidModal(true);
                                        }}
                                        style={{
                                            margin: "0",
                                        }}
                                    >
                                        Mark Paid
                                </p> : null}
                                {paymentConfig.pendingRefundStatusServer.initiated === eachData.status ? <p
                                        className="btn btn-sm btn-primary"
                                        title={"Edit"}
                                        onClick={() => {
                                            syncRefundStatus(eachData)
                                        }}
                                        style={{
                                            margin: "0",
                                        }}
                                    >
                                       Check Status
                                </p> : null}
                                </td>
                            </tr>
                        );
                    }) : null}
                </tbody>
            </Table>

        </div>
    )
}

const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePaymentTab: (tab) => dispatch(paymentDuck.actions.changePaymentTab(tab)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundList);


