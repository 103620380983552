import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from "@material-ui/core/styles";
const BorderLinearProgress = styled(LinearProgress)(({bgColor}) => ({
  height: 10,
  borderRadius: 5,
  [`&.MuiLinearProgress-colorPrimary`]: {
    background: 'transparent',
  },
  [`& .MuiLinearProgress-bar`]: {
    borderRadius: 5,
    backgroundColor: bgColor
  },
}));

export default BorderLinearProgress;


