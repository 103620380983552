import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { Table, Dropdown, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getEventDetails, updateCommission } from "../../../crud/online-event.crud";
import moment from "moment";
import bookingConfig from "../../../config/booking";
import eventConfig from "../../../config/event";
import ImageModal from './../../common/modal/image-modal';
import LinesEllipsis from 'react-lines-ellipsis';
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
  starWrapper: {
    "& img": {
      marginRight: 8,
    },
  },
  icon: {
    color: "#E12936",
  },
  detailsParagraph: {
    display: "block",
    marginBottom: "5px",
  },
  fullWidth: {
    width: "100%",
  },
  stat: {
    padding: "8px 0px",
    margin: 0,
    fontSize: "18px",
  },
  imageContainer: {
    borderRadius: 8,
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap'
  },
  eachImageWrapper: {
    width: 100,
    display: 'inline-block',
    marginRight: '10px'
  },
  eachImage: {
    height: 100,
    margin: 5,
    cursor: 'pointer',
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    borderRadius: '50%',
    border: '4px solid #fff',
    boxShadow: '6px 14px 31px #dae4f4',
  }
}));

const EventDetails = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { eventId } = useParams();
  const [eventDetailsInfo, setEventDetailsInfo] = useState();
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Confirmed"
  );
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [loading, setLoading] = useState(false);
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  let errors = {
    commissionPercentage: false
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };


  useEffect(() => {
    getEventDetails(eventId).then((output) => {
      setEventDetailsInfo({
        ...eventDetailsInfo,
        ...output.data.data,
      });
      setCommissionPercentage(output.data.data.commissionPercentage)
      statusHandler(output.data.data.status);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  const statusHandler = (status) => {
    setSelectedStatus(Number(status));
    switch (Number(status)) {
      case 1:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 2:
        setSelectedBookingStatus("Confirmed");
        break;
      case 3:
        setSelectedBookingStatus("Completed");
        break;
      case 4:
        setSelectedBookingStatus("Failed");
        break;
      case 5:
        setSelectedBookingStatus("Not Joined");
        break;
      default:
        break;
    }
  };

  const onChangeCommission = (event) => {
    setCommissionPercentage(event.target.value);
  }

  const saveCommissionPercentage = () => {
    setLoading(true);
    updateCommission(eventId, commissionPercentage)
      .then(() => {
        setLoading(false);
        toast.success("Save Commission Percentage is successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  if (!eventDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      {showImagesModal ? <ImageModal show={showImagesModal} images={eventDetailsInfo.images} handleClose={() => setShowImagesModal(false)} /> : null}
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
              <div className={classes.flexBox}>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                  <h5>{eventDetailsInfo.title}</h5>
                  <span className={classes.detailsParagraph}>
                    <LinesEllipsis text={eventDetailsInfo.description}
                      maxLine='3'
                      ellipsis='...'
                      trimRight
                      basedOn='letters'>
                    </LinesEllipsis>
                  </span>
                  <hr />
                  <span className={classes.detailsParagraph}>
                    <span>Fees -&nbsp;</span>
                    <span>Rs. {eventDetailsInfo.eventFees}</span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Date -&nbsp;</span>
                    <span>
                      {moment(eventDetailsInfo.date).format("DD-MMM-YYYY")}
                    </span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Time -&nbsp;</span>
                    <span>{renderSlots(eventDetailsInfo.slots)}</span>
                  </span>
                  <div className={classes.detailsParagraph}>
                    <span>Event Gallery -&nbsp;</span>
                    <div className={`${classes.imageContainer} pl-0 pr-0 pt-2 pb-0`} style={{ margin: 0 }}>
                      {eventDetailsInfo.images.map((each, key) => {
                        return (<div key={key} className={`${classes.eachImageWrapper} pl-0`} onClick={() => setShowImagesModal(true)}>
                          <div className={`${classes.eachImage}`} style={{
                            backgroundImage: `url(${each.url})`
                          }}></div>
                        </div>);
                      })}

                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }} >
              <div className={classes.flexBox}>
                <div className={`mr-3`}>
                  <img
                    src={
                      eventDetailsInfo.vendorRef.personalInfo.profilePicture
                        ? eventDetailsInfo.vendorRef.personalInfo
                          .profilePicture
                        : toAbsoluteUrl(
                          `/media/default-image/default-image.png`
                        )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                  <h5>{eventDetailsInfo.vendorRef.personalInfo.name}</h5>
                  <div className={classes.detailsParagraph}>
                    <LinesEllipsis text={eventDetailsInfo.vendorRef.personalInfo.description
                      ? eventDetailsInfo.vendorRef.personalInfo.description
                      : "N/A"}
                      maxLine='3'
                      ellipsis='...'
                      trimRight
                      basedOn='letters'>
                    </LinesEllipsis>
                  </div>
                  <hr />
                  <div className={classes.detailsParagraph}>
                    <span>Contact no -&nbsp;</span>
                    <span>
                      {eventDetailsInfo.vendorRef.personalInfo.phone.dialCode}
                      {eventDetailsInfo.vendorRef.personalInfo.phone.number}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Email -&nbsp;</span>
                    <span>
                      {eventDetailsInfo.vendorRef.personalInfo.email}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Experience -&nbsp;</span>
                    <span>
                      {
                        eventDetailsInfo.vendorRef.personalInfo.experience
                          .year
                      }{" "}
                      years
                    </span>{" "}
                    &nbsp;
                    {eventDetailsInfo.vendorRef.personalInfo.experience
                      .month ? (
                        <span>
                          {
                            eventDetailsInfo.vendorRef.personalInfo.experience
                              .month
                          }{" "}
                        months
                        </span>
                      ) : null}
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Categories -&nbsp;</span>
                    <span>
                      {eventDetailsInfo.vendorRef.personalInfo.categories.map(
                        (eachCat, key) => {
                          return (
                            <span key={key} className={classes.categoryTag}>
                              {eachCat.title}
                            </span>
                          );
                        }
                      )}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Website -&nbsp;</span>
                    <span>
                      {eventDetailsInfo.vendorRef.personalInfo.website
                        ? eventDetailsInfo.vendorRef.personalInfo.website
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p className={classes.stat}>
                Total Seats: {eventDetailsInfo.numberOfSeats}
              </p>
              <p className={classes.stat}>
                Booked Seats:{" "}
                {eventDetailsInfo.numberOfSeats -
                  eventDetailsInfo.availableSeats}
              </p>
              <p className={classes.stat}>
                Available Seats: {eventDetailsInfo.availableSeats}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.stat}>Commission:
              {[1, 2].includes(eventDetailsInfo.status) ? <div style={{ display: 'inline-block' }}>
                  <Form>
                    <Form.Group controlId="form.onChangeCommission" style={{
                      display: 'inline-block',
                      paddingRight: 16,
                      paddingLeft: 8,
                      margin: 0
                    }}>
                      <Form.Control
                        type="text"
                        value={commissionPercentage}
                        onChange={onChangeCommission}
                        isInvalid={!!errors.commissionPercentage}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.commissionPercentage}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <span>% </span>
                    <Button
                      variant="primary"
                      style={{
                        marginLeft: 8,
                        position: "relative",
                      }}
                      onClick={() => saveCommissionPercentage()}
                      disabled={loading}
                    >
                      {loading && (
                        <CircularProgress size={24} style={{
                          verticalAlign: 'inherit',
                          marginRight: '12px'
                        }} />
                      )}
                      Save
                    </Button>
                  </Form>
                </div> : <span className={classes.stat}> {eventDetailsInfo.commissionPercentage}%</span>}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <div style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                display: 'flex',
                paddingBottom: '12px'
              }}>
                <h4 className="mb-3">Event Bookings</h4>
                <div>
                  <span className="mb-3" style={{
                    display: 'inline-block',
                    fontSize: 16,
                    paddingRight: 16,
                    fontWeight: 500
                  }}>Status</span>
                  <Dropdown style={{ display: 'inline-block' }}>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus ? selectedBookingStatus : ''}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(eventConfig.bookingStatus).slice(1).map((eachStatus, key) => {
                        return (<Dropdown.Item
                          key={key}
                          onClick={() => {
                            statusHandler(eachStatus);
                          }}
                        >
                          {eventConfig.bookingStatus[eachStatus]}
                        </Dropdown.Item>)
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Booking No</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Service Fees</th>
                    <th>Convenience Fees</th>
                    <th>GST</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Time</th>
                  </tr>
                </thead>
                {eventDetailsInfo.eventBookings.filter((e) => e.status === selectedStatus).length ? (
                  <tbody>
                    {eventDetailsInfo.eventBookings.filter((e) => e.status === selectedStatus).map(
                      (eventBookingDoc, key) => {
                        return (
                          <tr key={key}>
                            <td>{eventBookingDoc.bookingNumber}</td>
                            <td>{eventBookingDoc.bookingForName}</td>
                            <td>
                              {
                                eventBookingDoc.customerRef.personalInfo.phone
                                  .dialCode
                              }
                              -
                              {
                                eventBookingDoc.customerRef.personalInfo.phone
                                  .number
                              }
                            </td>

                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.serviceFee}</td>
                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.convenienceFee}</td>
                             <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.gst}</td>
                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.total}</td>
                            <td>
                              {
                                eventConfig.bookingStatus[
                                eventBookingDoc.status
                                ]
                              }
                            </td>
                            <td>
                              {moment(eventBookingDoc.updatedAt).format("DD-MMM-YYYY hh:mm A")}
                            </td>

                          </tr>
                        );
                      }
                    )}
                  </tbody>
                ) : (
                    <tr>
                      <td colSpan={7} align={"center"}>
                        No Booking Found!
                    </td>
                    </tr>
                  )}
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <h4 className="mb-3">Rating & Feedback</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Rating</th>
                    <th>Feedback</th>
                  </tr>
                </thead>
                {eventDetailsInfo.ratingDetails.length ? (
                  <tbody>
                    {eventDetailsInfo.ratingDetails.map(
                      (doc, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Rating defaultValue={doc.rating}
                                size="large"
                                readOnly
                              />
                            </td>
                            <td>
                              {doc.feedback}
                            </td>

                          </tr>
                        );
                      }
                    )}
                  </tbody>
                ) : (
                    <tr>
                      <td colSpan={7} align={"center"}>
                        No Rating and Feedback Found!
                    </td>
                    </tr>
                  )}
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default EventDetails;
