import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Dropdown, Form } from "react-bootstrap";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  listEvent,
  listRequestedEvent,
  approveEventDetails,
  rejectEventDetails,
} from "../../../crud/online-event.crud";

import FullWidthTabs from "../../common/FullWidthTabs";
import ApproveEventModal from "./modal/approve-event-modal";
import RejectEventModal from "./modal/reject-event-modal";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";

import bookingConfig from "../../../config/booking";
import eventConfig from "../../../config/event";
import * as eventDuck from "../../../store/ducks/event.duck";
import * as notification from "../../../store/ducks/notification.duck";
import RemoveModal from "./modal/remove-modal";
import { deleteEventDetails } from "../../../crud/event.crud";
import StyleDropdown from "../../../components/StyleDropdown";
import CustomPagination from "../../../components/CustomPagination";
import { colorsCodes } from "../../../config/constants";
import NoDataFound from "../../../components/NoDataFound";
const EventList = (props) => {
  const {tab} = props.data.notification;
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });
  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedData, setSelectedData] = useState();
  const [page, setPage] = useState(1);
  const [showEventApproval, setShowEventApproval] = useState(false);
  const [showEventApprovalWait, setShowEventApprovalWait] = useState(false);
  const [showEventRejection, setShowEventRejection] = useState(false);
  const [eventName, setEventName] = useState("");
  const [professionalName, setProfessionalName] = useState("");
  const [professionalPhone, setProfessionalPhone] = useState("");
  const [searchType, setSearchType] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Select Status"
  );
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showDeleteEvent, setShowDeleteEvent] = useState(false);
  useEffect(() => {
    reloadList();
    return ()=>{
      props.updateTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  useEffect(() => {
    if (props.data.event.listData) {
      let listObject = {
        data: [...props.data.event.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.event.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.event.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.event.listData]);

  useEffect(() => {
    setPage(1);
    if (currentTab === 0) {
      listEvent({
        skip: 0,
        limit: tableData.limit,
        eventPayload: {
          status: selectedStatus,
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: 0,
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const reloadList = () => {
    if (currentTab === 0) {
      listEvent({
        skip: tableData.skip,
        limit: tableData.limit,
        eventPayload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          // console.log("docs.data.data", docs.data.data);
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
          setShowEventApprovalWait(false);
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: tableData.skip,
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });

          setShowEventApprovalWait(false);
        }
      });
    }
  };

  const handleChange = (_event, value) => {
    setPage(value);
    if (currentTab === 0) {
      listEvent({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        eventPayload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
          setShowEventApprovalWait(false);
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.onlineEvents,
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });

          setShowEventApprovalWait(false);
        }
      });
    }
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowEventApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowEventRejection(true);
  };

  const approveEvent = (eventId) => {
    approveEventDetails(eventId)
      .then(() => {
        toast.success("Event is successfully approved!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reloadList();
      })
      .catch((error) => {
        setShowEventApprovalWait(false);
      });
  };

  const rejectEvent = (eventId) => {
    rejectEventDetails(eventId)
      .then(() => {
        toast.success("Event is successfully rejected!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reloadList();
      })
      .catch((error) => {
        console.log("error", error);
        setShowEventApprovalWait(false);
      });
  };

  const handleApprove = (approve) => {
    if (approve) {
      setShowEventApprovalWait(true);
      approveEvent(selectedData._id);
    } else {
      rejectEvent(selectedData._id);
    }
  };

  const statusHandler = (status) => {
    setSelectedStatus(Number(status));
    switch (Number(status)) {
      case 2:
        setSelectedBookingStatus("Approved");
        break;
      case 3:
        setSelectedBookingStatus("Rejected");
        break;
      case 4:
        setSelectedBookingStatus("Completed");
        break;
      case 5:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 6:
        setSelectedBookingStatus("Failed");
        break;
      case 7:
        setSelectedBookingStatus("Canceled By Vendor");
        break;
      case 8:
        setSelectedBookingStatus("Auto Rejected");
        break;
      default:
        break;
    }
    listEvent({
      skip: tableData.skip,
      limit: tableData.limit,
      eventPayload: {
        status: Number(status),
        searchKey: searchKey,
        searchType: searchType,
        eventType: eventConfig.eventType.onlineEvents,
      },
    }).then((docs) => {
      if (docs.data) {
        // console.log("docs.data.data", docs.data.data);
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      } else {
      }
    });
  };

  const searchList = (bookingPayload) => {
    setPage(1);
    listEvent({
      skip: 0,
      limit: tableData.limit,
      eventPayload: {
        searchKey: bookingPayload.searchKey,
        searchType: bookingPayload.searchType,
        status: bookingPayload.status,
        eventType: bookingPayload.eventType,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleSearch = (name) => (event) => {
    switch (name) {
      case "professionalName":
        setProfessionalName(event.target.value);

        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 1,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        }
        break;
      case "professionalPhone":
        setProfessionalPhone(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 2,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        }
        break;
      case "eventName":
        setEventName(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 3,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.onlineEvents,
          });
        }
        break;
      default:
        break;
    }
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowDeleteEvent(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  const removeEventData = () => {
    deleteEventDetails(selectedData._id)
      .then(() => {
        setShowDeleteEvent(false);
        toast.success("Event is successfully deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reloadList();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 1:
        return colorsCodes.orange;
      case 2:
        return colorsCodes.blue;
      case 3:
        return colorsCodes.red;
      case 4:
        return colorsCodes.green;
      case 5:
        return colorsCodes.orange;
      case 6:
        return colorsCodes.red;
      case 7:
        return colorsCodes.red;
      case 8:
        return colorsCodes.red;
      default:
        return colorsCodes.red;
    }
    
  };

  return (
    <React.Fragment>
      <Backdrop style={{ zIndex: 1 }} open={showEventApprovalWait}>
        <CircularProgress color="inherit" />
        <p style={{ marginLeft: 24, marginTop: 16, fontWeight: 600 }}>
          {" "}
          Please wait. This may take some time.
        </p>
      </Backdrop>
      {showEventApproval ? (
        <ApproveEventModal
          event={selectedData}
          show={showEventApproval}
          handleClose={() => setShowEventApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showEventRejection ? (
        <RejectEventModal
          event={selectedData}
          show={showEventRejection}
          handleClose={() => setShowEventRejection(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showDeleteEvent ? (
        <RemoveModal
          event={selectedData}
          show={showDeleteEvent}
          handleClose={() => setShowDeleteEvent(false)}
          handleDelete={removeEventData}
        />
      ) : null}
      {showDeleteWarning ? (
        <DeleteWarningModal
          title={"event"}
          open={showDeleteWarning}
          handleClose={() => setShowDeleteWarning(false)}
        />
      ) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changeEventTab(tab);
          setCurrentTab(tab);
        }}
        title={"Sessions"}
        tabsTitle={["All", "Pending Verification Requests"]}
      />

      <React.Fragment>
        <Table bordered hover style={{ tableLayout: "fixed" }}>
          <thead className="custom-table-header">
            <tr>
              <th className="custom-table-header-text" style={{fontSize: 12}}>SL No</th>
              {userInfo && userInfo.roleInfo.isSuperAdmin ? <th className="custom-table-header-text" style={{fontSize: 12}}>ID</th> : null}
              <th className="custom-table-header-text" style={{fontSize: 12}}>Event Name</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Professional Name</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Professional Phone</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Date</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Time</th>
              <th className="custom-table-header-text" style={{fontSize: 12, width: 155}}>Status</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentTab === 0 ? (
              <tr>
                <th></th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? <th></th> : null}
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={eventName}
                    onChange={handleSearch("eventName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalName}
                    onChange={handleSearch("professionalName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalPhone}
                    onChange={handleSearch("professionalPhone")}
                  />
                </th>
                <th></th>
                <th></th>
                <th>
                  <StyleDropdown>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus ? selectedBookingStatus : ""}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(eventConfig.status)
                        .slice(1)
                        .map((eachStatus, key) => {
                          return (
                            <Dropdown.Item
                              key={key}
                              onClick={() => {
                                statusHandler(eachStatus);
                              }}
                            >
                              {eventConfig.status[eachStatus]}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </StyleDropdown>
                </th>
                <th></th>
              </tr>
            ) : null}
            {tableData.data.length
              ? tableData.data.map((eachData, key) => {
                  return (
                    <tr key={key} className={
                      key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                    }>
                      <td className="custom-table-body-text" style={{fontSize: 12}}>
                        <Link to={`/event-details/${eachData._id}`}>
                          {tableData.skip + key + 1}
                        </Link>
                      </td>
                      {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                        <td style={{ wordWrap: "break-word" }}>
                          {eachData._id}
                        </td>
                      ) : null}
                      <td className="custom-table-body-text" style={{fontSize: 12}}>{eachData.title}</td>
                      <td className="custom-table-body-text" style={{fontSize: 12}}>{eachData.vendor.personalInfo.name}</td>
                      <td className="custom-table-body-text" style={{fontSize: 12}}>
                        {eachData.vendor.personalInfo.phone.dialCode}-
                        {eachData.vendor.personalInfo.phone.number}
                      </td>
                      <td className="custom-table-body-text" style={{fontSize: 12}}>{moment(eachData.date).format("DD-MMM-YYYY")}</td>
                      <td className="custom-table-body-text" style={{fontSize: 12}}>{renderSlots(eachData.slots)}</td>
                      <td className="custom-table-body-text" style={{fontSize: 12, color: renderStatusColor(eachData.status)}}>{eventConfig.status[eachData.status]}</td>
                      <td className="text-center">
                        {currentTab === 0 ? (
                          <>
                            <Link
                              className="btn btn-icon custom-table-btn"
                              to={`/event-details/${eachData._id}`}
                            >
                              <i className="la la-eye"></i>
                            </Link>
                            {eachData.status===2 && eachData.availableSeats === eachData.numberOfSeats ? <p
                              className="btn btn-icon custom-table-btn"
                              title="Delete"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                openDeleteModal(eachData);
                              }}
                            >
                              <i className="la la-trash kt-font-danger"></i>
                            </p>: null}
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-icon custom-table-btn"
                              title={"Approve"}
                              onClick={() => approvalConsent(eachData)}
                              style={{
                                margin: "0",
                              }}
                              disabled={showEventApprovalWait}
                            >
                              <i className="la la-check-circle"></i>
                            </button>

                            <p
                              className="btn btn-icon custom-table-btn"
                              title="Reject"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                rejectionConsent(eachData);
                              }}
                            >
                              <i className="la la-close kt-font-danger"></i>
                            </p>
                            <Link
                              className="btn btn-icon custom-table-btn"
                              to={`/event-details/${eachData._id}`}
                            >
                              <i className="la la-eye"></i>
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>

        {tableData.data.length === 0 ? (
          <NoDataFound/>
        ) : null}
        {tableData.data.length ? (
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEventTab: (tab) => dispatch(eventDuck.actions.changeEventTab(tab)),
    updateTab: (data) => dispatch(notification.actions.updateTab({selectedTab: data})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
