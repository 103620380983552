import axios from './axios.interceptor';
import api from './api.util';

export const FAQ_URL = `${api}/account/admin/faq`;
export const ADD_FAQ_URL = `${FAQ_URL}/add`;
export const LIST_FAQ_URL = `${FAQ_URL}/list`;

export function addFaq({ title, description, userType }) {
  return axios.post(ADD_FAQ_URL, {
    title,
    description,
    userType
  });
}

export function listFaq({ skip, limit, userType }) {
  return axios.post(`${LIST_FAQ_URL}?skip=${skip}&limit=${limit}`, { userType });
}

export function changeOrderFaq(faqId, payload) {

  return axios.put(`${FAQ_URL}/${faqId}/change-order`, payload);
}


export function editFaq(faqId, payload) {

  return axios.put(`${FAQ_URL}/${faqId}/update`, payload);
}

export function removeFaq(faqId) {
  return axios.delete(`${FAQ_URL}/${faqId}/remove`);
}