import React, { useEffect, useState } from "react";
import {
  getDashBoardData,
} from "../../crud/dashboard.crud";
import CustomerSection from "./dashboard/customer";
import AppointmentSection from "./dashboard/appointment";
import ForumSection from "./dashboard/forum";
import EventSection from "./dashboard/event";
import ProfessionalSection from "./dashboard/professional";
import WorkshopSection from "./dashboard/workshop";
import AssesmentSection from "./dashboard/assesment";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalProfessionalByCategory: "",
    totalProfessional: "",
    totalProfessionalPendingApproval: "",
    totalProfessionalActive: "",
    totalProfessionalInActive: "",
    totalProfessionalToday: "",
    totalProfessionalThisWeek: "",
    totalProfessionalThisMonth: "",

    totalCustomer: "",
    totalCustomerToday: "",
    totalCustomerThisWeek: "",
    totalCustomerThisMonth: "",

    totalEvent: "",
    totalEventPendingApproval: "",
    totalEventUpcoming: "",
    totalEventCompleted: "",
    totalEventCancelled: "",
    totalEventToday: "",
    totalEventThisWeek: "",
    totalEventThisMonth: "",

    totalApprovedForumQuestion: "",
    totalPendingApprovalForumQuestion: "",
    totalForumQuestionToday: "",
    totalForumQuestionThisWeek: "",
    totalAnswersOfForumApprovedQuestions: "",
    totalAnswersOfForumApprovedQuestionsToday: "",
    totalAnswersOfForumApprovedQuestionsThisWeek: "",
    totalAvgAnswersOfForumQuestions: "",

    totalGroupBookedSessionTillDate: "",
    totalGroupCancelledSessionTillDate: "",
    totalGroupReschedulesSessionTillDate: "",
    totalGroupSessions: "",

    totalIndividualBookedSessionTillDate: "",
    totalIndividualBookedSessionUpcoming: "",
    totalIndividualBookedSessionCompleted: "",

    totalTestBookingTillDate: "",
    totalTestBookingToday: "",
    totalTestBookingThisWeek: "",
    totalTestBookingThisMonth: "",
  });

  useEffect(() => {
    (async () => {
      const dashboardOutput = await getDashBoardData();
      dashboardOutput.data &&
        dashboardOutput.data.data &&
        setDashboardData(dashboardOutput.data.data);
    })();
  }, []);
  // const getMonthText = (month) => {
  //   switch (month) {
  //     case 1:
  //       return 'January';
  //     case 2:
  //       return 'February';
  //     case 3:
  //       return 'March';
  //     case 4:
  //       return 'April';
  //     case 5:
  //       return 'May';
  //     case 6:
  //       return 'June';
  //     case 7:
  //       return 'July';
  //     case 8:
  //       return 'August';
  //     case 9:
  //       return 'September';
  //     case 10:
  //       return 'October';
  //     case 11:
  //       return 'November';
  //     case 12:
  //       return 'December'
  //     default:
  //       return '';
  //   }
  // }
 
  return (
    <>
      <ProfessionalSection dashboardData={dashboardData}/>
      <CustomerSection dashboardData={dashboardData}/>
      <AppointmentSection dashboardData={dashboardData}/>
      <ForumSection dashboardData={dashboardData}/>
      <EventSection dashboardData={dashboardData}/>
      <WorkshopSection dashboardData={dashboardData}/>
      <AssesmentSection dashboardData={dashboardData}/>

      {/* <div className="row">
        <div className="col">
          <div style={{ paddingBottom: 12 }}>
            <h3>Sessions (Group Session)</h3>
          </div>
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalGroupBookedSessionTillDate}
                    desc="Booked (till date)"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalGroupCancelledSessionTillDate}
                    desc="Cancelled (till date)"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalGroupReschedulesSessionTillDate}
                    desc="Rescheduled (till date)"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalGroupSessions}
                    desc="Today Sessions"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
