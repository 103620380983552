import React from 'react';
import { Form, Button } from "react-bootstrap";
// import NumberFormat from "react-number-format";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const PromoCodeInfo = ({ state, handleChangeAllowPromoDiscount, _handleChange, savePromoCodeInfo }) => {

    return (<div style={{ marginBottom: 0 }}>
        <Form.Label>Promo Code Information</Form.Label>
        <FormControl component="fieldset" style={{ display: 'block' }}>
            <FormLabel component="legend">Allow Discount</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={state.allowDiscount} onChange={handleChangeAllowPromoDiscount} name="allowDiscount" />}
                    label={state.allowDiscount ? "Yes" : "No"}
                />
            </FormGroup>
        </FormControl>
        {/* {
            state.allowDiscount ?
                <FormControl component="fieldset" style={{ display: 'block' }}>
                    <FormLabel component="legend">Discount Percentage</FormLabel>
                    <NumberFormat value={state.discountPercentage} onChange={handleChange} style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 8,
                        paddingRight: 8,
                        marginTop: 6
                    }} />
                </FormControl>
                : null
        } */}
        <Button style={{ marginTop: 16 }} variant="primary" onClick={savePromoCodeInfo}> Save </Button>
    </div>)
};

export default PromoCodeInfo;