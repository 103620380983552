import {listOrganization} from "../../crud/organization.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    ORGANIZATION_ADDED: "ORGANIZATION_ADDED",
    ORGANIZATION_ADDED_SUCCESS: "ORGANIZATION_ADDED_SUCCESS",
    ORGANIZATION_UPDATED: "ORGANIZATION_UPDATED",
    ORGANIZATION_UPDATED_SUCCESS: "ORGANIZATION_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.ORGANIZATION_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    organizationAdded: ({skip, limit}) => ({
        type: actionTypes.ORGANIZATION_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    organizationUpdated: ({skip, limit}) => ({
        type: actionTypes.ORGANIZATION_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.ORGANIZATION_ADDED, function * organizationAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listOrganization({skip, limit});

        yield put({type: actionTypes.ORGANIZATION_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.ORGANIZATION_UPDATED, function * organizationUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listOrganization({skip, limit});

        yield put({type: actionTypes.ORGANIZATION_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
