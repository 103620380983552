/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import objectPath from "object-path";
import { withRouter } from "react-router-dom";
import { LayoutContextConsumer } from "../LayoutContext";
import * as builder from "../../ducks/builder";
import CreateRole from "./../../../app/pages/home/role-management/create-role";
import CreateVendorCategory from "./../../../app/pages/home/vendor-category-management/create-vendor-category";
import CreateVendorPage from "./../../../app/pages/home/vendor-management/create-vendor-page";
import CreateProblemCategory from "./../../../app/pages/home/problem-category-management/create-problem-category";
import CreateProblem from "./../../../app/pages/home/problem/create-problem";
import CreateLanguage from "./../../../app/pages/home/language-management/create-language";
import CreateFaq from "./../../../app/pages/home/faq/create-faq";
import CreateAppsTutorial from "./../../../app/pages/home/apps-tutorial/create-apps-tutorial";
import CreateOrganization from "../../../app/pages/home/organization-management/create-organization";
import CreateInstitution from "../../../app/pages/home/institution-management/create-institution";
import CreateSubAdmin from "./../../../app/pages/home/sub-admin-management/create-sub-admin";
import CreateForum from "../../../app/pages/home/forum-management/create-forum";
const SubHeader = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {
    subheaderCssClasses,
    subheaderContainerCssClasses,
    subheaderMobileToggle,
  } = props;
  const [showAddRole, setShowAddRole] = useState(false);
  const [showVendorAddCategory, setShowVendorAddCategory] = useState(false);
  const [showVendorAdd, setShowVendorAdd] = useState(false);
  const [showProblemAddCategory, setShowProblemAddCategory] = useState(false);
  const [showProblemAdd, setShowProblemAdd] = useState(false);
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const [showAddFaq, setShowAddFaq] = useState(false);
  const [showAddAppsTutorial, setShowAddAppsTutorial] = useState(false);
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [showAddInstitution, setShowAddInstitution] = useState(false);
  const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
  const [showAddForum, setShowAddForum] = useState(false);
  const [currentPageName, setCurrentPageName] = useState("");
  const handleClose = (page) => {
    switch (page) {
      case "role":
        setShowAddRole(false);
        break;
      case "subAdmin":
        setShowAddSubAdmin(false);
        break;
      case "organizations":
        setShowAddOrganization(false);
        break;
      case "institutions":
        setShowAddInstitution(false);
        break;
      case "professional-category":
        setShowVendorAddCategory(false);
        break;
      case "professionals":
        setShowVendorAdd(false);
        break;
      case "languages":
        setShowAddLanguage(false);
        break;
      case "problemCategory":
        setShowProblemAddCategory(false);
        break;
      case "problems":
        setShowProblemAdd(false);
        break;
      case "faq":
        setShowAddFaq(false);
        break;
      case "tutorials":
        setShowAddAppsTutorial(false);
        break;
      case "forums":
        setShowAddForum(false);
        break;
      default:
        break;
    }
  };

  const handleShow = (page) => {
    switch (page) {
      case "role":
        setShowAddRole(true);
        break;
      case "subAdmin":
        setShowAddSubAdmin(true);
        break;
      case "organizations":
        setShowAddOrganization(true);
        break;
      case "institutions":
        setShowAddInstitution(true);
        break;
      case "professional-category":
        setShowVendorAddCategory(true);
        break;
      case "professionals":
        setShowVendorAdd(true);
        break;
      case "languages":
        setShowAddLanguage(true);
        break;
      case "problemCategory":
        setShowProblemAddCategory(true);
        break;
      case "problems":
        setShowProblemAdd(true);
        break;
      case "faq":
        setShowAddFaq(true);
        break;
      case "tutorials":
        setShowAddAppsTutorial(true);
        break;
      case "forums":
        setShowAddForum(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf("appointment-details") !== -1) {
      setCurrentPageName("Appointment Details");
    } else if (location.pathname.indexOf("forum-details") !== -1) {
      setCurrentPageName("Forum Details");
    } else if (location.pathname.indexOf("payment-details") !== -1) {
      setCurrentPageName("Payment Details");
    } else if (location.pathname.indexOf("support-details") !== -1) {
      setCurrentPageName("Support Details");
    } else if (location.pathname.indexOf("session-details") !== -1) {
      setCurrentPageName("Session Details");
    } else if (location.pathname.indexOf("event-details") !== -1) {
      setCurrentPageName("Event Details");
    } else if (location.pathname.indexOf("workshop-details") !== -1) {
      setCurrentPageName("Workshop Details");
    } else if (location.pathname.indexOf("edit-test-details") !== -1) {
      setCurrentPageName("Edit Test Details");
    } else if (location.pathname.indexOf("test-details") !== -1) {
      setCurrentPageName("Details of self assessments");
    } else {
      setCurrentPageName("");
    }
    return () => { };
  }, [location.pathname, setCurrentPageName]);

  return (
    <div
      id="kt_subheader"
      className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
    >
      {showAddRole ? (
        <CreateRole show={showAddRole} handleClose={handleClose} />
      ) : null}
      {showVendorAddCategory ? (
        <CreateVendorCategory
          show={showVendorAddCategory}
          handleClose={handleClose}
        />
      ) : null}
      {showVendorAdd ? (
        <CreateVendorPage show={showVendorAdd} handleClose={handleClose} />
      ) : null}
      {setShowAddOrganization ? (
        <CreateOrganization
          show={showAddOrganization}
          handleClose={handleClose}
        />
      ) : null}
      {setShowAddInstitution ? (
        <CreateInstitution
          show={showAddInstitution}
          handleClose={handleClose}
        />
      ) : null}
      {showAddSubAdmin ? (
        <CreateSubAdmin show={showAddSubAdmin} handleClose={handleClose} />
      ) : null}
      {showProblemAddCategory ? (
        <CreateProblemCategory
          show={showProblemAddCategory}
          handleClose={handleClose}
        />
      ) : null}
      {showProblemAdd ? (
        <CreateProblem show={showProblemAdd} handleClose={handleClose} />
      ) : null}
      {showAddLanguage ? (
        <CreateLanguage show={showAddLanguage} handleClose={handleClose} />
      ) : null}
      {showAddFaq ? (
        <CreateFaq show={showAddFaq} handleClose={handleClose} />
      ) : null}
      {showAddAppsTutorial ? (
        <CreateAppsTutorial show={showAddAppsTutorial} handleClose={handleClose} />
      ) : null}
      {showAddForum ? (
        <CreateForum show={showAddForum} handleClose={handleClose} />
      ) : null}
      <div className={`kt-container ${subheaderContainerCssClasses}`}>
        <div className="kt-subheader__main">
          {subheaderMobileToggle && (
            <button
              className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <LayoutContextConsumer>
            {({ subheader: { title } }) => (
              <>
              {currentPageName ===
                        "Forum Details" ||
                        currentPageName === "Payment Details" ||
                        currentPageName === "Support Details" ||
                        currentPageName === "Session Details" ||
                        currentPageName === "Event Details" ||
                        currentPageName === "Workshop Details" ||
                        currentPageName === "Appointment Details" ||
                        currentPageName === "Edit Test Details" ||
                        currentPageName === "Details of self assessments" ? (
                        <a
                          className="btn"
                          onClick={history.goBack}
                        >
                          <i className="fa fa-chevron-left"/>
                        </a>): null}
                <h3 className="kt-subheader__title">
                  {title
                    ? title
                    : location.state
                      ? location.state.title
                      : currentPageName}
                </h3>
              </>
            )}
          </LayoutContextConsumer>
        </div>

        <div className="kt-subheader__toolbar">
          <div className="kt-subheader__wrapper">
            <LayoutContextConsumer>
              {({ subheader: { title } }) => (
                <Fragment>
                  {(title &&
                    (title === "Dashboard" ||
                      title === "Reports" ||
                      title === "Appointments" ||
                      title === "Settlements" ||
                      title === "Refunds" ||
                      title === "Global Setting" ||
                      title === "Supports" ||
                      title === "Sessions" ||
                      title === "Workshops" ||
                      title === "Events" ||
                      title === "User" ||
                      title === "Send Email" ||
                      title === "Setup Tests" ||
                      title === "Tests" || 
                      title === "Customer Supports" || 
                      title === "Revenue Calculator" || 
                      title === "Notifications")) ? null : title ? (
                        <a
                          onClick={() => {
                            switch (title) {
                              case "Role":
                                handleShow("role");
                                break;
                              case "Sub Admin":
                                handleShow("subAdmin");
                                break;
                              case "Organizations":
                                handleShow("organizations");
                                break;
                              case "Institutions":
                                handleShow("institutions");
                                break;
                              case "Professional Category":
                                handleShow("professional-category");
                                break;
                              case "Professionals":
                                handleShow("professionals");
                                break;
                              case "Languages":
                                handleShow("languages");
                                break;
                              case "Problem Category":
                                handleShow("problemCategory");
                                break;
                              case "Problem Areas":
                                handleShow("problems");
                                break;
                              case "FAQ":
                                handleShow("faq");
                                break;
                              case "Tutorials":
                                handleShow("tutorials");
                                break;
                              case "Forums":
                                handleShow("forums");
                                break;
                              default:
                                break;
                            }
                          }}
                          className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
                        >
                          Add New
                        </a>
                      ) : null}
                </Fragment>
              )}
            </LayoutContextConsumer>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  subheaderMobileToggle: objectPath.get(
    store.builder.layoutConfig,
    "subheader.mobile-toggle"
  ),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: "subheader",
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: "subheader_container",
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
