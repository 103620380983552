import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Dropdown, Form } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  listEvent,
  listRequestedEvent,
  approveEventDetails,
  rejectEventDetails,
} from "./../../../crud/event.crud";

import FullWidthTabs from "./../../common/FullWidthTabs";
import ApproveSessionModal from "./modal/approve-session-modal";
import RejectSessionModal from "./modal/reject-session-modal";

import bookingConfig from "././../../../config/booking";
import eventConfig from "./../../../config/event";
import * as eventDuck from "../../../store/ducks/event.duck";
const SessionList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });
  const [currentTab, setCurrentTab] = useState(props.data.event.currTab);
  const [selectedData, setSelectedData] = useState();
  const [page, setPage] = useState(1);
  const [showEventApproval, setShowEventApproval] = useState(false);
  const [showEventRejection, setShowEventRejection] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [professionalName, setProfessionalName] = useState("");
  const [professionalPhone, setProfessionalPhone] = useState("");
  const [searchType, setSearchType] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Select Status"
  );
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.event.listData) {
      let listObject = {
        data: [...props.data.event.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.event.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.event.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.event.listData]);

  useEffect(() => {
    setPage(1);
    if (currentTab === 0) {
      listEvent({
        skip: 0,
        limit: tableData.limit,
        eventPayload: {
          status: selectedStatus,
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: 0,
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const reloadList = () => {
    if (currentTab === 0) {
      listEvent({
        skip: tableData.skip,
        limit: tableData.limit,
        eventPayload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          console.log("docs.data.data", docs.data.data);
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        } else {
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: tableData.skip,
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
  };

  const handleChange = (_event, value) => {
    setPage(value);
    if (currentTab === 0) {
      listEvent({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        eventPayload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
        }
      });
    } else if (currentTab === 1) {
      listRequestedEvent({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        eventPayload: {
          eventType: eventConfig.eventType.sessions
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
        }
      });
    }
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowEventApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowEventRejection(true);
  };

  const approveEvent = (eventId) => {
    approveEventDetails(eventId).then(() => {
      toast.success("Session is successfully approved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const rejectEvent = (eventId) => {
    rejectEventDetails(eventId).then(() => {
      toast.success("Session is successfully rejected!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const handleApprove = (approve) => {
    if (approve) {
      approveEvent(selectedData._id);
    } else {
      rejectEvent(selectedData._id);
    }
  };

  const statusHandler = (status) => {
    setSelectedStatus(status);
    switch (status) {
      case 2:
        setSelectedBookingStatus("Approved");
        break;
      case 4:
        setSelectedBookingStatus("Completed");
        break;
      case 3:
        setSelectedBookingStatus("Rejected");
        break;
      default:
        break;
    }
    listEvent({
      skip: tableData.skip,
      limit: tableData.limit,
      eventPayload: {
        status: status,
        searchKey: searchKey,
        searchType: searchType,
        eventType: eventConfig.eventType.sessions
      },
    }).then((docs) => {
      if (docs.data) {
        console.log("docs.data.data", docs.data.data);
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      } else {
      }
    });
  };

  const searchList = (bookingPayload) => {
    setPage(1)
    listEvent({
      skip: 0,
      limit: tableData.limit,
      eventPayload: {
        searchKey: bookingPayload.searchKey,
        searchType: bookingPayload.searchType,
        status: bookingPayload.status,
        eventType: bookingPayload.eventType
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleSearch = (name) => (event) => {
    switch (name) {
      case "professionalName":
        setProfessionalName(event.target.value);

        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 1,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        }
        break;
      case "professionalPhone":
        setProfessionalPhone(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 2,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        }
        break;
      case "sessionName":
        setSessionName(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 3,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            eventType: eventConfig.eventType.sessions
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {showEventApproval ? (
        <ApproveSessionModal
          session={selectedData}
          show={showEventApproval}
          handleClose={() => setShowEventApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showEventRejection ? (
        <RejectSessionModal
          session={selectedData}
          show={showEventRejection}
          handleClose={() => setShowEventRejection(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changeEventTab(tab);
          setCurrentTab(tab);
        }}
        title={"Sessions"}
        tabsTitle={["All", "Pending Verification Requests"]}
      />

      <React.Fragment>
        <Table striped bordered hover style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th>SL No</th>
              <th>Session Name</th>
              <th>Professional Name</th>
              <th>Professional Phone</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentTab === 0 ? (
              <tr>
                <th></th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={sessionName}
                    onChange={handleSearch("sessionName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalName}
                    onChange={handleSearch("professionalName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalPhone}
                    onChange={handleSearch("professionalPhone")}
                  />
                </th>
                <th></th>
                <th></th>
                <th>
                  <Dropdown>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          statusHandler(2);
                        }}
                      >
                        Approved
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          statusHandler(4);
                        }}
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          statusHandler(3);
                        }}
                      >
                        Rejected
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th></th>
              </tr>
            ) : null}
            {tableData.data.length
              ? tableData.data.map((eachData, key) => {
                return (
                  <tr key={key}>
                    <td style={{ wordWrap: "break-word" }}>
                      <Link to={`/session-details/${eachData._id}`}>
                        {eachData._id}
                      </Link>
                    </td>
                    <td>{eachData.title}</td>
                    <td>{eachData.vendor.personalInfo.name}</td>
                    <td>
                      {eachData.vendor.personalInfo.phone.dialCode}-
                        {eachData.vendor.personalInfo.phone.number}
                    </td>
                    <td>{moment(eachData.date).format("DD-MMM-YYYY")}</td>
                    <td>{renderSlots(eachData.slots)}</td>
                    <td>{eventConfig.status[eachData.status]}</td>
                    <td className="text-center">
                      {currentTab === 0 ? (
                        <>
                          <Link
                            className="btn btn-sm btn-clean btn-icon-md"
                            to={`/session-details/${eachData._id}`}
                          >
                            <i className="la la-eye"></i>
                          </Link>
                        </>
                      ) : (
                          <>
                            <p
                              className="btn btn-sm btn-clean btn-icon btn-icon-md"
                              title={"Approve"}
                              onClick={() => approvalConsent(eachData)}
                              style={{
                                margin: "0",
                              }}
                            >
                              <i className="la la-check-circle"></i>
                            </p>

                            <p
                              className="btn btn-sm btn-clean btn-icon btn-icon-md"
                              title="Reject"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                rejectionConsent(eachData);
                              }}
                            >
                              <i className="la la-close kt-font-danger"></i>
                            </p>
                            <Link
                              className="btn btn-sm btn-clean btn-icon-md"
                              to={`/session-details/${eachData._id}`}
                            >
                              <i className="la la-eye"></i>
                            </Link>
                          </>
                        )}
                    </td>
                  </tr>
                );
              })
              : null}
          </tbody>
        </Table>

        {tableData.data.length === 0 ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              fontSize: 20,
              height: "65%",
            }}
          >
            No Data Available
          </p>
        ) : null}
        {tableData.data.length ? (
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEventTab: (tab) => dispatch(eventDuck.actions.changeEventTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);
