import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import { listBookedTestPaper } from "../../../crud/test-paper.crud";
import { Card, CardContent } from "@material-ui/core";

const AssesmentDetails = (props) => {
    const { customerId } = useParams();
    const [tableData, setTableData] = useState({
        data: [],
        skip: 0,
        limit: 5,
        total: 0,
        totalPage: 0,
    });

    const [page, setPage] = useState(1);

    useEffect(() => {

        reloadList({
            customerRef: customerId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.data.testPaper.listData) {
            let listObject = {
                data: [...props.data.testPaper.listData.data.data.data],
                skip: tableData.skip,
                limit: tableData.limit,
                total: props.data.testPaper.listData.data.data.total,
                totalPage: Math.ceil(
                    Number(props.data.testPaper.listData.data.data.total / tableData.limit)
                ),
            };
            //   console.log("listObject", listObject);
            setTableData({
                ...tableData,
                ...listObject,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.testPaper.listData]);

    const reloadList = (payload) => {
        listBookedTestPaper({
            skip: tableData.skip,
            limit: tableData.limit,
            payload: {
                customerRef: payload.customerRef,
            },
        }).then((docs) => {
            if (docs.data) {
                setTableData({
                    data: [...docs.data.data.data],
                    skip: tableData.skip,
                    limit: tableData.limit,
                    total: 0,
                    totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
                });
            }
        });
    };

    const handleChange = (_event, value) => {
        setPage(value);

        listBookedTestPaper({
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            payload: {
                customerRef: customerId,
            },
        }).then((docs) => {
            let listObject = {
                data: [...docs.data.data.data],
                skip: Number((value - 1) * tableData.limit),
                limit: tableData.limit,
                total: docs.data.data.total,
                totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
            };

            setTableData({
                ...tableData,
                ...listObject,
            });
        });
    };

    // const renderBookingForType = (type) => {
    //     switch (type) {
    //         case 1:
    //             return 'Self';
    //         case 2:
    //             return 'Parents';
    //         case 3:
    //             return 'Guardian';
    //         case 4:
    //             return 'Other';
    //         default:
    //             return 'N/A';
    //     }
    // };

    // const renderGender = (gender) => {
    //     switch (gender) {
    //         case 1:
    //             return 'Male';
    //         case 2:
    //             return 'Female';
    //         case 3:
    //             return 'Other';
    //         default:
    //             return 'N/A';
    //     }
    // };


    return (<React.Fragment>
        {tableData.data.length ?
            <Card>
                <CardContent>
                    Booked by user: {tableData.data[0].customerRef.personalInfo.name},  {`${tableData.data[0].customerRef.personalInfo.phone.dialCode}-${tableData.data[0].customerRef.personalInfo.phone.number}`}
                </CardContent>
            </Card> : null}
        <Table striped bordered hover style={{ tableLayout: "fixed", marginTop: 8 }}>
            <thead>
                <tr>
                    <th style={{ verticalAlign: "top" }}>Booked For</th>
                    <th style={{ verticalAlign: "top" }}>Age</th>
                    {/* <th style={{ verticalAlign: "top" }}>Phone Number</th> */}
                    <th style={{ verticalAlign: "top" }}>Assesment Category Name</th>
                    <th style={{ verticalAlign: "top" }}>Assesment Title</th>
                    <th style={{ verticalAlign: "top" }}>Assesment Taken On</th>
                    <th style={{ verticalAlign: "top" }}>Assesment Score</th>
                    <th style={{ verticalAlign: "top" }}>Assesment Report Card Color</th>
                </tr>
            </thead>
            <tbody>
                {tableData.data.length
                    ? tableData.data.map((eachData, key) => {
                        const renderReport = (state) => {
                            switch (state) {
                                case 1:
                                    return <div style={{
                                        backgroundColor: '#0CBB71',
                                        height: 36,
                                        width: '100%',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontSize: 16,
                                        padding: 6,
                                        fontWeight: 600
                                    }}>LOW</div>;
                                case 2:
                                    return <div style={{
                                        backgroundColor: '#F3A243',
                                        height: 36,
                                        width: '100%',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontSize: 16,
                                        padding: 6,
                                        fontWeight: 600
                                    }}>MEDIUM</div>;
                                case 3:
                                    return <div style={{
                                        backgroundColor: '#F31630',
                                        height: 36,
                                        width: '100%',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontSize: 16,
                                        padding: 6,
                                        fontWeight: 600
                                    }}>HIGH</div>;
                                default:
                                    return <div style={{
                                        height: 36,
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: 16,
                                        padding: 6,
                                        fontWeight: 600
                                    }}>N/A</div>;
                            }
                        };
                        return (
                            <tr key={eachData._id}>
                                <td>
                                    {eachData.bookingForName
                                        ? eachData.bookingForName
                                        : "N/A"}
                                </td>
                                <td>
                                    {eachData.age}
                                </td>
                                <td>
                                    {eachData.testRef.category.length
                                        ? eachData.testRef.category.map((each, i, { length }) => {
                                            if (i + 1 === length) {
                                                return (<span key={i}>{each.title}</span>);
                                            } else {
                                                return (<span key={i}>{each.title}, </span>);
                                            }
                                        })
                                        : "N/A"}
                                </td>
                                <td>
                                    {eachData.testRef.title}
                                </td>
                                <td>
                                    {moment(eachData.updatedAt).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                </td>
                                <td>
                                    {eachData.result ? eachData.result.score : 'N/A'}
                                </td>
                                <td>
                                    {eachData.result ? renderReport(eachData.result.state) : 'N/A'}
                                </td>
                            </tr>
                        );
                    })
                    : null}
            </tbody>
        </Table>

        {tableData.data.length === 0 ? (
            <p
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: 20,
                    height: "65%",
                }}
            >
                No Data Available
            </p>
        ) : null}
        {tableData.data.length ? <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
        /> : null}
    </React.Fragment>)
};
const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentDetails);
