const paymentConfig = {
    searchType: {
        vendor: 1,
        organiser: 2,
        onlineEvent: 3,
        institution: 4,
        workshop: 5
    },
    'pendingTransactionStatus': {
        1: 'Success',
        2: 'Failed',
        3: 'Pending'
    },
    'pendingRefundStatusServer': {
        refunded: 1,
        failed: 2,
        initiated: 3,
        notInitiated: 4,
        manualRefund: 5
      },
    'pendingRefundStatusWeb':{
        1: 'Auto Refunded',
        2: 'Failed', // --> Mark Paid
        3: 'Pending', // --> Check Current Status 
        4: 'Failed', // -> Mark Paid
        5: 'Manually Refunded',
    }
};

export default paymentConfig;