import React, { useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import VisibilityConfirmModal from '../modal/visibility-confirm-modal';
export default function ProfileVisibilityForAppointment({ state, handleChange }) {
    const [open, setOpen] = useState(false);
    const [isOnline, setIsOnline] = useState(state.isOnline);

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        handleChange(isOnline);
    };

    const handleChangeVisibility = (event) => {
        setOpen(true);
        setIsOnline(event.target.checked);
    };

    return (
        <div>
            <VisibilityConfirmModal open={open} handleClose={handleClose} handleConfirm={handleConfirm} isOnline={state.isOnline} />
            <FormControl component="fieldset">
                <FormLabel component="legend">Appointment Booking feature</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={state.isOnline} onChange={handleChangeVisibility} name="isOnline" />}
                        label={state.isOnline ? "Enabled" : "Disabled"}
                    />
                </FormGroup>
            </FormControl>
        </div>

    );
}
