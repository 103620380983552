import { listTestPaper } from "../../crud/test-paper.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
    TEST_PAPER_UPDATED: "TEST_PAPER_UPDATED",
    TEST_PAPER_UPDATED_SUCCESS: "TEST_PAPER_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.TEST_PAPER_UPDATED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        default:
            return state;
    }
};

export const actions = {
    testPaperUpdated: ({ skip, limit }) => ({
        type: actionTypes.TEST_PAPER_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function* saga() {

    yield takeLatest(actionTypes.TEST_PAPER_UPDATED, function* testPaperUpdated(action) {
        const { skip, limit } = action.payload;
        const listData = yield listTestPaper({ skip, limit });

        yield put({
            type: actionTypes.TEST_PAPER_UPDATED_SUCCESS, payload: {
                listData
            }
        });
    });
}
