import {listSubAdmin} from "../../crud/sub-admin.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    SUB_ADMIN_ADDED: "SUB_ADMIN_ADDED",
    SUB_ADMIN_ADDED_SUCCESS: "SUB_ADMIN_ADDED_SUCCESS",
    SUB_ADMIN_UPDATED: "SUB_ADMIN_UPDATED",
    SUB_ADMIN_UPDATED_SUCCESS: "SUB_ADMIN_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.SUB_ADMIN_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.SUB_ADMIN_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    subAdminAdded: ({skip, limit}) => ({
        type: actionTypes.SUB_ADMIN_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    subAdminUpdated: ({skip, limit}) => ({
        type: actionTypes.SUB_ADMIN_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.SUB_ADMIN_ADDED, function * subAdminAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listSubAdmin({skip, limit});

        yield put({type: actionTypes.SUB_ADMIN_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.SUB_ADMIN_UPDATED, function * subAdminUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listSubAdmin({skip, limit});

        yield put({type: actionTypes.SUB_ADMIN_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
