import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomColumnBarChart from "./component/CustomColumnBarChart";
import CustomDateRange from "./component/CustomDateRange";
import CustomUserCard from "./component/CustomUserCard";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";
import SectionHoc from "./component/SectionHoc";
import StyleDropdown from "../../../components/StyleDropdown";
import CustomViewScroll from "./component/CustomViewScroll";
import {
  getThisWeekCustomerData,
  getThisMonthCustomerData,
  getThisCustomDateRangeCustomerData,
} from "./../../../crud/dashboard.crud";
import { recentListUser } from "./../../../crud/user.crud";
import NoDataFound from "./component/NoDataFound";

const CustomerSection = ({ dashboardData }) => {
  const [searchCustomerType, setSearchCustomerType] = useState({
    type: 1,
    text: " This Week Registration",
  });
  const [thisWeekCustomerData, setThisWeekCustomerData] = useState();
  const [thisMonthCustomerData, setThisMonthCustomerData] = useState();
  const [
    thisCustomDateRangeCustomerData,
    setThisCustomDateRangeCustomerData,
  ] = useState();

  const [recentCustomersData, setRecentCustomersData] = useState([]);

  const fetchCustomerWeekly = async () => {
    const output = await getThisWeekCustomerData();
    output.data &&
      output.data.data &&
      setThisWeekCustomerData(output.data.data);
  };

  const fetchCustomerMonthly = async () => {
    const output = await getThisMonthCustomerData();
    output.data &&
      output.data.data &&
      setThisMonthCustomerData(output.data.data);
  };

  const fetchCustomerCustom = async (startDate, endDate) => {
    const output = await getThisCustomDateRangeCustomerData({
      startDate,
      endDate,
    });
    output.data &&
      output.data.data &&
      setThisCustomDateRangeCustomerData(output.data.data);
  };

  const getTotalCustomers = () => {
    switch (searchCustomerType.type) {
      case 1:
        return thisWeekCustomerData?.totalCustomerThisWeek;
      case 2:
        return thisMonthCustomerData?.totalCustomerThisMonth;
      case 3:
        return thisCustomDateRangeCustomerData?.totalCustomerThis;
      default:
        return 0;
    }
  };

  const getCustomersChartData = () => {
    switch (searchCustomerType.type) {
      case 1:
        return thisWeekCustomerData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      case 2:
        return thisMonthCustomerData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      case 3:
        return thisCustomDateRangeCustomerData?.chartData?.map((each) => {
          return {
            name: each._id,
            y: each.count,
          };
        });
      default:
        return 0;
    }
  };

  useEffect(() => {
    (async () => {
      const recentCustomersData = await recentListUser({
        skip: 0,
        limit: 20,
      });
      recentCustomersData.data &&
        recentCustomersData.data.data &&
        setRecentCustomersData(recentCustomersData.data.data.data);
      await fetchCustomerWeekly();
    })();
  }, []);

  useEffect(() => {
    switch (searchCustomerType.type) {
      case 1:
        fetchCustomerWeekly();
        break;
      case 2:
        fetchCustomerMonthly();
        break;
      case 3:
        fetchCustomerCustom(new Date(), new Date());
        break;
      default:
        break;
    }
  }, [searchCustomerType]);

  return (
    <DashboardCardHOC
      title={"Customers"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL CUSTOMERS"
              value={dashboardData.totalCustomer}
              iconImg="User"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #2699FB 0%, #1886E4 43%, #046CC5 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="TODAY'S REGISTRATION"
              value={dashboardData.totalCustomerToday}
              iconImg="User"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="THIS WEEK"
              value={dashboardData.totalCustomerThisWeek}
              iconImg="User"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #1DE1EB 0%, #00F1FF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="THIS MONTH"
              value={dashboardData.totalCustomerThisMonth}
              iconImg="User"
              bgStyle={{
                background:
                  "transparent linear-gradient(180deg, #F26522 0%, #F26522 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              type="ViewMore"
              desc="VIEW DATA"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #6692FC 0%, #608FFF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="users"
            />
          </div>
        </div>
      }
      collapseContent={
        <div>
          <div className="row">
            <div className="col-lg-7">
              <SectionHoc
                leftContent={
                  <>
                    <StyleDropdown style={{ padding: "7px 16px" }}>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {searchCustomerType.text}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchCustomerType({
                              type: 1,
                              text: "This Week Registration",
                            });
                          }}
                        >
                          This Week Registration
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchCustomerType({
                              type: 2,
                              text: "This Month Registration",
                            });
                          }}
                        >
                          This Month Registration
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSearchCustomerType({
                              type: 3,
                              text: "Custom",
                            });
                          }}
                        >
                          Custom
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </StyleDropdown>
                  </>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    Total {getTotalCustomers()}
                  </p>
                }
              >
                {searchCustomerType.type === 3 ? (
                  <CustomDateRange viewData={fetchCustomerCustom} />
                ) : null}
                {(searchCustomerType.type === 1 &&
                  thisWeekCustomerData &&
                  thisWeekCustomerData.chartData.length) ||
                (searchCustomerType.type === 2 &&
                  thisMonthCustomerData &&
                  thisMonthCustomerData.chartData.length) ||
                (searchCustomerType.type === 3 &&
                  thisCustomDateRangeCustomerData &&
                  thisCustomDateRangeCustomerData.chartData.length) ? (
                  <CustomColumnBarChart data={getCustomersChartData()} />
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-5">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    Recent Customers
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/users">VIEW ALL</Link>
                  </p>
                }
              >
                {recentCustomersData && recentCustomersData.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={recentCustomersData.length > 14}
                  >
                    <div className="row row-cols-2">
                      {recentCustomersData.map((each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomUserCard
                              userType="customer"
                              data={{
                                id: each._id,
                                name: each.personalInfo?.name,
                              }}
                              color="#fef8e7"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CustomerSection;
