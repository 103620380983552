import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import {Form, Button} from "react-bootstrap";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {injectIntl} from "react-intl";
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {listLanguage} from '../../../crud/language.crud';
import {addCategory} from '../../../crud/problem-category.crud';
import * as problemCategory from "../../../store/ducks/problem-category.duck";
import PCLanguageInputCard from "../../common/languageProblemCategory/PCLanguageInputCard";
import PCAddLanguageInputCard from "../../common/languageProblemCategory/PCAddLanguageInputCard";
const CreateProblemCategory = (props) => {
    const {classes} = props;
    const [errors,
        setErrors] = useState({title: null, icon: null});
    const [loading,
        setLoading] = useState(false);
    const [title,
        setTitle] = useState('');
    const [languages,
        setLanguages] = useState([]);

    const [pcLang,
        setPCLang] = useState([
        {
            languageRef: '',
            title: '',
            description: ''
        }
    ]);

    const problemAdd = () => {
        setPCLang([
            ...pcLang, {
                languageRef: '',
                title: '',
                description: ''
            }
        ]);
    };

    const removePCLangInput = (index) => {
        let t = [...pcLang];
        t.splice(index, 1);
        setPCLang([...t]);
    };

    const updatePCLangInput = (index, payload) => {
        let t = [...pcLang];
        t.splice(index, 1, payload);
        setPCLang([...t]);
    };

    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    }

    const resetState = () => {
        setTitle('');

        setErrors({title: null});
    }

    const validForm = () => {
        let e = true;

        if (!title) {

            setErrors({
                ...errors,
                title: 'Title is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
        return e;
    }

    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);
            const requestPayload = {
                categoryTitle: title,
                languageSet: [...pcLang]
            };
            addCategory(requestPayload).then((output) => {
                if (output.data.success) {
                    props.problemCategoryAdded({skip: 0, limit: 5});
                    resetState();
                    setLoading(false);
                    toast.success('Problem Category is successfully added!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('problemCategory');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        listLanguage({skip: 0, limit: 0}).then((output) => {
            console.log('output', output.data.data.data);
            setLanguages([...output.data.data.data]);
        });
    }, []);

    return (
        <React.Fragment>
            <Drawer
                anchor={'right'}
                open={props.show}
                onClose={() => {
                resetState();
                props.handleClose('problemCategory')
            }}>
                <div className={classes.createWrapper}>

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                resetState();
                                props.handleClose('problemCategory')
                            }}
                                aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Create Problem Category
                            </Typography>
                            <Button
                                variant="primary"
                                style={{
                                position: "absolute",
                                right: 24
                            }}
                                onClick={() => handleSubmit()}
                                disabled={loading}>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}Save Changes
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <Form className={classes.createForm}>
                        <Form.Group controlId="problemCategoryForm.title">
                            <Form.Label>Problem Category Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={onTitleChangeHandler}
                                isInvalid={!!errors.title}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>

                        </Form.Group>
                        <PCAddLanguageInputCard problemAdd={problemAdd}/> {pcLang.length && languages.length
                            ? pcLang.map((each, index) => {
                                return <PCLanguageInputCard
                                    key={index}
                                    pcLangInput={each}
                                    languages={languages}
                                    updatePCLangInput={(payload) => updatePCLangInput(index, payload)}
                                    removePCLangInput={() => removePCLangInput(index)}/>;
                            })
                            : null}
                    </Form>
                </div>
            </Drawer>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {data: state};
};

const mapDispatchToProps = dispatch => {
    return {
        problemCategoryAdded: ({skip, limit}) => dispatch(problemCategory.actions.problemCategoryAdded({skip, limit}))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), {withTheme: true})(CreateProblemCategory)));
