import axios from "./axios.interceptor";
import api from "./api.util";
import eventConfig from "../config/event";

export const EVENT_URL = `${api}/account/admin/online-event`;
export const EVENT_LIST_URL = `${EVENT_URL}/list`;

export function getEventDetails(eventId) {
  return axios.get(`${EVENT_URL}/${eventId}`);
}

export function listEvent({ skip, limit, eventPayload }) {
  eventPayload = eventPayload || {};
  return axios.post(
    `${EVENT_LIST_URL}?skip=${skip}&limit=${limit}`,
    eventPayload
  );
}

export function listRequestedEvent({ skip, limit, eventPayload }) {
  eventPayload = eventPayload || {};
  eventPayload.status = eventConfig.serverStatus.approvalPending;
  return axios.post(
    `${EVENT_LIST_URL}?skip=${skip}&limit=${limit}`,
    eventPayload
  );
}

export function approveEventDetails(eventId) {
  return axios.put(`${EVENT_URL}/${eventId}/approve`);
}

export function rejectEventDetails(eventId) {
  return axios.put(`${EVENT_URL}/${eventId}/reject`);
}

export function updateCommission(eventId, commissionPercentage) {
  return axios.put(`${EVENT_URL}/${eventId}/update-commission`, { commissionPercentage });
}

