import axios from "./axios.interceptor";
import api from "./api.util";

export const NOTIFICATION_LIST = `${api}/account/admin/notification/`;

export const getNotificationList = async () => {
  const res = await axios.get(`${NOTIFICATION_LIST}list?skip=0&limit=100`);
  return res;
};

export const notificationMarkSeen = async (id) => {
  const res = await axios.put(`${NOTIFICATION_LIST}seen/${id}`);
  return res;
};
