import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Form, Button as RBButton } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import LayoutConfig from '../../../../_metronic/layout/LayoutConfig';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { connect } from "react-redux";
import * as qa from "../../../store/ducks/qa.duck";
import {
    qaDetails,
    answerQa,
    approveQaDetails,
    rejectQaDetails,
    deleteQaDetails,
    approveQaAnswerDetails,
    rejectQaAnswerDetails,
    deleteQaAnswerDetails,
    releaseFlag
} from '../../../crud/qa.crud';
import qaConfig from '../../../config/qa';
import { green } from '@material-ui/core/colors';
import ApproveModal from './../../common/modal/approve-modal';
import RejectModal from './../../common/modal/reject-modal';
import DeleteModal from './../../common/modal/delete-modal';
import FlagModal from './flag-modal';
import { toast } from "react-toastify";
import EditForum from './edit-forum';
import EditForumAnswer from './edit-forum-answer';

const useStyles = (theme) => ({
    root: {
        minWidth: 275
    },
    title: {
        fontSize: 14,
        marginBottom: 0
    },
    pos: {
        marginBottom: theme.spacing(1)
    },
    flexBetween: {
        display: 'flex !important',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dangerBtn: {
        backgroundColor: LayoutConfig.colors.state.danger,
        color: 'white',
        "&:hover": {
            backgroundColor: LayoutConfig.colors.state.danger,
            color: 'white'
        }
    },
    primaryBtn: {
        backgroundColor: LayoutConfig.colors.state.primary,
        color: 'white',
        "&:hover": {
            backgroundColor: LayoutConfig.colors.state.primary,
            color: 'white'
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const ForumDetails = (props) => {
    const history = useHistory();
    const { qaId } = useParams();
    const classes = props.classes;
    const [qaDetailsInfo, setQaDetailsInfo] = useState();
    const [answer, setAnswer] = useState('');
    const [errors, setErrors] = useState({ answer: null });
    const [loading, setLoading] = useState(false);
    const [showApproval, setShowApproval] = useState(false);
    const [showRejection, setShowRejection] = useState(false);
    const [showDeletion, setShowDeletion] = useState(false);
    const [showDeletionAnswer, setShowDeletionAnswer] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [showFlagQuestion, setShowFlagQuestion] = useState(false);
    const [showFlagAnswer, setShowFlagAnswer] = useState(false);
    const [showQaEditModal, setShowQaEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState();

    const [showForumAnswerEditModal, setShowForumAnswerEditModal] = useState(false);
    const [selectedForumAnswerData, setSelectedForumAnswerData] = useState();

    const { data: { qa: { updated } }, qaUpdatedDone } = props;
    useEffect(() => {
        qaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (updated) {
            qaDetails(qaId).then((output) => {
                setQaDetailsInfo({
                    ...qaDetailsInfo,
                    ...output.data.data
                });
            });
            qaUpdatedDone();
        }

    }, [updated, qaUpdatedDone, qaDetailsInfo, qaId])


    const onChangeHandlerForAnswer = (event) => {
        setAnswer(event.target.value);
    };

    const handleSubmit = () => {
        if (!answer) {
            setErrors({
                answer: 'Answer needs to be given'
            })
        } else {
            setLoading(true);
            answerQa(qaId, { answer })
                .then(() => {
                    qaDetails(qaId).then((output) => {
                        setQaDetailsInfo({
                            ...qaDetailsInfo,
                            ...output.data.data
                        });
                        setAnswer('');
                        setLoading(false);
                    });

                });
        }
    };

    const approvalConsent = () => {
        setShowApproval(true);
    };

    const rejectionConsent = () => {
        setShowRejection(true);
    };

    const deleteConsent = () => {
        setShowDeletion(true);
    };

    const deleteAnswerConsent = (answer) => {
        setSelectedAnswer(answer)
        setShowDeletionAnswer(true);
    };

    const flagAnswerConsent = (answer) => {
        setSelectedAnswer(answer)
        setShowFlagAnswer(true);
    };


    const approveItem = (qaId) => {
        approveQaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
            toast.success("Forum's question is successfully approved!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    const rejectItem = (qaId) => {
        rejectQaDetails(qaId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
            toast.error("Forum's question is successfully rejected!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });;
    };

    const deleteItem = (qaId) => {
        deleteQaDetails(qaId).then(() => {
            toast.error("Forum's question is successfully deleted!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack();
        });;
    };

    const handleApprove = (approve) => {
        if (approve) {
            approveItem(qaDetailsInfo._id);
        } else {
            rejectItem(qaDetailsInfo._id);
        }
    };

    const handleDelete = () => {
        deleteItem(qaDetailsInfo._id);
    }


    const approveQaAnswerHandler = (qaId, answerId) => {
        approveQaAnswerDetails(qaId, answerId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
    };

    const rejectQaAnswerHandler = (qaId, answerId) => {
        rejectQaAnswerDetails(qaId, answerId).then((output) => {
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
    }

    const deleteQaAnswerHandler = () => {
        deleteQaAnswerDetails(qaDetailsInfo._id, selectedAnswer._id).then((output) => {
            toast.error("Forum's question answer is successfully deleted!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setQaDetailsInfo({
                ...qaDetailsInfo,
                ...output.data.data
            });
        });
    }

    const releaseAnswerFlagHandler = () => {
        releaseFlag(qaDetailsInfo._id, selectedAnswer._id).then((output) => {
            toast.error("Answer flags are successfully released!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            qaDetails(qaId).then((output) => {
                setQaDetailsInfo({
                    ...qaDetailsInfo,
                    ...output.data.data
                });
            });
        });
    }

    const handleCloseQaEdit = () => {
        setShowQaEditModal(false);
    };


    const openEditQaModal = (data) => {
        setSelectedData(data);
        setShowQaEditModal(true);
    };

    const handleCloseForumAnswerEdit = () => {
        setShowForumAnswerEditModal(false);
    };


    const openEditForumAnswerModal = (question, answer) => {
        setSelectedForumAnswerData(answer);
        setShowForumAnswerEditModal(true);
    };

    if (!qaDetailsInfo) {
        return null;
    }

    return (
        <Fragment>
            {showApproval ? (
                <ApproveModal
                    type={'Forum Question'}
                    title={qaDetailsInfo.question}
                    item={qaDetailsInfo}
                    show={showApproval}
                    handleClose={() => setShowApproval(false)}
                    handleApprove={(approve) => handleApprove(approve)}
                />
            ) : null}
            {showRejection ? (
                <RejectModal
                    type={'Forum Question'}
                    title={qaDetailsInfo.question}
                    item={qaDetailsInfo}
                    show={showRejection}
                    handleClose={() => setShowRejection(false)}
                    handleApprove={(approve) => handleApprove(approve)}
                />
            ) : null}


            {showDeletion ? (
                <DeleteModal
                    type={'Forum Question'}
                    title={qaDetailsInfo.question}
                    item={qaDetailsInfo}
                    show={showDeletion}
                    handleClose={() => setShowDeletion(false)}
                    handleDelete={() => handleDelete()}
                />
            ) : null}

            {showDeletionAnswer ? (
                <DeleteModal
                    type={'Forum Question Answer'}
                    title={selectedAnswer.answer}
                    item={qaDetailsInfo}
                    show={showDeletionAnswer}
                    handleClose={() => setShowDeletionAnswer(false)}
                    handleDelete={() => deleteQaAnswerHandler()}
                />
            ) : null}
            {showFlagQuestion ? (
                <FlagModal
                    type={'Forum Question'}
                    title={qaDetailsInfo.question}
                    item={qaDetailsInfo}
                    show={showFlagQuestion}
                    handleClose={() => setShowFlagQuestion(false)}
                    handleDelete={() => handleDelete()}
                />
            ) : null}
            {showFlagAnswer ? (
                <FlagModal
                    type={'Answer'}
                    title={selectedAnswer.answer}
                    item={qaDetailsInfo}
                    answerItem={selectedAnswer}
                    show={showFlagAnswer}
                    handleClose={() => setShowFlagAnswer(false)}
                    handleDelete={deleteQaAnswerHandler}
                    releaseAnswerFlagHandler={releaseAnswerFlagHandler}
                />
            ) : null}
            {
                showQaEditModal ? (<EditForum
                    qa={selectedData}
                    show={showQaEditModal}
                    handleClose={handleCloseQaEdit}
                />
                ) : null
            }

            {
                showForumAnswerEditModal ? (<EditForumAnswer
                    qaId={qaDetailsInfo._id}
                    forumAnswer={selectedForumAnswerData}
                    show={showForumAnswerEditModal}
                    handleClose={handleCloseForumAnswerEdit}
                />
                ) : null
            }

            {/* Question */}
            <Card className={`${classes.root} ${classes.flexBetween}`}>
                <CardContent>
                    <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{
                            display: 'flex'
                        }
                        }>
                        <span
                            style={{
                                fontSize: '18px',
                                color: '#707299'
                            }}>Question&nbsp;:&nbsp;</span>
                        <span className="mt-1">
                            {qaDetailsInfo.question}
                            <span className="mt-3 d-block">
                                <span
                                    style={{
                                        color: '#4d59b6'
                                    }}>Asked By&nbsp;:&nbsp;</span>
                                <span>{qaDetailsInfo?.askedBy?.personalInfo?.name || qaDetailsInfo?.askedBy?.personalInfo?.fullName} </span>
                                <span
                                    style={{
                                        color: '#4d59b6'
                                    }}>
                                    | Date &nbsp;:&nbsp;</span>
                                <span>{moment(qaDetailsInfo.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                            </span>
                        </span>
                    </Typography >

                </CardContent >
                <CardActions
                    style={{
                        alignItems: 'baseline',
                        marginTop: '10px',
                        position: 'relative'
                    }}>
                    {
                        qaDetailsInfo.status === 1
                            ?
                            <Fragment>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.primaryBtn}
                                    onClick={() => {
                                        openEditQaModal(qaDetailsInfo);
                                    }}
                                >Edit</Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => approvalConsent()}
                                // onClick={() => {
                                //     approveQA(qaDetailsInfo._id);
                                // }}
                                >Approve</Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={classes.dangerBtn}
                                    onClick={() => {
                                        rejectionConsent();
                                    }}
                                // onClick={() => {
                                //     rejectQA(qaDetailsInfo._id);
                                // }}
                                >Reject</Button>
                            </Fragment>
                            : <Typography
                                style={{
                                    width: '100px',
                                    paddingTop: '16px'
                                }}
                                color={qaDetailsInfo.status === 2
                                    ? "secondary"
                                    : "error"}
                                align="center">{qaConfig.answerStatus[qaDetailsInfo.status]}</Typography>
                    }
                    <Fragment>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.primaryBtn}
                            onClick={() => {
                                openEditQaModal(qaDetailsInfo);
                            }}
                        >Edit</Button>
                        <Button
                            size="small"
                            variant="contained"
                            className={classes.dangerBtn}
                            onClick={() => {
                                deleteConsent();
                            }}
                        >Delete</Button>
                    </Fragment>
                </CardActions>
            </Card >

            {/* Answer */}
            {
                qaDetailsInfo.answers && qaDetailsInfo.answers.length
                    ? qaDetailsInfo
                        .answers.filter((each) => each ? true : false)
                        .map((eachAnswer, key) => {
                            return (
                                <Card className={`${classes.root} ${classes.flexBetween} mt-3`} key={key}>
                                    <CardContent>
                                        <Typography
                                            className={classes.title}
                                            color="textSecondary"
                                            gutterBottom
                                            style={{
                                                display: 'flex'
                                            }}>
                                            <span
                                                style={{
                                                    fontSize: '18px',
                                                    color: '#707299'
                                                }}>Answer&nbsp;:&nbsp;</span>
                                            <span className="mt-1">
                                                {eachAnswer.answer.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br /></span>
                                                })}
                                                <span className="mt-3 d-block">
                                                    <span
                                                        style={{
                                                            color: '#4d59b6'
                                                        }}>Answered By&nbsp;:&nbsp;</span>
                                                    <span>{eachAnswer.answeredBy.userType !== 3 ? eachAnswer.answeredBy.personalInfo.name : eachAnswer.answeredBy.personalInfo.fullName}</span>
                                                    <span
                                                        style={{
                                                            color: '#4d59b6'
                                                        }}>
                                                        | Date &nbsp;:&nbsp;</span>
                                                    <span>{moment(eachAnswer.time).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                                </span>
                                            </span>
                                        </Typography>

                                    </CardContent>
                                    <CardActions
                                        style={{
                                            alignItems: 'baseline',
                                            marginTop: '10px',
                                            position: 'relative'
                                        }}>
                                        {eachAnswer.flaggedBy && eachAnswer.flaggedBy.length ?
                                            <div style={{ position: 'absolute', right: '10px', top: '-5px', cursor: 'pointer' }} onClick={() => flagAnswerConsent(eachAnswer)}><img alt='flagged answer' src={require('../../../../images/flag-selected.png')} /></div> : null}
                                        {eachAnswer.status === 1
                                            ?
                                            <Fragment>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    className={classes.primaryBtn}
                                                    onClick={() => {
                                                        openEditForumAnswerModal(qaDetailsInfo, eachAnswer);
                                                    }}
                                                >Edit</Button>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        approveQaAnswerHandler(qaDetailsInfo._id, eachAnswer._id);
                                                    }}>Approve</Button>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    className={classes.dangerBtn}
                                                    onClick={() => {
                                                        rejectQaAnswerHandler(qaDetailsInfo._id, eachAnswer._id);
                                                    }}>Reject</Button></Fragment>
                                            : <Typography
                                                style={{
                                                    width: '100px',
                                                    paddingTop: '16px'
                                                }}
                                                color={eachAnswer.status === 2
                                                    ? "secondary"
                                                    : "error"}
                                                align="center">{qaConfig.answerStatus[eachAnswer.status]}</Typography>}
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className={classes.primaryBtn}
                                            onClick={() => {
                                                openEditForumAnswerModal(qaDetailsInfo, eachAnswer);
                                            }}
                                        >Edit</Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className={classes.dangerBtn}
                                            onClick={() => {
                                                deleteAnswerConsent(eachAnswer);
                                            }}>Delete</Button>
                                    </CardActions>
                                </Card>
                            );
                        })
                    : null
            }
            {
                qaDetailsInfo.status === 2 ? <Fragment>
                    <Form.Group style={{ marginTop: 16 }}>
                        <Form.Label>Answer</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            cols={50}
                            value={answer}
                            onChange={onChangeHandlerForAnswer}
                            isInvalid={!!errors.answer} />
                        <Form.Control.Feedback type="invalid">
                            {errors.answer}
                        </Form.Control.Feedback>

                    </Form.Group>
                    <RBButton
                        variant="primary"
                        style={{
                            position: "relative"
                        }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}Save Changes
            </RBButton>
                </Fragment> : null
            }
        </Fragment >
    )

};

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        qaUpdatedDone: () => dispatch(qa.actions.qaUpdatedDone())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...useStyles(theme)
}), { withTheme: true })(ForumDetails));