import React from "react";
import { Link } from "react-router-dom";
import CustomBookingCard from "./component/CustomBookingCard";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";
import NoDataFound from "./component/NoDataFound";
import SectionHoc from "./component/SectionHoc";
import CustomViewScroll from "./component/CustomViewScroll";

const EventSection = ({ dashboardData }) => {
  return (
    <DashboardCardHOC
      title={"Events"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL"
              value={dashboardData.totalEvent}
              iconImg="Event"
              bgStyle={{
                background: "#1784E2 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="PENDING"
              value={dashboardData.totalEventPendingApproval}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(112deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="UPCOMING"
              value={dashboardData.totalEventUpcoming}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #1DE1EB 0%, #00F1FF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="COMPLETED"
              value={dashboardData.totalEventCompleted}
              iconImg="Event"
              bgStyle={{
                background: "#1CC88A 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="CANCELLED"
              value={dashboardData.totalEventCancelled}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(180deg, #F26522 0%, #F26522 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="forums"
            />
          </div>
        </div>
      }
      collapseContent={
        <div>
          <div className="row" style={{ marginTop: 24 }}>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    Today ({dashboardData?.totalEventToday || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/events">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.eventListToday &&
                dashboardData.eventListToday.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={dashboardData.eventListToday.length > 8}
                  >
                    {dashboardData.eventListToday.map((each, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "#ffffff",
                            paddingBottom: 12,
                            paddingLeft: 12,
                          }}
                          key={key}
                        >
                          <CustomBookingCard
                            data={{
                              id: each._id,
                              title: each.title,
                              subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                              image: each.thumbnailImage,
                            }}
                            bgColor="#FEF8E7 0% 0% no-repeat padding-box"
                            pageDetails="event-details"
                          />
                        </div>
                      );
                    })}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    This Week ({dashboardData?.totalEventThisWeek || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/events">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.eventListThisWeek &&
                dashboardData.eventListThisWeek.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={
                      dashboardData.eventListThisWeek.length > 8
                    }
                  >
                    {dashboardData.eventListThisWeek.map((each, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "#ffffff",
                            paddingBottom: 12,
                            paddingLeft: 12,
                          }}
                          key={key}
                        >
                          <CustomBookingCard
                            data={{
                              id: each._id,
                              title: each.title,
                              subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                              image: each.thumbnailImage,
                            }}
                            pageDetails="event-details"
                            bgColor="#F5FFE2 0% 0% no-repeat padding-box"
                          />
                        </div>
                      );
                    })}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
            <div className="col-lg-4">
              <SectionHoc
                isleftRightTxt={true}
                leftContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingLeft: 16,
                      lineHeight: 1.334,
                    }}
                  >
                    This Month ({dashboardData?.totalEventThisMonth || 0})
                  </p>
                }
                rightContent={
                  <p
                    style={{
                      fontSize: 16,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      margin: 0,
                      paddingRight: 16,
                    }}
                  >
                    <Link to="/events">VIEW ALL</Link>
                  </p>
                }
              >
                {dashboardData.eventListThisMonth &&
                dashboardData.eventListThisMonth.length ? (
                  <CustomViewScroll
                    isMoreRightPadding={
                      dashboardData.eventListThisMonth.length > 8
                    }
                  >
                    {dashboardData.eventListThisMonth.map((each, key) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "#ffffff",
                            paddingBottom: 12,
                            paddingLeft: 12,
                          }}
                          key={key}
                        >
                          <CustomBookingCard
                            data={{
                              id: each._id,
                              title: each.title,
                              subTitle: `Created By: ${each.vendorRef.personalInfo.name}`,
                              image: each.thumbnailImage,
                            }}
                            pageDetails="event-details"
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"
                          />
                        </div>
                      );
                    })}
                  </CustomViewScroll>
                ) : (
                  <NoDataFound />
                )}
              </SectionHoc>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default EventSection;
