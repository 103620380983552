import axios from './axios.interceptor';
import api from './api.util';

export const ROLE_URL = `${api}/account/admin/role`;
export const ADD_ROLE_URL =`${ROLE_URL}/add`;
export const LIST_ROLE_URL =`${ROLE_URL}/list`;

export function addRole(payload) {
  return axios.post(ADD_ROLE_URL, payload);
}

export function listRole({skip, limit}) {
  return axios.post(`${LIST_ROLE_URL}?skip=${skip}&limit=${limit}`);
}

export function editRole(roleId, payload) {
  return axios.put(`${ROLE_URL}/${roleId}`, payload);
}

export function removeRole(roleId) {
  return axios.delete(`${ROLE_URL}/${roleId}`);
}