import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import styles from "./styles";
import { editLanguage } from "../../../crud/language.crud";
import * as language from "../../../store/ducks/language.duck";
const useStyles = makeStyles({
  root: {
    width: "600px",
  },
});
const EditLanguage = (props) => {
  const customClasses = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ title: null, icon: null });
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  const { classes, language } = props;

  useEffect(() => {
    const dataProps = JSON.parse(JSON.stringify(language));
    setTitle(dataProps.name);
    setCode(dataProps.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTitleChangeHandler = (event) => {
    setTitle(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        title: "Title is required",
      });
    } else {
      setErrors({
        ...errors,
        title: null,
      });
    }
  };

  const onCodeChangeHandler = (event) => {
    setCode(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        code: "Code is required",
      });
    } else {
      setErrors({
        ...errors,
        code: null,
      });
    }
  };

  const resetState = () => {
    setTitle("");
    setCode("");

    setErrors({
      title: null,
      code: null,
    });
  };

  const validForm = () => {
    let e = true;

    if (!title && !code) {
      setErrors({
        ...errors,
        title: "Title is required",
        code: "Code is required",
      });
      e = false;
    } else if (!title) {
      setErrors({
        ...errors,
        title: "Title is required",
        code: "Code is required",
      });
      e = false;
    } else if (!code) {
      setErrors({
        ...errors,
        code: "Code is required",
      });
      e = false;
    } else {
      setErrors({
        ...errors,
        title: null,
        code: null,
      });
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);

      const payload = {
        name: title,
        code: code,
      };

      editLanguage(language._id, payload)
        .then((output) => {
          console.log("output", output);
          if (output.data.success) {
            props.languageUpdated({
              skip: props.listOption.skip,
              limit: props.listOption.limit,
            });
            resetState();
            setLoading(false);
            toast.success("Language is successfully updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("language");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          resetState();
          props.handleClose("language");
        }}
      >
        <div className={customClasses.root}>
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "16px",
              backgroundColor: "#2e40d4",
              borderColor: "#293ccc",
              color: "#fff",
              padding: "4px 8px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              resetState();
              props.handleClose("vendor");
            }}
          >
            <i className="la la-close"></i>
          </div>

          <div
            className={`vendor-page row}`}
            style={{
              margin: 0,
            }}
          >
            <div className="col-12 categories-heading">
              <h4 className={`mb-0`}>Edit Tutorial</h4>
            </div>
            <div className="col-12" style={{ padding: "12px 16px" }}>
              <Form>
                <Form.Group controlId="professionalLanguageForm.title">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={onTitleChangeHandler}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="professionalLanguageForm.code">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={code}
                    onChange={onCodeChangeHandler}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
              <Button
                variant="primary"
                style={{
                  position: "relative",
                }}
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    languageUpdated: ({ skip, limit }) =>
      dispatch(language.actions.languageUpdated({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(EditLanguage)
  )
);
