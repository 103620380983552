import React from "react";
import { Button, Modal } from "react-bootstrap";
const RemoveModal = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Workshop: {props.workshop.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure, you want to remove this workshop?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => {
              props.handleDelete();
              props.handleClose();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default RemoveModal;
