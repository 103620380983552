import React, { useState } from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import Notifications from "../../components/Notifications";
const UserNotificationsCustom = (props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  return (
    <div>
      <Notifications show={show} handleClose={toggleShow} />
      <button
        style={{
          cursor: "pointer",
          padding: 14,
          border: 0,
          background: "#f2f3f8",
          borderRadius: "50%",
          margin: 6,
        }}
        onClick={() => setShow(true)}
      >
        <img
          src={
            props?.data?.notification.hasNotification
              ? toAbsoluteUrl("/media/icons/bell-notification-icon.svg")
              : toAbsoluteUrl("/media/icons/bell-icon.svg")
          }
          alt={`notification`}
        />
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  return {data: state};
};

export default connect(mapStateToProps)(UserNotificationsCustom);