import React, { useEffect, useState } from "react";
import { Table, Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import EditVendorPage from "./edit-vendor-page";
import RemoveVendor from "./remove-vendor";
import { toast } from "react-toastify";
import moment from "moment";
import StyleDropdown from "../../../components/StyleDropdown";
import {
  listVendor,
  listRequestedVendor,
  approveVendorDetails,
  rejectVendorDetails,
  sendNotificaton,
} from "../../../crud/vendor.crud";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import FullWidthTabs from "./../../common/FullWidthTabs";
import ApproveVendorModal from "./modal/approve-vendor-modal";
import RejectVendorModal from "./modal/reject-vendor-modal";
import { makeStyles } from "@material-ui/core/styles";
import CustomPagination from "../../../components/CustomPagination";
import { colorsCodes } from "../../../config/constants";
import { Tooltip } from "@material-ui/core";
import NoDataFound from "../../../components/NoDataFound";
import * as notification from "./../../../store/ducks/notification.duck";

const useStyles = makeStyles(() => ({
  calender: {
    "&.MuiIconButton-label": {
      color: "#6DB9FB",
    },
  },
  leftIconButton: {
    color: "#38A0FA",
  },
  rightIconButton: {
    color: "#38A0FA",
  },
  status: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
  },
  tooltip: {
    fontSize: "1.2em",
  },
}));

const VendorList = (props) => {
  const classes = useStyles();
  const { tab } = props.data.notification;
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedData, setSelectedData] = useState();
  const [showVendorEdit, setShowVendorEdit] = useState(false);
  const [showVendorDelete, setShowVendorDelete] = useState(false);
  const [showVendorApproval, setShowVendorApproval] = useState(false);
  const [showVendorRejection, setShowVendorRejection] = useState(false);
  const [professionalName, setProfessionalName] = useState("");
  const [professionalPhone, setProfessionalPhone] = useState("");
  const [professionalEmail, setProfessionalEmail] = useState("");
  const [searchType, setSearchType] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const [selectedProfessionalStatus, setSelectedProfessionalStatus] = useState(
    "Approved"
  );
  const [selectedStatus, setSelectedStatus] = useState(6);
  const [selectedDate, setSelectedDate] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    if (currentTab === 0) {
      listVendor({
        skip: tableData.skip,
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          submittedAt: undefined,
        },
      }).then((docs) => {
        if (docs.data) {
          // console.log("docs.data.data", docs.data.data);
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        } else {
        }
      });
    } else if (currentTab === 1) {
      listRequestedVendor({
        skip: tableData.skip,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
    return () => {
      props.updateTab(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.vendor.listData) {
      let listObject = {
        data: [...props.data.vendor.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.vendor.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.vendor.listData.data.data.total / tableData.limit)
        ),
      };
      setSelectedDate(null);
      //   //console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.vendor.listData]);

  useEffect(() => {
    setPage(1);
    if (currentTab === 0) {
      listVendor({
        skip: 0,
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          submittedAt: selectedDate
            ? {
                date: new Date(selectedDate).getDate(),
                month: new Date(selectedDate).getMonth() + 1,
                year: new Date(selectedDate).getFullYear(),
              }
            : undefined,
        },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else if (currentTab === 1) {
      listRequestedVendor({
        skip: 0,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    if (currentTab === 0) {
      listVendor({
        skip: tableData.skip,
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          submittedAt: selectedDate
            ? {
                date: new Date(selectedDate).getDate(),
                month: new Date(selectedDate).getMonth() + 1,
                year: new Date(selectedDate).getFullYear(),
              }
            : undefined,
        },
      }).then((docs) => {
        if (docs.data) {
          //console.log("docs.data.data", docs.data.data);
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        } else {
        }
      });
    } else if (currentTab === 1) {
      listRequestedVendor({
        skip: tableData.skip,
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
  };

  const handleChange = (_event, value) => {
    setPage(value);
    if (currentTab === 0) {
      listVendor({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
          submittedAt: selectedDate
            ? {
                date: new Date(selectedDate).getDate(),
                month: new Date(selectedDate).getMonth() + 1,
                year: new Date(selectedDate).getFullYear(),
              }
            : undefined,
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
        }
      });
    } else if (currentTab === 1) {
      listRequestedVendor({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
        }
      });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      searchList({
        submittedAt: {
          date: new Date(date).getDate(),
          month: new Date(date).getMonth() + 1,
          year: new Date(date).getFullYear(),
        },
        searchKey: searchKey,
        searchType: searchType,
        status: selectedStatus,
      });
    } else {
      searchList({
        submittedAt: undefined,
        searchKey: searchKey,
        searchType: searchType,
        status: selectedStatus,
      });
    }
  };

  const handleCloseEdit = () => {
    setShowVendorEdit(false);
    reloadList();
  };

  const handleCloseDelete = () => {
    setShowVendorDelete(false);
    reloadList();
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowVendorEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowVendorDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowVendorApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowVendorRejection(true);
  };

  const approveVendor = (vendorId) => {
    approveVendorDetails(vendorId).then(() => {
      toast.success("Professional is successfully approved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const rejectVendor = (vendorId, reasonOfReject) => {
    rejectVendorDetails(vendorId, reasonOfReject).then(() => {
      toast.success("Professional is successfully rejected!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const handleApprove = (approve, reasonOfReject) => {
    if (approve) {
      approveVendor(selectedData._id);
    } else {
      rejectVendor(selectedData._id, reasonOfReject);
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return "ACTIVE";
      case 2:
        return "BLOCKED";
      case 3:
        return "INACTIVE";
      case 4:
        return "APPROVAL PENDING";
      case 5:
        return "APPROVAL REJECTED";
      case 6:
        return "APPROVED";
      default:
        return "";
    }
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 1:
        return colorsCodes.blue;
      case 2:
        return colorsCodes.red;
      case 3:
        return colorsCodes.red;
      case 4:
        return colorsCodes.orange;
      case 5:
        return colorsCodes.orange;
      case 6:
        return colorsCodes.green;
      default:
        return "";
    }
  };

  const statusHandler = (status) => {
    setSelectedStatus(status);
    switch (status) {
      case 6:
        setPage(1);
        setSelectedProfessionalStatus("Approved");
        break;
      case 1:
        setPage(1);
        setSelectedProfessionalStatus("Active");
        break;
      case 3:
        setPage(1);
        setSelectedProfessionalStatus("Inactive");
        break;
      case 5:
        setPage(1);
        setSelectedProfessionalStatus("Rejected");
        break;
      default:
        break;
    }
    listVendor({
      skip: 0,
      limit: tableData.limit,
      payload: {
        status: status,
        searchKey: searchKey,
        searchType: searchType,
        submittedAt: selectedDate
          ? {
              date: new Date(selectedDate).getDate(),
              month: new Date(selectedDate).getMonth() + 1,
              year: new Date(selectedDate).getFullYear(),
            }
          : undefined,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const searchList = (vendorPayload) => {
    setPage(1);
    listVendor({
      skip: 0,
      limit: tableData.limit,
      payload: {
        searchKey: vendorPayload.searchKey,
        searchType: vendorPayload.searchType,
        status: vendorPayload.status,
        submittedAt: vendorPayload.submittedAt,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleSearch = (name) => (event) => {
    switch (name) {
      case "professionalName":
        setProfessionalName(event.target.value);

        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 1,
            status: selectedStatus,
            submittedAt: selectedDate
              ? {
                  date: new Date(selectedDate).getDate(),
                  month: new Date(selectedDate).getMonth() + 1,
                  year: new Date(selectedDate).getFullYear(),
                }
              : undefined,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            submittedAt: selectedDate
              ? {
                  date: new Date(selectedDate).getDate(),
                  month: new Date(selectedDate).getMonth() + 1,
                  year: new Date(selectedDate).getFullYear(),
                }
              : undefined,
          });
        }
        break;
      case "professionalPhone":
        setProfessionalPhone(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(3);
          searchList({
            searchKey: event.target.value,
            searchType: 3,
            status: selectedStatus,
            submittedAt: selectedDate
              ? {
                  date: new Date(selectedDate).getDate(),
                  month: new Date(selectedDate).getMonth() + 1,
                  year: new Date(selectedDate).getFullYear(),
                }
              : undefined,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
          });
        }
        break;
      case "professionalEmail":
        setProfessionalEmail(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(2);
          searchList({
            searchKey: event.target.value,
            searchType: 2,
            status: selectedStatus,
            submittedAt: selectedDate
              ? {
                  date: new Date(selectedDate).getDate(),
                  month: new Date(selectedDate).getMonth() + 1,
                  year: new Date(selectedDate).getFullYear(),
                }
              : undefined,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,
            submittedAt: selectedDate
              ? {
                  date: new Date(selectedDate).getDate(),
                  month: new Date(selectedDate).getMonth() + 1,
                  year: new Date(selectedDate).getFullYear(),
                }
              : undefined,
          });
        }
        break;
      default:
        break;
    }
  };

  const sendNotificatonToActiveVendor = async () => {
    await sendNotificaton();
  };

  return (
    <React.Fragment>
      {showVendorApproval ? (
        <ApproveVendorModal
          vendor={selectedData}
          show={showVendorApproval}
          handleClose={() => setShowVendorApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showVendorRejection ? (
        <RejectVendorModal
          vendor={selectedData}
          show={showVendorRejection}
          handleClose={() => setShowVendorRejection(false)}
          handleApprove={(approve, reasonOfReject) =>
            handleApprove(approve, reasonOfReject)
          }
        />
      ) : null}
      {showVendorEdit && selectedData ? (
        <EditVendorPage
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          vendor={selectedData}
          show={showVendorEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showVendorDelete ? (
        <RemoveVendor
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
            payload: {
              searchKey: searchKey,
              searchType: searchType,
              status: selectedStatus,
              submittedAt: selectedDate
                ? {
                    date: new Date(selectedDate).getDate(),
                    month: new Date(selectedDate).getMonth() + 1,
                    year: new Date(selectedDate).getFullYear(),
                  }
                : undefined,
            },
          }}
          vendor={selectedData}
          show={showVendorDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (
        <DeleteWarningModal
          title={"professional"}
          open={showDeleteWarning}
          handleClose={() => setShowDeleteWarning(false)}
        />
      ) : null}

      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        title={"Professionals"}
        tabsTitle={["All", "Pending Verification Requests"]}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <React.Fragment>
          <Table bordered hover style={{ tableLayout: "fixed" }}>
            <thead className="custom-table-header">
              <tr>
                <th
                  className="custom-table-header-text"
                  style={{ width: "60px" }}
                >
                  SL No
                </th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                  <th className="custom-table-header-text">ID</th>
                ) : null}
                <th
                  className="custom-table-header-text"
                  style={{ width: "160px" }}
                >
                  Submitted At
                </th>
                <th className="custom-table-header-text">Name</th>
                <th className="custom-table-header-text">Phone</th>
                <th className="custom-table-header-text">Email</th>
                {/* <th className="custom-table-header-text">Experience</th> */}
                <th className="custom-table-header-text">Status</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentTab === 0 ? (
                <tr>
                  <th style={{ width: "50px" }}></th>
                  {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                    <th></th>
                  ) : null}
                  <th style={{ width: "150px" }}>
                    {
                      <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        label=""
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ margin: 0 }}
                        classes={{
                          root: classes.calender,
                        }}
                      />
                    }
                  </th>
                  <th style={{ verticalAlign: "top", padding: "2px" }}>
                    <Form.Control
                      type="text"
                      value={professionalName}
                      onChange={handleSearch("professionalName")}
                    />
                  </th>
                  <th style={{ verticalAlign: "top", padding: "2px" }}>
                    <Form.Control
                      type="text"
                      value={professionalPhone}
                      onChange={handleSearch("professionalPhone")}
                    />
                  </th>
                  <th
                    style={{
                      verticalAlign: "top",
                      padding: "2px",
                      width: "150px",
                    }}
                  >
                    <Form.Control
                      type="text"
                      value={professionalEmail}
                      onChange={handleSearch("professionalEmail")}
                    />
                  </th>
                  {/* <th></th> */}
                  <th className="text-center">
                    <StyleDropdown>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {selectedProfessionalStatus}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            statusHandler(1);
                          }}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            statusHandler(3);
                          }}
                        >
                          Inactive
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            statusHandler(6);
                          }}
                        >
                          Approved
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            statusHandler(5);
                          }}
                        >
                          Rejected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </StyleDropdown>
                  </th>
                  <th></th>
                </tr>
              ) : null}
              {tableData.data.length
                ? tableData.data.map((eachData, key) => {
                    return (
                      <tr
                        key={eachData._id}
                        className={
                          key % 2 === 1 ? "odd-custom-row" : "even-custom-row"
                        }
                      >
                        <td className="custom-table-body-text">
                          {tableData.skip + key + 1}
                        </td>
                        {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                          <td
                            className="custom-table-body-text"
                            style={{ wordWrap: "break-word" }}
                          >
                            {eachData._id}
                          </td>
                        ) : null}
                        <td
                          className="custom-table-body-text"
                          style={{ wordWrap: "break-word" }}
                        >
                          {moment(eachData.submittedAt).format(
                            "DD-MMM-YYYY, hh:mm A"
                          )}
                        </td>
                        <td className="custom-table-body-text">
                          {eachData.personalInfo.name
                            ? eachData.personalInfo.name
                            : "N/A"}
                        </td>
                        <td className="custom-table-body-text">{`${eachData.personalInfo.phone.dialCode}-${eachData.personalInfo.phone.number}`}</td>
                        <td
                          className="custom-table-body-text"
                          style={{ wordWrap: "break-word" }}
                        >
                          {eachData.personalInfo.email
                            ? eachData.personalInfo.email
                            : "N/A"}
                        </td>
                        {/* <td className="custom-table-body-text">{`${eachData.personalInfo.experience.year} years ${eachData.personalInfo.experience.month} months`}</td> */}
                        <td className={classes.status}>
                          <span
                            style={{
                              color: renderStatusColor(eachData.accountStatus),
                            }}
                          >
                            {renderStatus(eachData.accountStatus)}&nbsp;&nbsp;
                          </span>
                          {eachData.accountStatus === 5 && (
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              title={eachData.reasonOfReject}
                            >
                              <i
                                className="la la-info"
                                style={{
                                  borderRadius: "50%",
                                  border: 1,
                                  background: "#f1823f",
                                  padding: 4,
                                }}
                              ></i>
                            </Tooltip>
                          )}
                        </td>
                        <td className="text-center">
                          {currentTab === 0 ? (
                            <>
                              <p
                                className="btn btn-icon custom-table-btn"
                                title={"Edit"}
                                onClick={() => openEditModal(eachData)}
                                style={{
                                  margin: "0",
                                }}
                              >
                                <i className="la la-edit"></i>
                              </p>
                              {eachData.accountStatus !== 3 ? (
                                <>
                                  <p
                                    className="btn btn-icon custom-table-btn"
                                    title="Delete"
                                    style={{
                                      margin: "0",
                                    }}
                                    onClick={() => {
                                      openDeleteModal(eachData);
                                    }}
                                  >
                                    <i className="la la-trash kt-font-danger"></i>
                                  </p>
                                  <a
                                    className="btn btn-icon custom-table-btn"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}/professional-details/${eachData._id}`}
                                  >
                                    <Tooltip title="View profile">
                                      <i className="la la-eye"></i>
                                    </Tooltip>
                                  </a>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <p
                                className="btn btn-icon custom-table-btn"
                                title={"Edit"}
                                onClick={() => openEditModal(eachData)}
                                style={{
                                  margin: "0",
                                }}
                              >
                                <i className="la la-edit"></i>
                              </p>
                              <p
                                className="btn btn-icon custom-table-btn"
                                title={"Approve"}
                                onClick={() => approvalConsent(eachData)}
                                style={{
                                  margin: "0",
                                }}
                              >
                                <i className="la la-check-circle"></i>
                              </p>

                              <p
                                className="btn btn-icon custom-table-btn"
                                title="Reject"
                                style={{
                                  margin: "0",
                                }}
                                onClick={() => {
                                  rejectionConsent(eachData);
                                }}
                              >
                                <i className="la la-close kt-font-danger"></i>
                              </p>
                              <a
                                className="btn btn-icon custom-table-btn"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_CUSTOMER_WEBSITE_URL}/professional-details/${eachData._id}`}
                              >
                                <Tooltip title="View profile">
                                  <i className="la la-eye"></i>
                                </Tooltip>
                              </a>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>

          {tableData.data.length === 0 ? <NoDataFound /> : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {tableData.data.length ? (
              <CustomPagination
                count={tableData.totalPage}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChange}
                backIconButtonProps={{ className: classes.leftIconButton }}
                nextIconButtonProps={{ className: classes.rightIconButton }}
              />
            ) : null}
            {selectedProfessionalStatus === "Active" && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={sendNotificatonToActiveVendor}
              >
                Send notification
              </button>
            )}
          </div>
        </React.Fragment>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTab: (data) =>
      dispatch(notification.actions.updateTab({ selectedTab: data })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
