import React from "react";
import VendorConfig from "./../vendor-config";
const ServiceManager = ({
  selectedProfile,
  serviceDetails,
  selectedServiceTime,
  selectServiceTime,
  profileType,
  mode,
  serviceList,
  chooseService,
  openRemove,
  openServiceList,
  serviceDetailsError,
  updateServiceDetailsError,
  updateServiceDetails,
  saveService,
  serviceTimeHours,
  selectedServiceMode,
  changeServiceMode,
  individualLocations,
  selectedIndividualLocation,
  getDuration,
  updateSelectedIndividualLocation,
  selectedIndividualLocationObj,
  updateSelectedIndividualLocationObj,
  locationsForOrganization,
  selectedLocationForOrganization,
  selectedLocationForOrganizationObj,
  selectLocationForOrganization
}) => {
    console.log(serviceDetails);
  const selectIndividualLocation = (e) => {
    individualLocations.forEach((each) => {
      if (each._id === e.target.value) {
        updateSelectedIndividualLocation(e.target.value);
        console.log('each', each);
        updateSelectedIndividualLocationObj({ ...each });
      }
    });
  };
  return (
    <div className="col-12 p-0">
      {mode === "list" ? (
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div className="col-12 p-0">
            <div
              className="row"
              style={{
                margin: 0,
              }}
            >
              <div className="col-8 pl-0 pt-2 mb-3">
                <h5 className={`mb-0`}>Service List</h5>
              </div>
              {/* <div className="col-4 pr-0 text-right mb-3">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                          onClick={() => updateMode("add")}
                        >
                          Add Service
                        </button>
                      </div> */}
            </div>
          </div>
          {serviceList.length ? (
            serviceList.map((professional, index) => {
              return (
                <div
                  className={`col-sm-6 col-xs-12 professional-wrapper ${
                    index % 2 === 0 ? "problem-more-768-pr-15-for-2" : ""
                  } ${
                    index !== serviceList.length - 1 &&
                    index !== serviceList.length - 2 &&
                    index !== serviceList.length - 3
                      ? "problem-more-768-pb-15"
                      : ""
                  } ${
                    index !== serviceList.length - 1 &&
                    index !== serviceList.length - 2
                      ? "problem-more-768-pb-15-for-2"
                      : ""
                  } ${
                    index !== serviceList.length - 1
                      ? "problem-more-768-pb-15-for-1"
                      : ""
                  } `}
                  key={index}
                  onClick={() => chooseService(professional)}
                >
                  <div
                    className="row service-row"
                    style={{
                      margin: 0,
                    }}
                  >
                    <div className="col-12 p-0">
                      <div
                        className="row"
                        style={{
                          margin: 0,
                        }}
                      >
                        <div className="professional-name col-12 p-0">
                          {professional.title}
                        </div>
                        <div className="professional-location service-name col-12 p-0">
                          {professional.description}
                        </div>
                        <div className="col-5 pl-0">
                          <div className="service-cost">
                            <i class="fas fa-rupee-sign"></i>
                            {professional.fees}
                          </div>
                        </div>
                        <div className="col-7 pr-0 text-right">
                          <div className="service-hour">
                            {professional.sessionTimeText}
                          </div>
                        </div>
                        <div class="col-12 p-0 service-cost">
                          {professional.serviceTypeText}
                        </div>
                      </div>
                    </div>
                    <img
                      src={require("./../../../../../images/remove.svg")}
                      onClick={(e) => {
                        e.stopPropagation();
                        openRemove(professional, "Service");
                      }}
                      alt="remove service"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center well col-12 mb-3 color-black">
              No Services Available!
            </div>
          )}
        </div>
      ) : null}
      {mode === "add" || mode === "edit" ? (
        <div
          className="row"
          style={{
            margin: 0,
          }}
        >
          <div className="col-12 p-0">
            <div
              className="row"
              style={{
                margin: 0,
              }}
            >
              <div className="col-6 pl-0 pt-2">
                <h5 className={`mb-0`}>
                  {mode === "add" ? "Add " : "Edit "}
                  Service
                </h5>
              </div>
              <div className="col-6 pr-0 text-right mb-3">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mr-2"
                  onClick={() => {
                    updateServiceDetailsError({
                      ...VendorConfig.initialServiceError,
                    });
                    openServiceList(selectedProfile.vendorId);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => saveService(mode)}
                >
                  Save
                </button>
              </div>
            </div>
            <div
              className="row"
              style={{
                margin: 0,
              }}
            >
              <div className="col-12 pl-0 mb-3">
                <label htmlFor="locationNickName" className="form-label">
                  Service title
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    serviceDetailsError.title ? "error-border" : ""
                  }`}
                  id="locationNickName"
                  placeholder="Enter Service Title"
                  value={serviceDetails.title}
                  onChange={(e) =>
                    updateServiceDetails(e.target.value, "title")
                  }
                />{" "}
                {serviceDetailsError.title ? (
                  <div className="input-error">{serviceDetailsError.title}</div>
                ) : null}
              </div>
              <div className="col-6 pl-0 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Service Fee
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-rupee-sign" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      serviceDetailsError.fees ? "error-border" : ""
                    }`}
                    id="exampleFormControlInput1"
                    placeholder="Service Fee (in Rs)"
                    value={serviceDetails.fees}
                    onChange={(e) =>
                      updateServiceDetails(
                        e.target.value.replace(/\D/, ""),
                        "fees"
                      )
                    }
                  />
                </div>
                {serviceDetailsError.fees ? (
                  <div className="input-error">{serviceDetailsError.fees}</div>
                ) : null}
              </div>
              <div className="col-6 pr-0 mb-3">
                <label for="exampleFormControlInput1" className="form-label">
                  Service Time
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="far fa-hourglass" />
                    </div>
                  </div>
                  <select
                    className="form-select form-control"
                    aria-label="Service Time"
                    value={selectedServiceTime}
                    onChange={selectServiceTime}
                  >
                    {serviceTimeHours.map((loc, index) => {
                      return (
                        <option key={index} value={loc.value}>
                          {loc.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-12 appointment-type-wrapper mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Appointment Type
                </label>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="serviceMode"
                    id="onlineServiceMode"
                    checked={selectedServiceMode === 1}
                    onChange={() => changeServiceMode(1)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="onlineServiceMode"
                  >
                    Online Only
                  </label>
                </div>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="serviceMode"
                    id="offlineServiceMode"
                    checked={selectedServiceMode === 2}
                    onChange={() => changeServiceMode(2)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="offlineServiceMode"
                  >
                    Offline Only
                  </label>
                </div>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="serviceMode"
                    id="bothServiceMode"
                    checked={selectedServiceMode === 3}
                    onChange={() => changeServiceMode(3)}
                  />
                  <label className="form-check-label" htmlFor="bothServiceMode">
                    Both Online and Offline
                  </label>
                </div>
              </div>
              <div className="col-12 p-0 mb-3">
                <label for="exampleFormControlTextarea1" className="form-label">
                  Description
                </label>
                <textarea
                  className={`form-control ${
                    serviceDetailsError.description ? "error-border" : ""
                  }`}
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Short description about the service..."
                  value={serviceDetails.description}
                  onChange={(e) =>
                    updateServiceDetails(e.target.value, "description")
                  }
                ></textarea>
                {serviceDetailsError.description ? (
                  <div className="input-error">
                    {serviceDetailsError.description}
                  </div>
                ) : null}
              </div>
            </div>
            {profileType === 2 ? (
              <div
                className="row"
                style={{
                  margin: 0,
                }}
              >
                <label for="exampleFormControlInput1" className="form-label">
                  Service Location
                </label>

                {individualLocations && individualLocations.length ? (
                  <>
                    <select
                      className="form-select form-control"
                      aria-label="Service Location"
                      value={selectedIndividualLocation}
                      onChange={selectIndividualLocation}
                    >
                      {individualLocations.map((loc, index) => {
                        return (
                          <option key={index} value={loc._id}>
                            {loc.title}
                          </option>
                        );
                      })}
                    </select>
                    {selectedIndividualLocationObj &&
                    selectedIndividualLocationObj.workingTimeSlots ? (
                      <div>
                        Service Duration:{" "}
                        {getDuration(
                          selectedIndividualLocationObj.workingTimeSlots
                        )}
                      </div>
                    ) : null}
                  </>
                ) : null}

{locationsForOrganization && locationsForOrganization.length ?
                                        <div className="col-12 p-0 mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Service Location</label>
                                            <select className="form-select form-control" aria-label="Service Location" value={selectedLocationForOrganization} onChange={selectLocationForOrganization}>
                                                {locationsForOrganization.map((loc, index) => {
                                                    return <option key={index} value={loc._id}>{loc.title}</option>
                                                })}
                                            </select>
                                            {selectedLocationForOrganizationObj && selectedLocationForOrganizationObj.workingTimeSlots ?
                                                <div>
                                                    Service Duration: {getDuration(selectedLocationForOrganizationObj.workingTimeSlots)}
                                                </div> : null}
                                        </div> : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ServiceManager;
