import React, { useEffect, useState } from "react";
import { Table, Form, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditUser from "./edit-user";
import RemoveUser from "./remove-user";
import moment from "moment";
import { listUser } from "../../../crud/user.crud";
import CustomPagination from "../../../components/CustomPagination";
import { Tooltip } from "@material-ui/core";
import NoDataFound from "../../../components/NoDataFound";
import StyleDropdown from "../../../components/StyleDropdown";
import userConfig from "../../../config/user";

const UserList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showUserEdit, setShowUserEdit] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showUserDelete, setShowUserDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  // const [searchType, setSearchType] = useState(undefined);
  // const [searchKey, setSearchKey] = useState(undefined);
  const [selectedGender, setSelectedGender] = useState(undefined);
  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.user.listData) {
      let listObject = {
        data: [...props.data.user.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.user.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.user.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.user.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listUser({ skip: tableData.skip, limit: tableData.limit, payload:{
      selectedGender: selectedGender
    } }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listUser({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowUserEdit(false);
  };

  const handleCloseDelete = () => {
    setShowUserDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowUserEdit(true);
  };

  const openDeleteModal = (data) => {

    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowUserDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  const renderGender = (selectedGender) => {
    let genderText = "N/A";
    switch (selectedGender) {
      case 1:
        genderText = "Male";
        break;
      case 2:
        genderText = "Female";
        break;
      case 3:
        genderText = "Other";
        break;
      default:
        break;
    }
    return genderText;
  };

  const searchList = (userPayload) => {
    setPage(1);
    listUser({
      skip: 0,
      limit: tableData.limit,
      payload: {
        searchKey: userPayload.searchKey,
        searchType: userPayload.searchType,
        status: userPayload.status,
        createdAt: userPayload.createdAt,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleSearch = (name) => (event) => {
    switch (name) {
      case "customerName":
        setCustomerName(event.target.value);

        if (event.target.value.length > 3) {
          // setSearchKey(event.target.value);
          // setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 1,
          });
        } else {
          // setSearchKey(undefined);
          // setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,

          });
        }
        break;
      case "customerPhone":
        setCustomerPhone(event.target.value);
        if (event.target.value.length > 3) {
          // setSearchKey(event.target.value);
          // setSearchType(3);
          searchList({
            searchKey: event.target.value,
            searchType: 3,
          });
        } else {
          // setSearchKey(undefined);
          // setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
          });
        }
        break;
      case "customerEmail":
        setCustomerEmail(event.target.value);
        if (event.target.value.length > 3) {
          // setSearchKey(event.target.value);
          // setSearchType(2);
          searchList({
            searchKey: event.target.value,
            searchType: 2,
          });
        } else {
          // setSearchKey(undefined);
          // setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
          });
        }
        break;
      default:
        break;
    }
  };

  const genderHandler = (gender) => {
    setSelectedGender(gender);
  };

  useEffect(()=>{
    reloadList();
    // eslint-disable-next-line
  }, [selectedGender])

  return (
    <React.Fragment>
      {showUserEdit ? (
        <EditUser
          user={selectedData}
          show={showUserEdit}
          handleClose={handleCloseEdit}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showUserDelete ? (
        <RemoveUser
          user={selectedData}
          show={showUserDelete}
          handleClose={handleCloseDelete}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'customer'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}

      <React.Fragment>
        <Table bordered hover style={{ tableLayout: "fixed" }}>
          <thead className="custom-table-header">
            <tr>
              <th className="custom-table-header-text">SL No</th>
              {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text">ID</th>: null}
              {/* <th className="custom-table-header-text">Profile</th> */}
              <th className="custom-table-header-text">Registration Date</th>
              <th className="custom-table-header-text">Name</th>
              <th className="custom-table-header-text">Phone</th>
              <th className="custom-table-header-text">Email</th>
              <th className="custom-table-header-text">Gender</th>
              <th className="custom-table-header-text">DOB</th>
              <th className="custom-table-header-text">Action</th>
            </tr>
          </thead>
            <tbody>
              <tr>
                <th></th>
                {userInfo && userInfo.roleInfo.isSuperAdmin? <th></th>: null}
                <th></th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={customerName}
                    onChange={handleSearch("customerName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={customerPhone}
                    onChange={handleSearch("customerPhone")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={customerEmail}
                    onChange={handleSearch("customerEmail")}
                  />
                </th>
                <th style={{textAlign:'center'}}>
                    <StyleDropdown>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {selectedGender? userConfig.gender[selectedGender]: 'All'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                      <Dropdown.Item
                          onClick={() => {
                            genderHandler(undefined);
                          }}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            genderHandler(1);
                          }}
                        >
                          Male
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            genderHandler(2);
                          }}
                        >
                          Female
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            genderHandler(3);
                          }}
                        >
                          Other
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </StyleDropdown>
                  </th>
              </tr>
              {!!tableData.data.length && tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{tableData.skip+key+1}</td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin? <td style={{ wordWrap: "break-word" }} className="custom-table-body-text">{eachData._id}</td>: null}
                    <td> {eachData.createdAt
                        ? moment(eachData.createdAt).format(
                          "DD-MMM-YYYY, hh:mm A"
                        )
                        : "N/A"}</td>
                    {/* <td>
                      {eachData.personalInfo.profilePicture ? (
                        <Image
                          src={eachData.personalInfo.profilePicture}
                          style={{
                            height: "120px",
                          }}
                        />
                      ) : (
                          <Image
                            alt={`user logo`}
                            src={toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )}
                            style={{
                              height: "120px",
                            }}
                          />
                        )}
                    </td> */}
                    <td className="custom-table-body-text">
                      {eachData.personalInfo.name
                        ? eachData.personalInfo.name
                        : "N/A"}
                    </td>
                    <td className="custom-table-body-text">{`${eachData.personalInfo.phone.dialCode}-${eachData.personalInfo.phone.number}`}</td>
                    <td style={{ wordWrap: "break-word" }} className="custom-table-body-text">
                      {eachData.personalInfo.email
                        ? eachData.personalInfo.email
                        : "N/A"}
                    </td>
                    <td className="custom-table-body-text">{renderGender(eachData.personalInfo.gender)}</td>
                    <td className="custom-table-body-text">
                      {eachData.personalInfo.dob
                        ? moment(eachData.personalInfo.dob).format(
                          "DD-MMM-YYYY"
                        )
                        : "N/A"}
                    </td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                         <Tooltip title="Edit">
                          <i className="la la-edit"></i>
                         </Tooltip>
                      </p>
                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
        </Table>

        {tableData.data.length === 0 ? (
          <NoDataFound/>
        ) : null}
        {tableData.data.length ? (<CustomPagination
          count={tableData.totalPage}
          color="primary"
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded" 
        />) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
