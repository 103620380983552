import React from 'react';
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import bookingConfig from "./../../../../config/booking";

export default function WorkshopPaymentList({ tableDataWorkshop, selectedDate }) {
    return (<Table bordered hover>
        <thead className="custom-table-header">
            <tr>
                <th className="custom-table-header-text">SL No</th>
                <th className="custom-table-header-text">Workshop Name</th>
                <th className="custom-table-header-text">Professional Name</th>
                <th className="custom-table-header-text">Professional Phone</th>
                <th className="custom-table-header-text">Total Fees</th>
                <th className="custom-table-header-text">Commission</th>
                <th className="custom-table-header-text">Payout Status</th>
                <th className="custom-table-header-text">Action</th>
            </tr>
        </thead>
        <tbody>
            {tableDataWorkshop.data.length ? tableDataWorkshop.data.map((eachData, key) => {
                return (
                    <tr key={`${eachData._id}`} className={
                        key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                      }>
                        <td className="custom-table-body-text">{`${tableDataWorkshop.skip + key + 1}`}</td>
                        <td className="custom-table-body-text">{eachData.workshop.title}</td>
                        <td className="custom-table-body-text">
                            {eachData?.vendorRef?.personalInfo.name
                                ? eachData?.vendorRef?.personalInfo.name
                                : "N/A"}
                        </td>
                        <td className="custom-table-body-text">{`${eachData?.vendorRef?.personalInfo.phone.dialCode}-${eachData?.vendorRef?.personalInfo.phone.number}`}</td>

                        <td className="custom-table-body-text">{eachData.totalFees.toFixed(2)}</td>
                        <td className="custom-table-body-text">{eachData.commission.toFixed(2)}</td>
                        <td className="custom-table-body-text">{bookingConfig.transferStatus[eachData.status]}</td>
                        <td className="text-center">
                            <Link to={{ pathname: `/payment-details/${eachData.workshop._id}/workshop`, query: { selectedDate: selectedDate } }}>
                                <p
                                    className="btn btn-icon custom-table-btn"
                                    style={{
                                        margin: "0",
                                    }}
                                >
                                    <i className="la la-eye"></i>
                                </p>
                            </Link>
                        </td>
                    </tr>
                );
            }) : null}
        </tbody>
    </Table>);
}
