import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
export default function HasCheque({ hasCheque, setHasCheque }) {

    const handleChangeCheque = (event) => {
        setHasCheque(event.target.checked);
    };

    return (
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Has Cheque</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={hasCheque} onChange={handleChangeCheque} name="hasCheque" />}
                        label={hasCheque ? "Yes" : "No"}
                    />
                </FormGroup>
            </FormControl>
        </div>

    );
}
