import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import EditFaq from "./edit-faq";
import RemoveFaq from "./remove-faq";
import { listFaq } from "../../../crud/faq.crud";
import FullWidthTabs from "../../common/FullWidthTabs";
// import InputBase from "@material-ui/core/InputBase";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import * as faq from "../../../store/ducks/faq.duck";
import userConfig from './../../../config/user';
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const FaqList = (props) => {
  const { data: { faq: { faqAdded, faqUpdated } }, faqAddedDone, faqUpdatedDone } = props;
  const [currentTab, setCurrentTab] = useState(props.data.faq.currTab);
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showFaqEdit, setShowFaqEdit] = useState(false);
  // const [openInput, setOpenInput] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showFaqDelete, setShowFaqDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    setPage(1);
    reloadList(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.faq.listData) {
      let listObject = {
        data: [...props.data.faq.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.faq.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.faq.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.faq.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = useCallback((skipZero) => {
    if (currentTab === 0) {
      listFaq({
        skip: skipZero ? 0 : tableData.skip,
        limit: tableData.limit,
        userType: userConfig.role.customer
      }).then((docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    } else if (currentTab === 1) {
      listFaq({
        skip: skipZero ? 0 : tableData.skip,
        limit: tableData.limit,
        userType: userConfig.role.vendor
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: skipZero ? 0 : tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    }
  }, [currentTab, tableData]);

  const handleChange = (_event, value) => {
    setPage(value);
    if (currentTab === 0) {
      listFaq({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        userType: userConfig.role.customer
      }).then((docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    } else if (currentTab === 1) {
      listFaq({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        userType: userConfig.role.vendor
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    }
  };

  const handleCloseEdit = () => {
    setShowFaqEdit(false);
  };

  const handleCloseDelete = () => {
    setShowFaqDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowFaqEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowFaqDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  useEffect(() => {
    if (faqAdded) {
      reloadList();
      faqAddedDone();
    }
  }, [faqAdded, reloadList, faqAddedDone]);

  useEffect(() => {
    if (faqUpdated) {
      reloadList();
      faqUpdatedDone();
    }
  }, [faqUpdated, reloadList, faqUpdatedDone]);

  return (
    <React.Fragment>
      {showFaqEdit ? (
        <EditFaq
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          faq={selectedData}
          show={showFaqEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showFaqDelete ? (
        <RemoveFaq
          faq={selectedData}
          show={showFaqDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'faq'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changeFAQTab(tab);
          setCurrentTab(tab);
        }}
        title={"FAQs"}
        tabsTitle={["Customer", "Professional"]}
      />
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text">ID</th>: null}
                <th className="custom-table-header-text">Title</th>
                <th className="custom-table-header-text">Description</th>
                {/* <th className="custom-table-header-text">Order</th> */}
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">
                      {tableData.skip+key+1}
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin?
                    <td className="custom-table-body-text">{eachData._id}</td> : null}
                    <td className="custom-table-body-text elipses-text-td">{eachData.title}</td>
                    <td className="custom-table-body-text elipses-text-td">{eachData.description}</td>
                    {/* <td
                      style={{ width: "10%" }}
                      onClick={() => setOpenInput(true)}
                    >
                      {!openInput ? (
                        <p>{eachData.order}</p>
                      ) : (
                          <InputBase
                            defaultValue={eachData.order}
                            onBlur={(event) => {
                              changeOrderFaq(eachData._id, {
                                newOrder: event.target.value,
                              }).then(() => {
                                reloadList();
                              });
                            }}
                          />
                        )}
                    </td> */}
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
      {tableData.totalPage ? <CustomPagination
        count={tableData.totalPage}
        color="primary"
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
      /> : null}

    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFAQTab: (tab) => dispatch(faq.actions.changeFAQTab(tab)),
    faqAddedDone: () => dispatch(faq.actions.faqAddedDone()),
    faqUpdatedDone: () => dispatch(faq.actions.faqUpdatedDone()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqList);
