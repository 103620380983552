import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Dropdown, Form } from "react-bootstrap";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  listWorkshop,
  approveWorkshopDetails,
  rejectWorkshopDetails,
  deleteWorkshopDetails
} from "../../../crud/workshop.crud";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import FullWidthTabs from "../../common/FullWidthTabs";
import ApproveWorkshopModal from "./modal/approve-modal";
import RejectWorkshopModal from "./modal/reject-modal";
import RemoveModal from "./modal/remove-modal";

import bookingConfig from "../../../config/booking";
import workshopConfig from "../../../config/workshop";
import * as workshopDuck from "../../../store/ducks/workshop.duck";
import * as notification from "./../../../store/ducks/notification.duck";

import CustomPagination from "../../../components/CustomPagination";
import StyleDropdown from "../../../components/StyleDropdown";
import { colorsCodes } from "../../../config/constants";
import NoDataFound from "../../../components/NoDataFound";
const WorkshopList = (props) => {
  const {tab} = props.data.notification;
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });
  const [currentTab, setCurrentTab] = useState(tab);
  const [selectedData, setSelectedData] = useState();
  const [page, setPage] = useState(1);
  const [showWorkshopApproval, setShowWorkshopApproval] = useState(false);
  const [showWorkshopApprovalWait, setShowWorkshopApprovalWait] = useState(false);
  const [showWorkshopRejection, setShowWorkshopRejection] = useState(false);
  const [workshopName, setWorkshopName] = useState("");
  const [professionalName, setProfessionalName] = useState("");
  const [professionalPhone, setProfessionalPhone] = useState("");
  const [searchType, setSearchType] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Select Status"
  );
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showDeleteWorkshop, setShowDeleteWorkshop] = useState(false);
  useEffect(() => {
    reloadList();
    return ()=>{
      props.updateTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  useEffect(() => {
    if (props.data.workshop.listData) {
      let listObject = {
        data: [...props.data.workshop.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.workshop.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.workshop.listData.data.data.total / tableData.limit)
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.workshop.listData]);

  useEffect(() => {
    setPage(1);
    if (currentTab === 0) {
      listWorkshop({
        skip: 0,
        limit: tableData.limit,
        payload: { status: selectedStatus },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    } else if (currentTab === 1) {
      console.log('current tab', 1);
      listWorkshop({
        skip: 0,
        limit: tableData.limit,
        payload: { status: workshopConfig.serverStatus.approvalPending },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: 0,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const reloadList = () => {
    if (currentTab === 0) {
      listWorkshop({
        skip: tableData.skip,
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          status: selectedStatus,
        },
      }).then((docs) => {
        if (docs.data) {
          // console.log("docs.data.data", docs.data.data);
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
          setShowWorkshopApprovalWait(false);
        }
      });
    } else if (currentTab === 1) {
      listWorkshop({
        skip: tableData.skip,
        limit: tableData.limit,
        payload: { status: workshopConfig.serverStatus.approvalPending },
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });

          setShowWorkshopApprovalWait(false);
        }
      });
    }
  };

  const handleChange = (_workshop, value) => {
    setPage(value);
    if (currentTab === 0) {
      listWorkshop({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        payload: {
          searchKey: searchKey,
          searchType: searchType,
          status: selectedStatus,
        },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });
          setShowWorkshopApprovalWait(false);
        }
      });
    } else if (currentTab === 1) {
      listWorkshop({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        payload: { status: workshopConfig.serverStatus.approvalPending },
      }).then((docs) => {
        if (docs.data) {
          let listObject = {
            data: [...docs.data.data.data],
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          };
          setTableData({
            ...tableData,
            ...listObject,
          });

          setShowWorkshopApprovalWait(false);
        }
      });
    }
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  const renderDates = (dates) => {
    return (<span>
      {dates && dates.length > 0 ? moment(dates[0]).format("DD-MMM-YYYY") : null}
      {dates && dates.length > 1 && dates[0] === dates[dates.length - 1] ? null : dates && dates.length > 1 ? '-' + moment(dates[dates.length - 1]).format("DD-MMM-YYYY") : null}
    </span>)
  };

  const approvalConsent = (data) => {
    setSelectedData(data);
    setShowWorkshopApproval(true);
  };

  const rejectionConsent = (data) => {
    setSelectedData(data);
    setShowWorkshopRejection(true);
  };

  const approveWorkshop = (workshopId) => {
    approveWorkshopDetails(workshopId).then(() => {
      toast.success("Workshop is successfully approved!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const rejectWorkshop = (workshopId) => {
    rejectWorkshopDetails(workshopId).then(() => {
      toast.success("Workshop is successfully rejected!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      reloadList();
    });
  };

  const handleApprove = (approve) => {
    if (approve) {
      setShowWorkshopApprovalWait(true);
      approveWorkshop(selectedData._id);
    } else {
      rejectWorkshop(selectedData._id);
    }
  };

  const statusHandler = (status) => {
    setSelectedStatus(Number(status));
    switch (Number(status)) {
      case 2:
        setSelectedBookingStatus("Approved");
        break;
      case 3:
        setSelectedBookingStatus("Rejected");
        break;
      case 4:
        setSelectedBookingStatus("Completed");
        break;
      case 5:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 6:
        setSelectedBookingStatus("Failed");
        break;
      case 7:
        setSelectedBookingStatus("Canceled By Vendor");
        break;
      case 8:
        setSelectedBookingStatus("Auto Rejected");
        break;
      default:
        break;
    }
    listWorkshop({
      skip: tableData.skip,
      limit: tableData.limit,
      payload: {
        status: Number(status),
        searchKey: searchKey,
        searchType: searchType,

      },
    }).then((docs) => {
      if (docs.data) {
        // console.log("docs.data.data", docs.data.data);
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      } else {
      }
    });
  };

  const searchList = (bookingPayload) => {
    setPage(1);
    listWorkshop({
      skip: 0,
      limit: tableData.limit,
      payload: {
        searchKey: bookingPayload.searchKey,
        searchType: bookingPayload.searchType,
        status: bookingPayload.status,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleSearch = (name) => (workshop) => {
    switch (name) {
      case "professionalName":
        setProfessionalName(workshop.target.value);

        if (workshop.target.value.length > 3) {
          setSearchKey(workshop.target.value);
          setSearchType(1);
          searchList({
            searchKey: workshop.target.value,
            searchType: 1,
            status: selectedStatus,

          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,

          });
        }
        break;
      case "professionalPhone":
        setProfessionalPhone(workshop.target.value);
        if (workshop.target.value.length > 3) {
          setSearchKey(workshop.target.value);
          setSearchType(1);
          searchList({
            searchKey: workshop.target.value,
            searchType: 2,
            status: selectedStatus,

          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,

          });
        }
        break;
      case "workshopName":
        setWorkshopName(workshop.target.value);
        if (workshop.target.value.length > 3) {
          setSearchKey(workshop.target.value);
          setSearchType(1);
          searchList({
            searchKey: workshop.target.value,
            searchType: 3,
            status: selectedStatus,

          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            status: selectedStatus,

          });
        }
        break;
      default:
        break;
    }
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowDeleteWorkshop(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  const removeWorkshopData = () => {
    deleteWorkshopDetails(selectedData._id)
      .then(() => {
        setShowDeleteWorkshop(false);
        toast.success("Workshop is successfully deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reloadList();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 1:
        return colorsCodes.orange;
      case 2:
        return colorsCodes.blue;
      case 3:
        return colorsCodes.red;
      case 4:
        return colorsCodes.green;
      case 5:
        return colorsCodes.orange;
      case 6:
        return colorsCodes.red;
      case 7:
        return colorsCodes.red;
      case 8:
        return colorsCodes.red;
      default:
        return colorsCodes.red;
    }
    
  };

  return (
    <React.Fragment>
      <Backdrop
        style={{ zIndex: 1 }}
        open={showWorkshopApprovalWait}
      >
        <CircularProgress color="inherit" />
        <p style={{ marginLeft: 24, marginTop: 16, fontWeight: 600 }}> Please wait. This may take some time.</p>
      </Backdrop>
      {showWorkshopApproval ? (
        <ApproveWorkshopModal
          workshop={selectedData}
          show={showWorkshopApproval}
          handleClose={() => setShowWorkshopApproval(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
      {showWorkshopRejection ? (
        <RejectWorkshopModal
          workshop={selectedData}
          show={showWorkshopRejection}
          handleClose={() => setShowWorkshopRejection(false)}
          handleApprove={(approve) => handleApprove(approve)}
        />
      ) : null}
       {showDeleteWorkshop ? (
        <RemoveModal
          workshop={selectedData}
          show={showDeleteWorkshop}
          handleClose={() => setShowDeleteWorkshop(false)}
          handleDelete={removeWorkshopData}
        />
      ) : null}
      {showDeleteWarning ? (
        <DeleteWarningModal
          title={"workshop"}
          open={showDeleteWarning}
          handleClose={() => setShowDeleteWarning(false)}
        />
      ) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changeWorkshopTab(tab);
          setCurrentTab(tab);
        }}
        title={"Sessions"}
        tabsTitle={["All", "Pending Verification Requests"]}
      />

      <React.Fragment>
        <Table bordered hover style={{ tableLayout: "fixed" }}>
          <thead className="custom-table-header">
            <tr>
              <th className="custom-table-header-text" style={{fontSize: 12}}>SL No</th>
              {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text" style={{fontSize: 12}}>ID</th>: null}
              <th className="custom-table-header-text" style={{fontSize: 12}}>Workshop Name</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Professional Name</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Professional Phone</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Date</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Time</th>
              <th className="custom-table-header-text" style={{fontSize: 12, width: 155}}>Status</th>
              <th className="custom-table-header-text" style={{fontSize: 12}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentTab === 0 ? (
              <tr>
                <th></th>
                {userInfo && userInfo.roleInfo.isSuperAdmin? <th></th>: null}
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={workshopName}
                    onChange={handleSearch("workshopName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalName}
                    onChange={handleSearch("professionalName")}
                  />
                </th>
                <th style={{ verticalAlign: "top", padding: "2px" }}>
                  <Form.Control
                    type="text"
                    value={professionalPhone}
                    onChange={handleSearch("professionalPhone")}
                  />
                </th>
                <th></th>
                <th></th>
                <th>
                  <StyleDropdown>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus ? selectedBookingStatus : ''}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(workshopConfig.status).slice(1).map((eachStatus, key) => {
                        return (<Dropdown.Item
                          key={key}
                          onClick={() => {
                            statusHandler(eachStatus);
                          }}
                        >
                          {workshopConfig.status[eachStatus]}
                        </Dropdown.Item>)
                      })}
                    </Dropdown.Menu>
                  </StyleDropdown>
                </th>
                <th></th>
              </tr>
            ) : null}
            {tableData.data.length
              ? tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text" style={{fontSize: 12}}>
                    <Link to={`/workshop-details/${eachData._id}`}>
                      {tableData.skip+key+1}
                    </Link></td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin?
                    <td className="custom-table-body-text" style={{fontSize: 12, wordWrap: "break-word" }}>
                        {eachData._id}
                    </td> : null}
                    <td className="custom-table-body-text" style={{fontSize: 12}}>{eachData.title}</td>
                    <td className="custom-table-body-text" style={{fontSize: 12}}>{eachData.vendor.personalInfo.name}</td>
                    <td className="custom-table-body-text" style={{fontSize: 12}}>
                      {eachData.vendor.personalInfo.phone.dialCode}-
                        {eachData.vendor.personalInfo.phone.number}
                    </td>
                    <td className="custom-table-body-text" style={{fontSize: 12}}>{renderDates(eachData.dates)}</td>
                    <td className="custom-table-body-text" style={{fontSize: 12}}>{renderSlots(eachData.slots)}</td>
                    <td className="custom-table-body-text" style={{fontSize: 12, color: renderStatusColor(eachData.status)}}>{workshopConfig.status[eachData.status]}</td>
                    <td className="text-center">
                      {currentTab === 0 ? (
                        <>
                          <Link
                            className="btn btn-icon custom-table-btn"
                            to={`/workshop-details/${eachData._id}`}
                          >
                            <i className="la la-eye"></i>
                          </Link>
                          {eachData.status===2 && eachData.availableSeats === eachData.numberOfSeats ? <p
                              className="btn btn-icon custom-table-btn"
                              title="Delete"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                openDeleteModal(eachData);
                              }}
                            >
                              <i className="la la-trash kt-font-danger"></i>
                            </p>: null}
                        </>
                      ) : (
                          <>
                            <button
                              className="btn btn-icon custom-table-btn"
                              title={"Approve"}
                              onClick={() => approvalConsent(eachData)}
                              style={{
                                margin: "0",
                              }}
                              disabled={showWorkshopApprovalWait}
                            >
                              <i className="la la-check-circle"></i>
                            </button>

                            <p
                              className="btn btn-icon custom-table-btn"
                              title="Reject"
                              style={{
                                margin: "0",
                              }}
                              onClick={() => {
                                rejectionConsent(eachData);
                              }}
                            >
                              <i className="la la-close kt-font-danger"></i>
                            </p>
                            <Link
                              className="btn btn-icon custom-table-btn"
                              to={`/workshop-details/${eachData._id}`}
                            >
                              <i className="la la-eye"></i>
                            </Link>
                          </>
                        )}
                    </td>
                  </tr>
                );
              })
              : null}
          </tbody>
        </Table>

        {tableData.data.length === 0 ? (
          <NoDataFound/>
        ) : null}
        {tableData.data.length ? (
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeWorkshopTab: (tab) => dispatch(workshopDuck.actions.changeWorkshopTab(tab)),
    updateTab: (data) => dispatch(notification.actions.updateTab({selectedTab: data})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopList);
