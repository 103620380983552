import {listProblem} from "../../crud/problem.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    PROBLEM_ADDED: "PROBLEM_ADDED",
    PROBLEM_ADDED_SUCCESS: "PROBLEM_ADDED_SUCCESS",
    PROBLEM_UPDATED: "PROBLEM_UPDATED",
    PROBLEM_UPDATED_SUCCESS: "PROBLEM_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.PROBLEM_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.PROBLEM_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    problemAdded: ({skip, limit}) => ({
        type: actionTypes.PROBLEM_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    problemUpdated: ({skip, limit}) => ({
        type: actionTypes.PROBLEM_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.PROBLEM_ADDED, function * problemAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listProblem({skip, limit});

        yield put({type: actionTypes.PROBLEM_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.PROBLEM_UPDATED, function * problemUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listProblem({skip, limit});

        yield put({type: actionTypes.PROBLEM_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
