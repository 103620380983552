import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { Table, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getEventDetails } from "./../../../crud/event.crud";
import moment from "moment";
import bookingConfig from "././../../../config/booking";
import eventConfig from "./../../../config/event";
import LinesEllipsis from 'react-lines-ellipsis'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
  starWrapper: {
    "& img": {
      marginRight: 8,
    },
  },
  icon: {
    color: "#E12936",
  },
  detailsParagraph: {
    display: "block",
    marginBottom: "5px",
  },
  fullWidth: {
    width: "100%",
  },
  stat: {
    padding: "8px 0px",
    margin: 0,
    fontSize: "18px",
  },
}));

const SessionDetails = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { sessionId } = useParams();
  const [sessionDetailsInfo, setSessionDetailsInfo] = useState();
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Confirmed"
  );
  const [selectedStatus, setSelectedStatus] = useState(2);

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  useEffect(() => {
    getEventDetails(sessionId).then((output) => {
      setSessionDetailsInfo({
        ...sessionDetailsInfo,
        ...output.data.data,
      });
      statusHandler(output.data.data.status);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  const statusHandler = (status) => {
    setSelectedStatus(Number(status));
    switch (Number(status)) {
      case 1:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 2:
        setSelectedBookingStatus("Confirmed");
        break;
      case 3:
        setSelectedBookingStatus("Completed");
        break;
      case 4:
        setSelectedBookingStatus("Failed");
        break;
      case 5:
        setSelectedBookingStatus("Not Joined");
        break;
      default:
        break;
    }
  };

  if (!sessionDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }}>
              <div className={classes.flexBox}>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                  <h5>{sessionDetailsInfo.title}</h5>
                  <span className={classes.detailsParagraph}>
                    <LinesEllipsis text={sessionDetailsInfo.description}
                      maxLine='3'
                      ellipsis='...'
                      trimRight
                      basedOn='letters'>
                    </LinesEllipsis>
                  </span>
                  <hr />
                  <span className={classes.detailsParagraph}>
                    <span>Fees -&nbsp;</span>
                    <span>Rs. {sessionDetailsInfo.eventFees}</span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Date -&nbsp;</span>
                    <span>
                      {moment(sessionDetailsInfo.date).format("DD-MMM-YYYY")}
                    </span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Time -&nbsp;</span>
                    <span>{renderSlots(sessionDetailsInfo.slots)}</span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Meeting Platform -&nbsp;</span>
                    <span>{sessionDetailsInfo.meetingPlatform}</span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Meeting Link -&nbsp;</span>
                    <span>
                      <a href={sessionDetailsInfo.meetingLink}>
                        {sessionDetailsInfo.meetingLink}
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
              <div className={classes.flexBox}>
                <div className={`mr-3`}>
                  <img
                    src={
                      sessionDetailsInfo.vendorRef.personalInfo.profilePicture
                        ? sessionDetailsInfo.vendorRef.personalInfo
                          .profilePicture
                        : toAbsoluteUrl(
                          `/media/default-image/default-image.png`
                        )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                  <h5>{sessionDetailsInfo.vendorRef.personalInfo.name}</h5>
                  <div className={classes.detailsParagraph}>
                    <LinesEllipsis text={sessionDetailsInfo.vendorRef.personalInfo.description
                      ? sessionDetailsInfo.vendorRef.personalInfo.description
                      : "N/A"}
                      maxLine='3'
                      ellipsis='...'
                      trimRight
                      basedOn='letters'>
                    </LinesEllipsis>
                  </div>
                  <hr />
                  <div className={classes.detailsParagraph}>
                    <span>Contact no -&nbsp;</span>
                    <span>
                      {sessionDetailsInfo.vendorRef.personalInfo.phone.dialCode}
                      {sessionDetailsInfo.vendorRef.personalInfo.phone.number}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Email -&nbsp;</span>
                    <span>
                      {sessionDetailsInfo.vendorRef.personalInfo.email}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Experience -&nbsp;</span>
                    <span>
                      {
                        sessionDetailsInfo.vendorRef.personalInfo.experience
                          .year
                      }{" "}
                      years
                    </span>{" "}
                    &nbsp;
                    {sessionDetailsInfo.vendorRef.personalInfo.experience
                      .month ? (
                        <span>
                          {
                            sessionDetailsInfo.vendorRef.personalInfo.experience
                              .month
                          }{" "}
                        months
                        </span>
                      ) : null}
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Categories -&nbsp;</span>
                    <span>
                      {sessionDetailsInfo.vendorRef.personalInfo.categories.map(
                        (eachCat, key) => {
                          return (
                            <span key={key} className={classes.categoryTag}>
                              {eachCat.title}
                            </span>
                          );
                        }
                      )}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Website -&nbsp;</span>
                    <span>
                      {sessionDetailsInfo.vendorRef.personalInfo.website
                        ? sessionDetailsInfo.vendorRef.personalInfo.website
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p className={classes.stat}>
                Total Seats: {sessionDetailsInfo.numberOfSeats}
              </p>
              <p className={classes.stat}>
                Booked Seats:{" "}
                {sessionDetailsInfo.numberOfSeats -
                  sessionDetailsInfo.availableSeats}
              </p>
              <p className={classes.stat}>
                Available Seats: {sessionDetailsInfo.availableSeats}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <div style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                display: 'flex',
                paddingBottom: '12px'
              }}>
                <h4 className="mb-3">Session Bookings</h4>
                <div>
                  <span className="mb-3" style={{
                    display: 'inline-block',
                    fontSize: 16,
                    paddingRight: 16,
                    fontWeight: 500
                  }}>Status</span>
                  <Dropdown style={{ display: 'inline-block' }}>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus ? selectedBookingStatus : ''}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(eventConfig.bookingStatus).slice(1).map((eachStatus, key) => {
                        return (<Dropdown.Item
                          key={key}
                          onClick={() => {
                            statusHandler(eachStatus);
                          }}
                        >
                          {eventConfig.bookingStatus[eachStatus]}
                        </Dropdown.Item>)
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Service Fees</th>
                    <th>GST</th>
                    <th>Convenience Fees</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Time</th>
                  </tr>
                </thead>
                {sessionDetailsInfo.eventBookings.filter((e) => e.status === selectedStatus).length ? (
                  <tbody>
                    {sessionDetailsInfo.eventBookings.filter((e) => e.status === selectedStatus).map(
                      (eventBookingDoc, key) => {
                        return (
                          <tr key={key}>
                            <td>{eventBookingDoc.bookingForName}</td>
                            <td>
                              {
                                eventBookingDoc.customerRef.personalInfo.phone
                                  .dialCode
                              }
                              -
                              {
                                eventBookingDoc.customerRef.personalInfo.phone
                                  .number
                              }
                            </td>

                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.serviceFee}</td>
                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.gst}</td>
                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.convenienceFee}
                            </td>
                            <td><i class="fas fa-rupee-sign" style={{
                              fontSize: 12,
                              color: '#555',
                              paddingRight: 1
                            }}></i>{eventBookingDoc.paymentDetails.total}</td>
                            <td>
                              {
                                eventConfig.bookingStatus[
                                eventBookingDoc.status
                                ]
                              }
                            </td>
                            <td>
                              {moment(eventBookingDoc.updatedAt).format("DD-MMM-YYYY hh:mm A")}
                            </td>

                          </tr>
                        );
                      }
                    )}
                  </tbody>
                ) : (
                    <tr>
                      <td colSpan={7} align={"center"}>
                        No Booking Found!
                    </td>
                    </tr>
                  )}
              </Table>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <h4 className="mb-3">Rating & Feedback</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Rating</th>
                    <th>Feedback</th>
                  </tr>
                </thead>
                {sessionDetailsInfo.ratingDetails.length ? (
                  <tbody>
                    {sessionDetailsInfo.ratingDetails.map(
                      (doc, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Rating defaultValue={doc.rating}
                                size="large"
                                readOnly
                              />
                            </td>
                            <td>
                              {doc.feedback}
                            </td>

                          </tr>
                        );
                      }
                    )}
                  </tbody>
                ) : (
                    <tr>
                      <td colSpan={7} align={"center"}>
                        No Rating and Feedback Found!
                    </td>
                    </tr>
                  )}
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default SessionDetails;
