import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { removeCategory } from "../../../crud/problem-category.crud";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { connect } from "react-redux";
import * as problemCategory from "../../../store/ducks/problem-category.duck";
const RemoveProblemCategory = (props) => {
  const { classes } = props;
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    removeCategory(props.category._id)
      .then((output) => {
        if (output.data.success) {
          props.problemCategoryUpdated({
            skip: props.listOption.skip,
            limit: props.listOption.limit,
          });
          setLoading(false);
          toast.success("Problem Category is successfully deleted!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.handleClose();
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.category.categoryTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete this problem category?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            No
          </Button>

          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleDelete(props.category._id)}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    problemCategoryUpdated: ({ skip, limit }) =>
      dispatch(problemCategory.actions.problemCategoryUpdated({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(RemoveProblemCategory)
  )
);
