import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Form} from "react-bootstrap";
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    mb: {
        marginBottom: '1rem !important'
    },
    pall: {
        padding: '16px !important'
    },
    toRight: {
        textAlign: 'right'
    },
    uploadImageButtonFile: {
        width: 'auto',
        display: 'inline-block',
        padding: '8px 16px',
        position: 'relative',
        background: '#e1e1e1',
        borderRadius: '4px'
    },
    styleSpan: {
        fontSize: '12px',
        paddingLeft: '8px',
        verticalAlign: 'sub'
    },
    profileImageUploaderContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    }
});

const PCLanguageInputCard = (props) => {

    const classes = useStyles();

    const [errors,
        setErrors] = useState({title: null, icon: null});
    const [title,
        setTitle] = useState(props.pcLangInput.title);
    const [description,
        setDescription] = useState(props.pcLangInput.description);

    const options = [...props.languages.map((v)=>{
        return {
            label: v.name, 
            value: v._id
        };
    })];

    const [selectedLanguage, setSelectedLanguage] = useState(options[0]);

    useEffect(()=>{

        if(!props.pcLangInput.languageRef){
            props.updatePCLangInput({
                ...props.pcLangInput,
                languageRef: props.languages[0]._id
            });
        }
        
        if(props.pcLangInput.languageRef){
            let ln = props.languages.filter((v)=>v._id===props.pcLangInput.languageRef)[0];
            setSelectedLanguage({
                label: ln.name,
                value: ln._id
            });  
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);

        props.updatePCLangInput({
            ...props.pcLangInput,
            title: event.target.value
        });

        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    }

    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        
        props.updatePCLangInput({
            ...props.pcLangInput,
            description: event.target.value
        });
        
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    return (
        <Card className={classes.root}>
            <CardContent className={classes.pall}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Form.Group className={classes.mb}>
                                <Form.Label>Language</Form.Label>
                                <Select value={selectedLanguage} defaultValue={selectedLanguage} options={options} onChange={(e)=>{
                                    setSelectedLanguage(e);
                                    props.updatePCLangInput({
                                        ...props.pcLangInput,
                                        languageRef: e.value
                                    });                            
                                }}/>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={classes.toRight}>
                                <IconButton onClick={props.removePCLangInput} aria-label="Close">
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        </Grid>
                        
                    </Grid>

                    <Form.Group className={classes.mb}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            onChange={onTitleChangeHandler}
                            isInvalid={!!errors.title}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.title}
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className={classes.mb}>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                             as="textarea"
                             rows={4}
                             cols={50}
                            value={description}
                            onChange={onDescriptionChangeHandler}
                            isInvalid={!!errors.description}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.description}
                        </Form.Control.Feedback>

                    </Form.Group>
            </CardContent>
        </Card>
    );
};
export default PCLanguageInputCard;
