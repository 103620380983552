import React, { useEffect, useState } from "react";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import {
  getAssessmentStatsData,
  getTestClickReports,
} from "../../crud/dashboard.crud";
import { listTestPaperAll } from "../../crud/test-paper.crud";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FullWidthTabs from "../common/FullWidthTabs";
import Select from "react-select";
import { Chart } from "react-google-charts";
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { getVendorsAllStateList, listVendorByState, listVendorByAllState } from "../../crud/vendor.crud";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from "highcharts/modules/map";
import mapData from "./in-all-disputed.topo.json";
import proj4 from "proj4";

HighchartsMap(Highcharts);

export const options = {
  chart: {
    title: "",
  },
  width: "100%",
  height: 400,
};

const TestReport = ({ dashboardData }) => {
  return (
    <React.Fragment>
      {/* OVERALL SELF ASSESMENT SUMMARY */}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>OVERALL SELF ASSESMENT SUMMARY</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingTillDate}
                    desc="Assesments Till Date"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingToday}
                    desc="Assesments Today"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisWeek}
                    desc="Assesments this Week"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisMonth}
                    desc="Assesments this Month"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
      {/* SELF ASSESMENT AGE GROUP SUMMARY */}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>SELF ASSESMENT AGE GROUP SUMMARY</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingChild}
                    desc="Assesments taken by Child Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingTeenager}
                    desc="Assesments taken by Teenage Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingAdult}
                    desc="Assesments taken by Adult Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
      {/*  SELF ASSESMENT APPOINTMENT SUMMARY	*/}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>SELF ASSESMENT APPOINTMENT SUMMARY</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssesmentAppointmentSummary
                        .totalAppointmentsByUsersWhoBookedAfterTest
                    }
                    desc="Appointments By Users who booked after assesment"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssesmentAppointmentSummary
                        .totalAppointmentsWithRecommendedProfessional
                    }
                    desc="Appointments With Recommended Professional"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssesmentAppointmentSummary
                        .totalAppointmentsWithNonRecommendedProfessional
                    }
                    desc="Appointments With Non Recommended Professional"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>

      {/*  SELF ASSESMENT  AGE GROUP REPORT			*/}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>SELF ASSESMENT AGE GROUP REPORT</h3>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisWeekByChild}
                    desc="Assesments this week by Child Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisWeekByTeenager}
                    desc="Assesments this week by Teenage Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisWeekByAdult}
                    desc="Assesments this week by Adult Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisMonthByChild}
                    desc="Assesments this month by Child Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisMonthByTeenager}
                    desc="Assesments this month by Teenage Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.totalTestBookingThisMonthByAdult}
                    desc="Assesments this month by Adult Group"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
      {/*  SELF ASSESMENT CATEGORY REPORT		*/}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>SELF ASSESMENT CATEGORY REPORT</h3>
          </div>
          <div className="row">
            {Object.keys(dashboardData.weeklySummaryByCategory).length
              ? Object.keys(dashboardData.weeklySummaryByCategory).map(
                  (e, k) => {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-3" key={k}>
                        <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                          <PortletBody fluid={true}>
                            <QuickStatsChart
                              value={dashboardData.weeklySummaryByCategory[e]}
                              desc={`Assesments this week by this category ${e}`}
                            />
                          </PortletBody>
                        </Portlet>
                      </div>
                    );
                  }
                )
              : null}
          </div>
          <div className="row">
            {Object.keys(dashboardData.monthlySummaryByCategory).length
              ? Object.keys(dashboardData.monthlySummaryByCategory).map(
                  (e, k) => {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-3" key={k}>
                        <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                          <PortletBody fluid={true}>
                            <QuickStatsChart
                              value={dashboardData.monthlySummaryByCategory[e]}
                              desc={`Assesments this month by this category ${e}`}
                            />
                          </PortletBody>
                        </Portlet>
                      </div>
                    );
                  }
                )
              : null}
          </div>
        </div>
      </div>
      {/* ASSESMENT TAKEN AS REPORT */}
      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>ASSESMENT TAKEN AS REPORT</h3>
          </div>
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h4>Self</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.self?.total
                    }
                    desc="Assesments Till Date"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.self?.today
                    }
                    desc="Assesments Today"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.self?.thisWeek
                    }
                    desc="Assesments this Week"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.self?.thisMonth
                    }
                    desc="Assesments this Month"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h4>Parents</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.parents?.total
                    }
                    desc="Assesments Till Date"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.parents?.today
                    }
                    desc="Assesments Today"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.parents
                        ?.thisWeek
                    }
                    desc="Assesments this Week"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.parents
                        ?.thisMonth
                    }
                    desc="Assesments this Month"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h4>Gurdian</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.guardian?.total
                    }
                    desc="Assesments Till Date"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.guardian?.today
                    }
                    desc="Assesments Today"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.guardian
                        ?.thisWeek
                    }
                    desc="Assesments this Week"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.guardian
                        ?.thisMonth
                    }
                    desc="Assesments this Month"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h4>Partner</h4>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.partner?.total
                    }
                    desc="Assesments Till Date"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.partner?.today
                    }
                    desc="Assesments Today"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.partner
                        ?.thisWeek
                    }
                    desc="Assesments this Week"
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={
                      dashboardData.selfAssessmentTakenAsReport?.partner
                        ?.thisMonth
                    }
                    desc="Assesments this Month"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div style={{ paddingTop: 24, paddingBottom: 12 }}>
            <h3>ASSESMENT VIEWER</h3>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3">
              <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={dashboardData.selfAssessmentViewers}
                    desc="Total"
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const TestClickReport = () => {
  const [page, setPage] = useState(1);
  const [totalTestClick, setTotalTestClick] = useState(0);
  const [todayClicks, setTodayClicks] = useState(0);
  const [thisWeekClicks, setThisWeekClicks] = useState(0);
  const [thisMonthClicks, setThisMonthClicks] = useState(0);
  const [testOptions, setTestOptions] = useState([]);
  const [selectedTestOption, setSelectedTestOption] = useState(null);
  const [
    selectedTestDateFilterOption,
    setSelectedTestDateFilterOption,
  ] = useState(null);
  const [
    selectedTestOptionWhoClicked,
    setSelectedTestOptionWhoClicked,
  ] = useState(null);
  const [
    selectedTestDateFilterOptionWhoClicked,
    setSelectedTestDateFilterOptionWhoClicked,
  ] = useState(null);
  const testDateFilterOptions = [
    {
      label: "All",
      value: 1,
    },
    {
      label: "Today",
      value: 2,
    },
    {
      label: "This Week",
      value: 3,
    },
    {
      label: "This Month",
      value: 4,
    },
  ];
  const testDateFilterOptionsGraph = [
    {
      label: "All",
      value: 1,
    },
    {
      label: "This Week",
      value: 3,
    },
    {
      label: "This Month",
      value: 4,
    },
  ];
  const [testClicksData, setTestClicksData] = useState([
    [{ type: "date", label: "Date" }, "Clicks"],
  ]);
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 5,
    total: 0,
    totalPage: 0,
  });

  const handleChange = (_event, value) => {
    setPage(value);

    getTestClickReports({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
      payload: {
        testRef: selectedTestOption ? selectedTestOption.value : undefined,
        testDateFilterOption: selectedTestDateFilterOption
          ? selectedTestDateFilterOption.value
          : undefined,
      },
    }).then((docs) => {
      if (docs.data.data.selfAssessmentViewersList) {
        let listObject = {
          data: [...docs.data.data.selfAssessmentViewersList.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.selfAssessmentViewersList.total,
          totalPage: Math.ceil(
            Number(
              docs.data.data.selfAssessmentViewersList.total / tableData.limit
            )
          ),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      }
    });
  };

  const handleChangeTestRef = (value) => {
    setPage(1);
    getTestClickReports({
      skip: 0,
      limit: 10,
      payload: {
        testRef: value,
        testDateFilterOption: selectedTestDateFilterOption
          ? selectedTestDateFilterOption.value
          : undefined,
      },
    }).then((docs) => {
      if (docs.data.data) {
        setTotalTestClick(docs.data.data.selfAssessmentViewers);
        setTodayClicks(docs.data.data.todayClicks);
        setThisWeekClicks(docs.data.data.thisWeekClicks);
        setThisMonthClicks(docs.data.data.thisMonthClicks);
        const tcd = [
          [{ type: "date", label: "Date" }, "Clicks"],
          ...docs.data.data.clickPerDate.map((e) => {
            return [new Date(e[0]), e[1]];
          }),
        ];
        setTestClicksData(tcd);
      }
    });
  };
  const handleChangeTestRefWhoClicked = (value) => {
    setPage(1);
    getTestClickReports({
      skip: 0,
      limit: 10,
      payload: {
        testRef: value,
        testDateFilterOption: selectedTestDateFilterOptionWhoClicked
          ? selectedTestDateFilterOptionWhoClicked.value
          : undefined,
      },
    }).then((docs) => {
      if (docs.data.data) {
        setTableData({
          data: [...docs.data.data.selfAssessmentViewersList.data],
          skip: 0,
          limit: docs.data.data.selfAssessmentViewersList.limit,
          total: docs.data.data.selfAssessmentViewersList.total,
          totalPage: Math.ceil(
            Number(
              docs.data.data.selfAssessmentViewersList.total /
                docs.data.data.selfAssessmentViewersList.limit
            )
          ),
        });
      }
    });
  };
  const handleChangeTestDateFilter = (value) => {
    setPage(1);
    getTestClickReports({
      skip: 0,
      limit: 10,
      payload: {
        testRef: selectedTestOption ? selectedTestOption.value : undefined,
        testDateFilterOption: value,
      },
    }).then((docs) => {
      if (docs.data.data) {
        setTotalTestClick(docs.data.data.selfAssessmentViewers);
        setTodayClicks(docs.data.data.todayClicks);
        setThisWeekClicks(docs.data.data.thisWeekClicks);
        setThisMonthClicks(docs.data.data.thisMonthClicks);
        const tcd = [
          [{ type: "date", label: "Date" }, "Clicks"],
          ...docs.data.data.clickPerDate.map((e) => {
            return [new Date(e[0]), e[1]];
          }),
        ];
        setTestClicksData(tcd);
      }
    });
  };
  const handleChangeTestDateFilterWhoClicked = (value) => {
    setPage(1);
    getTestClickReports({
      skip: 0,
      limit: 10,
      payload: {
        testRef: selectedTestOptionWhoClicked
          ? selectedTestOptionWhoClicked.value
          : undefined,
        testDateFilterOption: value,
      },
    }).then((docs) => {
      if (docs.data.data) {
        setTableData({
          data: [...docs.data.data.selfAssessmentViewersList.data],
          skip: 0,
          limit: docs.data.data.selfAssessmentViewersList.limit,
          total: docs.data.data.selfAssessmentViewersList.total,
          totalPage: Math.ceil(
            Number(
              docs.data.data.selfAssessmentViewersList.total /
                docs.data.data.selfAssessmentViewersList.limit
            )
          ),
        });
      }
    });
  };
  useEffect(() => {
    listTestPaperAll().then((doc) => {
      if (doc.data.data) {
        let data = doc.data.data.data;
        if (data.length) {
          setTestOptions([
            { label: "All", value: null },
            ...data.map((e) => {
              return {
                label: e.title,
                value: e._id,
              };
            }),
          ]);
        }
      }
    });

    getTestClickReports({
      skip: 0,
      limit: 10,
      payload: {
        testRef: undefined,
      },
    }).then((docs) => {
      setTotalTestClick(docs.data.data.selfAssessmentViewers);
      setTodayClicks(docs.data.data.todayClicks);
      setThisWeekClicks(docs.data.data.thisWeekClicks);
      setThisMonthClicks(docs.data.data.thisMonthClicks);
      const tcd = [
        ["Date", "Clicks"],
        ...docs.data.data.clickPerDate.map((e) => {
          return [new Date(e[0]), e[1]];
        }),
      ];
      setTestClicksData(tcd);
      setTableData({
        data: [...docs.data.data.selfAssessmentViewersList.data],
        skip: 0,
        limit: docs.data.data.selfAssessmentViewersList.limit,
        total: docs.data.data.selfAssessmentViewersList.total,
        totalPage: Math.ceil(
          Number(
            docs.data.data.selfAssessmentViewersList.total /
              docs.data.data.selfAssessmentViewersList.limit
          )
        ),
      });
    });
  }, []);

  return (
    <React.Fragment>
      <div style={{ paddingTop: 24, paddingBottom: 12 }}>
        <h3>Test Clicks</h3>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsChart value={totalTestClick} desc="Total Clicks" />
            </PortletBody>
          </Portlet>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsChart value={todayClicks} desc="Today's Clicks" />
            </PortletBody>
          </Portlet>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsChart
                value={thisWeekClicks}
                desc="This Week's Clicks"
              />
            </PortletBody>
          </Portlet>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
            <PortletBody fluid={true}>
              <QuickStatsChart
                value={thisMonthClicks}
                desc="This Month's Clicks"
              />
            </PortletBody>
          </Portlet>
        </div>
      </div>
      <div
        className="row"
        style={{
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <div className="col-sm-12 col-md-6 col-lg-6"></div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <h6>Date Range</h6>
          <Select
            isSearchable={true}
            value={selectedTestDateFilterOption}
            onChange={(e) => {
              setSelectedTestDateFilterOption(e);
              handleChangeTestDateFilter(e.value);
            }}
            options={
              testDateFilterOptionsGraph.length
                ? testDateFilterOptionsGraph
                : []
            }
          />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <h6>Test</h6>
          <Select
            isSearchable={true}
            value={selectedTestOption}
            onChange={(e) => {
              setSelectedTestOption(e);
              handleChangeTestRef(e.value);
            }}
            options={testOptions.length ? testOptions : []}
          />
        </div>
      </div>
      {testClicksData.length > 1 ? (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={testClicksData}
          options={options}
        />
      ) : null}

      <div
        className="row"
        style={{
          paddingTop: 16,
          paddingBottom: 16,
        }}
      >
        <div className="col-sm-12 col-md-6 col-lg-6">
          <h3>Test Viewers</h3>
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <h6>Date Range</h6>
          <Select
            isSearchable={true}
            value={selectedTestDateFilterOptionWhoClicked}
            onChange={(e) => {
              setSelectedTestDateFilterOptionWhoClicked(e);
              handleChangeTestDateFilterWhoClicked(e.value);
            }}
            options={testDateFilterOptions.length ? testDateFilterOptions : []}
          />
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <h6>Test</h6>
          <Select
            isSearchable={true}
            value={selectedTestOptionWhoClicked}
            onChange={(e) => {
              setSelectedTestOptionWhoClicked(e);
              handleChangeTestRefWhoClicked(e.value);
            }}
            options={testOptions.length ? testOptions : []}
          />
        </div>
      </div>

      <React.Fragment>
        <Table striped bordered hover style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th>Test ID</th>
              <th>Test Name</th>
              <th>Customer Name</th>
              <th>View At</th>
              <th>Test Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData.data.length
              ? tableData.data.map((eachData, key) => {
                  return (
                    <tr key={key}>
                      <td style={{ wordWrap: "break-word" }}>
                        {eachData.testRef._id}
                      </td>
                      <td>{eachData.testRef.title}</td>
                      <td>
                        {eachData.customerRef
                          ? eachData.customerRef.personalInfo.name
                          : "Guest"}
                      </td>
                      <td>
                        {moment(eachData.createdAt).format(
                          "DD-MMM-YYYY hh:mm a"
                        )}
                      </td>
                      <td>
                        {eachData.testRef.status === 1 ? "Active" : "InActive"}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
        {tableData.data.length ? (
          <Pagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};

const VendorListAllStates = ({ currUser, stateList }) => {
  const [checked, setChecked] = React.useState(true);
  const [mapOptions, setMapOptions] = useState({
    chart: {
      map: mapData, 
      proj4, 
      height: 550
    },

    title: {
        text: ''
    },

    subtitle: {
        text: ''
    },

    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: 'bottom'
        }
    },

    colorAxis: {
        min: 0
    },

    series: [{
        data: [],
        name: 'Professionals',
        states: {
            hover: {
                color: '#FFEE11'
            }
        },
        dataLabels: {
            enabled: true,
            format: '{point.name}'
        }
    }]
  });

  const [hasData, setHasData] = useState(false);
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    listVendorByAllState({
      payload: {
        selectedState: undefined
      },
    }).then((docs) => {
      if (docs.data) {
        setMapOptions({
          ...mapOptions,
          series:[{
            ...mapOptions.series[0],
            data: docs.data.data
          }]
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(mapOptions.series[0].data.length){
      setHasData(true);
    }
  },[mapOptions])

  return (
    <React.Fragment>
       <Grid container justify="space-between" style={{marginTop: 12, marginBottom: 12}}>
          <Grid style={{width: '50%'}}>
          <FormGroup>
            <FormControlLabel control={<Switch 
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}/>} label="Map View" />
          </FormGroup>
          </Grid>
          <Grid>
          </Grid>
        </Grid>
        {
          hasData && checked ? <HighchartsReact
            highcharts={Highcharts}
            options={mapOptions}
            constructorType={'mapChart'}
          />: null 
        }
        {
          !checked ? <VendorList
          stateList={stateList}
          currUser={currUser}
        />: null
        }
    </React.Fragment>
  );
}

const VendorList = ({ currUser, stateList }) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });
  const [userInfo, setUserInfo] = useState(undefined);
  const [page, setPage] = useState(1);
  const [selectedState, setSelectedState] = useState(undefined);
  const [
    selectedStateDrownDown,
    setSelectedStateDrownDown,
  ] = useState(null);

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return "ACTIVE";
      case 2:
        return "BLOCKED";
      case 3:
        return "INACTIVE";
      case 4:
        return "APPROVAL PENDING";
      case 5:
        return "APPROVAL REJECTED";
      case 6:
        return "APPROVED";
      default:
        return "";
    }
  };

  const handleChange = (_event, value) => {
    setPage(value);
    listVendorByState({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
      payload: {
        selectedState: selectedState
      },
    }).then((docs) => {
      if (docs.data) {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      }
    });
  };

  const stateHandler = (currState)=>{
    setSelectedState(currState);
    setSelectedStateDrownDown({value: currState, label: currState});
    listVendorByState({
      skip: Number((page - 1) * tableData.limit),
      limit: tableData.limit,
      payload: {
        selectedState: currState
      },
    }).then((docs) => {
      if (docs.data) {
        let listObject = {
          data: [...docs.data.data.data],
          skip: Number((page - 1) * tableData.limit),
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        };
        setTableData({
          ...tableData,
          ...listObject,
        });
      }
    });
  };

  useEffect(() => {
    listVendorByState({
      skip: tableData.skip,
      limit: tableData.limit,
      payload: {
        selectedState: selectedState
      },
    }).then((docs) => {
      if (docs.data) {
        // console.log("docs.data.data", docs.data.data);
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      } else {
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserInfo(currUser);
  }, [currUser]);
  return (
    <React.Fragment>
       <Grid container justify="space-between" style={{marginTop: 12, marginBottom: 12}}>
          <Grid style={{width: '50%'}}>
          <Select
            isSearchable={true}
            value={selectedStateDrownDown}
            onChange={(e) => {
              stateHandler(e.value);
            }}
            options={
              stateList.length? stateList.map((val)=>{
                return {
                  value: val, label: val
                }
              }): []
            }
          />
          </Grid>
          <Grid>
            <Portlet className="kt-portlet--height-fluid-full kt-portlet--border-bottom-brand">
              <PortletBody fluid={true}>
                <QuickStatsChart
                  value={tableData.total}
                  desc="Total Professional"
                />
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      <Table striped bordered hover style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th style={{ width: "50px" }}>SL No</th>
            {userInfo && userInfo.roleInfo.isSuperAdmin ? <th>ID</th> : null}
            <th style={{ width: "150px" }}>Submitted At</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Experience</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tableData.data.length
            ? tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id}>
                    <td>{tableData.skip + key + 1}</td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                      <td style={{ wordWrap: "break-word" }}>{eachData._id}</td>
                    ) : null}
                    <td style={{ wordWrap: "break-word" }}>
                      {moment(eachData.submittedAt).format(
                        "DD/MM/YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td>
                      {eachData.personalInfo.name
                        ? eachData.personalInfo.name
                        : "N/A"}
                    </td>
                    <td>{`${eachData.personalInfo.phone.dialCode}-${eachData.personalInfo.phone.number}`}</td>
                    <td style={{ wordWrap: "break-word" }}>
                      {eachData.personalInfo.email
                        ? eachData.personalInfo.email
                        : "N/A"}
                    </td>
                    <td>{`${eachData.personalInfo.experience.year} years ${eachData.personalInfo.experience.month} months`}</td>
                    <td>{renderStatus(eachData.accountStatus)}</td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>

      {tableData.data.length === 0 ? (
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            fontSize: 20,
            height: "65%",
          }}
        >
          No Data Available
        </p>
      ) : null}
      {tableData.data.length ? (
        <Pagination
          count={tableData.totalPage}
          color="primary"
          page={page}
          onChange={handleChange}
        />
      ) : null}
    </React.Fragment>
  );
};

const Reports = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [dashboardData, setDashboardData] = useState({
    totalTestBookingTillDate: "",
    totalTestBookingToday: "",
    totalTestBookingThisWeek: "",
    totalTestBookingThisMonth: "",

    totalTestBookingChild: "",
    totalTestBookingTeenager: "",
    totalTestBookingAdult: "",

    totalTestBookingThisWeekByChild: "",
    totalTestBookingThisWeekByTeenager: "",
    totalTestBookingThisWeekByAdult: "",

    totalTestBookingThisMonthByChild: "",
    totalTestBookingThisMonthByTeenager: "",
    totalTestBookingThisMonthByAdult: "",

    selfAssesmentAppointmentSummary: {
      totalAppointmentsByUsersWhoBookedAfterTest: "",
      totalAppointmentsWithRecommendedProfessional: "",
      totalAppointmentsWithNonRecommendedProfessional: "",
    },
    weeklySummaryByCategory: {},
    monthlySummaryByCategory: {},
    selfAssessmentTakenAsReport: {},
    selfAssessmentViewers: 0,
  });
  const [allStates, setAllStates] = useState([]);

  useEffect(() => {
    getAssessmentStatsData().then((output) => {
      if (output.data) {
        setDashboardData(output.data.data);
      } else {
        console.log("error encountered");
      }
    });

    getVendorsAllStateList().then((output) => {
      if (output.data) {
        console.log('output.data', output.data);
        setAllStates(output.data.data);
      } else {
        console.log("error encountered");
      }
    });
  }, []);

  // const getMonthText = (month) => {
  //   switch (month) {
  //     case 1:
  //       return 'January';
  //     case 2:
  //       return 'February';
  //     case 3:
  //       return 'March';
  //     case 4:
  //       return 'April';
  //     case 5:
  //       return 'May';
  //     case 6:
  //       return 'June';
  //     case 7:
  //       return 'July';
  //     case 8:
  //       return 'August';
  //     case 9:
  //       return 'September';
  //     case 10:
  //       return 'October';
  //     case 11:
  //       return 'November';
  //     case 12:
  //       return 'December'
  //     default:
  //       return '';
  //   }
  // }

  const renderTabContent = () => {
    switch (currentTab) {
      case 0:
        return <TestReport dashboardData={dashboardData} />;
      case 1:
        return (
          <TestClickReport
            totalTestClick={dashboardData.selfAssessmentViewers}
          />
        );
      case 2:
        return (
          <VendorListAllStates stateList={allStates}
          currUser={props.data.account.user}/>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          // props.changePaymentTab(tab);
          setCurrentTab(tab);
        }}
        title={"Reports"}
        tabsTitle={[
          "Test Reports",
          "Test Click Reports",
          "Professional Reports",
        ]}
      />
      {renderTabContent()}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
