import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Form} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../../_metronic/utils/utils";
import Select from 'react-select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    root: {
        minWidth: 275
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    mb: {
        marginBottom: '1rem !important'
    },
    pall: {
        padding: '16px !important'
    },
    toRight: {
        textAlign: 'right'
    },
    uploadImageButtonFile: {
        width: 'auto',
        display: 'inline-block',
        padding: '8px 16px',
        position: 'relative',
        background: '#e1e1e1',
        borderRadius: '4px'
    },
    styleSpan: {
        fontSize: '12px',
        paddingLeft: '8px',
        verticalAlign: 'sub'
    },
    profileImageUploaderContainer: {
        paddingTop: '8px',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    }
});

const PCLanguageInputCard = (props) => {

    const classes = useStyles();
    const [errors,
        setErrors] = useState({title: null, icon: null});
    const [title,
        setTitle] = useState(props.pcLangInput.title);
    const [selectedFile,
        setSelectedFile] = useState(null);
    const [description,
        setDescription] = useState(props.pcLangInput.description);
    const [highlightAnswer,
            setHighlightAnswer] = useState(props.pcLangInput.highlightAnswer);
    const [preview,
        setPreview] = useState(props.pcLangInput.audioFile);
    const [previewS,
            setPreviewS] = useState(false);
    const observed = useRef(null);
    const audio = useRef(null);
    const [ charLeft, setCharLeft] = useState(props.pcLangInput.highlightAnswer? 100 - props.pcLangInput.highlightAnswer.length: 100);
    const options = [...props.languages.map((v)=>{
        return {
            label: v.name, 
            value: v._id, 
            isDisabled: v.isDisabled? v.isDisabled: false
        };
    })];

    const [selectedLanguage, setSelectedLanguage] = useState(options[0]);

    useEffect(()=>{
        
        if(!props.pcLangInput.languageRef){
            props.updatePCLangInput({
                ...props.pcLangInput,
                languageRef: props.languages[0]._id
            });
        }
        
        if(props.pcLangInput.languageRef){
            let ln = props.languages.filter((v)=>v._id===props.pcLangInput.languageRef)[0];
            setSelectedLanguage({
                label: ln.name,
                value: ln._id
            });  
        }

        if(props.pcLangInput && props.pcLangInput.audioFile){
            setPreview(props.pcLangInput.audioFile);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(preview){
            setPreviewS(true);
        }
    }, [preview]);

    useEffect(() => {
        if (!selectedFile) {
            // setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);

        props.updatePCLangInput({
            ...props.pcLangInput,
            title: event.target.value
        });

        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    }

    const onChangeHandler = (event) => {
        let file = event.target.files[0];
        setSelectedFile(file);
        props.updatePCLangInput({
            ...props.pcLangInput,
            audioFile: file
        });
    }

    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        
        props.updatePCLangInput({
            ...props.pcLangInput,
            description: event.target.value
        });
        
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Answer is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    const onHighlightAnswerChangeHandler = (event) => {
        setHighlightAnswer(event.target.value);
        setCharLeft(100- event.target.value.length);
        props.updatePCLangInput({
            ...props.pcLangInput,
            highlightAnswer: event.target.value
        });
        
        if (!event.target.value) {
            setErrors({
                ...errors,
                highlightAnswer: 'Highlight Answer is required'
            });
        } else {
            setErrors({
                ...errors,
                highlightAnswer: null
            });
        }
    }

    return (
        <Card className={classes.root}>
            <CardContent className={classes.pall}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Form.Group className={classes.mb}>
                                <Form.Label>Language</Form.Label>
                                <Select value={selectedLanguage} defaultValue={selectedLanguage} options={options} onChange={(e)=>{
                                    setSelectedLanguage(e);
                                    props.updatePCLangInput({
                                        ...props.pcLangInput,
                                        languageRef: e.value
                                    });                            
                                }}/>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={classes.toRight}>
                                <IconButton onClick={props.removePCLangInput} aria-label="Close">
                                    <CloseIcon/>
                                </IconButton>
                            </div>
                        </Grid>
                        
                    </Grid>

                    <Form.Group className={classes.mb}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            onChange={onTitleChangeHandler}
                            isInvalid={!!errors.title}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.title}
                        </Form.Control.Feedback>

                    </Form.Group>

                    <Form.Group className={classes.mb}>
                        <Form.Label>Audio</Form.Label>
                        <div className="row">
                            <div className="col-12">
                                <div className={classes.bigAvatar}>
                                    {previewS
                                        ? (<audio key={preview} controls ref={audio}>
                                                <source type="audio/mpeg" src={`${preview}`}/>
                                            </audio>)
                                        : (
                                            <div
                                                style={{
                                                height: '60px',
                                                background: '#ccc',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                color: '#f6f6f6',
                                                fontSize: '14px'
                                            }}>
                                                <span>
                                                    No Audio Selected
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={classes.profileImageUploaderContainer}>
                                    <div
                                        className={classes.uploadImageButtonFile}
                                        onClick={(e) => {
                                        observed
                                            .current
                                            .click();
                                    }}>
                                        <input
                                            ref={observed}
                                            type="file"
                                            style={{
                                            display: "none"
                                        }}
                                            onChange={onChangeHandler}/>
                                        <img src={toAbsoluteUrl("/media/icons/image-icon.png")} alt="no-img"/>
                                        <span className={classes.styleSpan}>
                                            Browse Audio
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {errors.icon
                            ? (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{errors.icon}</div>
                                </div>
                            )
                            : null}

                    </Form.Group>

                    <Form.Group className={classes.mb}>
                        <Form.Label>Answer</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            cols={50}
                            value={description}
                            onChange={onDescriptionChangeHandler}
                            isInvalid={!!errors.description}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.description}
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className={classes.mb}>
                        <Form.Label>Highlighted Answer</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            cols={50}
                            maxLength="100"
                            value={highlightAnswer}
                            onChange={onHighlightAnswerChangeHandler}
                            isInvalid={!!errors.highlightAnswer}/>
                        {charLeft? (<p>Left Character {charLeft}</p>): <p>No Character Left</p>}
                        <Form.Control.Feedback type="invalid">
                            {errors.highlightAnswer}
                        </Form.Control.Feedback>

                    </Form.Group>
            </CardContent>
        </Card>
    );
};
export default PCLanguageInputCard;
