import React from 'react';
import { Form, Button } from "react-bootstrap";
const AddNote = ({ noteByAdmin, handleChange, saveNote }) => {
    return (<Form.Group style={{ marginBottom: 0 }}>
        <Form.Label>Note</Form.Label>
        <Form.Control
            as="textarea"
            rows={4}
            cols={50}
            value={noteByAdmin}
            onChange={handleChange} />
        <Button style={{ marginTop: 16 }} variant="primary" onClick={saveNote}> Save </Button>
    </Form.Group>)
};

export default AddNote;