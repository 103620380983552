import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Rating from "@material-ui/lab/Rating";
import Grid from "@material-ui/core/Grid";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { Table, Dropdown, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getWorkshopDetails,
  updateCommission,
  updateWorkshopDetails,
  broadcastNotification,
  resendCertificate
} from "../../../crud/workshop.crud";
import moment from "moment";
import bookingConfig from "../../../config/booking";
import workshopConfig from "../../../config/workshop";
import ImageModal from "../../common/modal/image-modal";
import LinesEllipsis from "react-lines-ellipsis";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
  starWrapper: {
    "& img": {
      marginRight: 8,
    },
  },
  icon: {
    color: "#E12936",
  },
  detailsParagraph: {
    display: "block",
    marginBottom: "5px",
  },
  detailsParagraphBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  fullWidth: {
    width: "100%",
  },
  stat: {
    padding: "8px 0px",
    margin: 0,
    fontSize: "18px",
  },
  imageContainer: {
    borderRadius: 8,
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
  },
  eachImageWrapper: {
    width: 100,
    display: "inline-block",
    marginRight: "10px",
  },
  eachImage: {
    height: 100,
    margin: 5,
    cursor: "pointer",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    borderRadius: "50%",
    border: "4px solid #fff",
    boxShadow: "6px 14px 31px #dae4f4",
  },
}));

const WorkshopDetails = () => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const { workshopId } = useParams();
  const [workshopDetailsInfo, setWorkshopDetailsInfo] = useState();
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Confirmed"
  );
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [loading, setLoading] = useState(false);
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  const [isEdit, setIsEdit] = useState({
    title: false,
    description: false,
    fees: false,
    meetingPlatform: false,
    meetingLink: false,
    nameOnTheCertificate: false,
    designationOnTheCertificate: false,
    numberOfSeats: false,
  });
  const [bookedSeats, setBookedSeats] = useState(0);
  const [isBroadcastNotify, setIsBroadcastNotify] = useState(false);
  const [isResendCertificate, setIsResendCertificate] = useState(false);
  let errors = {
    commissionPercentage: false,
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  const renderDates = (dates) => {
    return (
      <span>
        {dates && dates.length > 0
          ? moment(dates[0]).format("DD-MMM-YYYY")
          : null}
        {dates && dates.length > 1 && dates[0] === dates[dates.length - 1]
          ? null
          : dates && dates.length > 1
          ? "-" + moment(dates[dates.length - 1]).format("DD-MMM-YYYY")
          : null}
      </span>
    );
  };

  useEffect(() => {
    getWorkshopDetails(workshopId).then((output) => {
      setWorkshopDetailsInfo({
        ...workshopDetailsInfo,
        ...output.data.data,
      });
      setCommissionPercentage(output.data.data?.commissionPercentage);
      setBookedSeats(
        Number(output.data.data.numberOfSeats) -
          Number(output.data.data.availableSeats)
      );
      statusHandler(output.data.data?.status);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  const statusHandler = (status) => {
    setSelectedStatus(Number(status));
    switch (Number(status)) {
      case 1:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 2:
        setSelectedBookingStatus("Confirmed");
        break;
      case 3:
        setSelectedBookingStatus("Completed");
        break;
      case 4:
        setSelectedBookingStatus("Failed");
        break;
      case 5:
        setSelectedBookingStatus("Not Joined");
        break;
      default:
        break;
    }
  };

  const onChangeCommission = (event) => {
    setCommissionPercentage(event.target.value);
  };

  const saveCommissionPercentage = () => {
    setLoading(true);
    updateCommission(workshopId, commissionPercentage).then(() => {
      setLoading(false);
      toast.success("Save Commission Percentage is successfull", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const saveWorkshopDetails = (type, key, value) => {
    if (type === "numberOfSeats") {
      updateWorkshopDetails(
        workshopId,
        "availableSeats",
        workshopDetailsInfo.availableSeats
      );
    }
    updateWorkshopDetails(workshopId, key, value);
    switch (type) {
      case "title":
        setIsEdit({
          ...isEdit,
          title: false,
        });
        break;
      case "description":
        setIsEdit({
          ...isEdit,
          description: false,
        });
        break;
      case "fees":
          setIsEdit({
            ...isEdit,
            fees: false,
          });
          break;
      case "meetingPlatform":
        setIsEdit({
          ...isEdit,
          meetingPlatform: false,
        });
        break;
      case "meetingLink":
        setIsEdit({
          ...isEdit,
          meetingLink: false,
        });
        break;
      case "nameOnTheCertificate":
        setIsEdit({
          ...isEdit,
          nameOnTheCertificate: false,
        });
        break;
      case "designationOnTheCertificate":
        setIsEdit({
          ...isEdit,
          designationOnTheCertificate: false,
        });
        break;
      case "numberOfSeats":
        setIsEdit({
          ...isEdit,
          numberOfSeats: false,
        });
        break;
      default:
        break;
    }
  };

  if (!workshopDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      {showImagesModal ? (
        <ImageModal
          show={showImagesModal}
          images={workshopDetailsInfo.images}
          handleClose={() => setShowImagesModal(false)}
        />
      ) : null}
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
              <div className={classes.flexBox}>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                {[1,2].includes(workshopDetailsInfo.status)?<Form>
                    {!isEdit.title ? (
                      <span className={classes.detailsParagraphBetween}>
                        <h5>{workshopDetailsInfo.title}</h5>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() =>
                              setIsEdit({
                                ...isEdit,
                                title: true,
                              })
                            }
                            size="sm"
                          >
                            Edit
                          </Button>
                        </span>
                      </span>
                    ) : (
                      <span className={classes.detailsParagraphBetween}>
                        <span style={{ width: "100%" }}>
                          <Form.Group
                            controlId="form.onChangeCommission"
                            style={{
                              display: "inline-block",
                              paddingRight: 16,
                              paddingLeft: 0,
                              margin: 0,
                              width: "100%",
                            }}
                          >
                            <Form.Control
                              type="text"
                              value={workshopDetailsInfo.title}
                              onChange={(event) => {
                                setWorkshopDetailsInfo({
                                  ...workshopDetailsInfo,
                                  title: event.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </span>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() => {
                              saveWorkshopDetails(
                                "title",
                                "title",
                                workshopDetailsInfo.title
                              );
                            }}
                            size="sm"
                          >
                            Save
                          </Button>
                        </span>
                      </span>
                    )}
                  </Form>: <span className={classes.detailsParagraphBetween}>
                        <h5>{workshopDetailsInfo.title}</h5>
                    </span>}
                  {[1,2].includes(workshopDetailsInfo.status)?<Form>
                    {!isEdit.description ? (
                      <span className={classes.detailsParagraphBetween}>
                        <LinesEllipsis
                          text={workshopDetailsInfo.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        ></LinesEllipsis>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() =>
                              setIsEdit({
                                ...isEdit,
                                description: true,
                              })
                            }
                            size="sm"
                          >
                            Edit
                          </Button>
                        </span>
                      </span>
                    ) : (
                      <span className={classes.detailsParagraphBetween}>
                        <span style={{ width: "100%" }}>
                          <Form.Group
                            controlId="form.onChangeCommission"
                            style={{
                              display: "inline-block",
                              paddingRight: 16,
                              paddingLeft: 0,
                              margin: 0,
                              width: "100%",
                            }}
                          >
                            <Form.Control
                              type="text"
                              value={workshopDetailsInfo.description}
                              onChange={(event) => {
                                setWorkshopDetailsInfo({
                                  ...workshopDetailsInfo,
                                  description: event.target.value,
                                });
                              }}
                            />
                          </Form.Group>
                        </span>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() => {
                              saveWorkshopDetails(
                                "description",
                                "description",
                                workshopDetailsInfo.description
                              );
                            }}
                            size="sm"
                          >
                            Save
                          </Button>
                        </span>
                      </span>
                    )}
                  </Form>: <span className={classes.detailsParagraphBetween}>
                        <LinesEllipsis
                          text={workshopDetailsInfo.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        ></LinesEllipsis></span>}
                  <hr />
                  {workshopDetailsInfo.status === 1 ?
                  <div>
                    <Form>
                      {!isEdit.fees ? (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Fees -&nbsp;</span>
                            <span>{workshopDetailsInfo.fees}</span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() =>
                                setIsEdit({
                                  ...isEdit,
                                  fees: true,
                                })
                              }
                              size="sm"
                            >
                              Edit
                            </Button>
                          </span>
                        </span>
                      ) : (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Fees -&nbsp;</span>
                            <span>
                              <Form.Group
                                controlId="form.onChangeCommission"
                                style={{
                                  display: "inline-block",
                                  paddingRight: 16,
                                  paddingLeft: 8,
                                  margin: 0,
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  value={workshopDetailsInfo.fees}
                                  onChange={(event) => {
                                    setWorkshopDetailsInfo({
                                      ...workshopDetailsInfo,
                                      fees: event.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() => {
                                saveWorkshopDetails(
                                  "fees",
                                  "fees",
                                  workshopDetailsInfo.fees
                                );
                              }}
                              size="sm"
                            >
                              Save
                            </Button>
                          </span>
                        </span>
                      )}
                    </Form>
                  </div>: <span className={classes.detailsParagraph}>
                    <span>Fees -&nbsp;</span>
                    <span>Rs. {workshopDetailsInfo.fees}</span>
                    </span>}
                  <span className={classes.detailsParagraph}>
                    <span>Date -&nbsp;</span>
                    <span>{renderDates(workshopDetailsInfo.dates)}</span>
                  </span>
                  <span className={classes.detailsParagraph}>
                    <span>Time -&nbsp;</span>
                    <span>{renderSlots(workshopDetailsInfo.slots)}</span>
                  </span>
                  <div>
                  {[1,2].includes(workshopDetailsInfo.status)?<Form>
                      {!isEdit.meetingPlatform ? (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Platform -&nbsp;</span>
                            <span>{workshopDetailsInfo.meetingPlatform}</span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() =>
                                setIsEdit({
                                  ...isEdit,
                                  meetingPlatform: true,
                                })
                              }
                              size="sm"
                            >
                              Edit
                            </Button>
                          </span>
                        </span>
                      ) : (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Platform -&nbsp;</span>
                            <span>
                              <Form.Group
                                controlId="form.onChangeCommission"
                                style={{
                                  display: "inline-block",
                                  paddingRight: 16,
                                  paddingLeft: 8,
                                  margin: 0,
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  value={workshopDetailsInfo.meetingPlatform}
                                  onChange={(event) => {
                                    setWorkshopDetailsInfo({
                                      ...workshopDetailsInfo,
                                      meetingPlatform: event.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() => {
                                saveWorkshopDetails(
                                  "meetingPlatform",
                                  "meetingPlatform",
                                  workshopDetailsInfo.meetingPlatform
                                );
                              }}
                              size="sm"
                            >
                              Save
                            </Button>
                          </span>
                        </span>
                      )}
                    </Form>: <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Platform -&nbsp;</span>
                            <span>{workshopDetailsInfo.meetingPlatform}</span>
                          </p></span>}
                  </div>
                  <div>
                  {[1,2].includes(workshopDetailsInfo.status)? <Form>
                      {!isEdit.meetingLink ? (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Link -&nbsp;</span>
                            <span>{workshopDetailsInfo.meetingLink}</span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() =>
                                setIsEdit({
                                  ...isEdit,
                                  meetingLink: true,
                                })
                              }
                              size="sm"
                            >
                              Edit
                            </Button>
                          </span>
                        </span>
                      ) : (
                        <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Link -&nbsp;</span>
                            <span>
                              <Form.Group
                                controlId="form.onChangeCommission"
                                style={{
                                  display: "inline-block",
                                  paddingRight: 16,
                                  paddingLeft: 8,
                                  margin: 0,
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  value={workshopDetailsInfo.meetingLink}
                                  onChange={(event) => {
                                    setWorkshopDetailsInfo({
                                      ...workshopDetailsInfo,
                                      meetingLink: event.target.value,
                                    });
                                  }}
                                />
                              </Form.Group>
                            </span>
                          </p>
                          <span>
                            <Button
                              variant="primary"
                              style={{
                                marginLeft: 8,
                                position: "relative",
                              }}
                              onClick={() => {
                                saveWorkshopDetails(
                                  "meetingLink",
                                  "meetingLink",
                                  workshopDetailsInfo.meetingLink
                                );
                              }}
                              size="sm"
                            >
                              Save
                            </Button>
                          </span>
                        </span>
                      )}
                    </Form>: <span className={classes.detailsParagraphBetween}>
                          <p style={{ display: "inline-block" }}>
                            <span>Meeting Link -&nbsp;</span>
                            <span>{workshopDetailsInfo.meetingLink}</span>
                          </p></span>}
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Gallery -&nbsp;</span>
                    <div
                      className={`${classes.imageContainer} pl-0 pr-0 pt-2 pb-0`}
                      style={{ margin: 0 }}
                    >
                      {workshopDetailsInfo.images.map((each, key) => {
                        return (
                          <div
                            key={key}
                            className={`${classes.eachImageWrapper} pl-0`}
                            onClick={() => setShowImagesModal(true)}
                          >
                            <div
                              className={`${classes.eachImage}`}
                              style={{
                                backgroundImage: `url(${each.url})`,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }}>
              <div className={classes.flexBox}>
                <div className={`mr-3`}>
                  <img
                    src={
                      workshopDetailsInfo.vendorRef.personalInfo.profilePicture
                        ? workshopDetailsInfo.vendorRef.personalInfo
                            .profilePicture
                        : toAbsoluteUrl(
                            `/media/default-image/default-image.png`
                          )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className={`text-left mt-1 ${classes.fullWidth}`}>
                  <h5>{workshopDetailsInfo.vendorRef.personalInfo.name}</h5>
                  <div className={classes.detailsParagraph}>
                    <LinesEllipsis
                      text={
                        workshopDetailsInfo.vendorRef.personalInfo.description
                          ? workshopDetailsInfo.vendorRef.personalInfo
                              .description
                          : "N/A"
                      }
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    ></LinesEllipsis>
                  </div>
                  <hr />
                  <div className={classes.detailsParagraph}>
                    <span>Contact no -&nbsp;</span>
                    <span>
                      {
                        workshopDetailsInfo.vendorRef.personalInfo.phone
                          .dialCode
                      }
                      {workshopDetailsInfo.vendorRef.personalInfo.phone.number}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Email -&nbsp;</span>
                    <span>
                      {workshopDetailsInfo.vendorRef.personalInfo.email}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Experience -&nbsp;</span>
                    <span>
                      {
                        workshopDetailsInfo.vendorRef.personalInfo.experience
                          .year
                      }{" "}
                      years
                    </span>{" "}
                    &nbsp;
                    {workshopDetailsInfo.vendorRef.personalInfo.experience
                      .month ? (
                      <span>
                        {
                          workshopDetailsInfo.vendorRef.personalInfo.experience
                            .month
                        }{" "}
                        months
                      </span>
                    ) : null}
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Categories -&nbsp;</span>
                    <span>
                      {workshopDetailsInfo.vendorRef.personalInfo.categories.map(
                        (eachCat, key) => {
                          return (
                            <span key={key} className={classes.categoryTag}>
                              {eachCat.title}
                            </span>
                          );
                        }
                      )}
                    </span>
                  </div>
                  <div className={classes.detailsParagraph}>
                    <span>Website -&nbsp;</span>
                    <span>
                      {workshopDetailsInfo.vendorRef.personalInfo.website
                        ? workshopDetailsInfo.vendorRef.personalInfo.website
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
          {workshopDetailsInfo.isCertificate ? (
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <div>
                {[1,2].includes(workshopDetailsInfo.status)? 
                  <Form>
                    {!isEdit.nameOnTheCertificate ? (
                      <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span
                            style={{
                              fontSize: 18,
                              paddingTop: 12,
                            }}
                          >
                            <b>Certificate Issuer -</b>&nbsp;
                          </span>
                          <span>
                            {workshopDetailsInfo.nameOnTheCertificate}
                          </span>
                        </p>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() =>
                              setIsEdit({
                                ...isEdit,
                                nameOnTheCertificate: true,
                              })
                            }
                            size="sm"
                          >
                            Edit
                          </Button>
                        </span>
                      </span>
                    ) : (
                      <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span
                            style={{
                              fontSize: 18,
                              paddingTop: 12,
                            }}
                          >
                            <b>Certificate Issuer -</b>&nbsp;
                          </span>
                          <span>
                            <Form.Group
                              controlId="form.onChangeCommission"
                              style={{
                                display: "inline-block",
                                paddingRight: 16,
                                paddingLeft: 8,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                value={workshopDetailsInfo.nameOnTheCertificate}
                                onChange={(event) => {
                                  setWorkshopDetailsInfo({
                                    ...workshopDetailsInfo,
                                    nameOnTheCertificate: event.target.value,
                                  });
                                }}
                              />
                            </Form.Group>
                          </span>
                        </p>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() => {
                              saveWorkshopDetails(
                                "nameOnTheCertificate",
                                "nameOnTheCertificate",
                                workshopDetailsInfo.nameOnTheCertificate
                              );
                            }}
                            size="sm"
                          >
                            Save
                          </Button>
                        </span>
                      </span>
                    )}
                  </Form>: <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span
                            style={{
                              fontSize: 18,
                              paddingTop: 12,
                            }}
                          >
                            <b>Certificate Issuer -</b>&nbsp;
                          </span>
                          <span>
                            {workshopDetailsInfo.nameOnTheCertificate}
                          </span>
                        </p></span>}
                </div>
                <div>
                {[1,2].includes(workshopDetailsInfo.status)? 
                  <Form>
                    {!isEdit.designationOnTheCertificate ? (
                      <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span>
                            <b>Designation of Certificate Issuer -</b>&nbsp;
                          </span>
                          <span>
                            {workshopDetailsInfo.designationOnTheCertificate}
                          </span>
                        </p>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() =>
                              setIsEdit({
                                ...isEdit,
                                designationOnTheCertificate: true,
                              })
                            }
                            size="sm"
                          >
                            Edit
                          </Button>
                        </span>
                      </span>
                    ) : (
                      <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span>Designation of Certificate Issuer -&nbsp;</span>
                          <span>
                            <Form.Group
                              controlId="form.onChangeCommission"
                              style={{
                                display: "inline-block",
                                paddingRight: 16,
                                paddingLeft: 8,
                                margin: 0,
                              }}
                            >
                              <Form.Control
                                type="text"
                                value={
                                  workshopDetailsInfo.designationOnTheCertificate
                                }
                                onChange={(event) => {
                                  setWorkshopDetailsInfo({
                                    ...workshopDetailsInfo,
                                    designationOnTheCertificate:
                                      event.target.value,
                                  });
                                }}
                              />
                            </Form.Group>
                          </span>
                        </p>
                        <span>
                          <Button
                            variant="primary"
                            style={{
                              marginLeft: 8,
                              position: "relative",
                            }}
                            onClick={() => {
                              saveWorkshopDetails(
                                "designationOnTheCertificate",
                                "designationOnTheCertificate",
                                workshopDetailsInfo.designationOnTheCertificate
                              );
                            }}
                            size="sm"
                          >
                            Save
                          </Button>
                        </span>
                      </span>
                    )}
                  </Form>:  <span className={classes.detailsParagraphBetween}>
                        <p style={{ display: "inline-block" }}>
                          <span>
                            <b>Designation of Certificate Issuer -</b>&nbsp;
                          </span>
                          <span>
                            {workshopDetailsInfo.designationOnTheCertificate}
                          </span>
                        </p></span>}
                </div>
              </Paper>
            </Grid>
          ) : null}
          <Grid item xs={12}>
          {[1,2].includes(workshopDetailsInfo.status)? 
            <Form>
              {!isEdit.numberOfSeats ? (
                <Paper
                  className={`${classes.paper} text-left`}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={classes.stat}>
                    Total Seats: {workshopDetailsInfo.numberOfSeats}
                  </p>
                  <p className={classes.stat}>Booked Seats: {bookedSeats}</p>
                  <p className={classes.stat}>
                    Available Seats: {workshopDetailsInfo.availableSeats}
                  </p>
                  <Button
                    variant="primary"
                    style={{
                      marginLeft: 8,
                      position: "relative",
                    }}
                    onClick={() =>
                      setIsEdit({
                        ...isEdit,
                        numberOfSeats: true,
                      })
                    }
                    size="sm"
                  >
                    Edit
                  </Button>
                </Paper>
              ) : (
                <Paper
                  className={`${classes.paper} text-left`}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={classes.stat}>
                    Total Seats:
                    <Form.Group
                      controlId="form.onChangeCommission"
                      style={{
                        display: "inline-block",
                        paddingRight: 16,
                        paddingLeft: 8,
                        margin: 0,
                      }}
                    >
                      <Form.Control
                        type="text"
                        value={workshopDetailsInfo.numberOfSeats}
                        onChange={(event) => {
                          setWorkshopDetailsInfo({
                            ...workshopDetailsInfo,
                            numberOfSeats: event.target.value,
                            availableSeats: event.target.value - bookedSeats,
                          });
                        }}
                        isInvalid={
                          workshopDetailsInfo.numberOfSeats < bookedSeats
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {workshopDetailsInfo.numberOfSeats < bookedSeats &&
                          "Total seats should be greater than booked seats."}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </p>
                  <p className={classes.stat}>
                    Booked Seats:
                    {bookedSeats}
                  </p>
                  <p className={classes.stat}>
                    Available Seats: {workshopDetailsInfo.availableSeats}
                  </p>
                  <Button
                    variant="primary"
                    style={{
                      marginLeft: 8,
                      position: "relative",
                    }}
                    onClick={() => {
                      saveWorkshopDetails(
                        "numberOfSeats",
                        "numberOfSeats",
                        workshopDetailsInfo.numberOfSeats
                      );
                    }}
                    size="sm"
                    disabled={workshopDetailsInfo.numberOfSeats < bookedSeats}
                  >
                    Save
                  </Button>
                </Paper>
              )}
            </Form>:  <Paper
                  className={`${classes.paper} text-left`}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p className={classes.stat}>
                    Total Seats: {workshopDetailsInfo.numberOfSeats}
                  </p>
                  <p className={classes.stat}>Booked Seats: {bookedSeats}</p>
                  <p className={classes.stat}>
                    Available Seats: {workshopDetailsInfo.availableSeats}
                  </p></Paper>}
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.stat}>
                Commission:
                {[1, 2].includes(workshopDetailsInfo.status) ? (
                  <div style={{ display: "inline-block" }}>
                    <Form>
                      <Form.Group
                        controlId="form.onChangeCommission"
                        style={{
                          display: "inline-block",
                          paddingRight: 16,
                          paddingLeft: 8,
                          margin: 0,
                        }}
                      >
                        <Form.Control
                          type="text"
                          value={commissionPercentage}
                          onChange={onChangeCommission}
                          isInvalid={!!errors.commissionPercentage}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.commissionPercentage}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <span>% </span>
                      <Button
                        variant="primary"
                        style={{
                          marginLeft: 8,
                          position: "relative",
                        }}
                        onClick={() => saveCommissionPercentage()}
                        disabled={loading}
                      >
                        {loading && (
                          <CircularProgress
                            size={24}
                            style={{
                              verticalAlign: "inherit",
                              marginRight: "12px",
                            }}
                          />
                        )}
                        Save
                      </Button>
                    </Form>
                  </div>
                ) : (
                  <span className={classes.stat}>
                    {workshopDetailsInfo.commissionPercentage
                      ? workshopDetailsInfo.commissionPercentage
                      : 0}
                    %
                  </span>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  paddingBottom: "12px",
                }}
              >
                <h4 className="mb-3">Workshop Bookings</h4>
                <div>
                  <span
                    className="mb-3"
                    style={{
                      display: "inline-block",
                      fontSize: 16,
                      paddingRight: 16,
                      fontWeight: 500,
                    }}
                  >
                    Status
                  </span>
                  <Dropdown style={{ display: "inline-block" }}>
                    <Dropdown.Toggle
                      menualign="right"
                      variant="primary"
                      id="dropdown-menu-align-right"
                    >
                      {selectedBookingStatus ? selectedBookingStatus : ""}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Object.keys(workshopConfig.bookingStatus)
                        .slice(1)
                        .map((eachStatus, key) => {
                          return (
                            <Dropdown.Item
                              key={key}
                              onClick={() => {
                                statusHandler(eachStatus);
                              }}
                            >
                              {workshopConfig.bookingStatus[eachStatus]}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Booking No</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Service Fees</th>
                    <th>Convenience Fees</th>
                    <th>GST</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Time</th>
                    <th>Certificate</th>
                  </tr>
                </thead>
                {workshopDetailsInfo.workshopBookings?.filter(
                  (e) => e.status === selectedStatus
                ).length ? (
                  <tbody>
                    {workshopDetailsInfo.workshopBookings
                      .filter((e) => e.status === selectedStatus)
                      .map((workshopBookingDoc, key) => {
                        return (
                          <tr key={key}>
                            <td>{workshopBookingDoc.bookingNumber}</td>
                            <td>{workshopBookingDoc.bookingForName}</td>
                            <td>
                              {
                                workshopBookingDoc.customerRef.personalInfo
                                  .phone.dialCode
                              }
                              -
                              {
                                workshopBookingDoc.customerRef.personalInfo
                                  .phone.number
                              }
                            </td>
                            <td>
                              <i
                                class="fas fa-rupee-sign"
                                style={{
                                  fontSize: 12,
                                  color: "#555",
                                  paddingRight: 1,
                                }}
                              ></i>
                              {workshopBookingDoc.paymentDetails.serviceFee}
                            </td>
                            <td>
                              <i
                                class="fas fa-rupee-sign"
                                style={{
                                  fontSize: 12,
                                  color: "#555",
                                  paddingRight: 1,
                                }}
                              ></i>
                              {workshopBookingDoc.paymentDetails.convenienceFee}
                            </td>
                            <td>
                              <i
                                class="fas fa-rupee-sign"
                                style={{
                                  fontSize: 12,
                                  color: "#555",
                                  paddingRight: 1,
                                }}
                              ></i>
                              {workshopBookingDoc.paymentDetails.gst}
                            </td>
                            <td>
                              <i
                                class="fas fa-rupee-sign"
                                style={{
                                  fontSize: 12,
                                  color: "#555",
                                  paddingRight: 1,
                                }}
                              ></i>
                              {workshopBookingDoc.paymentDetails.total}
                            </td>
                            <td>
                              {
                                workshopConfig.bookingStatus[
                                  workshopBookingDoc.status
                                ]
                              }
                            </td>
                            <td>
                              {moment(workshopBookingDoc.createdAt).format(
                                "DD-MMM-YYYY hh:mm A"
                              )}
                            </td>
                            <td>
                              {[3,5].includes(workshopBookingDoc.status) && workshopDetailsInfo.isCertificate ?<Button
                                variant="primary"
                                style={{
                                  marginLeft: 8,
                                  position: "relative",
                                }}
                                onClick={() => {
                                  setIsResendCertificate(true);
                                  resendCertificate(workshopBookingDoc._id).then(() => {
                                    setIsResendCertificate(false);
                                    toast.success("Workshop certificate sent successfully.", {
                                      position: "top-right",
                                      autoClose: 5000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    });
                                  });
                                }}
                                size="sm"
                                disabled={isResendCertificate}
                              >
                                Resend
                              </Button>: 'N/A'}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tr>
                    <td colSpan={7} align={"center"}>
                      No Booking Found!
                    </td>
                  </tr>
                )}
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} text-left`}>
              <h4 className="mb-3">Rating & Feedback</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Rating</th>
                    <th>Feedback</th>
                  </tr>
                </thead>
                {workshopDetailsInfo.ratingDetails.length ? (
                  <tbody>
                    {workshopDetailsInfo.ratingDetails.map((doc, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Rating
                              defaultValue={doc.rating}
                              size="large"
                              readOnly
                            />
                          </td>
                          <td>{doc.feedback}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tr>
                    <td colSpan={7} align={"center"}>
                      No Rating and Feedback Found!
                    </td>
                  </tr>
                )}
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12}>
          <div
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  display: "flex",
                  paddingBottom: "12px",
                }}
              >
                {workshopDetailsInfo.status===2 && 
            <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <h4 style={{ margin: 0 }}>Notification</h4>
              <Button
                variant="primary"
                style={{
                  marginLeft: 8,
                  position: "relative",
                }}
                onClick={() => {
                  setIsBroadcastNotify(true);
                  broadcastNotification(workshopDetailsInfo._id).then(() => {
                    setIsBroadcastNotify(false);
                    toast.success("Notification sent successfully.", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
                }}
                size="sm"
                disabled={isBroadcastNotify}
              >
                Send
              </Button>
            </Paper>}
            {/* <Paper
              className={`${classes.paper} text-left`}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 16
              }}
            >
              <h4 style={{ margin: 0 }}>Workshop Certificate</h4>
              <Button
                variant="primary"
                style={{
                  marginLeft: 8,
                  position: "relative",
                }}
                onClick={() => {
                  setIsResendCertificate(true);
                  resendCertificate(workshopDetailsInfo._id).then(() => {
                    setIsResendCertificate(false);
                    toast.success("Workshop certificate sent successfully.", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
                }}
                size="sm"
                disabled={isResendCertificate}
              >
                Resend
              </Button>
            </Paper> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default WorkshopDetails;
