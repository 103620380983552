import { listWorkshop } from "../../crud/workshop.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
  WORKSHOP_UPDATED: "WORKSHOP_UPDATED",
  WORKSHOP_UPDATED_SUCCESS: "WORKSHOP_UPDATED_SUCCESS",
  CHANGE_WORKSHOP_TAB: "CHANGE_WORKSHOP_TAB",
};

const initialAuthState = {
  listData: undefined,
  currTab: 0,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.WORKSHOP_UPDATED_SUCCESS: {
      const { listData } = action.payload;
      return { listData };
    }
    case actionTypes.CHANGE_WORKSHOP_TAB: {
      return { ...state, currTab: action.payload.currTab };
    }
    default:
      return state;
  }
};

export const actions = {
  workshopUpdated: ({ skip, limit }) => ({
    type: actionTypes.WORKSHOP_UPDATED,
    payload: {
      skip,
      limit,
    },
  }),
  changeWorkshopTab: (currTab) => ({
    type: actionTypes.CHANGE_WORKSHOP_TAB,
    payload: {
      currTab: currTab,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.WORKSHOP_UPDATED, function* workshopUpdated(action) {
    const { skip, limit } = action.payload;
    const listData = yield listWorkshop({ skip, limit });

    yield put({
      type: actionTypes.WORKSHOP_UPDATED_SUCCESS,
      payload: {
        listData,
      },
    });
  });
}
