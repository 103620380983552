const eventConfig = {

  status: {
    1: "Approval Pending",
    2: "Approved",
    3: "Rejected",
    4: "Completed",
    5: "Payment Pending",
    6: "Failed",
    7: "Canceled By Vendor",
    8: "Auto Rejected"
  },

  serverStatus: {
    'approvalPending': 1,
    'active': 2,
    'rejected': 3,
    'completed': 4,
  },

  bookingStatus: {
    1: "Payment Pending",
    2: "Confirmed",
    3: "Completed",
    4: "Failed",
    5: "Not joined",
  },

  transactionStatus: {
    1: "Payment Pending",
    2: "Payment Confirmed",
    3: "Payment Failed",
    4: "Refund Initiated",
    5: "Refund Failed",
  },

  type: {
    1: "Session",
    2: "Event"
  },

  eventType: {
    "sessions": 1,
    "onlineEvents": 2
  },

  searchType: {
    1: "Vendor Name",
    2: "Vendor Phone",
    3: "Customer Name",
    4: "Customer Phone",
  },

};
export default eventConfig;
