import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_PROFILE = `${baseUrl}/account/admin/vendor/profile`;
export const UPDATE_BANK = `${baseUrl}/account/admin/vendor/profile/update-bank`;
export const UPDATE_PROFILE = `${baseUrl}/account/admin/vendor/profile/update`;
export const LOCATION = `${baseUrl}/account/admin/vendor/address`;
export const GET_CATEGORIES = `${baseUrl}/account/admin/vendor/category/list`;
export const UPDATE_PROFILE_IMAGE = `${baseUrl}/account/admin/vendor/profile/update-profile-pic`;
export const UPLOAD_IMAGE_URL = `${baseUrl}/account/admin/common/upload-image`;
export const ADD_IMAGE = `${baseUrl}/account/admin/vendor/profile/add-photo`;
export const ADD_PHYSICAL_CONTRACTS = `${baseUrl}/account/admin/vendor/profile/add-physical-contracts`;
export const REMOVE_IMAGE = `${baseUrl}/account/admin/vendor/profile/remove-photo`;
export const REMOVE_PHYSICAL_CONTRACTS = `${baseUrl}/account/admin/vendor/profile/remove-physical-contracts`;
export const GET_PROFILE_BY_EVENT = `${baseUrl}/account/admin/vendor/profile/event`;
export const GET_PROFILE_BY_WORKSHOP = `${baseUrl}/account/admin/vendor/profile/workshop`;
export const SERVICE = `${baseUrl}/account/admin/vendor/service`;
export const getProfile = async (vendorId) => {
    const res = await axios.get(`${GET_PROFILE}/${vendorId}`);
    return res;
}

export const getProfileByEventId = async (eventId) => {
    const res = await axios.get(`${GET_PROFILE_BY_EVENT}/${eventId}`);
    return res;
}

export const getProfileByWorkshopId = async (workshopId) => {
    const res = await axios.get(`${GET_PROFILE_BY_WORKSHOP}/${workshopId}`);
    return res;
}



export const getCategories = async () => {
    const res = await axios.get(GET_CATEGORIES);
    return res;
}

export const updateBank = async (vendorId, requestBody) => {
    const res = await axios.patch(`${UPDATE_BANK}/${vendorId}`, requestBody);
    return res;
}

export const updateProfile = async (vendorId, requestBody) => {
    const res = await axios.patch(`${UPDATE_PROFILE}/${vendorId}`, requestBody);
    return res;
}

export const uploadImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.post(UPLOAD_IMAGE_URL, formData, config);
    return res;
};

export const updateProfilePhoto = async (vendorId, imageFile) => {
    const formData = new FormData();
    formData.append('profilePicture', imageFile);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.put(`${UPDATE_PROFILE_IMAGE}/${vendorId}`, formData, config);
    return res;
}

export const addPhoto = async (vendorId, imageFile) => {
    const formData = new FormData();
    formData.append('photo', imageFile);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.put(`${ADD_IMAGE}/${vendorId}`, formData, config);
    return res;
}

export const addPhysicalContracts = async (vendorId, imageFile, fileName) => {
    const formData = new FormData();
    formData.append('physicalContracts', imageFile);
    formData.append('fileName', fileName);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.put(`${ADD_PHYSICAL_CONTRACTS}/${vendorId}`, formData, config);
    return res;
}

export const getLocationList = async (vendorId) => {
    const res = await axios.get(`${LOCATION}/${vendorId}`);
    return res;
}

export const addLocation = async (vendorId, requestBody) => {
    const res = await axios.post(`${LOCATION}/${vendorId}`, requestBody);
    return res;
}

export const updateLocation = async (vendorId, requestBody) => {
    const res = await axios.put(`${LOCATION}/${requestBody._id}/${vendorId}`, requestBody);
    return res;
}

export const removeLocation = async (vendorId, id) => {
    const res = await axios.delete(`${LOCATION}/${id}/${vendorId}`);
    return res;
}

export const removeImage = async (vendorId, id) => {
    const res = await axios.delete(`${REMOVE_IMAGE}/${id}/${vendorId}`);
    return res;
}

export const removePhysicalContracts = async (vendorId) => {
    const res = await axios.delete(`${REMOVE_PHYSICAL_CONTRACTS}/${vendorId}`);
    return res;
}

export const getServiceList = async (vendorId) => {
    const res = await axios.get(`${SERVICE}/${vendorId}`);
    return res;
}

export const updateService = async (vendorId, requestBody) => {
    const res = await axios.put(`${SERVICE}/${requestBody._id}/${vendorId}`, requestBody);
    return res;
}

export const removeService = async (vendorId, id) => {
    const res = await axios.delete(`${SERVICE}/${id}/${vendorId}`);
    return res;
}

