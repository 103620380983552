import { listInstitution } from "../../crud/institution.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
    INSTITUTION_ADDED: "INSTITUTION_ADDED",
    INSTITUTION_ADDED_SUCCESS: "INSTITUTION_ADDED_SUCCESS",
    INSTITUTION_UPDATED: "INSTITUTION_UPDATED",
    INSTITUTION_UPDATED_SUCCESS: "INSTITUTION_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.INSTITUTION_ADDED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        case actionTypes.INSTITUTION_UPDATED_SUCCESS:
            {
                const { listData } = action.payload;
                return { listData };
            }
        default:
            return state;
    }
};

export const actions = {
    institutionAdded: ({ skip, limit }) => ({
        type: actionTypes.INSTITUTION_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    institutionUpdated: ({ skip, limit }) => ({
        type: actionTypes.INSTITUTION_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function* saga() {
    yield takeLatest(actionTypes.INSTITUTION_ADDED, function* institutionAdded(action) {
        const { skip, limit } = action.payload;
        const listData = yield listInstitution({ skip, limit });

        yield put({
            type: actionTypes.INSTITUTION_ADDED_SUCCESS, payload: {
                listData
            }
        });
    });

    yield takeLatest(actionTypes.INSTITUTION_UPDATED, function* institutionUpdated(action) {
        const { skip, limit } = action.payload;
        const listData = yield listInstitution({ skip, limit });

        yield put({
            type: actionTypes.INSTITUTION_UPDATED_SUCCESS, payload: {
                listData
            }
        });
    });
}
