import React, { useCallback, useEffect, useState } from "react";
import "./../css/notification.css";
import { toast } from "react-toastify";
import { notificationTypesRedirectionURLMapObj } from "../config/notification";
import { API_ERROR } from "../config/error-message";
import {
  getNotificationList,
  notificationMarkSeen,
} from "../crud/notification.crud";
import { useHistory } from "react-router-dom";
import * as notification from "./../store/ducks/notification.duck";
import { connect } from "react-redux";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    width: "600px",
  },
});
const monthArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Notifications = (props) => {
  const customClasses = useStyles();
  const history = useHistory();
  const [list, updateList] = useState([]);
  const [disablePage, toggleDisablePage] = useState(false);
  const [loading, toggleLoading] = useState(true);

  const getDisplayFullDateFormat = (date) => {
    const day =
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate();
    const month = monthArr[new Date(date).getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const getAllList = useCallback(async () => {
    toggleDisablePage(true);
    const notificationResults = await getNotificationList();
    toggleDisablePage(false);
    toggleLoading(false);
    if (notificationResults.data.errorCode) {
      toast.error(API_ERROR[notificationResults.data.errorCode]);
      return;
    }
    if (
      notificationResults.data.data &&
      notificationResults.data.data.errorCode
    ) {
      toast.error(API_ERROR[notificationResults.data.data.errorCode]);
      return;
    }
    if (
      notificationResults.data.data &&
      notificationResults.data.data.data &&
      notificationResults.data.data.data.length
    ) {
      let notificationObj = {};
      const forumArr = [];
      notificationResults.data.data.data.forEach((each) => {
        if (each.inAppContent) {
          notificationObj = {};
          notificationObj._id = each._id;
          notificationObj.question = each.inAppContent.info.message;
          notificationObj.selected = false;
          notificationObj.isActionable = each.isActionable;
          notificationObj.seen = each.seen;
          notificationObj.notificationType = each.inAppContent.notificationType;
          notificationObj.redirectionId = each.inAppContent.info.redirectionId;
          notificationObj.date = getDisplayFullDateFormat(
            new Date(each.createdAt)
          );
          notificationObj.time = formatAMPM(new Date(each.createdAt));
          forumArr.push({ ...notificationObj });
        }
      });
      updateList([...forumArr]);
      if (notificationResults.data.data.unreadNotificationCount) {
        props.updateNotification();
      }
    }
    // eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    getAllList();
  }, [getAllList]);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getAllList();
    }, 30000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
    // eslint-disable-next-line
  }, []);

  const openPage = async (notificationData, ind) => {
    const notificationsForPendingList = [
      "vendorProfileVerificationPending",
      "newWorkshopAprrovalPending",
      "newEventAprrovalPending",
      "forumQuestionApprovalForPending",
    ];

    const notificationsForList = [
      "newCustomer",
      "newVendor",
      "approvedForumQuestion",
      "rejectedForumQuestion",
      "answeredForumQuestion",
      "newSupportCase",
      "newRefund",
      "monthlyAppointmentPaymentReminder",
      ...notificationsForPendingList,
    ];

    if (
      notificationTypesRedirectionURLMapObj[notificationData.notificationType]
    ) {
      if (!notificationsForList.includes(notificationData.notificationType)) {
        props.handleClose("notifications");
        if(notificationData.notificationType === 'workshopPayoutPending'){
          let date = notificationData.date;
          localStorage.setItem('paymentSelectedDate', new Date(date).toISOString());
          history.push(
            `${
              notificationTypesRedirectionURLMapObj[
                notificationData.notificationType
              ]
            }${notificationData.redirectionId}/workshop`
          );          
        }else if(notificationData.notificationType === 'eventPayoutPending'){
          let date = notificationData.date;
          localStorage.setItem('paymentSelectedDate', new Date(date).toISOString());
          history.push(
            `${
              notificationTypesRedirectionURLMapObj[
                notificationData.notificationType
              ]
            }${notificationData.redirectionId}/online-event`
          ); 
        }else{
          history.push(
            `${
              notificationTypesRedirectionURLMapObj[
                notificationData.notificationType
              ]
            }${notificationData.redirectionId}`
          );
        }
      } else {
        if (
          notificationsForPendingList.includes(
            notificationData.notificationType
          )
        ) {
          props.updateTab(1);
        }else{
          props.updateTab(0);
        }
        props.handleClose("notifications");
        if(notificationData.notificationType === 'monthlyAppointmentPaymentReminder'){
          let date = notificationData.redirectionId.split(',');
          date = `${Number(date[0])+1}-15-${date[1]}`;
          localStorage.setItem('paymentSelectedDate', new Date(date).toISOString());
        }
        history.push(
          `${
            notificationTypesRedirectionURLMapObj[
              notificationData.notificationType
            ]
          }`
        );
      }
    }
    if (!notificationData.seen) {
      await notificationMarkSeen(notificationData._id);
      const localList = [...list];
      localList[ind].seen = true;
      updateList([...localList]);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          props.handleClose("notification");
        }}
      >
        <div className={customClasses.root}>
          <div
            style={{
              paddingLeft: 16,
              paddingTop: 16,
              paddingBottom: 17,
            }}
          >
            <h3>Notifications</h3>
          </div>
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "16px",
              backgroundColor: "#2e40d4",
              borderColor: "#293ccc",
              color: "#fff",
              padding: "4px 8px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              props.handleClose("notifications");
            }}
          >
            <i className="la la-close"></i>
          </div>
          <div
            className={`row notification ${disablePage ? "pointer-none" : ""}`}
            style={{ margin: 0 }}
          >
            <div className="col-lg-12 pad-0-below-500">
              <div className="col-12">
                <div className="row" style={{ margin: 0 }}>
                  {list.length ? (
                    list.map((eachNotification, index) => {
                      return (
                        <div
                          className={`col-12 notification-wrapper`}
                          key={index}
                        >
                          <div
                            className="row notification-wrapper-row p-0"
                            style={{ margin: 0 }}
                          >
                            <div
                              className="col-12 p-0"
                              onClick={() => openPage(eachNotification, index)}
                            >
                              <div
                                style={{ display: "flex" }}
                                className={`notification-designation notification-designation-not-selected p-0 notification-wrapper ${
                                  eachNotification.seen
                                    ? "seen-notification"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`notification-date-time-wrapper`}
                                >
                                  <div className="only-day">
                                    {eachNotification.date.split("-")[0]}
                                  </div>
                                  <div className="month-year-wrapper">
                                    {eachNotification.date.split("-")[1]} <br />
                                    {eachNotification.date.split("-")[2]}
                                  </div>
                                  <div className="time-wrapper">
                                    {eachNotification.time}
                                  </div>
                                </div>
                                <div className="notification-text-wrapper">
                                  {eachNotification.question}
                                </div>
                                {!eachNotification.seen ? (
                                  <img
                                    src="https://parentcraftmedia.s3.ap-south-1.amazonaws.com/notification-dot.png"
                                    alt="notification unseen"
                                    className="unseen-dot"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center well col-12 color-black">
                      {!loading ? "No Notifications Available!" : "loading..."}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTab: (data) =>
      dispatch(notification.actions.updateTab({ selectedTab: data })),
    updateNotification: () =>
      dispatch(notification.actions.updateNotification()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
