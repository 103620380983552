import {green} from '@material-ui/core/colors';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    bigAvatar: {
        display: "flex",
        padding: "6px 0",
        height: "120px",
        margin: "9px 0"
    },
    profileImageUploaderContainer: {
        display: "flex",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "90%",
        alignItems: "flex-end"
    },
    profileImagePlaceholder: {
        height: "100%",
        width: "100%",
        background: "#ccc",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        color: "#f6f6f6",
        fontSize: "14px"
    },
    uploadImageButtonFile: {
        position: "relative",
        width: "auto",
        display: "inline-block",
        background: "#e1e1e1",
        padding: "8px 16px",
        borderRadius: "4px",
        "&>img": {
            verticalAlign: "-webkit-baseline-middle"
        },
        "&>span": {
            paddingLeft: "8px",
            fontSize: "12px",
            verticalAlign: "sub"
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    createWrapper: {
        width: 600,
        // overflow: 'hidden'
    },
    createForm: {
        padding: theme.spacing(2)
    },
    useFlexBtwn: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

export default styles;
