import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditProblemCategory from "./edit-problem-category";
import RemoveProblemCategory from "./remove-problem-category";
import { listCategory } from "../../../crud/problem-category.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const ProblemCategoryList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showProblemEditCategory, setShowProblemEditCategory] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showProblemDeleteCategory, setShowProblemDeleteCategory] = useState(
    false
  );
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);


  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.problemCategory.listData) {
      // console.log('props.data.problemCategory.listData',
      // props.data.problemCategory.listData);
      let listObject = {
        data: [...props.data.problemCategory.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.problemCategory.listData.data.data.total,
        totalPage: Math.ceil(
          Number(
            props.data.problemCategory.listData.data.data.total /
            tableData.limit
          )
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.problemCategory.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listCategory({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (event, value) => {
    // console.log("value", value);
    setPage(value);

    listCategory({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowProblemEditCategory(false);
  };

  const handleCloseDelete = () => {
    setShowProblemDeleteCategory(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowProblemEditCategory(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowProblemDeleteCategory(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <React.Fragment>
      {showProblemEditCategory ? (
        <EditProblemCategory
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          category={selectedData}
          show={showProblemEditCategory}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showProblemDeleteCategory ? (
        <RemoveProblemCategory
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          category={selectedData}
          show={showProblemDeleteCategory}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'problem category'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}

      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                <th className="custom-table-header-text">Title</th>
                <th className="custom-table-header-text">Language Sets</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{eachData._id}</td>
                    <td className="custom-table-body-text">{eachData.categoryTitle}</td>
                    <td className="custom-table-body-text">
                      <ul>
                        {eachData.languageSet.map((v, key) => {
                          return (
                            <li key={key}>
                              <p>
                                <span>{v.title}</span>
                                <span style={{ fontSize: "10px" }}>
                                  {v.languageRef.name}
                                </span>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblemCategoryList);
