import {listCategory} from "../../crud/problem-category.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    PROBLEM_CATEGORY_ADDED: "PROBLEM_CATEGORY_ADDED",
    PROBLEM_CATEGORY_ADDED_SUCCESS: "PROBLEM_CATEGORY_ADDED_SUCCESS",
    PROBLEM_CATEGORY_UPDATED: "PROBLEM_CATEGORY_UPDATED",
    PROBLEM_CATEGORY_UPDATED_SUCCESS: "PROBLEM_CATEGORY_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.PROBLEM_CATEGORY_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.PROBLEM_CATEGORY_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    problemCategoryAdded: ({skip, limit}) => ({
        type: actionTypes.PROBLEM_CATEGORY_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    problemCategoryUpdated: ({skip, limit}) => ({
        type: actionTypes.PROBLEM_CATEGORY_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.PROBLEM_CATEGORY_ADDED, function * problemCategoryAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listCategory({skip, limit});

        yield put({type: actionTypes.PROBLEM_CATEGORY_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.PROBLEM_CATEGORY_UPDATED, function * problemCategoryUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listCategory({skip, limit});

        yield put({type: actionTypes.PROBLEM_CATEGORY_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
