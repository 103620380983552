import axios from './axios.interceptor';
import api from './api.util';

export const LANGUAGE_URL = `${api}/account/admin/language`;
export const ADD_LANGUAGE_URL =`${LANGUAGE_URL}/add`;
export const LIST_LANGUAGE_URL =`${LANGUAGE_URL}/list`;

export function addLanguage({name, code}) {
  return axios.post(ADD_LANGUAGE_URL, {
    name,
    code
  });
}

export function listLanguage({skip, limit}) {
  return axios.get(`${LIST_LANGUAGE_URL}?skip=${skip}&limit=${limit}`);
}


export function editLanguage(languageId, payload) {
  
  return axios.put(`${LANGUAGE_URL}/${languageId}/update`, payload);
}

export function removeLanguage(languageId) {
  return axios.delete(`${LANGUAGE_URL}/${languageId}/remove`);
}