import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
const RejectVendorModal = (props) => {
  const [reasonOfReject, setReasonOfReject] = useState("");
  const [reasonOfRejectError, setReasonOfRejectError] = useState(false);
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reject Professional: {props.vendor.personalInfo.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure, you want to reject this professional?</p>
          <label htmlFor="reasonOfReject" className="form-label">
                      Reason for rejection: <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${reasonOfRejectError ? "error-border" : ""
                        }`}
                      id="reasonOfReject"
                      placeholder="Enter reason for rejection"
                      value={reasonOfReject}
                      onChange={(e) =>
                        setReasonOfReject(e.target.value)
                      }
                    />{" "}
                    {reasonOfRejectError ? (
                      <div className="input-error">{reasonOfRejectError}</div>
                    ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
          <Button
            disabled={!reasonOfReject.trim().length}
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => {
              if(!reasonOfReject){
                setReasonOfRejectError(true);
              }else{
                setReasonOfRejectError(false);
                props.handleApprove(false, reasonOfReject.trim());
                props.handleClose();
              }

            }}
          >
            {" "}
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default RejectVendorModal;
