import React from "react";
import { toAbsoluteUrl } from "../../_metronic";

export default function NoDataFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 20,
        height: 400,
      }}
    >
      <img
        style={{ height: "50%", opacity: 0.7 }}
        src={toAbsoluteUrl("/media/default-image/default-no-data.png")}
        alt="No Data"
      />
      <p style={{ fontSize: 16, fontWeight: 600 }}>No Data Found</p>
    </div>
  );
};

