import React from "react";
import { Button, Modal } from "react-bootstrap";
const MarkPaidModal = (props) => {
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refund({props.refundDoc.customerRef.personalInfo.name
            ? props.refundDoc.customerRef.personalInfo.name
            : "N/A"}) - Rs. {props.refundDoc.amount}/-</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure, you want to mark paid to this refund?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              props.handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => {
              props.handleMarkPaid(true);
              props.handleClose();
            }}
          >
            {" "}
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default MarkPaidModal;
