import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { addAppsTutorial } from '../../../crud/apps-tutorial.crud';
import * as appsTutorial from "../../../store/ducks/apps-tutorial.duck";
import userConfig from './../../../config/user';
const CreateAppsTutorial = (props) => {
    const { classes } = props;

    const [errors,
        setErrors] = useState({ title: null, icon: null });
    const [loading,
        setLoading] = useState(false);
    const [userType,
        setUserType] = useState(userConfig.role.customer);
    const [userTypeList,
            setUserTypeList] = useState(userConfig.role.customer);
    const [title,
        setTitle] = useState('');
    const [description,
        setDescription] = useState('');


    const onTitleChangeHandler = (event) => {
        setTitle(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
        } else {
            setErrors({
                ...errors,
                title: null
            });
        }
    }

    const onDescriptionChangeHandler = (event) => {
        setDescription(event.target.value);
        if (!event.target.value) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
        } else {
            setErrors({
                ...errors,
                description: null
            });
        }
    }

    const resetState = () => {
        setTitle('');
        setDescription('');

        setErrors({
            title: null,
            description: null
        });
    }

    const validForm = () => {
        let e = true;

        if (!title && !description) {

            setErrors({
                ...errors,
                title: 'Title is required',
                description: 'Description is required'
            });
            e = false;
        } else if (!title) {
            setErrors({
                ...errors,
                title: 'Title is required'
            });
            e = false;
        } else if (!description) {
            setErrors({
                ...errors,
                description: 'Description is required'
            });
            e = false;
        } else {
            setErrors({
                ...errors,
                title: null,
                description: null
            });
        }
        return e;
    }

    const handleSubmit = () => {
        if (validForm()) {
            setLoading(true);
            addAppsTutorial({ title, description, userType }).then((output) => {
                if (output.data.success) {
                    props.appsTutorialAdded({ skip: 0, limit: 5, userType: userTypeList });
                    resetState();
                    setLoading(false);
                    toast.success('Tutorial is successfully added!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                    props.handleClose('tutorials');
                }
            }).catch((error) => {
                console.log('error', error);
                setLoading(false);
            });
        }
    };

    const handleUserType = (userTypeVal) => {
        setUserType(userTypeVal);
    };

    useEffect(()=>{
        if(props.data.appsTutorial.currTab===0){
            setUserTypeList(userConfig.role.customer);
        }else{
            setUserTypeList(userConfig.role.vendor);
        }
    }, [props.data.appsTutorial.currTab])


    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={() => {
                    resetState();
                    props.handleClose('tutorials')
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Tutorial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>User</Form.Label>
                            <div key={`inline-radio`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="Customer"
                                    name="userType"
                                    type={'radio'}
                                    checked={userType === userConfig.role.customer}
                                    onChange={() => handleUserType(userConfig.role.customer)}
                                />
                                <Form.Check
                                    inline
                                    label="Professional"
                                    name="userType"
                                    type={'radio'}
                                    checked={userType === userConfig.role.vendor}
                                    onChange={() => handleUserType(userConfig.role.vendor)}
                                />

                            </div>
                        </Form.Group>
                        <Form.Group controlId="appsTutorialForm.title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={onTitleChangeHandler}
                                isInvalid={!!errors.title} />
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>

                        </Form.Group>
                        <Form.Group controlId="appsTutorialForm.description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                value={description}
                                onChange={onDescriptionChangeHandler}
                                isInvalid={!!errors.description} />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            resetState();
                            props.handleClose('tutorials');
                        }}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        style={{
                            position: "relative"
                        }}
                        onClick={() => handleSubmit()}
                        disabled={loading}>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        appsTutorialAdded: ({ skip, limit, userType }) => dispatch(appsTutorial.actions.appsTutorialAdded({ skip, limit, userType }))
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme)
}), { withTheme: true })(CreateAppsTutorial)));
