import axios from './axios.interceptor';
import api from './api.util';

export const GLOBAL_SETTINGS_URL = `${api}/account/admin/global-config`;
export const LIST_GLOBAL_SETTINGS_URL =`${GLOBAL_SETTINGS_URL}`;


export function listGlobalSettings({skip, limit}) {
  return axios.get(`${LIST_GLOBAL_SETTINGS_URL}?skip=${skip}&limit=${limit}`);
}


export function editGlobalSettings(payload) {
  return axios.put(`${GLOBAL_SETTINGS_URL}`, payload);
}

