import React, {Fragment, useState} from 'react';
import FullWidthTabs from '../../common/FullWidthTabs';
import AppointmentRevenueCalculator from './appointment-revenue';
import EventRevenueCalculator from './event-revenue';
import WorkshopRevenueCalculator from './workshop-revenue';

const RevenueCalculator = ()=>{

    const [currentTab, setCurrentTab] = useState(0);

    const renderCalculator = ()=>{
        switch (currentTab) {
            case 0:
              return <AppointmentRevenueCalculator/>;
            case 1:
              return <EventRevenueCalculator/>;
            case 2:
              return <WorkshopRevenueCalculator/>;
            default:
              return null;
          }
    };


    return (<>
     <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          setCurrentTab(tab);
        }}
        title={"Revenue Calculator"}
        tabsTitle={[
          "Appointment",
          "Events",  
          "Workshop"
        ]}
      />
       <Fragment>
        {
          renderCalculator()
        }
      </Fragment>
    </>)
};

export default RevenueCalculator;