import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";
import ForumLeftTabCard from "./component/ForumLeftTabCard";
import NoDataFound from "./component/NoDataFound";
import SectionHoc from "./component/SectionHoc";
import CustomBookingCard from "./component/CustomBookingCard";
import CustomViewScroll from "./component/CustomViewScroll";

const ForumSection = ({ dashboardData }) => {
  const [forumLeftTab, setForumLeftTab]= useState(1);
  return (
    <DashboardCardHOC
      title={"Forum"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL QUESTIONS"
              value={dashboardData.totalApprovedForumQuestion}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #2699FB 0%, #1886E4 67%, #046CC5 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="TOTAL ANSWERS"
              value={dashboardData.totalAnswersOfForumApprovedQuestions}
              iconImg="Event"
              bgStyle={{
                background: "#1CC88A 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="AVG ANSWER"
              value={dashboardData.totalAvgAnswersOfForumQuestions}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(112deg, #1DE1EB 0%, #00F1FF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="PENDING"
              value={dashboardData.totalPendingApprovalForumQuestion}
              iconImg="Event"
              bgStyle={{
                background:
                  "transparent linear-gradient(112deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              type="ViewMore"
              desc="VIEW DATA"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #6692FC 0%, #608FFF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="forums"
            />
          </div>
        </div>
      }
      collapseContent={
        <div>
          <SectionHoc
            isleftRightTxt={true}
            leftContent={
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  margin: 0,
                  paddingLeft: 16,
                  lineHeight: 1.334,
                }}
              >
                Forum Activity
              </p>
            }
            rightContent={
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  margin: 0,
                  paddingRight: 16,
                }}
              >
                <Link to="/forums">VIEW ALL</Link>
              </p>
            }
          >
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 400,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            > */}
              <div className="row" style={{ marginTop: 24, paddingLeft: 16 }}>
                <div className="col-lg-3">
                  <ForumLeftTabCard title={`QUESTION TODAY (${dashboardData.totalForumQuestionToday})`} color="#1987E6" onClick={()=>{setForumLeftTab(1)}}/>
                  <ForumLeftTabCard title={`QUESTION THIS WEEK (${dashboardData.totalForumQuestionThisWeek})`} color="#1987E6" onClick={()=>{setForumLeftTab(2)}}/>
                  <ForumLeftTabCard title={`ANSWER TODAY (${dashboardData.totalAnswersOfForumApprovedQuestionsToday})`} color="#1987E6" onClick={()=>{setForumLeftTab(3)}}/>
                  <ForumLeftTabCard title={`ANSWER THIS WEEK (${dashboardData.totalAnswersOfForumApprovedQuestionsThisWeek})`} color="#1987E6" onClick={()=>{setForumLeftTab(4)}}/>
                </div>
                <div className="col-lg-9" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <div style={{marginLeft: 16, background: '#ccc', width: 1, height: '100%'}}>
                  </div>
                  <div style={{width: '100%'}}>
                    {forumLeftTab===1 && dashboardData.forumQuestionListToday &&
                      dashboardData.forumQuestionListToday.length ?(
                      <CustomViewScroll
                      isMoreRightPadding={dashboardData.forumQuestionListToday.length > 8}
                    >
                      {dashboardData.forumQuestionListToday.map((each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard data={{
                              id: each._id,
                              title: each.question,
                              subTitle: `Asked By: ${each?.askedBy?.personalInfo?.name}`,
                              image: each.thumbnailImage,
                              type: 'forum'
                            }} 
                            pageDetails='forum-details'
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"/>
                          </div>
                        );
                      })}
                      </CustomViewScroll>): forumLeftTab===1? <NoDataFound/>: null}
                     {forumLeftTab===2 && dashboardData.forumQuestionListThisWeek &&
                      dashboardData.forumQuestionListThisWeek.length ?
                      ( <CustomViewScroll
                        isMoreRightPadding={dashboardData.forumQuestionListThisWeek.length > 8}
                      >{dashboardData.forumQuestionListThisWeek.map((each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard data={{
                              id: each._id,
                              title: each.question,
                              subTitle: `Asked By: ${each?.askedBy?.personalInfo?.name}`,
                              image: each.thumbnailImage,
                              type: 'forum'
                            }} 
                            pageDetails='forum-details'
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"/>
                          </div>
                        );
                      })}</CustomViewScroll>): forumLeftTab===2? <NoDataFound/>: null}
                      {forumLeftTab===3 && dashboardData.answersOfForumListApprovedQuestionsToday &&
                      dashboardData.answersOfForumListApprovedQuestionsToday.length ?
                      ( <CustomViewScroll
                        isMoreRightPadding={dashboardData.answersOfForumListApprovedQuestionsToday.length > 8}
                      >{ dashboardData.answersOfForumListApprovedQuestionsToday.map((each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard data={{
                              id: each.questionId,
                              title: each.answer,
                              subTitle: `Answered By: ${each?.answerBy?.personalInfo?.fullName || ''}${each?.answerBy?.personalInfo?.name || ''}`,
                              image: each.thumbnailImage,
                              type: 'forum'
                            }} 
                            pageDetails='forum-details'
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"/>
                          </div>
                        );
                      })}</CustomViewScroll>): forumLeftTab===3? <NoDataFound/>: null}
                       {forumLeftTab===4 && dashboardData.answersOfForumListApprovedQuestionsThisWeek &&
                      dashboardData.answersOfForumListApprovedQuestionsThisWeek.length ?
                      ( <CustomViewScroll
                        isMoreRightPadding={dashboardData.answersOfForumListApprovedQuestionsThisWeek.length > 8}
                      >
                      {dashboardData.answersOfForumListApprovedQuestionsThisWeek.map((each, key) => {
                        return (
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              paddingBottom: 12,
                              paddingLeft: 12,
                            }}
                            key={key}
                          >
                            <CustomBookingCard data={{
                              id: each.questionId,
                              title: each.answer,
                              subTitle: `Answered By: ${each?.answerBy?.personalInfo?.fullName || ''}${each?.answerBy?.personalInfo?.name || ''}`,
                              image: each.thumbnailImage,
                              type: 'forum'
                            }} 
                            pageDetails='forum-details'
                            bgColor="#e3f0fc 0% 0% no-repeat padding-box"/>
                          </div>
                        );
                      })}</CustomViewScroll>): forumLeftTab===4? <NoDataFound/>: null}
                  </div>
                </div>
              </div>
            {/* </div> */}
          </SectionHoc>
        </div>
      }
    />
  );
};

export default ForumSection;
