import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Form, Button, Modal, Image as BImage } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { addInstitution } from "../../../crud/institution.crud";
import * as institution from "../../../store/ducks/institution.duck";
import {
  // VALID_PROMO_ERROR,
  // VALID_NAME_ERROR,
  INVALID_EMAIL,
} from "../../../config/error-message";
const CreateInstitution = (props) => {
  const { classes } = props;
  const [errors, setErrors] = useState({
    name: null,
    profilePicture: null,
    email: null,
    promoCode: null
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [email, setEmail] = useState("");
  const [preview, setPreview] = useState(null);
  const observed = useRef(null);
  let sub;

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    return () => {
      if (sub) {
        clearTimeout(sub);
      }
    };
  }, [sub]);

  const onChangeHandler = (event) => {
    let file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      // console.log(img.width);
      // console.log(img.height);
      if (img.width <= 120 && img.height <= 120) {
        // console.log("true", true);
        setSelectedFile(file);
        setErrors({
          ...errors,
          profilePicture: null,
        });
        return true;
      }
      setErrors({
        ...errors,
        profilePicture: "Profile Picture should not be grater than 120x120px",
      });
      sub = setTimeout(() => {
        setErrors({
          ...errors,
          profilePicture: null,
        });
      }, 3000);
      return false;
    };
  };

  const onChangeNameHandler = (event) => {
    setName(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        name: "Name is required",
      });
    }
    // else if (validateName(event.target.value)) {
    //   setErrors({
    //     ...errors,
    //     name: null,
    //   });
    // } 
    else {
      setErrors({
        ...errors,
        name: null,
      });
    }
  };

  const onChangePromoCodeHandler = (event) => {
    setPromoCode(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        promoCode: "Promo code is required",
      });
    }
    // else if (validateName(event.target.value)) {
    //   setErrors({
    //     ...errors,
    //     promoCode: null,
    //   });
    // } 
    else {
      setErrors({
        ...errors,
        promoCode: null,
      });
    }
  };

  const onChangeEmailHandler = (event) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setErrors({
        ...errors,
        email: "Email is required",
      });
    } else if (validateEmail(event.target.value)) {
      setErrors({
        ...errors,
        email: null,
      });
    } else {
      setErrors({
        ...errors,
        email: INVALID_EMAIL,
      });
    }
  };

  // const validateName = (name) => {
  //   return /^[A-Za-z\s]+$/.test(name);
  // };

  const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const resetState = () => {
    setName("");
    setEmail("");
    setPromoCode("");
    setSelectedFile(undefined);
    setPreview(undefined);
    setErrors({ name: null, profilePicture: null, email: null });
  };

  const validForm = () => {
    let e = true;

    if (!name && !email && !promoCode) {
      setErrors({
        ...errors,
        name: "Name is required",
        email: "Email is required",
        promoCode: "Promo Code is required"
      });
      e = false;
    } else {


      if (!name) {
        setErrors({
          ...errors,
          name: "Name is required",
        });
        e = false;
      }


      if (!email) {
        setErrors({
          ...errors,
          email: "Email is required",
        });
        e = false;
      }

      if (email && !validateEmail(email)) {
        setErrors({
          ...errors,
          email: INVALID_EMAIL,
        });
        e = false;
      }

      if (!promoCode) {
        setErrors({
          ...errors,
          promoCode: "Promo Code is required",
        });
        e = false;
      }

      if (name && email && validateEmail(email) && promoCode) {
        // console.log('All Ok');
        setErrors({
          ...errors,
          name: null,
          email: null,
          promoCode: null
        });
      }
    }
    return e;
  };

  const handleSubmit = () => {
    if (validForm()) {
      setLoading(true);
      addInstitution({ profilePicture: selectedFile, name, email, promoCode })
        .then((output) => {
          if (output.data.success) {
            props.institutionAdded({ skip: 0, limit: 5 });
            resetState();
            setLoading(false);
            toast.success("Institution is successfully added!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            props.handleClose("institutions");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } else {
      // console.log('errors', errors);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={() => {
          resetState();
          props.handleClose("institutions");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Institution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="institution.profilePicture">
              <Form.Label>Profile Picture</Form.Label>
              <div className="row">
                <div className="col-5">
                  <div className={classes.bigAvatar}>
                    {selectedFile ? (
                      <BImage src={preview} thumbnail />
                    ) : (
                        <div
                          style={{
                            height: "100%",
                            width: "120px",
                            background: "#ccc",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            justifyContent: "center",
                            color: "#f6f6f6",
                            fontSize: "14px",
                          }}
                        >
                          <span>120 x 120 px</span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-5">
                  <div className={classes.profileImageUploaderContainer}>
                    <div
                      className={classes.uploadImageButtonFile}
                      onClick={(e) => {
                        observed.current.click();
                      }}
                    >
                      <input
                        ref={observed}
                        type="file"
                        style={{
                          display: "none",
                        }}
                        onChange={onChangeHandler}
                      />
                      <img
                        src={toAbsoluteUrl("/media/icons/image-icon.png")}
                        alt="no-img"
                      />
                      <span>Browse Profile Picture</span>
                    </div>
                  </div>
                </div>
              </div>
              {errors.profilePicture ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{errors.profilePicture}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="institution.name">
              <Form.Label>
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={onChangeNameHandler}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="institution.email">
              <Form.Label>
                Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={onChangeEmailHandler}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="institution.promocode">
              <Form.Label>
                Promo Code <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={promoCode}
                onChange={onChangePromoCodeHandler}
                isInvalid={!!errors.promoCode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.promoCode}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              resetState();
              props.handleClose("institutions");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    institutionAdded: ({ skip, limit }) =>
      dispatch(institution.actions.institutionAdded({ skip, limit })),
  };
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withStyles(
      (theme) => ({
        ...styles(theme),
      }),
      { withTheme: true }
    )(CreateInstitution)
  )
);
