import React from "react";
import DashboardCardHOC from "./component/DashboardCardHoc";
import DashboardStat from "./component/DashboardStat";

const AssesmentSection = ({ dashboardData }) => {
  return (
    <DashboardCardHOC
      title={"Assesments"}
      fixedContent={
        <div className="row row-cols-5">
          <div className="col">
            <DashboardStat
              desc="TOTAL ASSESMENTS"
              value={dashboardData.totalTestBookingTillDate}
              iconImg="List"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #2699FB 0%, #1886E4 43%, #046CC5 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="TODAY'S ASSESMENTS"
              value={dashboardData.totalTestBookingToday}
              iconImg="List"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #FFCD33 0%, #FFC206 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="THIS WEEK"
              value={dashboardData.totalTestBookingThisWeek}
              iconImg="List"
              bgStyle={{
                background:
                  "transparent linear-gradient(110deg, #1DE1EB 0%, #00F1FF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              desc="THIS MONTH"
              value={dashboardData.totalTestBookingThisMonth}
              iconImg="List"
              bgStyle={{
                background:
                  "transparent linear-gradient(180deg, #F26522 0%, #F26522 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
            />
          </div>
          <div className="col">
            <DashboardStat
              type="ViewMore"
              desc="VIEW DATA"
              bgStyle={{
                background:
                  "transparent linear-gradient(111deg, #6692FC 0%, #608FFF 100%) 0% 0% no-repeat padding-box",
                boxShadow: "0px 2px 2px #00000029",
              }}
              link="reports"
            />
          </div>
        </div>
      }
    />
  );
};

export default AssesmentSection;
