import axios from './axios.interceptor';
import api from './api.util';

export const DASHBOARD_URL = `${api}/account/admin/dashboard`;

export const ASSESSMENT_STATS_URL = `${api}/account/admin/dashboard/get-assessment-stats-data`;
export const TEST_CLICK_REPORTS_URL = `${api}/account/admin/dashboard/get-test-clicks-data`;

export function getDashBoardData() {
  return axios.get(`${DASHBOARD_URL}`);
}

export function getThisWeekProfessionalData() {
  return axios.get(`${DASHBOARD_URL}/get-this-week-professionals`);
}

export function getThisMonthProfessionalData() {
  return axios.get(`${DASHBOARD_URL}/get-this-month-professionals`);
}

export function getThisCustomDateRangeProfessionalData(payload) {
  return axios.post(`${DASHBOARD_URL}/get-professionals-by-custom-date-range`, payload);
}

export function getThisWeekCustomerData() {
  return axios.get(`${DASHBOARD_URL}/get-this-week-customers`);
}

export function getThisMonthCustomerData() {
  return axios.get(`${DASHBOARD_URL}/get-this-month-customers`);
}

export function getThisCustomDateRangeCustomerData(payload) {
  return axios.post(`${DASHBOARD_URL}/get-customers-by-custom-date-range`, payload);
}

export function getAssessmentStatsData() {
  return axios.get(`${ASSESSMENT_STATS_URL}`);
}

export function getTestClickReports({ skip, limit, payload }) {
  return axios.post(`${TEST_CLICK_REPORTS_URL}?skip=${skip}&limit=${limit}`, payload);
}
