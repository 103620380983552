import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { MyLocation } from "@material-ui/icons";
import { TimePicker } from "@material-ui/pickers";
import {
  REQUIRED_ERROR,
  INVALID_FROM_TO_DATE,
  CHOOSE_ERROR,
  INVALID_LOCATION,
} from "./../../../../config/error-message";
let map;
let marker;
let geocoder;
let myLatLng;

const AddressManager = ({
  mode,
  profileType,
  addresses,
  updateMode,
  chooseLocation,
  openRemove,
  locationDetails,
  updateLocationDetails,
  initialLocationError,
  locationDetailsError,
  updateLocationDetailsError,
  openLocationList,
  saveLocation,
  daysArray,
  handleFromDateChange,
  selectedFromDate,
  selectedToDate,
  handleToDateChange,
  toggleDaySelection,
  updateDateError,
  locationData,
  updateCoordinate
}) => {
  const [locationPinned, pinLocation] = useState(true);
  const [selectedCoordinates, setSelectedCoordinates] = useState([]);
  const [draggedAddress, updateDraggedAddress] = useState({});
  const [userLocation, updateUserLocation] = useState([]);
  const geocodePosition = (pos) => {
    geocoder.geocode(
      {
        latLng: pos,
      },
      (responses) => {
        if (responses && responses.length > 0) {
          setSelectedCoordinates([pos.lng(), pos.lat()]);
          updateCoordinate([pos.lng(), pos.lat()]);
          updateDraggedAddress(responses[0]);
        } else {
          console.log("Cannot determine address at this location.");
        }
      }
    );
  };

  const setMarker = (pos, isCurrent) => {
    marker = new window.google.maps.Marker({
      position: pos,
      map,
      draggable: true,
    });
    if (isCurrent) {
      geocodePosition(marker.getPosition());
    }
    marker.addListener("dragend", () => {
      myLatLng = {
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng(),
      };
    });
  };

  const initAutocomplete = () => {
    setSelectedCoordinates([]);
    if (window.google) {
      const input = document.getElementById("location-search-input");
      const searchBox = new window.google.maps.places.Autocomplete(input);

      searchBox.setComponentRestrictions({
        country: ["in"],
      });

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });

      searchBox.addListener("place_changed", () => {
        const place = searchBox.getPlace();

        if (!place || (place && !Object.keys(place).length)) {
          return;
        }

        const bounds = new window.google.maps.LatLngBounds();
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        marker.setMap(null);
        myLatLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMarker({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setSelectedCoordinates([
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ]);
        updateCoordinate([
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ])
        updateLocationDetails(place, "address", true);
        map.fitBounds(bounds);
      });
    }
  };

  const initMap = (locationData) => {
    setTimeout(() => {
      geocoder = new window.google.maps.Geocoder();
      myLatLng = { lat: 22.7196, lng: 75.8577 };
      if (locationData && locationData.length) {
        myLatLng = { lat: locationData[1], lng: locationData[0] };
      } else if (userLocation.length) {
        myLatLng = { lat: userLocation[1], lng: userLocation[0] };
      }
      map = new window.google.maps.Map(document.getElementById("mapAddress"), {
        center: myLatLng,
        zoom: 18,
        mapTypeId: "roadmap",
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      });
      setMarker(myLatLng);
     
    }, 100);
  };

  const chooseCurrentUserLocation = () => {
    if (userLocation.length) {
      marker.setMap(null);
      myLatLng = { lat: userLocation[1], lng: userLocation[0] };
      setMarker(myLatLng);
      map.setCenter(myLatLng);
    } else {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            if (
              position &&
              position.coords &&
              position.coords.latitude &&
              position.coords.longitude
            ) {
              updateUserLocation([
                position.coords.longitude,
                position.coords.latitude,
              ]);
              marker.setMap(null);
              myLatLng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              setMarker(myLatLng);
              map.setCenter(myLatLng);
            }
          },
          (error) => {
            if (error.code === 1) {
              toast.error(
                "Not able access your current location. If you have denied the permission then please refresh the page or Please change the location access from your browser's settings"
              );
            }
          }
        );
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    }
  };

  const validateLocation = () => {
    const localLocationError = { ...locationDetailsError };
    let daysError = true;
    let noError = true;
    if (!locationDetails.title || !locationDetails.title.trim().length) {
      localLocationError.title = REQUIRED_ERROR;
      noError = false;
    } else {
      localLocationError.title = "";
    }

    if (!locationDetails.address || !locationDetails.address.trim().length) {
      localLocationError.address = REQUIRED_ERROR;
      noError = false;
    } else {
      localLocationError.address = "";
    }

    if (new Date(selectedFromDate) > new Date(selectedToDate)) {
      localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
      noError = false;
    } else {
      localLocationError.selectedFromDate = "";
    }

    daysArray.forEach((each) => {
      if (each.selected) {
        daysError = false;
      }
    });

    if (daysError) {
      localLocationError.days = CHOOSE_ERROR;
      noError = false;
    } else {
      localLocationError.days = "";
    }

    if (!selectedCoordinates.length) {
      localLocationError.address = INVALID_LOCATION;
      noError = false;
    } else {
      localLocationError.address = "";
    }
    updateLocationDetailsError({ ...localLocationError });
    return noError;
  };

  useEffect(() => {
    if (draggedAddress && Object.keys(draggedAddress).length) {
      updateLocationDetails(draggedAddress, "address", true);
      setTimeout(() => {
        updateDraggedAddress({});
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggedAddress]);

  useEffect(() => {
    initMap(locationData);
    if(locationData.length)
    {
      setSelectedCoordinates(locationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  return (
    <div className="col-12 p-0">
      {mode === "list" ? (
        <div className="row" style={{ margin: 0 }}>
          <div className="col-12 p-0">
            <div className="row" style={{ margin: 0 }}>
              <div className="col-sm-8 col-xs-12 pl-0 pt-2">
                <h5 className={`mb-2`}>Address Manager</h5>
              </div>
              {(profileType === 1 && addresses.length < 1) ||
              profileType === 2 ? (
                <div className="col-sm-4 col-xs-12 pr-0 text-right mb-3">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      updateMode("add");
                      pinLocation(true);
                    }}
                  >
                    Add Address
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {addresses.length ? (
            addresses.map((professional, index) => {
              return (
                <div
                  className={`col-sm-6 col-xs-12 professional-wrapper
        ${index % 2 === 0 ? "problem-more-768-pr-15-for-2" : ""}
        ${
          index !== addresses.length - 1 &&
          index !== addresses.length - 2 &&
          index !== addresses.length - 3
            ? "problem-more-768-pb-15"
            : ""
        }
        ${
          index !== addresses.length - 1 && index !== addresses.length - 2
            ? "problem-more-768-pb-15-for-2"
            : ""
        }
        ${index !== addresses.length - 1 ? "problem-more-768-pb-15-for-1" : ""}
        `}
                  key={index}
                  onClick={() => chooseLocation(professional)}
                >
                  <div className="row service-row" style={{ margin: 0 }}>
                    <div className="col-12 p-0">
                      <div className="row" style={{ margin: 0 }}>
                        <div className="professional-name col-12 p-0">
                          {professional.title}
                        </div>
                        <div className="professional-location service-name col-12 p-0">
                          {professional.addressLine1
                            ? professional.addressLine1
                            : professional.address}
                        </div>
                        <div className="col-5 pl-0">
                          <div className="service-cost">
                            {professional.days}
                          </div>
                        </div>
                        <div className="col-7 pr-0 text-right">
                          <div className="service-hour">
                            {professional.duration}
                          </div>
                        </div>
                      </div>
                    </div>
                    {addresses.length > 1 ? (
                      <img
                        src={require("./../../../../../images/remove.svg")}
                        onClick={(e) => {
                          e.stopPropagation();
                          openRemove(professional, "Location");
                        }}
                        alt="remove location"
                      />
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center well col-12 mb-3 color-black">
              No saved address
            </div>
          )}
        </div>
      ) : null}
      {mode === "add" || mode === "edit" ? (
        <div className="row" style={{ margin: 0 }}>
          <div className="col-12 p-0">
            <div className="row" style={{ margin: 0 }}>
              <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                <h5 className={`mb-0`}>
                  {mode === "add" ? "Add" : "Edit"} Location
                </h5>
              </div>
              <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm mr-2"
                  onClick={() => {
                    updateLocationDetailsError({ ...initialLocationError });
                    openLocationList();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    if (!validateLocation()) {
                      return;
                    }
                    saveLocation(mode);
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="row" style={{ margin: 0 }}>
              <div className="col-12 text-center mb-3 form-label">
                Your Current Location Of Practice
              </div>
              <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  City, State
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      locationDetailsError.title ? "error-border" : ""
                    }`}
                    id="exampleFormControlInput1"
                    placeholder="Enter City, State"
                    value={locationDetails.title}
                    onChange={(e) =>
                      updateLocationDetails(e.target.value, "title")
                    }
                  />
                </div>
                {locationDetailsError.title ? (
                  <div className="input-error">
                    {locationDetailsError.title}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  {!locationPinned
                    ? "Search on Google maps"
                    : "Choose map location"}
                </label>
                <br />
                {locationPinned ? (
                  <button
                    className="btn btn-default"
                    style={{
                      backgroundColor: "#eff5ff",
                      color: "#566986",
                    }}
                    onClick={() => {
                      pinLocation(false);
                      initMap(
                        selectedCoordinates.length ? selectedCoordinates : []
                      );
                    }}
                  >
                    {selectedCoordinates.length ? (
                      <i
                        className={`fa fa-check-circle mr-2 status-icon`}
                        style={{ color: "#48cc39" }}
                        aria-hidden="true"
                      />
                    ) : (
                      <i
                        className={`fa fa-exclamation-circle mr-2 status-icon`}
                        style={{ color: "#f29900" }}
                        aria-hidden="true"
                      />
                    )}
                    {!selectedCoordinates.length
                      ? "Choose map location "
                      : "Location selected"}
                  </button>
                ) : (
                  <input
                    type="text"
                    className={`form-control ${
                      locationDetailsError.address ? "error-border" : ""
                    }`}
                    id="location-search-input"
                    placeholder="Search on Google maps"
                    value={locationDetails.address}
                    onChange={(e) => {
                      initAutocomplete();
                      updateLocationDetails(e.target.value, "address");
                    }}
                  />
                )}
                {locationDetailsError.address ? (
                  <div className="input-error">
                    {locationDetailsError.address}
                  </div>
                ) : null}
                {!locationPinned ? (
                  <>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        pinLocation(true);
                        setMarker(myLatLng, true);
                      }}
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "-60px",
                        zIndex: 9,
                      }}
                    >
                      Confirm Location
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        chooseCurrentUserLocation();
                      }}
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "-110px",
                        zIndex: 9,
                      }}
                    >
                      <MyLocation />
                    </button>
                  </>
                ) : null}
              </div>
              {!locationPinned ? (
                <div
                  id="mapAddress"
                  className="col-12 mb-3"
                  style={{
                    height: "250px",
                  }}
                />
              ) : null}
              <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Full address
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      locationDetailsError.addressLine1 ? "error-border" : ""
                    }`}
                    id="exampleFormControlInput1"
                    placeholder="Enter full address"
                    value={locationDetails.addressLine1}
                    onChange={(e) =>
                      updateLocationDetails(e.target.value, "addressLine1")
                    }
                  />
                </div>
                {locationDetailsError.addressLine1 ? (
                  <div className="input-error">
                    {locationDetailsError.addressLine1}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-12 right-padding mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Pincode
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      locationDetailsError.addressLine2 ? "error-border" : ""
                    }`}
                    id="exampleFormControlInput1"
                    placeholder="Enter pincode"
                    value={locationDetails.addressLine2}
                    maxLength={6}
                    onChange={(e) =>
                      updateLocationDetails(
                        e.target.value.replace(/\D/, ""),
                        "addressLine2"
                      )
                    }
                  />
                </div>
                {locationDetailsError.addressLine2 ? (
                  <div className="input-error">
                    {locationDetailsError.addressLine2}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-12 left-padding mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Landmark
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      locationDetailsError.addressLine3 ? "error-border" : ""
                    }`}
                    id="exampleFormControlInput1"
                    placeholder="Enter landmark"
                    value={locationDetails.addressLine3}
                    onChange={(e) =>
                      updateLocationDetails(e.target.value, "addressLine3")
                    }
                  />
                </div>
                {locationDetailsError.addressLine3 ? (
                  <div className="input-error">
                    {locationDetailsError.addressLine3}
                  </div>
                ) : null}
              </div>
              <div className="col-12 text-center mb-3 form-label">
                Your work hours and workdays
              </div>
              <div className="col-md-6 col-xs-12 left-padding mb-3 custom-time-picker-design">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Start time
                </label>
                <br />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="far fa-hourglass" />
                    </div>
                  </div>
                  <TimePicker
                    className={`${
                      locationDetailsError.selectedFromDate
                        ? "timepicker-error-border"
                        : ""
                    } time-picker-with-icon`}
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    onClose={updateDateError}
                    minutesStep={30}
                  />
                </div>
                {locationDetailsError.selectedFromDate ? (
                  <div className="input-error">
                    {locationDetailsError.selectedFromDate}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-xs-12 right-padding mb-3 custom-time-picker-design">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  End time
                </label>
                <br />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="far fa-hourglass" />
                    </div>
                  </div>
                  <TimePicker
                    className={`${
                      locationDetailsError.selectedFromDate
                        ? "timepicker-error-border"
                        : ""
                    } time-picker-with-icon`}
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    minutesStep={30}
                    onClose={updateDateError}
                  />
                </div>
              </div>
              <div className="col-12 p-0 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Choose Day(s)
                </label>
                <div className="row" style={{ maargin: 0 }}>
                  {daysArray.length
                    ? daysArray.map((dayValue, index) => {
                        return (
                          <div className="col-md-3 col-6" key={index}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`${dayValue.text}-checkbox`}
                                checked={dayValue.selected}
                                onChange={() => toggleDaySelection(index)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`${dayValue.text}-checkbox`}
                              >
                                {dayValue.text}
                              </label>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
                {locationDetailsError.days ? (
                  <div className="input-error">{locationDetailsError.days}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddressManager;
