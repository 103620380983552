import React, { Fragment } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

const ModulePermission = (props) => {
  const handleChange = (name) => (event) => {
    props.setModules({
      ...props.modules,
      [props.module.key]: {
        ...props.module,
        [name]: event.target.checked,
      },
    });
  };

  // const handleChangePermission = (event) => {
  //   props.setModules({
  //     ...props.modules,
  //     [props.module.key]: {
  //       ...props.module,
  //       role: event.target.value,
  //     },
  //   });
  // };

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.module.checked}
            onChange={handleChange("checked")}
            value={"checked"}
            color="primary"
          />
        }
        label={props.module.name}
      />
      {/* <FormControl>
                <Select
                    value={props.module.role}
                    onChange={handleChangePermission}
                    inputProps={{
                    name: 'role'
                }}>
                    <MenuItem value={1}>View</MenuItem>
                    <MenuItem value={2}>Edit</MenuItem>
                    <MenuItem value={3}>Delete</MenuItem>
                </Select>
            </FormControl> */}
    </Fragment>
  );
};

export default ModulePermission;
