import React from 'react';
import { Form, Button } from "react-bootstrap";
const AddBusinessId = ({ businessIdByAdmin, handleChange, saveBusinessId }) => {
    return (<Form.Group style={{ width: '100%', marginBottom: 0 }}>
        <Form.Label>Business Id</Form.Label>
        <Form.Control
            value={businessIdByAdmin}
            onChange={handleChange} />
        <Button style={{ marginTop: 16 }} variant="primary" onClick={saveBusinessId}> Save </Button>
    </Form.Group>)
};

export default AddBusinessId;