import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { listPayment } from "../../../crud/payment.crud";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FullWidthTabs from "./../../common/FullWidthTabs";
import * as paymentDuck from "../../../store/ducks/payment.duck";
import paymentConfig from './../../../config/payment';
import VendorPaymentList from './vendor/index';
// import OrganizationPaymentList from './organization/index';
import OnlineEventPaymentList from './online-event/index';
import InstitutionPaymentList from './institution/index';
import WorkshopPaymentList from './workshop/index';
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";
const PaymentList = (props) => {

  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  // const [tableDataOrganiser, setTableDataOrganiser] = useState({
  //   data: [],
  //   skip: 0,
  //   limit: 10,
  //   total: 0,
  //   totalPage: 0,
  // });

  const [tableDataOnlineEvent, setTableDataOnlineEvent] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [tableDataWorkshop, setTableDataWorkshop] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [tableDataInstitution, setTableDataInstitution] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(props.data.payment.currTab);
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('paymentSelectedDate')? new Date(localStorage.getItem('paymentSelectedDate')): new Date());

  const getSearchType = () => {
    switch (currentTab) {
      case 0:
        return paymentConfig.searchType.vendor;
      // case 1:
      //   return paymentConfig.searchType.organiser;
      case 1:
        return paymentConfig.searchType.workshop;
      case 2:
        return paymentConfig.searchType.onlineEvent;
      case 3:
        return paymentConfig.searchType.institution;
      default:
        return null;
    }
  };

  const handleMonthChange = (date) => {
    reloadList({
      year: new Date(date).getFullYear(),
      month: new Date(date).getMonth() + 1,
      searchType: getSearchType()
    });
    localStorage.setItem('paymentSelectedDate', new Date(date).toISOString());
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    localStorage.setItem('paymentSelectedDate', new Date(date).toISOString());
  };

  useEffect(() => {
    setPage(1);
    switch (currentTab) {
      case 0:
        listPayment({
          skip: 0,
          limit: tableData.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableData({
              data: [...docs.data.data.data],
              skip: 0,
              limit: tableData.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableData.limit)
              ),
            });
          }
        });
        break;
      // case 1:
      //   listPayment({
      //     skip: 0,
      //     limit: tableDataOrganiser.limit,
      //     queryPayload: {
      //       year: new Date(selectedDate).getFullYear(),
      //       month: new Date(selectedDate).getMonth() + 1,
      //       searchType: getSearchType()
      //     },

      //   }).then((docs) => {
      //     if (docs.data) {
      //       setTableDataOrganiser({
      //         data: [...docs.data.data.data],
      //         skip: 0,
      //         limit: tableDataOrganiser.limit,
      //         total: docs.data.data.total,
      //         totalPage: Math.ceil(
      //           Number(docs.data.data.total / tableDataOrganiser.limit)
      //         ),
      //       });
      //     }
      //   });
      //   break;
      case 1:
        listPayment({
          skip: 0,
          limit: tableDataWorkshop.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataWorkshop({
              data: [...docs.data.data.data],
              skip: 0,
              limit: tableDataWorkshop.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataWorkshop.limit)
              ),
            });
          }
        });
        break;
      case 2:
        listPayment({
          skip: 0,
          limit: tableDataOnlineEvent.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataOnlineEvent({
              data: [...docs.data.data.data],
              skip: 0,
              limit: tableDataOnlineEvent.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataOnlineEvent.limit)
              ),
            });
          }
        });
        break;
      case 3:
        listPayment({
          skip: 0,
          limit: tableDataInstitution.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataInstitution({
              data: [...docs.data.data.data],
              skip: 0,
              limit: tableDataInstitution.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataInstitution.limit)
              ),
            });
          }
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    reloadList({
      year: new Date(selectedDate).getFullYear(),
      month: new Date(selectedDate).getMonth() + 1,
      searchType: getSearchType()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadList = ({ year, month, searchType }) => {
    listPayment({
      skip: tableData.skip,
      limit: tableData.limit,
      queryPayload: {
        month: month,
        year: year,
        searchType: searchType
      },
    }).then((docs) => {
      if (docs.data) {
        if (searchType === paymentConfig.searchType.vendor) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
        } 
        // else if (searchType === paymentConfig.searchType.organiser) {
        //   setTableDataOrganiser({
        //     data: [...docs.data.data.data],
        //     skip: tableDataOrganiser.skip,
        //     limit: tableDataOrganiser.limit,
        //     total: docs.data.data.total,
        //     totalPage: Math.ceil(
        //       Number(docs.data.data.total / tableDataOrganiser.limit)
        //     ),
        //   });
        // } 
        else if (searchType === paymentConfig.searchType.onlineEvent) {
          setTableDataOnlineEvent({
            data: [...docs.data.data.data],
            skip: tableDataOnlineEvent.skip,
            limit: tableDataOnlineEvent.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableDataOnlineEvent.limit)
            ),
          });
        }
        else if (searchType === paymentConfig.searchType.institution) {
          setTableDataInstitution({
            data: [...docs.data.data.data],
            skip: tableDataInstitution.skip,
            limit: tableDataInstitution.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableDataInstitution.limit)
            ),
          });
        } else if (searchType === paymentConfig.searchType.workshop) {
          setTableDataWorkshop({
            data: [...docs.data.data.data],
            skip: tableDataWorkshop.skip,
            limit: tableDataWorkshop.limit,
            total: docs.data.data.total,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableDataWorkshop.limit)
            ),
          });
        }
      }
    });
  };

  const handleChange = (_event, value) => {
    setPage(value);

    switch (currentTab) {
      case 0:
        listPayment({
          skip: Number((value - 1) * tableData.limit),
          limit: tableData.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableData({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableData.limit),
              limit: tableData.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableData.limit)
              ),
            });
          }
        });
        break;
      // case 1:
      //   listPayment({
      //     skip: Number((value - 1) * tableDataOrganiser.limit),
      //     limit: tableDataOrganiser.limit,
      //     queryPayload: {
      //       year: new Date(selectedDate).getFullYear(),
      //       month: new Date(selectedDate).getMonth() + 1,
      //       searchType: getSearchType()
      //     },

      //   }).then((docs) => {
      //     if (docs.data) {
      //       setTableDataOrganiser({
      //         data: [...docs.data.data.data],
      //         skip: Number((value - 1) * tableDataOrganiser.limit),
      //         limit: tableDataOrganiser.limit,
      //         total: docs.data.data.total,
      //         totalPage: Math.ceil(
      //           Number(docs.data.data.total / tableDataOrganiser.limit)
      //         ),
      //       });
      //     }
      //   });
      //   break;
      case 1:
        listPayment({
          skip: Number((value - 1) * tableDataWorkshop.limit),
          limit: tableDataWorkshop.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataWorkshop({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableDataWorkshop.limit),
              limit: tableDataWorkshop.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataWorkshop.limit)
              ),
            });
          }
        });
        break;
      case 2:
        listPayment({
          skip: Number((value - 1) * tableDataOnlineEvent.limit),
          limit: tableDataOnlineEvent.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataOnlineEvent({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableDataOnlineEvent.limit),
              limit: tableDataOnlineEvent.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataOnlineEvent.limit)
              ),
            });
          }
        });
        break;
      case 3:
        listPayment({
          skip: Number((value - 1) * tableDataInstitution.limit),
          limit: tableDataInstitution.limit,
          queryPayload: {
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
            searchType: getSearchType()
          },

        }).then((docs) => {
          if (docs.data) {
            setTableDataInstitution({
              data: [...docs.data.data.data],
              skip: Number((value - 1) * tableDataInstitution.limit),
              limit: tableDataInstitution.limit,
              total: docs.data.data.total,
              totalPage: Math.ceil(
                Number(docs.data.data.total / tableDataInstitution.limit)
              ),
            });
          }
        });
        break;
      default:
        break;
    }
  };

  const renderPagination = () => {
    switch (currentTab) {
      case 0:
        if (tableData.data.length) {
          return (<CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          console.log('break');
          break;
        }
      // case 1:
      //   if (tableDataOrganiser.data.length) {
      //     return (<CustomPagination
      //       count={tableDataOrganiser.totalPage}
      //       color="primary"
      //       page={page}
      //       onChange={handleChange}
      //       variant="outlined"
      //       shape="rounded" 
      //     />);
      //   } else {
      //     break;
      //   }
      case 1:
        if (tableDataWorkshop.data.length) {
          return (<CustomPagination
            count={tableDataWorkshop.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          break;
        }
      case 2:
        if (tableDataOnlineEvent.data.length) {
          return (<CustomPagination
            count={tableDataOnlineEvent.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          break;
        }
      case 3:
        if (tableDataInstitution.data.length) {
          return (<CustomPagination
            count={tableDataInstitution.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />);
        } else {
          break;
        }

      default:
        return <NoDataFound/>;
    }
    return <NoDataFound/>;
  };

  const renderPaymentList = () => {
    switch (currentTab) {
      case 0:
        return <VendorPaymentList tableData={tableData} selectedDate={selectedDate} />;
      // case 1:
      //   return <OrganizationPaymentList tableDataOrganiser={tableDataOrganiser} selectedDate={selectedDate} />;
      case 1:
        return <WorkshopPaymentList tableDataWorkshop={tableDataWorkshop} selectedDate={selectedDate} />;
      case 2:
        return <OnlineEventPaymentList tableDataOnlineEvent={tableDataOnlineEvent} selectedDate={selectedDate} />;
      case 3:
        return <InstitutionPaymentList tableData={tableDataInstitution} selectedDate={selectedDate} />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-end">
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Month and Year"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changePaymentTab(tab);
          setCurrentTab(tab);
        }}
        title={"Payments"}
        tabsTitle={[
          "Professional", 
          // "Organization",
          "Workshop",
          "Events", 
          "Institution"]}
      />

      <React.Fragment>
        {
          renderPaymentList()
        }
      </React.Fragment>

      {
        renderPagination()
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePaymentTab: (tab) => dispatch(paymentDuck.actions.changePaymentTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
