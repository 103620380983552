import React, { useEffect, useState, useCallback } from "react";
import { Drawer, Card, CardContent } from "@material-ui/core";
import "./create-vendor-page.css";
import { toast } from "react-toastify";

import { Form } from "react-bootstrap";

import {
  uploadImage,
  getProfile,
  updateBank,
  updateProfile,
  getLocationList,
  addLocation,
  updateLocation,
  removeLocation,
  getCategories,
  // updateProfilePhoto,
  addPhoto,
  addPhysicalContracts,
  removeImage,
  removePhysicalContracts,
  getServiceList,
  updateService,
  removeService,
} from "../../../crud/vendor-profile.crud";
import * as vendorDuck from "../../../store/ducks/vendor.duck";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import RemoveModal from "./modal/remove-modal";
import ProfileVisibility from "./component/ProfileVisibility";
import ProfileVisibilityForAppointment from "./component/ProfileVisibilityForAppointment";
import AddNote from "./component/AddNote";
import HasCheque from "./component/HasCheque";
import {
  REQUIRED_ERROR,
  INVALID_EMAIL,
  CHOOSE_CATEGORY_ERROR,
  API_ERROR,
  INVALID_WEBSITE,
  INVALID_MONTH,
  VALID_PHONE_ERROR,
} from "../../../config/error-message";
import { API_SUCCESS } from "../../../config/success-message";
import { timeArrConst, daysConstant } from "../../../config/constants";
import { makeStyles } from "@material-ui/core/styles";
import ChangeProfileType from "./component/ChangeProfileType";
import AddBusinessId from "./component/AddBusinessId";
import PromoCodeInfo from "./component/PromoCodeInfo";
import ConvenienceFeesInfo from "./component/ConvenienceFeesInfo";
import VendorConfig from "./vendor-config";
import AddressManager from "./component/AddressManager";
import ServiceManager from "./component/ServiceManager";
import VendorStatesDropdown from "./component/VendorStatesDropdown";
// import ImageViewModal from "./modal/image-view-modal";
const useStyles = makeStyles({
  root: {
    width: "1013px",
  },
});

const days = [...daysConstant];

const timeArr = [...timeArrConst];

const serviceTimeHours = [...VendorConfig.serviceTimeHoursConstant];

const EditVendorPage = (props) => {
  const classes = useStyles();
  const [selectedProfile, setSelectedProfile] = useState({
    name: props.vendor.personalInfo.name,
    profilePicture: props.vendor.personalInfo.profilePicture,
    email: props.vendor.personalInfo.email,
    phone: props.vendor.personalInfo.phone.number,
    description: props.vendor.personalInfo.description,
    websiteUrl: props.vendor.personalInfo.website,
    experience: {
      year: props.vendor.personalInfo.experience.year,
      month: props.vendor.personalInfo.experience.month,
    },
    vendorId: props.vendor._id,
    bankDetails: { ...props.vendor.bankDetails },
    isOnline: props.vendor.personalInfo.isOnline,
    noteByAdmin: props.vendor.noteByAdmin,
    isVisible: props.vendor.profileVisible,
  });

  const [selectedMenu, selectMenu] = useState("Gallery");
  const [menuOptions, updateMenuOptions] = useState([
    ...VendorConfig.initialMenuOptions,
  ]);
  const [addresses, updateAddresses] = useState([]);
  const [mode, updateMode] = useState("list");
  const [selectedFromDate, handleFromDateChange] = useState(
    new Date(
      new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)
    )
  );
  const [selectedToDate, handleToDateChange] = useState(
    new Date(
      new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)
    )
  );
  const [daysArray, updateDaysArray] = useState([...days]);
  const [uploadedGalleryImage, updateGalleryImage] = useState(null);
  const [uploadedPhysicalContracts, setUploadedPhysicalContracts] = useState(
    null
  );
  const [displayRemoveModal, openRemoveModal] = useState(false);
  const [removeType, updateRemoveType] = useState("");
  const [bankDetailsError, updateBankDetailsError] = useState({
    ...VendorConfig.initialBankDetailsError,
  });
  const [locationDetails, modifyLocationDetails] = useState({
    ...VendorConfig.initialLocation,
  });
  const [locationDetailsError, updateLocationDetailsError] = useState({
    ...VendorConfig.initialLocationError,
  });
  const [serviceList, updateServiceList] = useState([]);
  const [selectedLocationIdToRemove, updateLocationId] = useState("");
  // const [selectedServiceType, changeServiceType] = useState("individual");
  const [serviceDetails, updateServiceDetails] = useState({
    ...VendorConfig.initialServiceDetails,
  });
  const [serviceDetailsError, updateServiceDetailsError] = useState({
    ...VendorConfig.initialServiceError,
  });
  const [selectedServiceTime, updateSelectedServiceTime] = useState(
    serviceTimeHours[0].value
  );
  const [selectedServiceMode, changeServiceMode] = useState(1);
  const [selectedServiceIdToRemove, setSelectedServiceIdToRemove] = useState(
    ""
  );
  const [individualLocations, updateIndividualLocations] = useState([]);
  const [
    selectedIndividualLocation,
    updateSelectedIndividualLocation,
  ] = useState("");
  const [
    selectedIndividualLocationObj,
    updateSelectedIndividualLocationObj,
  ] = useState({});
  const [
    selectedLocationForOrganization,
    updateSelectedLocationForOrganization,
  ] = useState("");
  const [
    selectedLocationForOrganizationObj,
    updateSelectedLocationForOrganizationObj,
  ] = useState({});
  const [locationsForOrganization, updateLocationsForOrganization] = useState(
    []
  );
  const [categoryList, updateCategoryList] = useState([]);
  const [profileError, updateProfileError] = useState({
    ...VendorConfig.initialProfileError,
  });
  const [uploadedProfileImage, updateProfileImage] = useState("");
  const [selectedImageIdToRemove, updateImageId] = useState("");
  const [categoryDropdownOpened, toggleCategory] = useState(false);
  const [disablePage, toggleDisablePage] = useState(false);
  const [selectedCoordinates, updateCoordinate] = useState([]);
  const [vendorProfileData, setVendorProfileData] = useState(true);
  const [profileVisibility, setProfileVisibility] = useState({
    isVisible: props.vendor.profileVisible
      ? props.vendor.profileVisible
      : false,
  });
  const [
    profileVisibilityForAppointment,
    setProfileVisibilityForAppointment,
  ] = useState({
    isOnline: props.vendor.personalInfo.isOnline
      ? props.vendor.personalInfo.isOnline
      : false,
  });
  const [profileType, setProfileType] = useState(
    props.vendor.subType ? props.vendor.subType : 0
  );
  const [state, setState] = useState(props.vendor.personalInfo.stateName && props.vendor.personalInfo.stateCode ? {
    stateName: props.vendor.personalInfo.stateName,
    stateCode: props.vendor.personalInfo.stateCode,
  }: {});
  const [selectedProfileType, setSelectedProfileType] = useState();
  const [showChangeProfileTypeModal, setShowChangeProfileTypeModal] = useState(
    false
  );
  const [businessIdByAdmin, setBusinessIdByAdmin] = useState(
    props.vendor.businessId ? props.vendor.businessId : ""
  );
  const [noteByAdmin, setNoteByAdmin] = useState(
    props.vendor.noteByAdmin ? props.vendor.noteByAdmin : ""
  );
  const [hasCheque, setHasCheque] = useState(
    props.vendor.bankDetails ? props.vendor.bankDetails.isCheque : false
  );
  const [promoCodeInfo, setPromoCodeInfo] = useState(
    props.vendor.promoCodeInfo
  );
  const [convenienceFeesInfo, setConvenienceFeesInfo] = useState(
    props.vendor.customVendorCut
  );

  const handleChangeAllowConvenienceFees = (event) => {
    setConvenienceFeesInfo({
      ...convenienceFeesInfo,
      allowed: event.target.checked,
    });
  };

  const handleChangeConvenienceFeesDiscount = (event) => {
    setConvenienceFeesInfo({
      ...convenienceFeesInfo,
      value: event.target.value,
    });
  };

  const saveVendorStateHandler = async (stateLocal) => {
    const profileData = await updateProfile(selectedProfile.vendorId, stateLocal);
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    setState(stateLocal);
    await getAllLocations(selectedProfile.vendorId);
    await getAllServices(selectedProfile.vendorId);
  };

  const saveConvenienceFeesInfo = async () => {
    const profileData = await updateProfile(selectedProfile.vendorId, {
      customVendorCut: convenienceFeesInfo,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: localSelectedProfile.isVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });
      setPromoCodeInfo({ ...profileData.data.data.promoCodeInfo });
      setConvenienceFeesInfo({ ...profileData.data.data.customVendorCut });
      setVendorProfileData(true);
    }
  };

  const handleChangeAllowPromoDiscount = (event) => {
    setPromoCodeInfo({ ...promoCodeInfo, allowDiscount: event.target.checked });
  };

  const handleChangePromoDiscount = (event) => {
    setPromoCodeInfo({
      ...promoCodeInfo,
      discountPercentage: event.target.value,
    });
  };

  const savePromoCodeInfo = async () => {
    const profileData = await updateProfile(selectedProfile.vendorId, {
      promoCodeInfo: promoCodeInfo,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: localSelectedProfile.isVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });

      setPromoCodeInfo({ ...profileData.data.data.promoCodeInfo });
      setConvenienceFeesInfo({ ...profileData.data.data.customVendorCut });
      setVendorProfileData(true);
    }
  };

  const handleChangeProfileVisibility = async (isVisible) => {
    setProfileVisibility({ ...profileVisibility, isVisible: isVisible });
    const profileData = await updateProfile(selectedProfile.vendorId, {
      isVisible: isVisible,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }

      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: profileData.data.data.profileVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });
      setVendorProfileData(true);
    }
  };

  const handleChangeProfileVisibilityForAppointment = async (isOnline) => {
    setProfileVisibilityForAppointment({
      ...profileVisibilityForAppointment,
      isOnline: isOnline,
    });
    const profileData = await updateProfile(selectedProfile.vendorId, {
      isOnline: isOnline,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: profileData.data.data.profileVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });
      setVendorProfileData(true);
    }
  };

  const handleChangeOnNoteByAdmin = (event) => {
    setNoteByAdmin(event.target.value);
  };

  const saveNote = async () => {
    const profileData = await updateProfile(selectedProfile.vendorId, {
      noteByAdmin: noteByAdmin,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: profileData.data.data.profileVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });
      setVendorProfileData(true);
    }
  };

  const handleChangeOnBusinessIdByAdmin = (event) => {
    setBusinessIdByAdmin(event.target.value);
  };

  const saveBusinessId = async () => {
    const profileData = await updateProfile(selectedProfile.vendorId, {
      businessId: businessIdByAdmin,
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    if (profileData.data.data) {
      props.vendorUpdated({
        skip: props.listOption.skip,
        limit: props.listOption.limit,
      });
      const localSelectedProfile = {
        vendorId: profileData.data.data._id,
      };
      if (profileData.data.data.bankingDetails) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };
      }
      if (profileData.data.data.personalInfo) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;
        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        localSelectedProfile.isOnline =
          profileData.data.data.personalInfo.isOnline;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (profileData.data.data.photos) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      setProfileVisibility({ isVisible: profileData.data.data.profileVisible });
      setProfileVisibilityForAppointment({
        isOnline: localSelectedProfile.isOnline,
      });
      setVendorProfileData(true);
    }
  };

  const resetState = () => {};

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateWebsite = (textval) => {
    /* eslint-disable no-useless-escape */
    let urlregex = new RegExp(
      "^(http|https|ftp)://([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&amp;%$-]+)*@)*((25[0-5]|" +
        "2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]).(25[0-5]|2[0-4][0-9]|[0-1]" +
        "{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-" +
        "9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[" +
        "0-9])|localhost|([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.(com|edu|gov|int|mil|net|org|" +
        "biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(/($|[a-zA-Z0-9" +
        ".,?'\\+&amp;%$#=~_-]+))*$"
    );
    return urlregex.test(textval);
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const updateMenu = (item) => {
    const localMenuOptions = [...menuOptions];
    localMenuOptions.forEach((each) => {
      each.selected = false;
      if (each.option === item.option) {
        each.selected = true;
      }
    });
    selectMenu(item.option);
    if (item.option === "Physical Contracts") {
      getProfileDetails("physicalContracts");
    }
    updateMenuOptions([...localMenuOptions]);
  };

  const getTime = (timeStr) => {
    let time = timeStr.slice(0, timeStr.length - 3);
    let amPm = timeStr.slice(timeStr.length - 2);
    let d = new Date();
    if (amPm === "AM" && time.split(":")[0] === "12") {
      return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]));
    }
    if (amPm === "PM" && time.split(":")[0] !== "12") {
      return new Date(
        new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(
          time.split(":")[1]
        )
      );
    }
    return new Date(
      new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])
    );
  };

  const getDuration = (times) => {
    times.sort((a, b) => {
      return a - b;
    });
    const startTime = timeArr[times[0] - 1];
    const endTime = timeArr[times[times.length - 1]]
      ? timeArr[times[times.length - 1]]
      : timeArr[0];
    return `${startTime} - ${endTime}`;
  };

  const getStartTime = (times) => {
    times.sort((a, b) => {
      return a - b;
    });
    return getTime(timeArr[times[0] - 1]);
  };

  const getEndTime = (times) => {
    times.sort((a, b) => {
      return a - b;
    });
    return getTime(
      timeArr[times[times.length - 1]]
        ? timeArr[times[times.length - 1]]
        : timeArr[0]
    );
  };

  const getAllLocations = async (vendorId) => {
    toggleDisablePage(true);
    const locationsResult = await getLocationList(vendorId);
    toggleDisablePage(false);
    if (locationsResult.data.errorCode) {
      toast.error(API_ERROR[locationsResult.data.errorCode]);
      return;
    }

    if (locationsResult.data.data && locationsResult.data.data.length) {
      const localAddresses = [];
      let localSingleAddress = {};
      locationsResult.data.data.forEach((each) => {
        localSingleAddress.title = each.title;
        localSingleAddress.address = each.address;
        localSingleAddress.addressLine1 = each.addressLine1;
        localSingleAddress.addressLine2 = each.addressLine2;
        localSingleAddress.addressLine3 = each.addressLine3;
        localSingleAddress._id = each._id;
        localSingleAddress.selected = false;
        localSingleAddress.days = "";
        localSingleAddress.duration = getDuration(each.workingTimeSlots);
        localSingleAddress.daysOfWeek = each.daysOfWeek;
        localSingleAddress.workingTimeSlots = each.workingTimeSlots;
        localSingleAddress.location = each.location;

        if (each.daysOfWeek && each.daysOfWeek.length) {
          each.daysOfWeek.forEach((item, index) => {
            localSingleAddress.days += VendorConfig.dayName[item - 1];
            if (index !== each.daysOfWeek.length - 1) {
              localSingleAddress.days += ", ";
            }
          });
        }
        localAddresses.push({
          ...localSingleAddress,
        });
        localSingleAddress = {};
      });
      updateAddresses([...localAddresses]);
      updateIndividualLocations([...locationsResult.data.data]);
      updateSelectedIndividualLocation(locationsResult.data.data[0]._id);
      updateSelectedIndividualLocationObj({ ...locationsResult.data.data[0] });
    } else if (
      locationsResult.data.data &&
      locationsResult.data.data.length === 0
    ) {
      const localAddresses = [];
      updateAddresses([...localAddresses]);
    }
  };

  const getAllServices = async (vendorId) => {
    toggleDisablePage(true);
    const servicesResult = await getServiceList(vendorId);
    toggleDisablePage(false);
    if (servicesResult.data.errorCode) {
      toast.error(API_ERROR[servicesResult.data.errorCode]);
      return;
    }

    if (servicesResult.data.data && servicesResult.data.data.length) {
      const localServices = [];
      let localSingleService = {};
      servicesResult.data.data.forEach((each) => {
        localSingleService = { ...each };
        localSingleService.sessionTimeText =
          VendorConfig.serviceTimeHoursConstant[each.sessionTime - 1].text;
        switch (each.serviceType) {
          case 1:
            localSingleService.serviceTypeText = "Online only";
            break;
          case 2:
            localSingleService.serviceTypeText = "Offline only";
            break;
          case 3:
            localSingleService.serviceTypeText = "Both Online and Offline";
            break;
          default:
            break;
        }
        localSingleService.selected = false;
        localServices.push({
          ...localSingleService,
        });
        localSingleService = {};
      });
      updateServiceList([...localServices]);
    } else if (
      servicesResult.data.data &&
      servicesResult.data.data.length === 0
    ) {
      const localServices = [];
      updateServiceList([...localServices]);
    }
  };

  const getAllCategories = async (categoryIds) => {
    toggleDisablePage(true);
    const categoriesResult = await getCategories();
    toggleDisablePage(false);
    if (categoriesResult.data.errorCode) {
      toast.error(API_ERROR[categoriesResult.data.errorCode]);
      return;
    }
    if (categoriesResult.data.data && categoriesResult.data.data.length) {
      categoriesResult.data.data.forEach((each) => {
        if (categoryIds.includes(each._id)) {
          each.selected = true;
        } else {
          each.selected = false;
        }
      });
      updateCategoryList(categoriesResult.data.data);
    }
  };

  const getProfileDetails = async (resetType) => {
    toggleDisablePage(true);
    const profileData = await getProfile(selectedProfile.vendorId);
    toggleDisablePage(false);
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      return;
    }
    if (profileData.data.data) {
      const localSelectedProfile = {
        ...selectedProfile,
      };
      if (
        (!resetType || resetType === "bank") &&
        profileData.data.data.bankingDetails
      ) {
        localSelectedProfile.bankDetails = {
          ...profileData.data.data.bankingDetails,
        };

        if (profileData.data.data.bankingDetails.isCheque) {
          setHasCheque(true);
        } else {
          setHasCheque(false);
        }
      }
      if (
        (!resetType || resetType === "personalInfo") &&
        profileData.data.data.personalInfo
      ) {
        localSelectedProfile.profilePicture =
          profileData.data.data.personalInfo.profilePicture;
        localSelectedProfile.name = profileData.data.data.personalInfo.name;
        localSelectedProfile.description =
          profileData.data.data.personalInfo.description;
        localSelectedProfile.email = profileData.data.data.personalInfo.email;
        localSelectedProfile.phone =
          profileData.data.data.personalInfo.phone.number;

        localSelectedProfile.websiteUrl =
          profileData.data.data.personalInfo.website;
        if (
          profileData.data.data.personalInfo.experience &&
          profileData.data.data.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            profileData.data.data.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          profileData.data.data.personalInfo.categories &&
          profileData.data.data.personalInfo.categories.length
        ) {
          profileData.data.data.personalInfo.categories.forEach((each) => {
            localSelectedProfile.categories.push(each._id);
          });
        }
      }
      if (
        (!resetType || resetType === "photos") &&
        profileData.data.data.personalInfo
      ) {
        localSelectedProfile.descriptionImages = profileData.data.data.photos;
      }

      if (
        (!resetType || resetType === "physicalContracts") &&
        profileData.data.data.personalInfo
      ) {
        localSelectedProfile.physicalContracts =
          profileData.data.data.physicalContracts;
      }
      setSelectedProfile({
        ...localSelectedProfile,
      });
      if (!resetType) {
        getAllCategories(localSelectedProfile.categories);
      }
    }
  };

  const getInitialProfileDetails = useCallback(async () => {
    toggleDisablePage(true);
    if (props.vendor) {
      const localSelectedProfile = {};
      localSelectedProfile.vendorId = props.vendor._id;
      if (props.vendor.bankingDetails) {
        localSelectedProfile.bankDetails = { ...props.vendor.bankingDetails };
        if (props.vendor.bankingDetails.isCheque) {
          setHasCheque(true);
        } else {
          setHasCheque(false);
        }
      }

      if (props.vendor.personalInfo) {
        localSelectedProfile.profilePicture =
          props.vendor.personalInfo.profilePicture;
        localSelectedProfile.name = props.vendor.personalInfo.name;
        localSelectedProfile.description =
          props.vendor.personalInfo.description;
        localSelectedProfile.email = props.vendor.personalInfo.email;
        localSelectedProfile.phone = props.vendor.personalInfo.phone.number;
        localSelectedProfile.websiteUrl = props.vendor.personalInfo.website;
        if (
          props.vendor.personalInfo.experience &&
          props.vendor.personalInfo.experience.year
        ) {
          localSelectedProfile.experience =
            props.vendor.personalInfo.experience;
        } else {
          localSelectedProfile.experience = {
            year: "",
            month: "",
          };
        }
        localSelectedProfile.categories = [];
        if (
          props.vendor.personalInfo.categories &&
          props.vendor.personalInfo.categories.length
        ) {
          // props.vendor.personalInfo.categories.forEach(each => {
          //     localSelectedProfile.categories.push(each._id);
          // });
          localSelectedProfile.categories =
            props.vendor.personalInfo.categories;
        }
      }
      if (props.vendor.personalInfo) {
        localSelectedProfile.descriptionImages = props.vendor.photos;
      }
      setSelectedProfile({ ...localSelectedProfile });
      getAllCategories(localSelectedProfile.categories.map((each) => each._id));
      setProfileType(props.vendor.subType ? props.vendor.subType : 0);
    }
    toggleDisablePage(false);
  }, [props.vendor]);

  const openFileUploader = (imageId) => {
    if (document.getElementById(imageId)) {
      document.getElementById(imageId).click();
    }
  };

  const uploadGalleryImage = () => {
    if (document.getElementById("galleryImage")) {
      let imageFile = document.getElementById("galleryImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateGalleryImage(imageFile.files[0]);
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const uploadPhysicalContracts = () => {
    if (document.getElementById("physicalContracts")) {
      let imageFile = document.getElementById("physicalContracts");
      if (imageFile.files[0]) {
        if (
          imageFile.files[0].type.indexOf("image") > -1 ||
          imageFile.files[0].type.indexOf("pdf") > -1 ||
          imageFile.files[0].type.indexOf("document") > -1
        ) {
          setUploadedPhysicalContracts(imageFile.files[0]);
        } else {
          toast.error("Please upload physicalContractsfile");
          return;
        }
      }
    }
  };

  const uploadProfileImage = async () => {
    if (document.getElementById("profileImage")) {
      let imageFile = document.getElementById("profileImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateProfileImage(URL.createObjectURL(imageFile.files[0]));
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const uploadProfilePicture = async () => {
    if (uploadedProfileImage) {
      let imageFile = document.getElementById("profileImage").files[0];
      if (imageFile) {
        const result = await uploadImage(imageFile);

        if (result.data.errorCode) {
          toast.error(API_ERROR[result.data.errorCode]);
          return;
        }
        return result;
      } else {
        return;
      }
    }
  };

  const openRemove = (item, type) => {
    if (type === "Location" && addresses.length > 1) {
      updateLocationId(item._id);
      updateRemoveType(type);
      openRemoveModal(true);
    } else if (type === "Location" && addresses.length === 1) {
      toast.error("Minimum 1 location is mandatory");
    }

    if (type === "Image") {
      updateImageId(item._id);
      updateRemoveType(type);
      openRemoveModal(true);
    }

    if (type === "Service" && serviceList.length > 1) {
      setSelectedServiceIdToRemove(item._id);
      updateRemoveType(type);
      openRemoveModal(true);
    } else if (type === "Service" && serviceList.length === 1) {
      toast.error("Minimum 1 service is mandatory");
    }
  };

  const removeUploadedImage = () => {
    updateGalleryImage(null);
    if (document.getElementById("galleryImage")) {
      document.getElementById("galleryImage").value = "";
    }
  };

  const removeUploadedPhysicalContracts = () => {
    setUploadedPhysicalContracts(null);
    if (document.getElementById("physicalContracts")) {
      document.getElementById("physicalContracts").value = "";
    }
  };

  const updateBankDetails = (text, fieldName) => {
    const localProfile = {
      ...selectedProfile,
    };
    const localBank = {
      ...localProfile.bankDetails,
    };
    const localBankError = {
      ...bankDetailsError,
    };
    localBank[fieldName] = text;
    localProfile.bankDetails = {
      ...localBank,
    };
    localBankError[fieldName] = "";
    setSelectedProfile({
      ...localProfile,
    });
    updateBankDetailsError({
      ...localBankError,
    });
  };

  const validateBankDetails = () => {
    const localProfile = {
      ...selectedProfile,
    };
    const localBank = {
      ...localProfile.bankDetails,
    };
    const localBankError = {
      ...bankDetailsError,
    };
    let noErrorFlag = true;

    for (let item in localBank) {
      if (
        typeof localBank[item] !== "boolean" &&
        !["chequeName", "chequeAddress", "gstNumber"].includes(item) &&
        !localBank[item].trim().length
      ) {
        localBankError[item] = REQUIRED_ERROR;
        noErrorFlag = false;
      } else {
        localBankError[item] = "";
      }
    }

    updateBankDetailsError({
      ...localBankError,
    });
    return noErrorFlag;
  };

  const validateBankDetailsHasCheque = () => {
    const localProfile = {
      ...selectedProfile,
    };
    const localBank = {
      ...localProfile.bankDetails,
    };
    const localBankError = {
      ...bankDetailsError,
    };
    let noErrorFlag = true;

    for (let item in localBank) {
      if (
        hasCheque &&
        typeof localBank[item] !== "boolean" &&
        ["chequeName", "chequeAddress"].includes(item) &&
        !localBank[item].trim().length
      ) {
        localBankError[item] = REQUIRED_ERROR;
        noErrorFlag = false;
      } else {
        localBankError[item] = "";
      }
    }

    updateBankDetailsError({
      ...localBankError,
    });
    return noErrorFlag;
  };

  const saveBankDetails = async () => {
    let validated = false;
    if (hasCheque && validateBankDetailsHasCheque()) {
      validated = true;
    } else if (validateBankDetails()) {
      validated = true;
    }
    if (validated) {
      const bankDetailsPayload = {
        ...selectedProfile.bankDetails,
      };
      if (hasCheque) {
        bankDetailsPayload.isCheque = true;
      } else {
        bankDetailsPayload.isCheque = false;
        bankDetailsPayload.chequeName = "";
        bankDetailsPayload.chequeAddress = "";
      }
      toggleDisablePage(true);
      const result = await updateBank(
        selectedProfile.vendorId,
        bankDetailsPayload
      );
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.UPDATE_BANK);
      getProfileDetails("bank");
    }
  };

  const changePersonalInfo = (text, fieldName) => {
    const localProfile = {
      ...selectedProfile,
    };
    const localProfileError = {
      ...profileError,
    };
    if (fieldName !== "year" && fieldName !== "month") {
      localProfile[fieldName] = text;
      localProfileError[fieldName] = "";
    } else {
      localProfile.experience = {
        ...localProfile.experience,
      };
      localProfile.experience[fieldName] = text;
      localProfileError.experience = "";
    }
    setSelectedProfile({
      ...localProfile,
    });
    updateProfileError({
      ...localProfileError,
    });
  };

  const validateProfile = () => {
    const localProfileError = {
      ...profileError,
    };
    let noErrorFlag = true;
    for (let item in localProfileError) {
      if (item === "name" || item === "email" || item === "phone") {
        if (item === "email" && selectedProfile[item].trim().length) {
          if (!validateEmail(selectedProfile[item])) {
            localProfileError[item] = INVALID_EMAIL;
            noErrorFlag = false;
          } else {
            localProfileError[item] = "";
          }
        }

        if (item === "phone" && selectedProfile[item].trim().length < 10) {
          localProfileError[item] = VALID_PHONE_ERROR;
          noErrorFlag = false;
        } else if (
          item === "phone" &&
          selectedProfile[item].trim().length === 10
        ) {
          localProfileError[item] = "";
        }
      }

      if (item === "websiteUrl" && selectedProfile[item].trim().length) {
        if (!validateWebsite(selectedProfile[item])) {
          localProfileError[item] = INVALID_WEBSITE;
          noErrorFlag = false;
        } else {
          localProfileError[item] = "";
        }
      }

      if (item === "experience") {
        if (
          selectedProfile.experience.year === "" ||
          selectedProfile.experience.month === ""
        ) {
          localProfileError.experience = REQUIRED_ERROR;
          noErrorFlag = false;
        } else {
          localProfileError.experience = "";
        }

        if (selectedProfile.experience.month !== "") {
          if (Number(selectedProfile.experience.month) > 11) {
            localProfileError.experience = INVALID_MONTH;
            noErrorFlag = false;
          } else {
            localProfileError.experience = "";
          }
        }
      }
      if (item === "category") {
        let categoryError = true;
        if (categoryList.length) {
          categoryList.forEach((each) => {
            if (each.selected) {
              categoryError = false;
            }
          });
        }

        if (categoryError) {
          localProfileError.category = CHOOSE_CATEGORY_ERROR;
          noErrorFlag = false;
        } else {
          localProfileError.category = "";
        }
      }
    }
    updateProfileError({
      ...localProfileError,
    });
    return noErrorFlag;
  };

  const savePersonalInfo = async () => {
    if (validateProfile()) {
      toggleDisablePage(true);
      const imgData = await uploadProfilePicture();
      const localSelectedCategories = [];
      if (categoryList.length) {
        categoryList.forEach((each) => {
          if (each.selected) {
            localSelectedCategories.push(each._id);
          }
        });
      }
      const requestBody = {
        name: selectedProfile.name,
        email: selectedProfile.email,
        phone: {
          countryCode: "IN",
          dialCode: "+91",
          number: selectedProfile.phone,
        },
        categories: localSelectedCategories,
        experience: {
          year: Number(selectedProfile.experience.year),
          month: Number(selectedProfile.experience.month),
        },
      };

      if (selectedProfile.description && selectedProfile.description.trim()) {
        requestBody.description = selectedProfile.description.trim();
      } else {
        requestBody.description = "_blank_";
      }

      if (selectedProfile.websiteUrl && selectedProfile.websiteUrl.trim()) {
        requestBody.website = selectedProfile.websiteUrl.trim();
      } else {
        requestBody.website = "_blank_";
      }

      if (imgData) {
        requestBody.profilePicture = imgData.data.data;
      }
      const profileData = await updateProfile(
        selectedProfile.vendorId,
        requestBody
      );
      if (profileData.data.errorCode) {
        toast.error(API_ERROR[profileData.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.UPDATE_PROFILE);
      if (profileData.data.data) {
        props.vendorUpdated({
          skip: props.listOption.skip,
          limit: props.listOption.limit,
        });
        const localSelectedProfile = {
          vendorId: profileData.data.data._id,
        };
        if (profileData.data.data.bankingDetails) {
          localSelectedProfile.bankDetails = {
            ...profileData.data.data.bankingDetails,
          };
        }
        if (profileData.data.data.personalInfo) {
          localSelectedProfile.profilePicture =
            profileData.data.data.personalInfo.profilePicture;
          localSelectedProfile.name = profileData.data.data.personalInfo.name;
          localSelectedProfile.description =
            profileData.data.data.personalInfo.description;
          localSelectedProfile.email = profileData.data.data.personalInfo.email;
          localSelectedProfile.phone =
            profileData.data.data.personalInfo.phone.number;
          localSelectedProfile.websiteUrl =
            profileData.data.data.personalInfo.website;
          if (
            profileData.data.data.personalInfo.experience &&
            profileData.data.data.personalInfo.experience.year
          ) {
            localSelectedProfile.experience =
              profileData.data.data.personalInfo.experience;
          } else {
            localSelectedProfile.experience = {
              year: "",
              month: "",
            };
          }
          localSelectedProfile.categories = [];
          if (
            profileData.data.data.personalInfo.categories &&
            profileData.data.data.personalInfo.categories.length
          ) {
            profileData.data.data.personalInfo.categories.forEach((each) => {
              localSelectedProfile.categories.push(each._id);
            });
          }
        }
        if (profileData.data.data.photos) {
          localSelectedProfile.descriptionImages = profileData.data.data.photos;
        }
        setSelectedProfile({ ...localSelectedProfile });
        setVendorProfileData(true);
      }
    }
  };

  const changeServiceDetails = (text, fieldName) => {
    const localService = { ...serviceDetails };
    const localServiceError = { ...serviceDetailsError };
    localService[fieldName] = text;
    localServiceError[fieldName] = "";
    updateServiceDetails({ ...localService });
    updateServiceDetailsError({ ...localServiceError });
  };

  const toggleDaySelection = (index) => {
    const localDays = [...daysArray];
    const localLocationError = {
      ...locationDetailsError,
    };
    localDays[index].selected = !localDays[index].selected;
    localLocationError.days = "";
    updateDaysArray([...localDays]);
    updateLocationDetailsError({
      ...localLocationError,
    });
  };

  const updateDateError = () => {
    const localLocationError = {
      ...locationDetailsError,
    };
    localLocationError.selectedFromDate = "";
    updateLocationDetailsError({
      ...localLocationError,
    });
  };

  const validateService = () => {
    const localServiceError = {
      ...serviceDetailsError,
    };
    let noError = true;
    if (!serviceDetails.title || !serviceDetails.title.trim().length) {
      localServiceError.title = REQUIRED_ERROR;
      noError = false;
    } else {
      localServiceError.title = "";
    }

    if (!individualLocations.length) {
      // need toastr error
      toast.error("No locations available to select. Please add a location.");
      noError = false;
    }
    if (
      selectedIndividualLocationObj &&
      selectedIndividualLocationObj.workingTimeSlots &&
      Math.ceil(selectedIndividualLocationObj.workingTimeSlots.length) <
        Number(selectedServiceTime)
    ) {
      toast.error("Service time cannot be more than service duration");
      noError = false;
    }

    updateServiceDetailsError({
      ...localServiceError,
    });
    return noError;
  };

  const openLocationList = async (vendorId) => {
    const localDays = [...daysArray];
    localDays.forEach((each) => {
      each.selected = false;
    });
    updateDaysArray([...localDays]);
    updateMode("list");
    updateLocationDetailsError({
      ...VendorConfig.initialLocationError,
    });
    await getAllLocations(vendorId);
    await getAllServices(vendorId);
    modifyLocationDetails({});
    handleFromDateChange(
      new Date(
        new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)
      )
    );
    handleToDateChange(
      new Date(
        new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)
      )
    );
  };

  const openServiceList = async (vendorId) => {
    updateMode("list");
    await getAllServices(vendorId);
  };

  const updateLocationDetails = (text, fieldName, isChosen) => {
    const localLocation = { ...locationDetails };
    const localLocationError = { ...locationDetailsError };

    localLocationError[fieldName] = "";
    if (fieldName === "address" && isChosen) {
      localLocation.address = text.formatted_address;
      localLocation.addressLine1 = text.formatted_address;
      localLocation[fieldName] = text.formatted_address;
      for (let i = 0; i < text.address_components.length; i++) {
        for (let j = 0; j < text.address_components[i].types.length; j++) {
          if (text.address_components[i].types[j] === "postal_code") {
            localLocation.addressLine2 = text.address_components[i].long_name;
          }
        }
      }
    } else {
      localLocation[fieldName] = text;
    }
    updateLocationDetailsError({ ...localLocationError });
    modifyLocationDetails({ ...localLocation });
  };

  const saveLocation = async (currentMode) => {
    let result;
    const fromIndex = timeArr.indexOf(formatAMPM(selectedFromDate)) + 1;
    const toIndex = timeArr.indexOf(formatAMPM(selectedToDate));
    const localWorkingTimeSlots = [];
    const localDays = [];
    daysArray.forEach((each) => {
      if (each.selected) {
        localDays.push(each.day);
      }
    });
    if (fromIndex === toIndex) {
      localWorkingTimeSlots.push(fromIndex);
    } else {
      for (let i = fromIndex; i <= toIndex; i++) {
        localWorkingTimeSlots.push(i);
      }
    }
    if (!localWorkingTimeSlots.length) {
      toast.error("Please choose correct start and end time");
      return;
    }
    const requestBody = {
      title: locationDetails.title,
      address: locationDetails.address,
      addressLine1: locationDetails.addressLine1,
      addressLine2: locationDetails.addressLine2,
      addressLine3: locationDetails.addressLine3,
      workingTimeSlots: localWorkingTimeSlots,
      location: {
        coordinates: selectedCoordinates,
      },
      daysOfWeek: localDays,
    };
    toggleDisablePage(true);
    // return;
    if (currentMode === "add") {
      result = await addLocation(selectedProfile.vendorId, requestBody);
    } else {
      requestBody._id = locationDetails._id;
      result = await updateLocation(selectedProfile.vendorId, requestBody);
    }
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(
      currentMode === "add"
        ? API_SUCCESS.ADD_LOCATION
        : API_SUCCESS.UPDATE_LOCATION
    );

    openLocationList(selectedProfile.vendorId);
  };

  const chooseLocation = (locationData) => {
    updateMode("edit");
    const localLocationDetails = { ...locationDetails };
    const localDays = [...daysArray];
    localLocationDetails.title = locationData.title;
    localLocationDetails.address = locationData.address;
    localLocationDetails.addressLine1 = locationData.addressLine1;
    localLocationDetails.addressLine2 = locationData.addressLine2;
    localLocationDetails.addressLine3 = locationData.addressLine3;
    localLocationDetails._id = locationData._id;
    handleFromDateChange(getStartTime(locationData.workingTimeSlots));
    handleToDateChange(getEndTime(locationData.workingTimeSlots));
    updateCoordinate(locationData.location.coordinates);

    if (locationData.daysOfWeek && locationData.daysOfWeek.length) {
      locationData.daysOfWeek.forEach((each, index) => {
        localDays.forEach((each1) => {
          if (each === each1.day) {
            each1.selected = true;
          }
        });
      });
    }
    updateDaysArray([...localDays]);
    modifyLocationDetails({ ...localLocationDetails });
  };

  const selectServiceTime = (e) => {
    updateSelectedServiceTime(e.target.value);
  };

  const chooseService = (serviceData) => {
    const localServiceDetails = { ...serviceDetails };
    const localDays = [...daysArray];
    localServiceDetails.title = serviceData.title;
    localServiceDetails.description = serviceData.description;
    localServiceDetails._id = serviceData._id;
    localServiceDetails.fees = serviceData.fees;
    if (serviceData.serviceType) {
      changeServiceMode(Number(serviceData.serviceType));
    }
    updateSelectedServiceTime(serviceData.sessionTime);
    if (serviceData.ownerShipType === 1) {
      // changeServiceType("individual");
      if (individualLocations.length) {
        updateSelectedIndividualLocation(serviceData.addressId);
        individualLocations.forEach((each) => {
          if (each._id === serviceData.addressId) {
            updateSelectedIndividualLocationObj({ ...each });
          }
        });
      }
    } else {
      // changeServiceType("organization");
      handleFromDateChange(getStartTime(serviceData.availableSlots));
      handleToDateChange(getEndTime(serviceData.availableSlots));

      if (serviceData.daysOfWeek && serviceData.daysOfWeek.length) {
        serviceData.daysOfWeek.forEach((each, index) => {
          localDays.forEach((each1) => {
            if (each === each1.day) {
              each1.selected = true;
            }
          });
        });
      }
      updateDaysArray([...localDays]);
    }
    updateMode("edit");
    updateServiceDetails({ ...localServiceDetails });
  };

  const selectLocationForOrganization = (e) => {
    const localDays = [...daysArray];
    localDays.forEach((each) => {
      each.selected = false;
    });
    updateDaysArray([...localDays]);
    locationsForOrganization.forEach((each) => {
      if (each._id === e.target.value) {
        updateSelectedLocationForOrganization(e.target.value);
        updateSelectedLocationForOrganizationObj({ ...each });
      }
    });
  };

  const saveService = async (currentMode) => {
    if (!validateService()) {
      return;
    }
    let requestBody = {};
    let result;
    if (Number(serviceDetails.fees) === 0) {
      toast.error("Fees cannot be zero");
      return;
    }

    requestBody = {
      title: serviceDetails.title,
      address: selectedIndividualLocationObj.address,
      addressLine1: selectedIndividualLocationObj.addressLine1,
      addressLine2: selectedIndividualLocationObj.addressLine2,
      addressLine3: selectedIndividualLocationObj.addressLine3,
      sessionTime: Number(selectedServiceTime),
      fees: Number(serviceDetails.fees),
      ownerShipType: 1,
      availableSlots: selectedIndividualLocationObj.workingTimeSlots,
      daysOfWeek: selectedIndividualLocationObj.daysOfWeek,
      addressId: selectedIndividualLocationObj._id,
      addressTitle: selectedIndividualLocationObj.title,
      location: selectedIndividualLocationObj.location,
      serviceType: selectedServiceMode,
    };

    if (serviceDetails.description && serviceDetails.description.trim()) {
      requestBody.description = serviceDetails.description.trim();
    } else {
      requestBody.description = " ";
    }

    toggleDisablePage(true);
    try {
      if (currentMode === "add") {
        // result = await addLocation(selectedProfile.vendorId, requestBody);
      } else {
        requestBody._id = serviceDetails._id;
        result = await updateService(selectedProfile.vendorId, requestBody);
      }

      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(
        currentMode === "add"
          ? API_SUCCESS.ADD_SERVICE
          : API_SUCCESS.UPDATE_SERVICE
      );

      openServiceList(selectedProfile.vendorId);
    } catch (e) {
      console.log("error", e);
    }
  };

  const removeData = async () => {
    if (removeType === "Location") {
      toggleDisablePage(true);
      const result = await removeLocation(
        selectedProfile.vendorId,
        selectedLocationIdToRemove
      );
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.REMOVE_LOCATION);
      updateLocationId("");
      openLocationList(selectedProfile.vendorId);
      openRemoveModal(false);
    }
    if (removeType === "Image") {
      toggleDisablePage(true);
      const result = await removeImage(
        selectedProfile.vendorId,
        selectedImageIdToRemove
      );
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.REMOVE_IMAGE);
      updateImageId("");
      getProfileDetails("photos");
      openRemoveModal(false);
    }
    if (removeType === "Physical Contracts") {
      toggleDisablePage(true);
      const result = await removePhysicalContracts(selectedProfile.vendorId);
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.REMOVE_PHYSICAL_CONTRACTS);
      getProfileDetails("physicalContracts");
      openRemoveModal(false);
    }
    if (removeType === "Service") {
      toggleDisablePage(true);
      const result = await removeService(
        selectedProfile.vendorId,
        selectedServiceIdToRemove
      );
      if (result && result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        toggleDisablePage(false);
        return;
      }
      toast.success(API_SUCCESS.REMOVE_SERVICE);
      setSelectedServiceIdToRemove("");
      openServiceList(selectedProfile.vendorId);
      openRemoveModal(false);
    }
  };

  const toggleCategorySelection = (categoryValue, index) => {
    const localCateories = [...categoryList];
    const localProfileError = {
      ...profileError,
    };
    localCateories[index].selected = !localCateories[index].selected;
    localProfileError.category = "";
    updateCategoryList([...localCateories]);
    updateProfileError({
      ...localProfileError,
    });
    setSelectedProfile({
      ...selectedProfile,
      categories: localCateories
        .filter((each) => each.selected)
        .map((each) => each._id),
    });
  };

  const saveGalleryImage = async () => {
    toggleDisablePage(true);
    const result = await addPhoto(
      selectedProfile.vendorId,
      uploadedGalleryImage
    );
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.ADD_IMAGE);
    removeUploadedImage();
    getProfileDetails("photos");
  };

  const savePhysicalContracts = async () => {
    toggleDisablePage(true);
    const result = await addPhysicalContracts(
      selectedProfile.vendorId,
      uploadedPhysicalContracts,
      uploadedPhysicalContracts.name
    );
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.ADD_PHYSICAL_CONTRACTS);
    removeUploadedPhysicalContracts();
    getProfileDetails("physicalContracts");
  };

  const saveProfileTypeHandler = (selectedProfileTypeVal) => {
    setShowChangeProfileTypeModal(true);
    setSelectedProfileType(selectedProfileTypeVal);
  };

  const handleConfirmProfileType = () => {
    setShowChangeProfileTypeModal(false);
    saveProfileType();
  };

  const saveProfileType = async () => {
    const profileData = await updateProfile(selectedProfile.vendorId, {
      subType: Number(selectedProfileType),
    });
    if (profileData.data.errorCode) {
      toast.error(API_ERROR[profileData.data.errorCode]);
      toggleDisablePage(false);
      return;
    }
    toast.success(API_SUCCESS.UPDATE_PROFILE);
    setProfileType(selectedProfileType);
    await getAllLocations(selectedProfile.vendorId);
    await getAllServices(selectedProfile.vendorId);
  };

  // const showImageModal = (imgUrl) => {
  //   setSelectedImgUrl(imgUrl);
  //   setShowImage(true);
  // };

  useEffect(() => {
    getInitialProfileDetails();
  }, [getInitialProfileDetails]);

  useEffect(() => {
    if (selectedProfile.vendorId) {
      openLocationList(selectedProfile.vendorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile.vendorId]);

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        transitionDuration={500}
        open={props.show}
        onClose={() => {
          resetState();
          props.handleClose("vendor");
        }}
      >
        <div className={classes.root}>
          <div
            style={{
              position: "absolute",
              right: "24px",
              top: "16px",
              backgroundColor: "#2e40d4",
              borderColor: "#293ccc",
              color: "#fff",
              padding: "4px 8px",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => {
              resetState();
              props.handleClose("vendor");
            }}
          >
            <i className="la la-close"></i>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div
              className={`vendor-page row ${disablePage ? "pointer-none" : ""}`}
              style={{
                margin: 0,
              }}
            >
              <RemoveModal
                displayRemoveModal={displayRemoveModal}
                openRemoveModal={openRemoveModal}
                removeType={removeType}
                removeData={removeData}
              />
              {/* <ImageViewModal
                show={showImage}
                handleClose={() => setShowImage(false)}
                imgUrl={selectedImgUrl}
              /> */}
              <div className="col-12 categories-heading">
                <h4 className={`mb-0`}>Edit Professional Profile</h4>
              </div>
              <div className="col-md-4">
                <div
                  className="row profile-details-wrapper"
                  style={{
                    margin: 0,
                  }}
                >
                  <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm save-button"
                      onClick={savePersonalInfo}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        updateProfileError({
                          ...VendorConfig.initialProfileError,
                        });
                        getProfileDetails("personalInfo");
                      }}
                    >
                      Reset
                    </button>
                  </div>
                  {!uploadedProfileImage ? (
                    <div className="col-12 p-0 profile-image-wrapper mb-3">
                      {selectedProfile.profilePicture ? (
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage:
                              "url(" + selectedProfile.profilePicture + ")",
                          }}
                        >
                          <div className="file-uploader">
                            <input
                              type="file"
                              id="profileImage"
                              accept="image/*"
                              hidden
                              onChange={uploadProfileImage}
                            />
                            <i
                              className="fa fa-camera"
                              aria-hidden="true"
                              onClick={() => openFileUploader("profileImage")}
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="profile-image"
                          style={{
                            backgroundImage:
                              "url(" +
                              require("./../../../../images/imgAvtr.png") +
                              ")",
                          }}
                        >
                          <div className="file-uploader">
                            <input
                              type="file"
                              id="profileImage"
                              accept="image/*"
                              hidden
                              onChange={uploadProfileImage}
                            />
                            <i
                              className="fa fa-camera"
                              aria-hidden="true"
                              onClick={() => openFileUploader("profileImage")}
                            ></i>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-12 p-0 profile-image-wrapper mb-3">
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage: "url(" + uploadedProfileImage + ")",
                        }}
                      >
                        <div className="file-uploader">
                          <input
                            type="file"
                            id="profileImage"
                            accept="image/*"
                            hidden
                            onChange={uploadProfileImage}
                          />
                          <i
                            className="fa fa-camera"
                            aria-hidden="true"
                            onClick={() => openFileUploader("profileImage")}
                          ></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-12 p-0 mb-3">
                    <label htmlFor="vendorName" className="form-label">
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        profileError.name ? "error-border" : ""
                      }`}
                      id="vendorName"
                      placeholder="Enter Name"
                      value={selectedProfile.name}
                      onChange={(e) =>
                        changePersonalInfo(e.target.value, "name")
                      }
                    />{" "}
                    {profileError.name ? (
                      <div className="input-error">{profileError.name}</div>
                    ) : null}
                  </div>
                  {categoryList.length ? (
                    <div className="col-12 p-0 mb-3 custom-category-dropdown-wrapper">
                      <label htmlFor="vendorCategory" className="form-label">
                        Professional Category{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        id="vendorCategory"
                        className="col-12 custom-category-dropdown-select"
                        onClick={() => toggleCategory(!categoryDropdownOpened)}
                      >
                        {selectedProfile.categories &&
                        selectedProfile.categories.length
                          ? `${selectedProfile.categories.length} Selected`
                          : "Select"}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                      {categoryDropdownOpened ? (
                        <div
                          className="row"
                          style={{
                            margin: 0,
                          }}
                        >
                          {categoryList.length
                            ? categoryList.map((dayValue, index) => {
                                return (
                                  <div className="col-12" key={dayValue._id}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={dayValue._id}
                                        checked={dayValue.selected}
                                        onChange={() =>
                                          toggleCategorySelection(
                                            dayValue._id,
                                            index
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={dayValue._id}
                                      >
                                        {dayValue.title}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      ) : null}
                      {profileError.category ? (
                        <div className="input-error">
                          {profileError.category}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  <div className="col-12 p-0 mb-3">
                    <label htmlFor="vendorTotalExp" className="form-label">
                      Total Exp <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="row m-0">
                      <div className="col-6 pl-0">
                        <input
                          id="vendorTotalExp"
                          type="text"
                          className={`form-control ${
                            profileError.experience &&
                            !selectedProfile.experience.year
                              ? "error-border"
                              : ""
                          }`}
                          placeholder="in year"
                          value={
                            selectedProfile.experience
                              ? selectedProfile.experience.year
                              : ""
                          }
                          onChange={(e) => {
                            changePersonalInfo(
                              e.target.value.replace(/\D/, ""),
                              "year"
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 pr-0">
                        <input
                          type="text"
                          className={`form-control ${
                            profileError.experience &&
                            (!selectedProfile.experience.month ||
                              profileError.experience === INVALID_MONTH)
                              ? "error-border"
                              : ""
                          }`}
                          placeholder="in month"
                          value={
                            selectedProfile.experience
                              ? selectedProfile.experience.month
                              : ""
                          }
                          onChange={(e) => {
                            changePersonalInfo(
                              e.target.value.replace(/\D/, ""),
                              "month"
                            );
                          }}
                        />
                      </div>
                    </div>
                    {profileError.experience ? (
                      <div className="input-error">
                        {profileError.experience}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 p-0 mb-3">
                    <label htmlFor="vendorPhone" className="form-label">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        profileError.phone ? "error-border" : ""
                      }`}
                      id="vendorPhone"
                      placeholder="Enter Phone"
                      value={selectedProfile.phone}
                      onChange={(e) =>
                        changePersonalInfo(e.target.value, "phone")
                      }
                    />{" "}
                    {profileError.phone ? (
                      <div className="input-error">{profileError.phone}</div>
                    ) : null}
                  </div>
                  <div className="col-12 p-0 mb-3">
                    <label htmlFor="vendorEmail" className="form-label">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        profileError.email ? "error-border" : ""
                      }`}
                      id="vendorEmail"
                      placeholder="Enter Email"
                      value={selectedProfile.email}
                      onChange={(e) =>
                        changePersonalInfo(e.target.value, "email")
                      }
                    />{" "}
                    {profileError.email ? (
                      <div className="input-error">{profileError.email}</div>
                    ) : null}
                  </div>
                  <div className="col-12 p-0 mb-3">
                    <label htmlFor="vendorWebsiteUrl" className="form-label">
                      Website
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        profileError.websiteUrl ? "error-border" : ""
                      }`}
                      id="vendorWebsiteUrl"
                      placeholder="Enter Website"
                      value={selectedProfile.websiteUrl}
                      onChange={(e) =>
                        changePersonalInfo(e.target.value, "websiteUrl")
                      }
                    />{" "}
                    {profileError.websiteUrl ? (
                      <div className="input-error">
                        {profileError.websiteUrl}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 p-0">
                    <label htmlFor="vendorDescription" className="form-label">
                      Short Bio
                    </label>
                    <textarea
                      className="form-control"
                      id="vendorDescription"
                      rows="3"
                      value={selectedProfile.description}
                      onChange={(e) =>
                        changePersonalInfo(e.target.value, "description")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {vendorProfileData ? null : (
                  <div
                    style={{
                      position: "absolute",
                      height: "100%",
                      background: "rgba(255,255,255,0.5)",
                      zIndex: 1000,
                      width: "98%",
                      overflow: "hidden",
                    }}
                  ></div>
                )}

                <div
                  className="row profile-details-wrapper"
                  style={{
                    margin: 0,
                  }}
                >
                  <div className="col-12 p-0">
                    <ul className="nav nav-tabs profile-menu-options">
                      {menuOptions.map((menu, index) => {
                        return (
                          <li
                            className="nav-item"
                            key={index}
                            onClick={() => updateMenu(menu)}
                          >
                            <span
                              className={`nav-link ${
                                menu.selected ? "active" : ""
                              }`}
                            >
                              {menu.option}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-12 p-0">
                    {selectedMenu === "Gallery" ? (
                      <div className="col-12 p-0">
                        <div
                          className="row"
                          style={{
                            margin: "0",
                          }}
                        >
                          <div className="col-12 p-0">
                            <div
                              className="row"
                              style={{
                                margin: 0,
                              }}
                            >
                              <div
                                className={`col-6 pl-0 pt-2 ${
                                  !uploadedGalleryImage ? "mb-3" : ""
                                }`}
                              >
                                <h5 className={`mb-0`}>Gallery</h5>
                              </div>
                              {uploadedGalleryImage ? (
                                <div className="col-6 pr-0 text-right mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm mr-2"
                                    onClick={removeUploadedImage}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={saveGalleryImage}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {selectedProfile.descriptionImages &&
                          selectedProfile.descriptionImages.length
                            ? selectedProfile.descriptionImages.map(
                                (image, index) => {
                                  return (
                                    <div
                                      className={`about-image-wrapper pl-0`}
                                      key={index}
                                    >
                                      <div
                                        className="profile-image description-image"
                                        style={{
                                          backgroundImage:
                                            "url(" + image.url + ")",
                                        }}
                                      />
                                      <img
                                        src={require("./../../../../images/remove.svg")}
                                        onClick={() =>
                                          openRemove(image, "Image")
                                        }
                                        alt={`remove gallery`}
                                      />
                                    </div>
                                  );
                                }
                              )
                            : null}
                          {!uploadedGalleryImage ? (
                            <div className={`about-image-wrapper pl-0`}>
                              <input
                                type="file"
                                accept="image/*"
                                id="galleryImage"
                                hidden
                                onChange={uploadGalleryImage}
                              />
                              <div
                                className="profile-image description-image"
                                style={{
                                  backgroundColor: "#eee",
                                  backgroundImage:
                                    "url(" +
                                    require("./../../../../images/plus.svg") +
                                    ")",
                                }}
                                onClick={() => openFileUploader("galleryImage")}
                              />
                            </div>
                          ) : (
                            <div className={`about-image-wrapper pl-0`}>
                              <input
                                type="file"
                                accept="image/*"
                                id="galleryImage"
                                hidden
                                onChange={uploadGalleryImage}
                              />
                              <div
                                className="profile-image description-image"
                                style={{
                                  backgroundColor: "#eee",
                                  backgroundImage:
                                    "url(" +
                                    URL.createObjectURL(uploadedGalleryImage) +
                                    ")",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    {selectedMenu === "Bank Details" ? (
                      <div className="col-12 p-0">
                        <div
                          className="row"
                          style={{
                            margin: 0,
                          }}
                        >
                          <div className="col-12 p-0">
                            <div
                              className="row"
                              style={{
                                margin: 0,
                              }}
                            >
                              <div className="col-6 pl-0 pt-2">
                                <h5 className={`mb-0`}>Bank Details</h5>
                              </div>
                              <div className="col-6 pr-0 text-right mb-3">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary btn-sm mr-2"
                                  onClick={() => {
                                    updateBankDetailsError({
                                      ...VendorConfig.initialBankDetailsError,
                                    });
                                    getProfileDetails("bank");
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={saveBankDetails}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 pl-0 mb-3">
                            <label htmlFor="bankName" className="form-label">
                              Bank Name
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.bankName ? "error-border" : ""
                              }`}
                              id="bankName"
                              placeholder="Enter Bank Name"
                              value={selectedProfile.bankDetails.bankName}
                              onChange={(e) =>
                                updateBankDetails(e.target.value, "bankName")
                              }
                            />{" "}
                            {bankDetailsError.bankName ? (
                              <div className="input-error">
                                {bankDetailsError.bankName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-6 pr-0 mb-3">
                            <label
                              htmlFor="accountHolderName"
                              className="form-label"
                            >
                              Account Holder Name
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.accountHolderName
                                  ? "error-border"
                                  : ""
                              }`}
                              id="accountHolderName"
                              placeholder="Enter Account Holder Name"
                              value={
                                selectedProfile.bankDetails.accountHolderName
                              }
                              onChange={(e) =>
                                updateBankDetails(
                                  e.target.value,
                                  "accountHolderName"
                                )
                              }
                            />{" "}
                            {bankDetailsError.accountHolderName ? (
                              <div className="input-error">
                                {bankDetailsError.accountHolderName}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-6 pl-0 mb-3">
                            <label
                              htmlFor="accountNumber"
                              className="form-label"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.accountNumber
                                  ? "error-border"
                                  : ""
                              }`}
                              id="accountNumber"
                              placeholder="Enter Account Number"
                              value={selectedProfile.bankDetails.accountNumber}
                              onChange={(e) =>
                                updateBankDetails(
                                  e.target.value.replace(/\D/, ""),
                                  "accountNumber"
                                )
                              }
                            />{" "}
                            {bankDetailsError.accountNumber ? (
                              <div className="input-error">
                                {bankDetailsError.accountNumber}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-6 pr-0 mb-3">
                            <label htmlFor="ifscCode" className="form-label">
                              IFSC Code
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.ifscCode ? "error-border" : ""
                              }`}
                              id="ifscCode"
                              placeholder="Enter IFSC Code"
                              value={selectedProfile.bankDetails.ifscCode}
                              onChange={(e) =>
                                updateBankDetails(e.target.value, "ifscCode")
                              }
                            />{" "}
                            {bankDetailsError.ifscCode ? (
                              <div className="input-error">
                                {bankDetailsError.ifscCode}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-6 pl-0 mb-3">
                            <label htmlFor="branchName" className="form-label">
                              Branch
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.branchName
                                  ? "error-border"
                                  : ""
                              }`}
                              id="branchName"
                              placeholder="Enter Branch Name"
                              value={selectedProfile.bankDetails.branchName}
                              onChange={(e) =>
                                updateBankDetails(e.target.value, "branchName")
                              }
                            />{" "}
                            {bankDetailsError.branchName ? (
                              <div className="input-error">
                                {bankDetailsError.branchName}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-6 pr-0 mb-3">
                            <label htmlFor="gstCode" className="form-label">
                              GST Code
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                bankDetailsError.gstNumber ? "error-border" : ""
                              }`}
                              id="gstCode"
                              placeholder="Enter GST Code"
                              value={selectedProfile.bankDetails.gstNumber}
                              onChange={(e) =>
                                updateBankDetails(e.target.value, "gstNumber")
                              }
                            />{" "}
                            {bankDetailsError.gstNumber ? (
                              <div className="input-error">
                                {bankDetailsError.gstNumber}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-12 pl-0 mb-3"
                            style={{
                              width: "100%",
                              marginTop: 12,
                              padding: 12,
                            }}
                          >
                            <HasCheque
                              hasCheque={hasCheque}
                              setHasCheque={(hasChequeVal) => {
                                setHasCheque(hasChequeVal);
                                // setSelectedProfile({
                                //   ...selectedProfile,
                                //   bankDetails: {
                                //     ...selectedProfile.bankDetails,
                                //     isCheque: false,
                                //     chequeName: '',
                                //     chequeAddress: ''
                                //   }
                                // });
                              }}
                            />
                          </div>
                          {hasCheque ? (
                            <React.Fragment>
                              <div className="col-6 pl-0 mb-3">
                                <label
                                  htmlFor="chequeName"
                                  className="form-label"
                                >
                                  Cheque Name
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    bankDetailsError.chequeName
                                      ? "error-border"
                                      : ""
                                  }`}
                                  id="chequeName"
                                  placeholder="Enter Cheque Name"
                                  value={selectedProfile.bankDetails.chequeName}
                                  onChange={(e) =>
                                    updateBankDetails(
                                      e.target.value,
                                      "chequeName"
                                    )
                                  }
                                />{" "}
                                {bankDetailsError.chequeName ? (
                                  <div className="input-error">
                                    {bankDetailsError.chequeName}
                                  </div>
                                ) : null}
                              </div>
                              <div className="col-6 pl-0 mb-3">
                                <label
                                  htmlFor="chequeAddress"
                                  className="form-label"
                                >
                                  Cheque Address
                                </label>
                                <input
                                  type="text"
                                  className={`form-control ${
                                    bankDetailsError.chequeAddress
                                      ? "error-border"
                                      : ""
                                  }`}
                                  id="chequeAddress"
                                  placeholder="Enter Cheque Address"
                                  value={
                                    selectedProfile.bankDetails.chequeAddress
                                  }
                                  onChange={(e) =>
                                    updateBankDetails(
                                      e.target.value,
                                      "chequeAddress"
                                    )
                                  }
                                />{" "}
                                {bankDetailsError.chequeAddress ? (
                                  <div className="input-error">
                                    {bankDetailsError.chequeAddress}
                                  </div>
                                ) : null}
                              </div>
                            </React.Fragment>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {selectedMenu === "Address Book" ? (
                      <AddressManager
                        mode={mode}
                        profileType={profileType}
                        addresses={addresses}
                        updateMode={updateMode}
                        chooseLocation={chooseLocation}
                        openRemove={openRemove}
                        updateLocationDetailsError={updateLocationDetailsError}
                        openLocationList={openLocationList}
                        saveLocation={saveLocation}
                        locationDetails={locationDetails}
                        updateLocationDetails={updateLocationDetails}
                        daysArray={daysArray}
                        handleFromDateChange={handleFromDateChange}
                        selectedFromDate={selectedFromDate}
                        selectedToDate={selectedToDate}
                        handleToDateChange={handleToDateChange}
                        toggleDaySelection={toggleDaySelection}
                        updateDateError={updateDateError}
                        initialLocationError={VendorConfig.initialLocationError}
                        locationDetailsError={locationDetailsError}
                        locationData={selectedCoordinates}
                        updateCoordinate={updateCoordinate}
                      />
                    ) : null}
                    {selectedMenu === "Services" ? (
                      <ServiceManager
                        selectedProfile={selectedProfile}
                        serviceDetails={serviceDetails}
                        selectedServiceTime={selectedServiceTime}
                        selectServiceTime={selectServiceTime}
                        profileType={profileType}
                        mode={mode}
                        serviceList={serviceList}
                        chooseService={chooseService}
                        openRemove={openRemove}
                        openServiceList={openServiceList}
                        serviceDetailsError={serviceDetailsError}
                        updateServiceDetailsError={updateServiceDetailsError}
                        updateServiceDetails={changeServiceDetails}
                        saveService={saveService}
                        serviceTimeHours={serviceTimeHours}
                        selectedServiceMode={selectedServiceMode}
                        changeServiceMode={changeServiceMode}
                        individualLocations={individualLocations}
                        selectedIndividualLocation={selectedIndividualLocation}
                        getDuration={getDuration}
                        updateSelectedIndividualLocation={
                          updateSelectedIndividualLocation
                        }
                        selectedIndividualLocationObj={
                          selectedIndividualLocationObj
                        }
                        updateSelectedIndividualLocationObj={
                          updateSelectedIndividualLocationObj
                        }
                        selectedLocationForOrganization={
                          selectedLocationForOrganization
                        }
                        selectedLocationForOrganizationObj={
                          selectedLocationForOrganizationObj
                        }
                        selectLocationForOrganization={
                          selectLocationForOrganization
                        }
                        updateLocationsForOrganization={
                          updateLocationsForOrganization
                        }
                      />
                    ) : null}
                    {selectedMenu === "Physical Contracts" ? (
                      <div className="col-12 p-0">
                        <div
                          className="row"
                          style={{
                            margin: "0",
                          }}
                        >
                          <div className="col-12 p-0">
                            <div
                              className="row"
                              style={{
                                margin: 0,
                              }}
                            >
                              <div
                                className={`col-6 pl-0 pt-2 ${
                                  !uploadedPhysicalContracts ? "mb-3" : ""
                                }`}
                              >
                                <h5 className={`mb-0`}>Physical Contracts</h5>
                              </div>
                              {uploadedPhysicalContracts ? (
                                <div className="col-6 pr-0 text-right mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm mr-2"
                                    onClick={removeUploadedPhysicalContracts}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={savePhysicalContracts}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {selectedProfile.physicalContracts &&
                          Object.keys(selectedProfile.physicalContracts)
                            .length &&
                          selectedProfile.physicalContracts.url &&
                          selectedProfile.physicalContracts.fileName ? (
                            <div
                              style={{ marginRight: 15, position: "relative" }}
                            >
                              <a
                                target="blank"
                                href={selectedProfile.physicalContracts.url}
                              >
                                {selectedProfile.physicalContracts.fileName}
                              </a>
                              <img
                                style={{ paddingLeft: 24 }}
                                src={require("./../../../../images/remove.svg")}
                                onClick={() =>
                                  openRemove(
                                    selectedProfile.physicalContracts,
                                    "Physical Contracts"
                                  )
                                }
                                alt={`remove physical contracts`}
                              />
                            </div>
                          ) : !uploadedPhysicalContracts ? (
                            <div className={`about-image-wrapper pl-0`}>
                              <input
                                type="file"
                                accept=".pdf, .jpeg, .jpg, .png, .docx"
                                id="physicalContracts"
                                hidden
                                onChange={uploadPhysicalContracts}
                              />
                              <div
                                className="profile-image description-image"
                                style={{
                                  backgroundColor: "#eee",
                                  backgroundImage:
                                    "url(" +
                                    require("./../../../../images/plus.svg") +
                                    ")",
                                }}
                                onClick={() =>
                                  openFileUploader("physicalContracts")
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <h5>{uploadedPhysicalContracts.name}</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {Object.keys(props.vendor.idPhoto).filter(
                  (key) => props.vendor.idPhoto[key]
                ).length ? (
                  <div className="row ml-0 mr-0 mt-3 profile-details-wrapper id-details-wrapper">
                    <div className={`col-12 pl-0 pb-3`}>
                      <h5 className={`mb-0`}>Documents and Approval</h5>
                    </div>

                    {vendorProfileData && props.vendor.idPhoto.govtId ? (
                      <div className="id-image-wrapper mb-3">
                        <div
                          className="profile-image"
                          onClick={() =>
                            window.open(props.vendor.idPhoto.govtId)
                          }
                          style={{
                            backgroundImage:
                              "url(" + props.vendor.idPhoto.govtId + ")",
                          }}
                        ></div>
                        <div className="text-center mt-2">
                          Gov Id Front Proof
                        </div>
                      </div>
                    ) : null}
                    {vendorProfileData && props.vendor.idPhoto.govtIdBack ? (
                      <div className="id-image-wrapper second-id-image-wrapper mb-3">
                        <div
                          className="profile-image"
                          onClick={() =>
                            window.open(props.vendor.idPhoto.govtIdBack)
                          }
                          style={{
                            backgroundImage:
                              "url(" + props.vendor.idPhoto.govtIdBack + ")",
                          }}
                        ></div>
                        <div className="text-center mt-2">
                          Gov Id Back Proof
                        </div>
                      </div>
                    ) : null}
                    {vendorProfileData &&
                    props.vendor.idPhoto.certificates &&
                    props.vendor.idPhoto.certificates.length
                      ? props.vendor.idPhoto.certificates.map(
                          (certificate, key) => (
                            <div
                              className="id-image-wrapper second-id-image-wrapper mb-3"
                              key={key}
                            >
                              <div
                                className="profile-image"
                                onClick={
                                  () => window.open(certificate.url)
                                  // showImageModal(props.vendor.idPhoto.certificate)
                                }
                                style={{
                                  backgroundImage:
                                    "url(" + certificate.url + ")",
                                }}
                              ></div>
                              <div className="text-center mt-2">
                                Professional Qualification {key + 1}
                              </div>
                            </div>
                          )
                        )
                      : null}
                    {vendorProfileData && props.vendor.idPhoto.gumasta ? (
                      <div
                        className="profile-image-wrapper id-image-wrapper second-id-image-wrapper mb-3"
                        style={{ marginLeft: 32 }}
                      >
                        <div
                          className="profile-image"
                          onClick={
                            () => window.open(props.vendor.idPhoto.gumasta)
                            // showImageModal(props.vendor.idPhoto.certificate)
                          }
                          style={{
                            backgroundImage:
                              "url(" + props.vendor.idPhoto.gumasta + ")",
                          }}
                        ></div>
                        <div className="text-center mt-2">
                          License to Operate/Gumasta{" "}
                        </div>
                      </div>
                    ) : null}
                    {vendorProfileData &&
                    props.vendor.idPhoto.rciCertificate ? (
                      <div
                        className="profile-image-wrapper id-image-wrapper second-id-image-wrapper mb-3"
                        style={{ marginLeft: 32 }}
                      >
                        <div
                          className="profile-image"
                          onClick={
                            () =>
                              window.open(props.vendor.idPhoto.rciCertificate)
                            // showImageModal(props.vendor.idPhoto.certificate)
                          }
                          style={{
                            backgroundImage:
                              "url(" +
                              props.vendor.idPhoto.rciCertificate +
                              ")",
                          }}
                        ></div>
                        <div className="text-center mt-2">RCI Certificate</div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {/** Profile Type  && Profile Visibility */}
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Card style={{ flex: 1, marginRight: 8 }}>
                    <CardContent>
                      <ChangeProfileType
                        accountStatus={props.vendor.accountStatus}
                        show={showChangeProfileTypeModal}
                        handleConfirm={handleConfirmProfileType}
                        handleClose={() => setShowChangeProfileTypeModal(false)}
                        profileType={profileType}
                        saveHandler={saveProfileTypeHandler}
                      />
                    </CardContent>
                  </Card>
                  <Card style={{ flex: 1, marginRight: 8 }}>
                    <CardContent>
                      <ProfileVisibility
                        handleChange={handleChangeProfileVisibility}
                        state={profileVisibility}
                      />
                      <ProfileVisibilityForAppointment
                        handleChange={
                          handleChangeProfileVisibilityForAppointment
                        }
                        state={profileVisibilityForAppointment}
                      />
                    </CardContent>
                  </Card>
                </div>
                {/** Business Id && Promo Code */}
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Card style={{ flex: 1, marginRight: 8 }}>
                    <CardContent>
                      <VendorStatesDropdown
                        state={state}
                        saveHandler={saveVendorStateHandler}
                      />
                    </CardContent>
                  </Card>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <PromoCodeInfo
                        state={promoCodeInfo}
                        handleChangeAllowPromoDiscount={
                          handleChangeAllowPromoDiscount
                        }
                        handleChange={handleChangePromoDiscount}
                        savePromoCodeInfo={savePromoCodeInfo}
                      />
                    </CardContent>
                  </Card>
                </div>
                {/** Note && Convenience Fee */}
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Card style={{ flex: 1, marginRight: 8 }}>
                    <CardContent>
                      <AddBusinessId
                        handleChange={handleChangeOnBusinessIdByAdmin}
                        businessIdByAdmin={businessIdByAdmin}
                        saveBusinessId={saveBusinessId}
                      />
                    </CardContent>
                  </Card>
                  <Card style={{ flex: 1 }}>
                    <CardContent>
                      <ConvenienceFeesInfo
                        state={convenienceFeesInfo}
                        handleChangeAllow={handleChangeAllowConvenienceFees}
                        handleChange={handleChangeConvenienceFeesDiscount}
                        saveConvenienceFees={saveConvenienceFeesInfo}
                      />
                    </CardContent>
                  </Card>
                </div>
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Card style={{ flex: 1, marginRight: 8 }}>
                    <CardContent>
                      <AddNote
                        handleChange={handleChangeOnNoteByAdmin}
                        noteByAdmin={noteByAdmin}
                        saveNote={saveNote}
                      />
                    </CardContent>
                    {props.vendor.reasonOfReject ? (
                      <CardContent>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label>Reason for rejection: </Form.Label>
                          <p>{props.vendor.reasonOfReject}</p>
                        </Form.Group>
                      </CardContent>
                    ) : null}
                  </Card>
                </div>
              </div>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};
const mapDispatchToProps = (dispatch) => {
  return {
    vendorUpdated: ({ skip, limit }) =>
      dispatch(vendorDuck.actions.vendorUpdated({ skip, limit })),
  };
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EditVendorPage)
);
