const ModuleConfig = {
  dashboard: {
    key: "dashboard",
    name: "Dashboard",
    checked: false,
    role: 1,
  },
  reports: {
    key: "reports",
    name: "Reports",
    checked: false,
    role: 1,
  },
  // organizations: {
  //   key: "organizations",
  //   name: "Organization",
  //   checked: false,
  //   role: 1,
  // },
  institutions: {
    key: "institutions",
    name: "Institution",
    checked: false,
    role: 1,
  },
  professionalCategories: {
    key: "professionalCategories",
    name: "Professional Category",
    checked: false,
    role: 1,
  },
  professionals: {
    key: "professionals",
    name: "Professional",
    checked: false,
    role: 1,
  },
  languages: {
    key: "languages",
    name: "Language",
    checked: false,
    role: 1,
  },
  problemCategories: {
    key: "problemCategories",
    name: "Problem Category",
    checked: false,
    role: 1,
  },
  problems: {
    key: "problems",
    name: "Problem",
    checked: false,
    role: 1,
  },
  forums: {
    key: "forums",
    name: "Forum",
    checked: false,
    role: 1,
  },
  faqs: {
    key: "faqs",
    name: "FAQ",
    checked: false,
    role: 1,
  },
  users: {
    key: "users",
    name: "User Management",
    checked: false,
    role: 1,
  },
  appointments: {
    key: "appointments",
    name: "Appointments",
    checked: false,
    role: 1,
  },
  // sessions: {
  //   key: "sessions",
  //   name: "Sessions",
  //   checked: false,
  //   role: 1,
  // },
  events: {
    key: "events",
    name: "Events",
    checked: false,
    role: 1,
  },
  workshops: {
    key: "workshops",
    name: "Workshops",
    checked: false,
    role: 1,
  },
  payments: {
    key: "payments",
    name: "Payments",
    checked: false,
    role: 1,
  },
  refunds: {
    key: "refunds",
    name: "Refunds",
    checked: false,
    role: 1,
  },

  supports: {
    key: "supports",
    name: "Support",
    checked: false,
    role: 1,
  },
  tutorials: {
    key: "tutorials",
    name: "Tutorials",
    checked: false,
    role: 1,
  },
  setUpTests: {
    key: "setUpTests",
    name: "Setup Tests",
    checked: false,
    role: 1,
  },
  tests: {
    key: "tests",
    name: "Tests",
    checked: false,
    role: 1,
  },
  sendEmail: {
    key: "sendEmail",
    name: "Send Email",
    checked: false,
    role: 1,
  },
  globalSetting: {
    key: "globalSetting",
    name: "Global Settings",
    checked: false,
    role: 1,
  },
  revenueCalculator: {
    key: "revenueCalculator",
    name: "Revenue Calculator",
    checked: false,
    role: 1,
  },
};

export default ModuleConfig;
