import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { listTestPaper } from "../../../crud/test-paper.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const SetupTests = (props) => {
    const [tableData, setTableData] = useState({
        data: [],
        skip: 0,
        limit: 5,
        total: 0,
        totalPage: 0,
    });

    const [page, setPage] = useState(1);
    // eslint-disable-next-line
    const [selectedStatus, setSelectedStatus] = useState(undefined);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {

        reloadList({
            status: selectedStatus,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setUserInfo(props.data.account.user);
      }, [props.data.account.user]);

    useEffect(() => {
        if (props.data.testPaper.listData) {
            let listObject = {
                data: [...props.data.testPaper.listData.data.data.data],
                skip: tableData.skip,
                limit: tableData.limit,
                total: props.data.testPaper.listData.data.data.total,
                totalPage: Math.ceil(
                    Number(props.data.testPaper.listData.data.data.total / tableData.limit)
                ),
            };
            //   console.log("listObject", listObject);
            setTableData({
                ...tableData,
                ...listObject,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.testPaper.listData]);

    const reloadList = (payload) => {
        listTestPaper({
            skip: tableData.skip,
            limit: tableData.limit,
            payload: {
                status: payload.status,
            },
        }).then((docs) => {
            if (docs.data) {
                console.log('docs.data', docs.data.data.data);
                setTableData({
                    data: [...docs.data.data.data],
                    skip: tableData.skip,
                    limit: tableData.limit,
                    total: 0,
                    totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
                });
            }
        });
    };

    const handleChange = (_event, value) => {
        setPage(value);

        listTestPaper({
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
            payload: {
                status: selectedStatus,
            },
        }).then((docs) => {
            let listObject = {
                data: [...docs.data.data.data],
                skip: Number((value - 1) * tableData.limit),
                limit: tableData.limit,
                total: docs.data.data.total,
                totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
            };

            setTableData({
                ...tableData,
                ...listObject,
            });
        });
    };


    return (<React.Fragment>
        <Table bordered hover style={{ tableLayout: "fixed" }}>
            <thead className="custom-table-header">
                <tr>
                    <th className="custom-table-header-text">SL No</th>
                    {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text">ID</th>: null}
                    <th className="custom-table-header-text">Title</th>
                    <th className="custom-table-header-text">Description</th>
                    {/* <th className="custom-table-header-text">Instruction</th> */}
                    {/* <th className="custom-table-header-text">Approx Time</th> */}
                    {/* <th className="custom-table-header-text">Fare</th> */}
                    <th className="custom-table-header-text">Categories</th>
                    <th className="custom-table-header-text">Professionals</th>
                    <th className="custom-table-header-text">Test Picture</th>
                    <th className="custom-table-header-text">Status</th>
                    <th className="custom-table-header-text">Action</th>
                </tr>
            </thead>
            <tbody>
                {tableData.data.length
                    ? tableData.data.map((eachData, key) => {
                        return (
                            <tr key={eachData._id} className={
                                key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                              }>
                                <td className="custom-table-body-text">
                                {tableData.skip+key+1}
                                </td>
                                {userInfo && userInfo.roleInfo.isSuperAdmin?
                                <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{eachData._id}</td> : null}
                                <td className="custom-table-body-text">
                                    {eachData.title
                                        ? eachData.title
                                        : "N/A"}
                                </td>
                                <td className="custom-table-body-text">
                                    {eachData.description
                                        ? eachData.description
                                        : "N/A"}</td>
                                {/* <td>
                                    {eachData.testInstruction
                                        ? eachData.testInstruction
                                        : "N/A"}
                                </td> */}
                                {/* <td className="custom-table-body-text">{`${eachData.approxTime}`}</td> */}
                                {/* <td>
                                    {eachData.fare ? eachData.fare : "N/A"}
                                </td> */}
                                <td className="custom-table-body-text">
                                    {eachData.category.length
                                        ? eachData.category.map((each, i, { length }) => {
                                            if (i + 1 === length) {
                                                return (<span key={i}>{each.title}</span>);
                                            } else {
                                                return (<span key={i}>{each.title}, </span>);
                                            }
                                        })
                                        : "N/A"}
                                </td>
                                <td className="custom-table-body-text">  {eachData.vendorReferences.length
                                    ? eachData.vendorReferences.map((each, i, { length }) => {
                                        if (i + 1 === length) {
                                            return (<span key={i}>{each.vendorId.personalInfo.name}</span>);
                                        } else {
                                            return (<span key={i}>{each.vendorId.personalInfo.name}, </span>);
                                        }
                                    })
                                    : "N/A"}</td>
                                <td><img style={{
                                    height: 100,
                                    width: 100
                                }} src={eachData.imageLink} alt="test-view" /></td>
                                <td className="custom-table-body-text">
                                    {eachData.status === 1 ? <b>Active</b> : 'Inactive'}
                                </td>
                                <td className="text-center">
                                    <Link to={`/edit-test-details/${eachData._id}`}>
                                        <p
                                            className="btn btn-icon custom-table-btn"
                                            style={{
                                                margin: "0",
                                            }}
                                        >
                                            <i className="la la-eye"></i>
                                        </p>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })
                    : null}
            </tbody>
        </Table>

        {tableData.data.length === 0 ? (
           <NoDataFound/>
        ) : null}
        {tableData.data.length ? <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
        /> : null}
    </React.Fragment>)
};
const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupTests);
