import axios from './axios.interceptor';
import api from './api.util';

export const PROBLEM_CATEGORY_URL = `${api}/account/admin/problem-category`;
export const ADD_PROBLEM_CATEGORY_URL =`${PROBLEM_CATEGORY_URL}/add`;
export const LIST_PROBLEM_CATEGORY_URL =`${PROBLEM_CATEGORY_URL}/list`;

export function addCategory(payload) {
  return axios.post(ADD_PROBLEM_CATEGORY_URL, payload);
}

export function listCategory({skip, limit}) {
  return axios.get(`${LIST_PROBLEM_CATEGORY_URL}?skip=${skip}&limit=${limit}`);
}

export function editCategory(categoryId, payload) {
  return axios.put(`${PROBLEM_CATEGORY_URL}/${categoryId}/update`, payload);
}

export function removeCategory(categoryId) {
  return axios.delete(`${PROBLEM_CATEGORY_URL}/${categoryId}/remove`);
}