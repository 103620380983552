import React from 'react';
import { Form, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const ConvenienceFeesInfo = ({ state, handleChangeAllow, handleChange, saveConvenienceFees }) => {

    return (<div style={{ marginBottom: 0 }}>
        <Form.Label>Professional Convenience Fee Information</Form.Label>
        <FormControl component="fieldset" style={{ display: 'block' }}>
            <FormLabel component="legend">Allow Custom</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={state.allowed} onChange={handleChangeAllow} name="allowDiscount" />}
                    label={state.allowed ? "Yes" : "No"}
                />
            </FormGroup>
        </FormControl>
        {
            state.allowed ?
                <FormControl component="fieldset" style={{ display: 'block' }}>
                    <FormLabel component="legend">Convenience Fee Percentage</FormLabel>
                    <NumberFormat value={state.value} onChange={handleChange} style={{
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 8,
                        paddingRight: 8,
                        marginTop: 6
                    }} />
                </FormControl>
                : null
        }
        <Button style={{ marginTop: 16 }} variant="primary" onClick={saveConvenienceFees}> Save </Button>
    </div>)
};

export default ConvenienceFeesInfo;