import React from 'react';
import { Table } from "react-bootstrap";
import paymentConfig from "./../../../../config/payment";

export default function TransactionList({ tableData }) {
    return (
        <Table bordered hover>
            <thead className="custom-table-header">
                <tr>
                    <th className="custom-table-header-text">SL No</th>
                    <th className="custom-table-header-text">Name</th>
                    <th className="custom-table-header-text">Phone</th>
                    <th className="custom-table-header-text">Transaction Number</th>
                    <th className="custom-table-header-text">Order Number</th>
                    <th className="custom-table-header-text">Amount</th>
                    <th className="custom-table-header-text">Status</th>
                </tr>
            </thead>
            <tbody>
                {tableData.data.length ? tableData.data.map((eachData, key) => {
                    return (
                        <tr key={`${eachData._id}`} className={
                            key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                          }>
                            <td className="custom-table-body-text" style={{ wordWrap: "break-word" }}>{`***${eachData._id.substr(eachData._id.length-5,5)}`}</td>
                            <td className="custom-table-body-text">
                                {eachData.customerRef.personalInfo.name
                                    ? eachData.customerRef.personalInfo.name
                                    : "N/A"}
                            </td>
                            <td className="custom-table-body-text">{`${eachData.customerRef.personalInfo.phone.dialCode}-${eachData.customerRef.personalInfo.phone.number}`}</td>
                            <td className="custom-table-body-text">{eachData.txnId}</td>
                            <td className="custom-table-body-text">{eachData.orderId}</td>
                            <td className="custom-table-body-text">Rs. {eachData.amount}/-</td>
                            <td className="custom-table-body-text">{paymentConfig.pendingTransactionStatus[eachData.status]}</td>
                        </tr>
                    );
                }) : null}
            </tbody>
        </Table>
    )
}