import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import CustomPagination from "../../../components/CustomPagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import bookingConfig from "././../../../config/booking";
import { listBooking } from "../../../crud/booking.crud";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Form } from "react-bootstrap";
import StyleDropdown from "../../../components/StyleDropdown";
import { colorsCodes } from "../../../config/constants";
import NoDataFound from "../../../components/NoDataFound";
import * as booking from "./../../../store/ducks/booking.duck";
const BookingList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: Number((props.data.booking.page - 1) * 10),
    limit: 10,
    total: 0,
    totalPage: 0, 
  });

  const [page, setPage] = useState(props.data.booking.page);
  const [selectedDate, setSelectedDate] = useState(props.data.booking.selectedBookingMonth);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(
    "Select Status"
  );
  const [selectedStatus, setSelectedStatus] = useState(props.data.booking.selectedBookingStatus);
  const [professionalName, setProfessionalName] = useState("");
  const [professionalPhone, setProfessionalPhone] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [searchType, setSearchType] = useState(undefined);
  const [searchKey, setSearchKey] = useState(undefined);
  const [userInfo, setUserInfo] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleMonthChange = (date) => {
    setSelectedDate(date);
    props.updateBookingMonth(date);
    const startDate = moment(date)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(date)
      .endOf("month")
      .format("YYYY-MM-DD");
    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: selectedStatus,
    });
  };

  const statusHandler = (status) => {
    setSelectedStatus(status);
    props.updateBookingStatus(status);
    switch (status) {
      case 0:
        setSelectedBookingStatus("All");
        break;
      case 1:
        setSelectedBookingStatus("Payment Pending");
        break;
      case 2:
        setSelectedBookingStatus("Confirmed");
        break;
      case 3:
        setSelectedBookingStatus("Completed");
        break;
      case 4:
        setSelectedBookingStatus("Cancelled by customer");
        break;
      case 5:
        setSelectedBookingStatus("Cancelled by vendor");
        break;
      case 6:
        setSelectedBookingStatus("Failed");
        break;
      case 7:
        setSelectedBookingStatus("Timed out");
        break;
      case 8:
        setSelectedBookingStatus("Cancelled by Admin");
        break;
      default:
        break;
    }
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: status ===0 ? undefined: status,
    });
  };

  useEffect(() => {
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");

    reloadList({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      status: selectedStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.user.listData) {
      let listObject = {
        data: [...props.data.user.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.user.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.user.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.user.listData]);

  const reloadList = (bookingPayload) => {
    listBooking({
      skip: Number((page - 1) * tableData.limit),
      limit: tableData.limit,
      bookingPayload: {
        searchKey: searchKey,
        searchType: searchType,
        startDate: bookingPayload.startDate,
        endDate: bookingPayload.endDate,
        status: bookingPayload.status,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: tableData.skip,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const searchList = (bookingPayload) => {
    setPage(1);
    listBooking({
      skip: 0,
      limit: tableData.limit,
      bookingPayload: {
        searchKey: bookingPayload.searchKey,
        searchType: bookingPayload.searchType,
        startDate: bookingPayload.startDate,
        endDate: bookingPayload.endDate,
        status: bookingPayload.status,
      },
    }).then((docs) => {
      if (docs.data) {
        setTableData({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableData.limit,
          total: 0,
          totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
        });
      }
    });
  };

  const handleChange = (_event, value) => {
    setPage(value);
    props.updatePage(value);
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    listBooking({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
      bookingPayload: {
        searchKey: searchKey,
        searchType: searchType,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        status: selectedStatus,
      },
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleSearch = (name) => (event) => {
    const startDate = moment(selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment(selectedDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    switch (name) {
      case 'bookingNumber': 
        setBookingNumber(event.target.value);

        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(5);
          searchList({
            searchKey: event.target.value,
            searchType: 5,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        }
        break;
      case "professionalName":
        setProfessionalName(event.target.value);

        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(1);
          searchList({
            searchKey: event.target.value,
            searchType: 1,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        }
        break;
      case "professionalPhone":
        setProfessionalPhone(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(2);
          searchList({
            searchKey: event.target.value,
            searchType: 2,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        }
        break;
      case "customerName":
        setCustomerName(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(3);
          searchList({
            searchKey: event.target.value,
            searchType: 3,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        }
        break;
      case "customerPhone":
        setCustomerPhone(event.target.value);
        if (event.target.value.length > 3) {
          setSearchKey(event.target.value);
          setSearchType(4);
          searchList({
            searchKey: event.target.value,
            searchType: 4,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        } else {
          setSearchKey(undefined);
          setSearchType(undefined);
          searchList({
            searchKey: undefined,
            searchType: undefined,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            status: selectedStatus,
          });
        }
        break;
      default:
        break;
    }
  };

  const renderSlots = (slots) => {
    return (
      <span>
        {bookingConfig.slots[slots[0]]}-
        {bookingConfig.slots[slots[slots.length - 1] + 1]}
      </span>
    );
  };

  const renderStatus = (status) => {
    let statusText = "N/A";
    switch (status) {
      case 1:
        statusText = "Payment Pending";
        break;
      case 2:
        statusText = "Confirmed";
        break;
      case 3:
        statusText = "Completed";
        break;
      case 4:
        statusText = "Cancelled by customer";
        break;
      case 5:
        statusText = "Cancelled by vendor";
        break;
      case 6:
        statusText = "Failed";
        break;
      case 7:
        statusText = "Timed out";
        break;
      case 8:
        statusText = "Cancelled by Admin";
        break;
      default:
        break;
    }
    return <p>{statusText}</p>;
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 1:
        return colorsCodes.orange;
      case 2:
        return colorsCodes.blue;
      case 3:
        return colorsCodes.green;
      case 4:
        return colorsCodes.red;
      case 5:
        return colorsCodes.red;
      case 6:
        return colorsCodes.red;
      case 7:
        return colorsCodes.red;
      case 8:
        return colorsCodes.red;
      default:
        return colorsCodes.blue;
    }
    
  };


  const renderBookingType  = (type) => {
    let bookingType = "N/A";
    switch (type) {
      case 1:
        bookingType = "Online";
        break;
      case 2:
        bookingType = "Offline";
        break;
      default:
        break;
    }
    return <p>{bookingType}</p>;
  };

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  useEffect(()=>{
    if(props.data?.booking?.selectedBookingStatus){
      switch (props.data.booking.selectedBookingStatus) {
        case 0:
          setSelectedBookingStatus("All");
          break;
        case 1:
          setSelectedBookingStatus("Payment Pending");
          break;
        case 2:
          setSelectedBookingStatus("Confirmed");
          break;
        case 3:
          setSelectedBookingStatus("Completed");
          break;
        case 4:
          setSelectedBookingStatus("Cancelled by customer");
          break;
        case 5:
          setSelectedBookingStatus("Cancelled by vendor");
          break;
        case 6:
          setSelectedBookingStatus("Failed");
          break;
        case 7:
          setSelectedBookingStatus("Timed out");
          break;
        case 8:
          setSelectedBookingStatus("Cancelled by Admin");
          break;
        default:
          break;
      }
    }
  },[props.data.booking])

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-between">
          <Grid>
            <StyleDropdown>
              <Dropdown.Toggle
                menualign="right"
                variant="primary"
                id="dropdown-menu-align-right"
              >
                {selectedBookingStatus}
              </Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item
                  onClick={() => {
                    statusHandler(0);
                  }}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(1);
                  }}
                >
                  Payment Pending
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(2);
                  }}
                >
                  Confirmed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(3);
                  }}
                >
                  Completed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(4);
                  }}
                >
                  Cancelled By Customer
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(5);
                  }}
                >
                  Cancelled By Vendor
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    statusHandler(6);
                  }}
                >
                  Failed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(7);
                  }}
                >
                  Timed out
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    statusHandler(8);
                  }}
                >
                  Cancelled By Admin
                </Dropdown.Item>
              </Dropdown.Menu>
            </StyleDropdown>
          </Grid>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Start from year selection"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Table bordered hover style={{ tableLayout: "fixed" }}>
        <thead className="custom-table-header">
          <tr>
            <th className="custom-table-header-text" style={{fontSize: 12}}>SL No</th>
            {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text" style={{fontSize: 11}}>ID</th>: null}
            <th className="custom-table-header-text" style={{fontSize: 12}}>Booking No</th>
            <th className="custom-table-header-text" style={{fontSize: 12, width: 95}}>Professional Name</th>
            <th className="custom-table-header-text" style={{fontSize: 12, width: 95}}>Professional Phone</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Customer Name</th>
            <th className="custom-table-header-text" style={{fontSize: 12, width: 95}}>Customer Phone</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Service</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Booking Type</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Booked Slot</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Booked Date</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Booked Status</th>
            <th className="custom-table-header-text" style={{fontSize: 12}}>Action</th>
          </tr>
         
        </thead>
        <tbody>
        <tr>
            <th style={{ verticalAlign: "top" }}></th>
            {userInfo && userInfo.roleInfo.isSuperAdmin? <th style={{ verticalAlign: "top" }}></th>: null}
            <th style={{ verticalAlign: "top", padding: "2px" }}>
              <Form.Control
                type="text"
                value={bookingNumber}
                onChange={handleSearch("bookingNumber")}
              />
            </th>
            <th style={{ verticalAlign: "top", padding: "2px" }}>
              <Form.Control
                type="text"
                value={professionalName}
                onChange={handleSearch("professionalName")}
              />
            </th>
            <th style={{ verticalAlign: "top", padding: "2px" }}>
              <Form.Control
                type="text"
                value={professionalPhone}
                onChange={handleSearch("professionalPhone")}
              />
            </th>
            <th style={{ verticalAlign: "top", padding: "2px" }}>
              <Form.Control
                type="text"
                value={customerName}
                onChange={handleSearch("customerName")}
              />
            </th>
            <th style={{ verticalAlign: "top", padding: "2px" }}>
              <Form.Control
                type="text"
                value={customerPhone}
                onChange={handleSearch("customerPhone")}
              />
            </th>
            <th style={{ verticalAlign: "top" }}></th>
            <th style={{ verticalAlign: "top" }}></th>
            <th style={{ verticalAlign: "top" }}></th>
            <th style={{ verticalAlign: "top" }}></th>
            <th style={{ verticalAlign: "top" }}></th>
            <th style={{ verticalAlign: "top" }}></th>
          </tr>
          {tableData.data.length
            ? tableData.data.map((eachData, key) => {
              return (
                <tr key={eachData._id} className={
                  key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                }>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>{tableData.skip+key+1}</td>
                  {userInfo && userInfo.roleInfo.isSuperAdmin? <td className="custom-table-body-text" style={{ wordWrap: "break-word", fontSize: 11 }}>{eachData._id}</td>: null}
                  <td className="custom-table-body-text" style={{ wordWrap: "break-word", fontSize: 12 }}>{eachData.bookingNumber}</td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>
                    {eachData.vendor.personalInfo.name
                      ? eachData.vendor.personalInfo.name
                      : "N/A"}
                  </td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>{`${eachData.vendor.personalInfo.phone.dialCode}-${eachData.vendor.personalInfo.phone.number}`}</td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>
                    {eachData.customer.personalInfo.name
                      ? eachData.customer.personalInfo.name
                      : "N/A"}
                  </td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>{`${eachData.customer.personalInfo.phone.dialCode}-${eachData.customer.personalInfo.phone.number}`}</td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>
                    {eachData.service.title ? eachData.service.title : "N/A"}
                  </td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>{renderBookingType(eachData.appointmentBookingType)}</td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>{renderSlots(eachData.slots)}</td>
                  <td className="custom-table-body-text" style={{fontSize: 12}}>
                    {eachData.date
                      ? moment(eachData.date).format("DD-MMM-YYYY")
                      : "N/A"}
                  </td>
                  <td className="custom-table-body-text" style={{fontSize: 12, color: renderStatusColor(eachData.status)}}>{renderStatus(eachData.status)}</td>
                  <td className="text-center">
                    <Link to={`/appointment-details/${eachData._id}`}>
                      <p
                        className="btn btn-icon custom-table-btn"
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-eye"></i>
                      </p>
                    </Link>
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </Table>

      {tableData.data.length === 0 ? (
       <NoDataFound/>
      ) : null}
      {tableData.data.length ? <CustomPagination
        count={tableData.totalPage}
        color="primary"
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded" 
      /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePage: (data) => dispatch(booking.actions.updatePage({page: data})),
    updateBookingStatus: (data) => dispatch(booking.actions.updateBookingStatus({selectedBookingStatus: data})),
    updateBookingMonth: (data) => dispatch(booking.actions.updateBookingMonth({selectedBookingMonth: data})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
