import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import EditSubAdmin from "./edit-sub-admin";
import RemoveSubAdmin from "./remove-sub-admin";
import { listSubAdmin } from "../../../crud/sub-admin.crud";
// import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
// import { AspectRatio } from "react-aspect-ratio";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";
const SubAdminList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);

  const [showSubAdminEdit, setShowSubAdminEdit] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [showSubAdminDelete, setShowSubAdminDelete] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.subAdmin.listData) {
      let listObject = {
        data: [...props.data.subAdmin.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.subAdmin.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.subAdmin.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.subAdmin.listData]);

  const reloadList = () => {
    listSubAdmin({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    // console.log("value", value);
    setPage(value);

    listSubAdmin({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowSubAdminEdit(false);
  };

  const handleCloseDelete = () => {
    setShowSubAdminDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowSubAdminEdit(true);
  };

  const openDeleteModal = (data) => {
    setSelectedData(data);
    setShowSubAdminDelete(true);
  };

  return (
    <React.Fragment>
      {showSubAdminEdit ? (
        <EditSubAdmin
          subAdmin={selectedData}
          show={showSubAdminEdit}
          handleClose={handleCloseEdit}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {showSubAdminDelete ? (
        <RemoveSubAdmin
          subAdmin={selectedData}
          show={showSubAdminDelete}
          handleClose={handleCloseDelete}
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {/* <th className="custom-table-header-text">Profile Picture</th> */}
                <th className="custom-table-header-text">Name</th>
                <th className="custom-table-header-text">Email</th>
                <th className="custom-table-header-text">Role</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{eachData._id}</td>
                    {/* <td style={{ textAlign: "center" }}>
                      {eachData.personalInfo.profilePicture ? (
                        <Image
                          src={eachData.personalInfo.profilePicture}
                          roundedCircle
                          style={{
                            height: "120px",
                          }}
                        />
                      ) : (
                        <AspectRatio
                          ratio="1/1"
                          style={{ maxWidth: "64px", maxHeight: "64px" }}
                        >
                          <img
                            alt={`user logo`}
                            src={toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )}
                            style={{ maxWidth: "64px", maxHeight: "64px" }}
                          />
                        </AspectRatio>
                      )}
                    </td> */}
                    <td className="custom-table-body-text">
                      {eachData.personalInfo.firstName}{" "}
                      {eachData.personalInfo.lastName}
                    </td>
                    <td className="custom-table-body-text">{eachData.personalInfo.email}</td>
                    <td className="custom-table-body-text">
                      {eachData.roleInfo.isSuperAdmin
                        ? "Super Admin"
                        : eachData.roleInfo.roleId.name}
                    </td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded" 
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminList);
