import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import FullWidthTabs from "../../common/FullWidthTabs";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditAppsTutorial from "./edit-apps-tutorial";
import RemoveAppsTutorial from "./remove-apps-tutorial";
// import InputBase from "@material-ui/core/InputBase";
import userConfig from './../../../config/user';
import * as appsTutorial from "../../../store/ducks/apps-tutorial.duck";
import { listAppsTutorial } from "../../../crud/apps-tutorial.crud";
import CustomPagination from "../../../components/CustomPagination";
import NoDataFound from "../../../components/NoDataFound";

const AppsTutorialList = (props) => {
  const [currentTab, setCurrentTab] = useState(props.data.appsTutorial.currTab);
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showAppsTutorialEdit, setShowAppsTutorialEdit] = useState(false);
  // const [openInput, setOpenInput] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showAppsTutorialDelete, setShowAppsTutorialDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    setPage(1);
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.appsTutorial.listData) {
      let listObject = {
        data: [...props.data.appsTutorial.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.appsTutorial.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.appsTutorial.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.appsTutorial.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    if (currentTab === 0) {
      listAppsTutorial({
        skip: tableData.skip,
        limit: tableData.limit,
        userType: userConfig.role.customer
      }).then((docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    } else if (currentTab === 1) {
      listAppsTutorial({
        skip: tableData.skip,
        limit: tableData.limit,
        userType: userConfig.role.vendor
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    if (currentTab === 0) {
      listAppsTutorial({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        userType: userConfig.role.customer
      }).then((docs) => {
        // console.log(docs);
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    } else if (currentTab === 1) {
      listAppsTutorial({
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        userType: userConfig.role.vendor
      }).then((docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
          });
          // setOpenInput(false);
        }
      });
    }
  };

  const handleCloseEdit = () => {
    setShowAppsTutorialEdit(false);
  };

  const handleCloseDelete = () => {
    setShowAppsTutorialDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowAppsTutorialEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowAppsTutorialDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <React.Fragment>
      {showAppsTutorialEdit ? (
        <EditAppsTutorial
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          userType={currentTab===0? userConfig.role.customer: userConfig.role.vendor}
          appsTutorial={selectedData}
          show={showAppsTutorialEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showAppsTutorialDelete ? (
        <RemoveAppsTutorial
          userType={currentTab===0? userConfig.role.customer: userConfig.role.vendor}
          appsTutorial={selectedData}
          show={showAppsTutorialDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'tutorial'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}
      <FullWidthTabs
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          props.changeAppsTutorialTab(tab);
          setCurrentTab(tab);
        }}
        title={"Tutorials"}
        tabsTitle={["Customer", "Professional"]}
      />
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
               <th className="custom-table-header-text">SL No</th>
               {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text">ID</th>: null}
                <th className="custom-table-header-text">Title</th>
                <th className="custom-table-header-text">Description</th>
                {/* <th className="custom-table-header-text">Order</th> */}
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={key} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">
                      {tableData.skip+key+1}
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                        <td style={{ wordWrap: "break-word" }}>
                          {eachData._id}
                        </td>
                      ) : null}
                    <td className="custom-table-body-text elipses-text-td">{eachData.title}</td>
                    <td className="custom-table-body-text elipses-text-td">{eachData.description}</td>
                    {/* <td
                      style={{ width: "10%" }}
                      onClick={() => setOpenInput(true)}
                    >
                      {!openInput ? (
                        <p>{eachData.order}</p>
                      ) : (
                          <InputBase
                            defaultValue={eachData.order}
                            onBlur={(event) => {
                              changeOrderAppsTutorial(eachData._id, {
                                newOrder: event.target.value,
                              }).then(() => {
                                reloadList();
                              });
                            }}
                          />
                        )}
                    </td> */}
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
      {tableData.totalPage ?
        <CustomPagination
          count={tableData.totalPage}
          color="primary"
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
        /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAppsTutorialTab: (tab) => dispatch(appsTutorial.actions.changeAppsTutorialTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsTutorialList);
