import React from "react";

const CustomViewScroll = ({ children, isMoreRightPadding }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 460,
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          padding: 16,
          paddingRight: isMoreRightPadding ? 24 : 16,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomViewScroll;
