import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic";

export default function NoDataFound() {
  return (
    <div
      style={{
        height: 460,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={{ height: "50%", opacity: 0.7 }}
        src={toAbsoluteUrl("/media/default-image/default-no-data.png")}
        alt="No Data"
      />
      <p style={{ fontSize: 16, fontWeight: 600 }}>No Data Found</p>
    </div>
  );
};

