import axios from './axios.interceptor';
import api from './api.util';

export const GET_PROFILE = `${api}/account/admin/institution/profile`;
export const INSTITUTION_URL = `${api}/account/admin/institution`;
export const ADD_INSTITUTION_URL = `${INSTITUTION_URL}/add`;
export const LIST_INSTITUTION_URL = `${INSTITUTION_URL}/list`;

export function addInstitution({ profilePicture, name, email, promoCode }) {
  const formData = new FormData();
  formData.append('profilePicture', profilePicture);
  formData.append('name', name);
  formData.append('promoCode', promoCode);
  formData.append('email', email);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.post(ADD_INSTITUTION_URL, formData, config);
}

export function listInstitution({ skip, limit }) {
  return axios.get(`${LIST_INSTITUTION_URL}?skip=${skip}&limit=${limit}`);
}

export function editInstitution(institutionId, payload) {
  const formData = new FormData();
  if (payload.profilePicture) {
    formData.append('profilePicture', payload.profilePicture);
  }

  if (payload.name) {
    formData.append('name', payload.name);
  }

  if (payload.promoCode) {
    formData.append('promoCode', payload.promoCode);
  }

  if (payload.email) {
    formData.append('email', payload.email);
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return axios.put(`${INSTITUTION_URL}/${institutionId}/update`, formData, config);
}

export function removeInstitution(institutionId) {
  return axios.delete(`${INSTITUTION_URL}/${institutionId}/remove`);
}

export const getProfile = async (institutionId) => {
  const res = await axios.get(`${GET_PROFILE}/${institutionId}`);
  return res;
}