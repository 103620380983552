import React, { useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
const CustomDatePicker = ({
    label,
    selectedDate,
    handleDateChange,
    style
})=>{
    return <div style={{...style}}>
        <span style={{marginRight: 12, fontSize: 14}}>{label}</span>
         <KeyboardDatePicker
            format="dd/MM/yyyy"
            margin="normal"
            label=""
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            style={{ margin: 0}}
        />
    </div>
};

const CustomDateRange = ({viewData})=>{
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    
    const handleFromDateChange = (date) => {
        setFromDate(date);
    }
    const handleToDateChange = (date) => {
        setToDate(date);
    }
    return (<MuiPickersUtilsProvider utils={DateFnsUtils}><Paper elevation={1}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 16, marginBottom: 16 }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <CustomDatePicker 
                    label={'FROM'} 
                    selectedDate={fromDate}
                    handleDateChange={handleFromDateChange}
                    style={{flex: 0.45, display: 'flex', alignItems: 'center'}}/>
                <CustomDatePicker 
                    label={'TO'} 
                    selectedDate={toDate}
                    handleDateChange={handleToDateChange}
                    style={{flex: 0.45, display: 'flex', alignItems: 'center'}}/>
            </div>
            <Button 
                variant="contained" 
                size="large" 
                onClick={()=>{
                    viewData(fromDate, toDate)
                }} 
                style={{ backgroundColor: '#2699fb', color: '#fff' }}
            >View</Button>
        </div>
    </Paper></MuiPickersUtilsProvider>)
};

export default CustomDateRange;