import React, { useEffect } from "react";
import { Form, Button, Modal, Dropdown } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  buttonProgress:{
  }
}));
const PayoutModal = ({
  showModal,
  setShowModal,
  selectedAmount,
  onAmountChangeHandler,
  selectedPaymentMode,
  setSelectedPaymentMode,
  isPaymentModeCheque,
  setIsPaymentModeCheque,
  referenceNo,
  onReferenceChangeHandler,
  otherPayAmount,
  onOtherPayChangeHandler,
  errors,
  loading,
  submitPayoutFees,
  bankingDetails
}) => {
  const classes = useStyles();
  useEffect(()=>{
    if(bankingDetails && bankingDetails.isCheque){
      setSelectedPaymentMode({
        key: 5,
        name: "CHEQUE",
      });
      setIsPaymentModeCheque(true);
    }
  },[bankingDetails, setSelectedPaymentMode, setIsPaymentModeCheque])


  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payout Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="amoountForm.title">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={selectedAmount}
                onChange={onAmountChangeHandler}
                isInvalid={errors && !!errors.selectedAmount}
                disabled={true}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.selectedAmount}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="amoountForm.paymentMode"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Label>Payment Mode</Form.Label>
              {bankingDetails && !bankingDetails.isCheque? (
              <Dropdown>
                <Dropdown.Toggle
                  menualign="left"
                  variant="primary"
                  id="dropdown-menu-align-left"
                >
                  {selectedPaymentMode ? selectedPaymentMode.name : "Select"}
                </Dropdown.Toggle>
               
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedPaymentMode({
                        key: 1,
                        name: "NEFT",
                      });
                      setIsPaymentModeCheque(false);
                    }}
                  >
                    NEFT
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedPaymentMode({
                        key: 2,
                        name: "IMPS",
                      });
                      setIsPaymentModeCheque(false);
                    }}
                  >
                    IMPS
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedPaymentMode({
                        key: 3,
                        name: "RTGS",
                      });
                      setIsPaymentModeCheque(false);
                    }}
                  >
                    RTGS
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedPaymentMode({
                        key: 4,
                        name: "UPI",
                      });
                      setIsPaymentModeCheque(false);
                    }}
                  >
                    UPI
                  </Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
              ): 
              (<Button
                variant="primary"
                style={{
                  position: "relative",
                }}
              >
                CHEQUE
              </Button>) }
            </Form.Group>
            {errors && errors.selectedPaymentMode ? (
              <p style={{ color: "red" }}>{errors.selectedPaymentMode}</p>
            ) : null}
             {selectedPaymentMode ? (
              <Form.Group controlId="amoountForm.referenceNo">
                <Form.Label>Reference No.</Form.Label>
                <Form.Control
                  type="text"
                  value={referenceNo}
                  onChange={onReferenceChangeHandler}
                  isInvalid={errors && !!errors.referenceNo}
                />
                <Form.Control.Feedback type="invalid">
                  {errors && errors.referenceNo}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
            {isPaymentModeCheque ? (
              <Form.Group controlId="amoountForm.otherPayAmount">
                <Form.Label>Other Deductions</Form.Label>
                <Form.Control
                  type="text"
                  value={otherPayAmount}
                  onChange={onOtherPayChangeHandler}
                  isInvalid={errors && !!errors.otherPayAmount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors && errors.otherPayAmount}
                </Form.Control.Feedback>
              </Form.Group>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => submitPayoutFees()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default PayoutModal;
