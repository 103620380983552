import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditInstitution from "./edit-institution";
import RemoveInstitution from "./remove-institution";
import { listInstitution } from "../../../crud/institution.crud";
import CustomPagination from "../../../components/CustomPagination";
import { Tooltip } from "@material-ui/core";
import NoDataFound from "../../../components/NoDataFound";
const InstitutionList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showInstitutionEdit, setShowInstitutionEdit] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showInstitutionDelete, setShowInstitutionDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  useEffect(() => {
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  useEffect(() => {
    if (props.data.institution.listData) {
      let listObject = {
        data: [...props.data.institution.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.institution.listData.data.data.total,
        totalPage: Math.ceil(
          Number(
            props.data.institution.listData.data.data.total / tableData.limit
          )
        ),
      };
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.institution.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listInstitution({ skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    setPage(value);

    listInstitution({
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowInstitutionEdit(false);
  };

  const handleCloseDelete = () => {
    setShowInstitutionDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowInstitutionEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowInstitutionDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  return (
    <React.Fragment>
      {showInstitutionEdit ? (
        <EditInstitution
          institution={selectedData}
          show={showInstitutionEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showInstitutionDelete ? (
        <RemoveInstitution
          institution={selectedData}
          show={showInstitutionDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (<DeleteWarningModal title={'institution'} open={showDeleteWarning} handleClose={() => setShowDeleteWarning(false)} />) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
              <th className="custom-table-header-text">SL No</th>
              {userInfo && userInfo.roleInfo.isSuperAdmin? <th className="custom-table-header-text">ID</th>: null}
                {/* <th className="custom-table-header-text">Profile Picture</th> */}
                <th className="custom-table-header-text">Name</th>
                <th className="custom-table-header-text">Email</th>
                <th className="custom-table-header-text">Promo Code</th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr key={eachData._id} className={
                    key % 2 === 1 ? 'odd-custom-row' : 'even-custom-row'
                  }>
                    <td className="custom-table-body-text">{tableData.skip+key+1}</td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin? <td style={{ wordWrap: "break-word" }} className="custom-table-body-text">{eachData._id}</td>: null}
                    {/* <td style={{ textAlign: "center" }}>
                      {eachData.personalInfo.profilePicture ? (
                        <Image
                          alt={`user logo`}
                          src={eachData.personalInfo.profilePicture}
                          style={{
                            height: "120px",
                          }}
                        />
                      ) : (
                          <Image
                            alt={`user logo`}
                            src={toAbsoluteUrl(
                              `/media/default-image/default-image.png`
                            )}
                            style={{
                              height: "120px",
                            }}
                          />
                        )}
                    </td> */}
                    <td className="custom-table-body-text">{eachData.personalInfo.name}</td>
                    <td className="custom-table-body-text">{eachData.personalInfo.email}</td>

                    <td className="custom-table-body-text">{eachData.promoCode}</td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                      >
                        <Tooltip title="Edit">
                          <i className="la la-edit"></i>
                        </Tooltip>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            variant="outlined"
            shape="rounded" 
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
        )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionList);
