import axios from "./axios.interceptor";
import api from "./api.util";

export const TEST_PAPER_URL = `${api}/account/admin/test-paper`;
export const LIST_TEST_PAPER_URL = `${TEST_PAPER_URL}/list`;
export const LIST_BOOKED_TEST_PAPER_URL = `${TEST_PAPER_URL}/bookings`;
export const GET_ALL_ASSESMENTS_BY_USERS_URL = `${TEST_PAPER_URL}/get-all-assessments-by-users`;
export const TEST_PAPER_DETAILS_URL = `${TEST_PAPER_URL}/details`;


export function listTestPaper({ skip, limit, payload }) {
  return axios.post(`${LIST_TEST_PAPER_URL}?skip=${skip}&limit=${limit}`, payload);
}


export function listTestPaperAll() {
  return axios.post(`${LIST_TEST_PAPER_URL}`);
}

export function listProfessionals() {
  return axios.get(`${TEST_PAPER_URL}/professionals`);
}

export function listTestPaperCategories() {
  return axios.get(`${TEST_PAPER_URL}/categories`);
}

export function getTestPaper(testPaperId) {
  return axios.get(`${TEST_PAPER_DETAILS_URL}/${testPaperId}`);
}

export function saveTestPaper(testPaperId, payload) {
  const formData = new FormData();

  Object.keys(payload).forEach((e) => {
    formData.append(e, payload[e]);
  })

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.put(`${TEST_PAPER_DETAILS_URL}/${testPaperId}/save`, formData, config);
}

export function listBookedTestPaper({ skip, limit, payload }) {
  return axios.post(`${LIST_BOOKED_TEST_PAPER_URL}?skip=${skip}&limit=${limit}`, payload);
}

export function getAllAssessmentByUsers({ skip, limit, payload }) {
  return axios.post(`${GET_ALL_ASSESMENTS_BY_USERS_URL}?skip=${skip}&limit=${limit}`, payload);
}