const bookingConfig = {
    status: {
        1: 'Accepted',
        2: 'Rejected'
    },
    answerStatus: {
        1: 'Accepted',
        2: 'Rejected'
    },
    slots: {
        1: '12 AM',
        2: '12:30 AM',
        3: '1 AM',
        4: '1:30 AM',
        5: '2 AM',
        6: '2:30 AM',
        7: '3 AM',
        8: '3:30 AM',
        9: '4 AM',
        10: '4:30 AM',
        11: '5 AM',
        12: '5:30 AM',
        13: '6 AM',
        14: '6:30 AM',
        15: '7 AM',
        16: '7:30 AM',
        17: '8 AM',
        18: '8:30 AM',
        19: '9 AM',
        20: '9:30 AM',
        21: '10 AM',
        22: '10:30 AM',
        23: '11 AM',
        24: '11:30 AM',
        25: '12 PM',
        26: '12:30 PM',
        27: '1 PM',
        28: '1:30 PM',
        29: '2 PM',
        30: '2:30 PM',
        31: '3 PM',
        32: '3:30 PM',
        33: '4 PM',
        34: '4:30 PM',
        35: '5 PM',
        36: '5:30 PM',
        37: '6 PM',
        38: '6:30 PM',
        39: '7 PM',
        40: '7:30 PM',
        41: '8 PM',
        42: '8:30 PM',
        43: '9 PM',
        44: '9:30 PM',
        45: '10 PM',
        46: '10:30 PM',
        47: '11 PM',
        48: '11:30 PM'
    },
    bookingStatus: {
        1: 'Payment Pending',
        2: 'Confirmed',
        3: 'Completed',
        4: 'Canceled By Customer',
        5: 'Canceled By Vendor',
        6: 'Failed',
        7: 'Timed out'
    },
    workshopBookingStatus: {
        1: 'Payment Pending',
        2: 'Confirmed',
        3: 'Completed',
        4: 'Failed',
        5: 'Timedout',
    },
    eventBookingStatus: {
        1: 'Payment Pending',
        2: 'Confirmed',
        3: 'Completed',
        4: 'Failed',
        5: 'Timedout',
    },
    transferStatus: {
        1: 'Due',
        2: 'Paid'
    },
    bookingType: {
        1: 'Appointment',
        2: 'Session',
        3: 'Event'
    },
}
export default bookingConfig;