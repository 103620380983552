import axios from './axios.interceptor';
import api from './api.util';

export const SEND_EMAIL = `${api}/account/admin/send-email`;

export const SEND_EMAIL_CUSTOM = `${api}/account/admin/send-email/custom`;

export function sendEmail(payload) {
  return axios.post(`${SEND_EMAIL}`, payload);
}

export function sendEmailCustom(payload) {
  return axios.post(`${SEND_EMAIL_CUSTOM}`, payload);
}
