import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import DeleteWarningModal from "../../common/modal/delete-warning-modal";
import EditProblem from "./edit-problem";
import RemoveProblem from "./remove-problem";
import { listCategory } from "../../../crud/problem-category.crud";
import { listProblem } from "../../../crud/problem.crud";
import CustomPagination from "../../../components/CustomPagination";
import StyleDropdown from "../../../components/StyleDropdown";
import { styled } from "@material-ui/core/styles";
import NoDataFound from "../../../components/NoDataFound";
const StyleDropdownMenu = styled((props) => (
  <Dropdown.Menu
    {...props}
  />
))(() => ({
  '&.dropdown-menu.show':{
      top: '245px!important'
  }
}));

const ProblemList = (props) => {
  const [tableData, setTableData] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const [showProblemEdit, setShowProblemEdit] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [showProblemDelete, setShowProblemDelete] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCat, setSelectedCat] = useState(undefined);

  useEffect(() => {
    listCategory({ skip: 0, limit: 0 }).then((docs) => {
      // console.log(docs);
      if (docs.data) {
        setCategoryList([...docs.data.data.data]);
      }
    });
    reloadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.data.problem.listData) {
      let listObject = {
        data: [...props.data.problem.listData.data.data.data],
        skip: tableData.skip,
        limit: tableData.limit,
        total: props.data.problem.listData.data.data.total,
        totalPage: Math.ceil(
          Number(props.data.problem.listData.data.data.total / tableData.limit)
        ),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.problem.listData]);

  useEffect(() => {
    setUserInfo(props.data.account.user);
  }, [props.data.account.user]);

  const reloadList = () => {
    listProblem({ payload: {problemCategoryRef: selectedCat? selectedCat._id: undefined}, skip: tableData.skip, limit: tableData.limit }).then(
      (docs) => {
        if (docs.data) {
          setTableData({
            data: [...docs.data.data.data],
            skip: tableData.skip,
            limit: tableData.limit,
            total: 0,
            totalPage: Math.ceil(
              Number(docs.data.data.total / tableData.limit)
            ),
          });
        }
      }
    );
  };

  const handleChange = (_event, value) => {
    setPage(value);

    listProblem({
      payload: {problemCategoryRef: selectedCat? selectedCat._id: undefined},
      skip: Number((value - 1) * tableData.limit),
      limit: tableData.limit,
    }).then((docs) => {
      // console.log(docs);
      let listObject = {
        data: [...docs.data.data.data],
        skip: Number((value - 1) * tableData.limit),
        limit: tableData.limit,
        total: docs.data.data.total,
        totalPage: Math.ceil(Number(docs.data.data.total / tableData.limit)),
      };
      //   console.log("listObject", listObject);
      setTableData({
        ...tableData,
        ...listObject,
      });
    });
  };

  const handleCloseEdit = () => {
    setShowProblemEdit(false);
  };

  const handleCloseDelete = () => {
    setShowProblemDelete(false);
  };

  const openEditModal = (data) => {
    setSelectedData(data);
    setShowProblemEdit(true);
  };

  const openDeleteModal = (data) => {
    if (userInfo.roleInfo.isSuperAdmin) {
      setSelectedData(data);
      setShowProblemDelete(true);
    } else {
      setShowDeleteWarning(true);
    }
  };

  const catHandler = (value) => {
    setSelectedCat(value);
  };

  useEffect(()=>{
    reloadList();
    // eslint-disable-next-line
  }, [selectedCat])

  return (
    <React.Fragment>
      {showProblemEdit ? (
        <EditProblem
          listOption={{
            skip: tableData.skip,
            limit: tableData.limit,
          }}
          problem={selectedData}
          show={showProblemEdit}
          handleClose={handleCloseEdit}
        />
      ) : null}
      {showProblemDelete ? (
        <RemoveProblem
          problem={selectedData}
          show={showProblemDelete}
          handleClose={handleCloseDelete}
        />
      ) : null}
      {showDeleteWarning ? (
        <DeleteWarningModal
          title={"problem"}
          open={showDeleteWarning}
          handleClose={() => setShowDeleteWarning(false)}
        />
      ) : null}
      {tableData.data.length ? (
        <React.Fragment>
          <Table bordered hover>
            <thead className="custom-table-header">
              <tr>
                <th className="custom-table-header-text">SL No</th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                  <th className="custom-table-header-text">ID</th>
                ) : null}
                <th className="custom-table-header-text">Problem Category</th>
                <th className="custom-table-header-text" style={{ width: 400 }}>
                  Language Sets
                </th>
                <th className="custom-table-header-text">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                {userInfo && userInfo.roleInfo.isSuperAdmin ? <th></th> : null}
                <th style={{position:'relative', textAlign:'center'}}>
                  {categoryList.length ? (
                    <StyleDropdown>
                      <Dropdown.Toggle
                        menualign="right"
                        variant="primary"
                        id="dropdown-menu-align-right"
                      >
                        {selectedCat ? selectedCat.name : "All"}
                      </Dropdown.Toggle>
                      <StyleDropdownMenu style={{top: '250px'}}>
                        {categoryList.map((eachCat) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                catHandler({
                                  name: eachCat.categoryTitle,
                                  _id: eachCat._id,
                                });
                              }}
                            >
                              {eachCat.categoryTitle}
                            </Dropdown.Item>
                          );
                        })}
                      </StyleDropdownMenu>
                    </StyleDropdown>
                  ) : null}
                </th>
                <th></th>
                <th></th>
              </tr>
              {tableData.data.map((eachData, key) => {
                return (
                  <tr
                    key={key}
                    className={
                      key % 2 === 1 ? "odd-custom-row" : "even-custom-row"
                    }
                  >
                    <td className="custom-table-body-text">
                      {tableData.skip + key + 1}
                    </td>
                    {userInfo && userInfo.roleInfo.isSuperAdmin ? (
                      <td
                        className="custom-table-body-text"
                        style={{ wordWrap: "break-word" }}
                      >
                        {eachData._id}
                      </td>
                    ) : null}

                    <td className="custom-table-body-text">
                      {eachData.problemCategoryRef.categoryTitle}
                    </td>
                    <td className="custom-table-body-text">
                      <ul>
                        {eachData.languageSet.map((v, key) => {
                          return (
                            <li
                              key={key}
                              style={{ listStyle: "none", textAlign: "left" }}
                            >
                              <p>
                                <span>{v.title}</span>{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {v.languageRef.name}
                                </span>
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                    <td className="text-center">
                      <p
                        className="btn btn-icon custom-table-btn"
                        onClick={() => openEditModal(eachData)}
                        style={{
                          margin: "0",
                        }}
                      >
                        <i className="la la-edit"></i>
                      </p>

                      <p
                        className="btn btn-icon custom-table-btn"
                        title="Delete"
                        style={{
                          margin: "0",
                        }}
                        onClick={() => {
                          openDeleteModal(eachData);
                        }}
                      >
                        <i className="la la-trash kt-font-danger"></i>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <CustomPagination
            count={tableData.totalPage}
            color="primary"
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </React.Fragment>
      ) : (
        <NoDataFound/>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProblemList);
