import { listEvent } from "../../crud/event.crud";
import { put, takeLatest } from "redux-saga/effects";
export const actionTypes = {
  EVENT_UPDATED: "EVENT_UPDATED",
  EVENT_UPDATED_SUCCESS: "EVENT_UPDATED_SUCCESS",
  CHANGE_EVENT_TAB: "CHANGE_EVENT_TAB",
};

const initialAuthState = {
  listData: undefined,
  currTab: 0,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.EVENT_UPDATED_SUCCESS: {
      const { listData } = action.payload;
      return { listData };
    }
    case actionTypes.CHANGE_EVENT_TAB: {
      return { ...state, currTab: action.payload.currTab };
    }
    default:
      return state;
  }
};

export const actions = {
  eventUpdated: ({ skip, limit }) => ({
    type: actionTypes.EVENT_UPDATED,
    payload: {
      skip,
      limit,
    },
  }),
  changeEventTab: (currTab) => ({
    type: actionTypes.CHANGE_EVENT_TAB,
    payload: {
      currTab: currTab,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.EVENT_UPDATED, function* eventUpdated(action) {
    const { skip, limit } = action.payload;
    const listData = yield listEvent({ skip, limit });

    yield put({
      type: actionTypes.EVENT_UPDATED_SUCCESS,
      payload: {
        listData,
      },
    });
  });
}
