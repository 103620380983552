import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Image as BImage } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Chip, Grid, CircularProgress, Card, CardContent, Switch, FormGroup, FormControlLabel } from "@material-ui/core";
import Select from 'react-select'

import { green } from '@material-ui/core/colors';
import { listTestPaperCategories, listProfessionals, getTestPaper, saveTestPaper } from "../../../crud/test-paper.crud";
import { toast } from 'react-toastify';
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { toAbsoluteUrl } from "../../../../_metronic/utils/utils";
import { connect } from "react-redux";
const EditTestPaper = (props) => {
    const { classes } = props;
    const observed = useRef();
    const [checked, setChecked] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    const [testPaper, setTestPaper] = useState({
        title: '',
        description: '',
        ageInfo: ''
    });
    const { testPaperId } = useParams();
    const [errors, setErrors] = useState({
        title: '',
        description: '',
        ageInfo: '',
        imageLink: ''
    });
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedProfessionals, setSelectedProfessionals] = useState([]);
    const [professionals, setProfessionals] = useState([]);
    const [loading,
        setLoading] = useState(false);

    const onImageChangeHandler = (event) => {
        let file = event.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            setSelectedFile(file);
            setErrors({
                ...errors,
                imageLink: null,
            });
            return true;
        };
    };
    const onChangeHandler = (event) => {
        setTestPaper({
            ...testPaper,
            [event.target.name]: event.target.value
        });
        if (!event.target.value && event.target.name !== 'ageInfo') {
            setErrors({
                ...errors,
                [event.target.name]: 'Required'
            });
        }
    };

    const handleDeleteCategories = (e) => {
        setSelectedCategories([...selectedCategories.map((sc) => {
            if (sc.value === e.value) {
                sc.isSelected = false;
            }
            return sc;
        })]);
    };

    const handleDeleteProfessionals = (e) => {
        setSelectedProfessionals([...selectedProfessionals.map((sc) => {
            if (sc.value === e.value) {
                sc.isSelected = false;
            }
            return sc;
        })]);
    };


    useEffect(() => {
        if (selectedFile) {
            const objectUrl = window.URL.createObjectURL(selectedFile);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [selectedFile]);

    useEffect(() => {
        if (categories.length && professionals.length && testPaperId) {
            getTestPaper(testPaperId)
                .then((doc) => {
                    setTestPaper({ title: doc.data.data.title, description: doc.data.data.description, ageInfo: doc.data.data.ageInfo });
                    setChecked(doc.data.data.status === 1 ? true : false);
                    if (doc.data.data.imageLink) {
                        setPreview(doc.data.data.imageLink);
                    }
                    const categoryData = doc.data.data.category.filter((e) => e).map((e) => e.title);
                    const selectedCGs = categories.map((eachC) => {
                        if (categoryData.includes(eachC.label)) {
                            eachC.isSelected = true;
                        };
                        return eachC;
                    });
                    setSelectedCategories(selectedCGs);
                    const professionalData = doc.data.data.vendorReferences.filter((e) => e).map((e) => e.vendorId._id);
                    const selectedProfs = professionals.map((eachP) => {
                        if (professionalData.includes(eachP.value)) {
                            eachP.isSelected = true;
                            eachP.updatedAt = new Date();
                        };
                        return eachP;
                    });
                    setSelectedProfessionals(selectedProfs);
                });
        }


    }, [testPaperId, categories, professionals]);

    useEffect(() => {
        listTestPaperCategories()
            .then((doc) => {
                setCategories([...doc.data.data].map((e) => {
                    return {
                        label: e.title,
                        value: e._id,
                        isSelected: false
                    }
                }));
            });
        listProfessionals()
            .then((doc) => {
                setProfessionals([...doc.data.data].map((e) => {
                    return {
                        label: e.personalInfo.name,
                        value: e._id,
                        isSelected: false
                    }
                }));
            });
    }, []);

    useEffect(() => {
        setUserInfo(props.data.account.user);
    }, [props.data.account.user]);

    const saveHandler = () => {
        setLoading(true);
        const payload = {
            title: testPaper.title,
            description: testPaper.description,
            ageInfo: testPaper.ageInfo,
            category: JSON.stringify(selectedCategories.filter((e) => e.isSelected).map((e) => e.value)),
            vendorReferences: JSON.stringify(selectedProfessionals.filter((e) => e.isSelected).map((each, key) => {
                return {
                    'order': key + 1,
                    'vendorId': each.value
                };
            })),
        };

        if (userInfo && userInfo.roleInfo.isSuperAdmin) {
            payload.status = checked ? 1 : 2;
        }

        if (selectedFile) {
            payload.imageLink = selectedFile;
        }

        saveTestPaper(testPaperId, payload)
            .then((output) => {
                setLoading(false);
                if (output.data.success) {
                    toast.success('Test is successfully saved!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            }).catch(() => {
                setLoading(false);
            });
    }

    return (<Card>
        <CardContent>
            <Form>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Form.Group controlId="institutionForm.profilePicture">
                            <Form.Label>Test Image</Form.Label>
                            <div className="row">
                                <div className="col-12">
                                    <div className={classes.bigAvatar}>
                                        {preview ? (
                                            <BImage src={preview} thumbnail />
                                        ) : (
                                                <div
                                                    style={{
                                                        height: "100%",
                                                        width: "320px",
                                                        background: "#ccc",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        color: "#f6f6f6",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <span></span>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={classes.profileImageUploaderContainer}>
                                        <div
                                            className={classes.uploadImageButtonFile}
                                            onClick={(e) => {
                                                observed.current.click();
                                            }}
                                        >
                                            <input
                                                ref={observed}
                                                type="file"
                                                style={{
                                                    display: "none",
                                                }}
                                                onChange={onImageChangeHandler}
                                            />
                                            <img
                                                src={toAbsoluteUrl("/media/icons/image-icon.png")}
                                                alt="no-img"
                                            />
                                            <span>Browse Test Image</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Grid>
                    <Grid item xs={8}>
                        {userInfo && userInfo.roleInfo.isSuperAdmin && checked !== null ?
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }} />} label={checked ? 'Active' : 'Inactive'} />
                            </FormGroup>
                            : null}
                        <Form.Group controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={testPaper.title}
                                onChange={onChangeHandler}
                                isInvalid={!!errors.title}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                as="textarea"
                                rows="4"
                                value={testPaper.description}
                                onChange={onChangeHandler}
                                isInvalid={!!errors.description}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.description}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="ageInfo">
                            <Form.Label>Age Info</Form.Label>
                            <Form.Control
                                type="text"
                                name="ageInfo"
                                as="textarea"
                                rows="4"
                                value={testPaper.ageInfo}
                                onChange={onChangeHandler}
                                isInvalid={!!errors.ageInfo}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.ageInfo}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="categories">
                            <Form.Label>Categories</Form.Label>
                            <div style={{ clear: 'both', marginBottom: 16 }}>
                                {selectedCategories.length ? selectedCategories.filter((e) => e.isSelected).map((e) => <Chip style={{ marginLeft: 8 }} label={e.label} variant="outlined" onDelete={() => handleDeleteCategories(e)} />) : null}
                            </div>
                            <Select
                                isSearchable={true}
                                value={''}
                                onChange={(e) => {
                                    setSelectedCategories([...selectedCategories.map((ec) => {
                                        if (ec.value === e.value) {
                                            ec.isSelected = true;
                                        }
                                        return ec;
                                    })]);
                                }}
                                options={selectedCategories.length ? selectedCategories.filter((e) => !e.isSelected) : []}
                            />
                        </Form.Group>
                        <Form.Group controlId="professionals">
                            <Form.Label>Professionals</Form.Label>
                            <div style={{ clear: 'both', marginBottom: 16 }}>
                                {selectedProfessionals.length ? selectedProfessionals.filter((e) => e.isSelected).sort((p, n) => new Date(p.updatedAt) - new Date(n.updatedAt)).map((e) => <Chip style={{ marginLeft: 8 }} label={e.label} variant="outlined" onDelete={() => handleDeleteProfessionals(e)} />) : null}
                            </div>
                            <Select
                                isSearchable={true}
                                value={''}
                                onChange={(e) => {
                                    setSelectedProfessionals([...selectedProfessionals.map((ec) => {
                                        if (ec.value === e.value) {
                                            ec.isSelected = true;
                                            ec.updatedAt = new Date();
                                        }
                                        return ec;
                                    })]);
                                }}
                                options={selectedProfessionals.length ? selectedProfessionals.filter((e) => !e.isSelected) : []}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button
                        disabled={errors.title || errors.description || !selectedCategories.length || !selectedProfessionals.length}
                        variant="primary"
                        style={{
                            marginTop: 48,
                            position: 'relative'
                        }}
                        onClick={() => {
                            saveHandler();
                        }}
                    >
                        {loading && <CircularProgress size={24} style={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12
                        }} />} Update
              </Button>
                </div>
            </Form>
        </CardContent>
    </Card >);
};

const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(
    (theme) => ({
        ...styles(theme),
    }),
    { withTheme: true }
)(EditTestPaper));
