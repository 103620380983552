const qaConfig ={
    status:{
        1: 'Pending',
        2: 'Active', 
        3: 'Rejected'
    },
    answerStatus:{
        1: 'Pending',
        2: 'Active', 
        3: 'Rejected'
    }
}
export default qaConfig;