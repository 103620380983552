import {listCategory} from "../../crud/vendor-category.crud";
import {put, takeLatest} from "redux-saga/effects";
export const actionTypes = {
    VENDOR_CATEGORY_ADDED: "VENDOR_CATEGORY_ADDED",
    VENDOR_CATEGORY_ADDED_SUCCESS: "VENDOR_CATEGORY_ADDED_SUCCESS",
    VENDOR_CATEGORY_UPDATED: "VENDOR_CATEGORY_UPDATED",
    VENDOR_CATEGORY_UPDATED_SUCCESS: "VENDOR_CATEGORY_UPDATED_SUCCESS"
};

const initialAuthState = {
    listData: undefined
};

export const reducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.VENDOR_CATEGORY_ADDED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        case actionTypes.VENDOR_CATEGORY_UPDATED_SUCCESS:
            {
                const {listData} = action.payload;
                return {listData};
            }
        default:
            return state;
    }
};

export const actions = {
    vendorCategoryAdded: ({skip, limit}) => ({
        type: actionTypes.VENDOR_CATEGORY_ADDED,
        payload: {
            skip,
            limit
        }
    }),
    vendorCategoryUpdated: ({skip, limit}) => ({
        type: actionTypes.VENDOR_CATEGORY_UPDATED,
        payload: {
            skip,
            limit
        }
    })
};

export function * saga() {
    yield takeLatest(actionTypes.VENDOR_CATEGORY_ADDED, function * vendorCategoryAdded(action) {
        const {skip, limit} = action.payload;
        const listData = yield listCategory({skip, limit});

        yield put({type: actionTypes.VENDOR_CATEGORY_ADDED_SUCCESS, payload: {
                listData
            }});
    });

    yield takeLatest(actionTypes.VENDOR_CATEGORY_UPDATED, function * vendorCategoryUpdated(action) {
        const {skip, limit} = action.payload;
        const listData = yield listCategory({skip, limit});

        yield put({type: actionTypes.VENDOR_CATEGORY_UPDATED_SUCCESS, payload: {
                listData
            }});
    });
}
