import axios from './axios.interceptor';
import api from './api.util';

export const VENDOR_CATEGORY_URL = `${api}/account/admin/category`;
export const ADD_VENDOR_CATEGORY_URL =`${VENDOR_CATEGORY_URL}/add`;
export const LIST_VENDOR_CATEGORY_URL =`${VENDOR_CATEGORY_URL}/list`;

export function addCategory(icon, title) {
  const formData = new FormData();
  formData.append('icon',icon);
  formData.append('title',title)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  return axios.post(ADD_VENDOR_CATEGORY_URL, formData,config);
}

export function listCategory({skip, limit}) {
  return axios.get(`${LIST_VENDOR_CATEGORY_URL}?skip=${skip}&limit=${limit}`);
}

export function editCategory(categoryId, payload) {
  const formData = new FormData();
  if(payload.icon){
    formData.append('icon',payload.icon);
  }
  formData.append('title',payload.title);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  
  return axios.put(`${VENDOR_CATEGORY_URL}/${categoryId}/update`, formData,config);
}

export function removeCategory(categoryId) {
  return axios.delete(`${VENDOR_CATEGORY_URL}/${categoryId}/remove`);
}